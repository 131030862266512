import React from 'react';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import StoreSettings from '../StoreSettings/StoreSettings';
import StoreGenerateKey from '../StoreGenerateKey/StoreGenerateKey';

export default function StoreSettingsModal({ open, setOpen, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const [showEditKey, setShowEditKey] = React.useState(false);

    const handleEditKeyToggle = (show: boolean) => setShowEditKey(show);
    const handleClose = () => setOpen(false);
    const handleUnmount = () => setShowEditKey(false);

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            disableOutsideClick={showEditKey}
            disableEscapeKeyDown={showEditKey}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            onUnmount={handleUnmount}
            {...props}
        >
            {!showEditKey ? (
                <StoreSettings onClick={handleEditKeyToggle} onClose={handleClose} />
            ) : (
                <StoreGenerateKey onBackClick={handleEditKeyToggle} onClose={handleClose} />
            )}
        </Dialog>
    );
}
