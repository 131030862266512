export enum View {
    Main,
    EmailAdd,
    EmailEdit,
    PhoneAdd,
    PhoneEdit,
    PasswordAdd,
    PasswordEdit,
    Confirm
}

export interface ProfileProps {
    view: View;
    setView: (v: View) => void;
    onClose?: () => void;
}
