import React from 'react';
import { useFloating } from '@floating-ui/react';

export default function useDropdown({
    initialOpen = false,
    open: controlledOpen,
    onOpenChange: setControlledOpen
}: {
    initialOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
} = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const context = useFloating({
        open,
        onOpenChange: setOpen
    });

    return React.useMemo(
        () => ({
            open,
            setOpen,
            ...context
        }),
        [open, setOpen, context]
    );
}
