import React from 'react';
import cn from 'classnames';
import AedIcon from 'assets/images/token-aed.svg';
import EosIcon from 'assets/images/token-eos.svg';
import EtherFilledIcon from 'assets/images/token-ether-color.svg';
import EtherIcon from 'assets/images/token-ether.svg';
import EurcashIcon from 'assets/images/token-eurcash.svg';
import EuroIcon from 'assets/images/token-euro.svg';
import GbpcashIcon from 'assets/images/token-gbpcash.svg';
import InrcashIcon from 'assets/images/token-inrcash.svg';
import JpycashIcon from 'assets/images/token-jpycash.svg';
import RmbcashIcon from 'assets/images/token-rmbcash.svg';
import RubIcon from 'assets/images/pay-rub.svg';
import RubcashIcon from 'assets/images/token-rubcash.svg';
import RublesIcon from 'assets/images/token-rubles.svg';
import RussiaIcon from 'assets/images/flag-russia.svg';
import TronColorIcon from 'assets/images/token-tron-color.svg';
import TronIcon from 'assets/images/token-tron.svg';
import UahcashIcon from 'assets/images/token-uahcash.svg';
import UsdIcon from 'assets/images/pay-dollar-green.svg';
import UsdcashIcon from 'assets/images/token-usdcash.svg';
import UsdtIcon from 'assets/images/token-usdt.svg';
import WorldColorIcon from 'assets/images/pay-world-color.svg';
import WorldIcon from 'assets/images/pay-world.svg';
import styles from './TokenIcon.module.scss';

const tokenList = [
    { name: 'aed', src: AedIcon, alt: 'AED' },
    { name: 'eos', src: EosIcon, alt: 'EOS' },
    { name: 'ether', src: EtherIcon, alt: 'Ether' },
    { name: 'ether-color', src: EtherFilledIcon, alt: 'Ether' },
    { name: 'eurcash', src: EurcashIcon, alt: 'EURCASH' },
    { name: 'eur', src: EuroIcon, alt: 'Euro' },
    { name: 'gbpcash', src: GbpcashIcon, alt: 'GBPCASH' },
    { name: 'inrcash', src: InrcashIcon, alt: 'INRCASH' },
    { name: 'jpycash', src: JpycashIcon, alt: 'JPYCASH' },
    { name: 'rmbcash', src: RmbcashIcon, alt: 'RMBCASH' },
    { name: 'rub', src: RubIcon, alt: 'Rub' },
    { name: 'rubcash', src: RubcashIcon, alt: 'RUBCASH' },
    { name: 'rubles', src: RublesIcon, alt: 'RUBLES' },
    { name: 'russia', src: RussiaIcon, alt: 'Russia' },
    { name: 'tron', src: TronIcon, alt: 'Tron' },
    { name: 'tron-color', src: TronColorIcon, alt: 'Tron' },
    { name: 'uahcash', src: UahcashIcon, alt: 'UAHCASH' },
    { name: 'usd', src: UsdIcon, alt: 'Usd' },
    { name: 'usdcash', src: UsdcashIcon, alt: 'USDCASH' },
    { name: 'usdt', src: UsdtIcon, alt: 'Tether' },
    { name: 'world', src: WorldIcon, alt: 'World' },
    { name: 'world-color', src: WorldColorIcon, alt: 'World' },
    { name: '', src: '', alt: '' }
] as const;

type Token = (typeof tokenList)[number];
type TokenName = Token['name'];

interface TokenIconClasses {
    image: string;
    root: string;
}

interface TokenIconProps {
    classes?: Partial<TokenIconClasses>;
    name?: TokenName | string;
    /**
     * @default 16
     */
    size?: number;
}

function findTokenByName(token?: string): Token {
    return (
        tokenList.find((currentToken) => currentToken.name === token?.toLowerCase()) || { name: '', src: '', alt: '' }
    );
}

export default function TokenIcon({ classes, name, size = 16 }: TokenIconProps) {
    const { root, image } = classes ?? {};

    const currentToken = findTokenByName(name);

    return (
        <span className={cn(styles.Root, root)}>
            <img
                className={cn(styles.Image, image)}
                src={currentToken.src}
                alt={currentToken.alt}
                width={size}
                height={size}
            />
        </span>
    );
}
