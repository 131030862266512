import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    DateTime: { input: any; output: any };
    Decimal: { input: any; output: any };
};

export type AbortTransferOutput =
    | AbortTransferSuccess
    | ConfirmationFailedError
    | TransferNotFoundError
    | WrongTransferStatusError
    | WrongTransferTypeError;

export type AbortTransferSuccess = {
    __typename: 'AbortTransferSuccess';
    transferId: Scalars['String']['output'];
};

export type ActionLockedError = {
    __typename: 'ActionLockedError';
    cooldown?: Maybe<Scalars['DateTime']['output']>;
    errorMessage: Scalars['String']['output'];
};

export type ActionNode = {
    __typename: 'ActionNode';
    blockNumber?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isIrreversible: Scalars['Boolean']['output'];
    isReversed: Scalars['Boolean']['output'];
    txid: Scalars['String']['output'];
};

export type ActivateFiatMethodInput = {
    fiatMethodId: Scalars['String']['input'];
};

export type ActivateFiatMethodOutput = FiatMethodNotFoundError | UpdateFiatMethodSuccess;

export type AmountNode = {
    __typename: 'AmountNode';
    fee: Scalars['String']['output'];
    receive: Scalars['String']['output'];
    send: Scalars['String']['output'];
};

export enum AuthStep {
    CodeVerification = 'CODE_VERIFICATION',
    Finish = 'FINISH'
}

export type BlockchainBalancesNode = {
    __typename: 'BlockchainBalancesNode';
    blockchain?: Maybe<Scalars['String']['output']>;
    count?: Maybe<Scalars['Int']['output']>;
    nativeAmount?: Maybe<Scalars['String']['output']>;
    usdtAmount?: Maybe<Scalars['String']['output']>;
    wallets?: Maybe<Array<Maybe<WalletBalancesNode>>>;
};

export type BuyerBillNode = {
    __typename: 'BuyerBillNode';
    averageValue: Scalars['String']['output'];
    chartPoints?: Maybe<Array<Maybe<ChartNode>>>;
    currency?: Maybe<CurrencyNode>;
    currencyId: Scalars['String']['output'];
    dateFrom?: Maybe<Scalars['DateTime']['output']>;
    dateTo?: Maybe<Scalars['DateTime']['output']>;
    prevPeriod?: Maybe<BuyerBillNode>;
};

export type BuyerBillNodeChartPointsArgs = {
    interval?: InputMaybe<ChartInterval>;
};

export type CancelOrderInput = {
    id: Scalars['String']['input'];
};

export type CancelOrderOutput = CancelOrderSuccess | OrderInactiveError | OrderNotFoundError | OrderPaidError;

export type CancelOrderSuccess = {
    __typename: 'CancelOrderSuccess';
    order: OrderNode;
};

export type CantExpireError = {
    __typename: 'CantExpireError';
    errorMessage: Scalars['String']['output'];
};

export type ChangeLoginInput = {
    newEmail?: InputMaybe<Scalars['String']['input']>;
    newPhone?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
};

export type ChangeLoginOutput =
    | ChangeLoginSuccess
    | InvalidCredentialsError
    | InvalidEmailError
    | InvalidPasswordError
    | InvalidPhoneError
    | UserAlreadyExistsError;

export type ChangeLoginSuccess = {
    __typename: 'ChangeLoginSuccess';
    token: Scalars['String']['output'];
};

export type ChangePasswordInput = {
    newPassword: Scalars['String']['input'];
    oldPassword: Scalars['String']['input'];
};

export type ChangePasswordOutput = ChangePasswordSuccess | InvalidCredentialsError | InvalidPasswordError;

export type ChangePasswordSuccess = {
    __typename: 'ChangePasswordSuccess';
    me: UserNode;
};

export type ChangeUserGroupInput = {
    groupId?: InputMaybe<Scalars['String']['input']>;
    userId: Scalars['String']['input'];
};

export type ChangeUserGroupOutput =
    | ChangeUserGroupSuccess
    | GroupNotFoundError
    | UserHasNoPasswordError
    | UserNotFoundError;

export type ChangeUserGroupSuccess = {
    __typename: 'ChangeUserGroupSuccess';
    group?: Maybe<GroupNode>;
    user: UserNode;
};

export enum ChartInterval {
    Day = 'DAY'
}

export type ChartNode = {
    __typename: 'ChartNode';
    dateFrom: Scalars['String']['output'];
    dateTo: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type CheckPasswordInput = {
    password: Scalars['String']['input'];
};

export type CheckPasswordOutput = CheckPasswordSuccess | InvalidCredentialsError;

export type CheckPasswordSuccess = {
    __typename: 'CheckPasswordSuccess';
    me: UserNode;
};

export type CheckTransferOutput =
    | CheckTransferSuccess
    | FiatMethodNotFoundError
    | OrderInactiveError
    | OrderNotFoundError
    | OrderPaidError
    | TransferNotFoundError
    | WrongTransferStatusError
    | WrongTransferTypeError;

export type CheckTransferSuccess = {
    __typename: 'CheckTransferSuccess';
    status: PaymentStatus;
};

export type ClientWithdrawInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    amountToReceive?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    fiatMethodId?: InputMaybe<Scalars['String']['input']>;
    memo?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    recipient?: InputMaybe<Scalars['String']['input']>;
    storeId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientWithdrawOutput =
    | ClientWithdrawSuccess
    | ConfirmationFailedError
    | CurrencyNotFoundError
    | DuplicateTransferError
    | EosAccountNotExistsError
    | ExchangePairNotFoundError
    | FiatMethodNotFoundError
    | FiatTransferNotPossibleError
    | FiatWithdrawalsNotAllowedError
    | ForbiddenWithdrawalReceiverError
    | InsufficientFundsError
    | InvalidParamsError
    | StoreNotFoundError
    | WithdrawDuplicationError
    | WithdrawLimitExceededError
    | WithdrawalAmountTooHighError
    | WithdrawalAmountTooLowError;

export type ClientWithdrawSuccess = {
    __typename: 'ClientWithdrawSuccess';
    me: UserNode;
    transferId: Scalars['String']['output'];
    /** @deprecated OBSOLETE, always empty */
    txid?: Maybe<Scalars['String']['output']>;
};

export type CollectWalletInput = {
    currencyId?: InputMaybe<Scalars['String']['input']>;
    from_: Scalars['String']['input'];
    password: Scalars['String']['input'];
    recipient?: InputMaybe<Scalars['String']['input']>;
};

export type CollectWalletOutput =
    | CollectWalletSuccess
    | ConfirmationFailedError
    | CurrencyNotFoundError
    | InvalidParamsError;

export type CollectWalletSuccess = {
    __typename: 'CollectWalletSuccess';
    txid?: Maybe<Scalars['String']['output']>;
};

export enum CombinedStatusEnum {
    Accepted = 'ACCEPTED',
    Cancelled = 'CANCELLED',
    Created = 'CREATED',
    Declined = 'DECLINED',
    Expired = 'EXPIRED',
    Failed = 'FAILED',
    Frozen = 'FROZEN',
    Initiated = 'INITIATED',
    MoneyReceived = 'MONEY_RECEIVED',
    Paid = 'PAID',
    Pending = 'PENDING',
    Received = 'RECEIVED',
    Retry = 'RETRY',
    Sended = 'SENDED'
}

export type CombinedTransferNode = {
    __typename: 'CombinedTransferNode';
    account?: Maybe<Scalars['String']['output']>;
    amount: AmountNode;
    createdDate: Scalars['DateTime']['output'];
    currency?: Maybe<CurrencyNode>;
    declineReason?: Maybe<DeclineReasonNode>;
    fiatAmount?: Maybe<Scalars['String']['output']>;
    fiatFee?: Maybe<Scalars['String']['output']>;
    fiatMethod?: Maybe<FiatMethodNode>;
    isTest?: Maybe<Scalars['Boolean']['output']>;
    orderId?: Maybe<Scalars['String']['output']>;
    originId?: Maybe<Scalars['String']['output']>;
    paymentDate?: Maybe<Scalars['DateTime']['output']>;
    sequentNumber?: Maybe<Scalars['Int']['output']>;
    status: CombinedStatusEnum;
    storeId?: Maybe<Scalars['String']['output']>;
    storeName?: Maybe<Scalars['String']['output']>;
    transferId?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

export type CombinedTransfersList = {
    __typename: 'CombinedTransfersList';
    pageInfo: PageInfo;
    transfers: Array<Maybe<CombinedTransferNode>>;
};

export enum CombinedTypeEnum {
    AdminDeposit = 'ADMIN_DEPOSIT',
    AdminWithdraw = 'ADMIN_WITHDRAW',
    ClientWithdraw = 'CLIENT_WITHDRAW',
    Conversion = 'CONVERSION',
    Deposit = 'DEPOSIT',
    Give = 'GIVE',
    OrderPayment = 'ORDER_PAYMENT',
    Payback = 'PAYBACK',
    Take = 'TAKE',
    TestOrderPayment = 'TEST_ORDER_PAYMENT',
    Unknown = 'UNKNOWN',
    Withdraw = 'WITHDRAW'
}

export type ConfirmPasswordResetInput = {
    password: Scalars['String']['input'];
};

export type ConfirmPasswordResetOutput =
    | ConfirmPasswordResetSuccess
    | InvalidJwtError
    | InvalidPasswordError
    | InvalidVerifyCodeError
    | UserNotFoundError;

export type ConfirmPasswordResetSuccess = {
    __typename: 'ConfirmPasswordResetSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type ConfirmationFailedError = {
    __typename: 'ConfirmationFailedError';
    errorMessage: Scalars['String']['output'];
};

export type ConnectByWalletInput = {
    walletName: WalletName;
};

export type ConnectByWalletOutput = ConnectByWalletSuccess | DeeplinkNotGeneratedError;

export type ConnectByWalletSuccess = {
    __typename: 'ConnectByWalletSuccess';
    deepLink?: Maybe<Scalars['String']['output']>;
    qrData?: Maybe<Scalars['String']['output']>;
    verificationCode: Scalars['String']['output'];
};

export type ConnectShopifyInput = {
    accessToken: Scalars['String']['input'];
    clientSecret: Scalars['String']['input'];
    currency?: InputMaybe<Scalars['String']['input']>;
    shopName: Scalars['String']['input'];
    storeId: Scalars['String']['input'];
};

export type ConnectShopifyOutput =
    | ConnectShopifySuccess
    | InvalidCredentialsError
    | InvalidParamsError
    | ShopifyIntegrationExistsError
    | ShopifyPermissionRequiredError;

export type ConnectShopifySuccess = {
    __typename: 'ConnectShopifySuccess';
    integration: ShopifyIntegrationNode;
};

export type ConversionInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    toCurrencyId?: InputMaybe<Scalars['String']['input']>;
};

export type ConversionOutput =
    | ConfirmationFailedError
    | ConversionsNotAllowedError
    | CurrencyNotFoundError
    | InsufficientFundsError
    | InvalidParamsError
    | WithdrawDuplicationError
    | WithdrawSuccess;

export type ConversionsNotAllowedError = {
    __typename: 'ConversionsNotAllowedError';
    errorMessage: Scalars['String']['output'];
};

export type CoreBalanceNode = {
    __typename: 'CoreBalanceNode';
    amount: Scalars['String']['output'];
    currency: CurrencyNode;
};

export type CoreWithdrawInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    from_?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    recipient?: InputMaybe<Scalars['String']['input']>;
};

export type CoreWithdrawOutput =
    | ConfirmationFailedError
    | CurrencyNotFoundError
    | EosAccountNotExistsError
    | FiatTransferNotPossibleError
    | ForbiddenWithdrawalReceiverError
    | InvalidParamsError
    | WithdrawDuplicationError
    | WithdrawSuccess;

export type CreateFeeInput = {
    currencyId?: InputMaybe<Scalars['String']['input']>;
    direction: Direction;
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
    feeType: FeeType;
    fiatMethodId?: InputMaybe<Scalars['String']['input']>;
    isActive: Scalars['Boolean']['input'];
    storeId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFeeOutput =
    | CreateFeeSuccess
    | CurrencyNotFoundError
    | FiatMethodNotFoundError
    | InvalidParamsError
    | StoreNotFoundError
    | UserNotFoundError;

export type CreateFeeSuccess = {
    __typename: 'CreateFeeSuccess';
    fee?: Maybe<CustomFeeNode>;
};

export type CreateFiatBlackListInput = {
    bankName?: InputMaybe<Scalars['String']['input']>;
    bin?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    fiatMethodId: Scalars['String']['input'];
    isWhitelist?: InputMaybe<Scalars['Boolean']['input']>;
    paymentSystem?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFiatBlackListOutput = CreateFiatBlackListSuccess | FiatMethodNotFoundError | InvalidParamsError;

export type CreateFiatBlackListSuccess = {
    __typename: 'CreateFiatBlackListSuccess';
    blacklistEntry?: Maybe<FiatBlackListNode>;
};

export type CreateGroupInput = {
    name: Scalars['String']['input'];
    permissions?: InputMaybe<Array<InputMaybe<Permission>>>;
};

export type CreateGroupOutput = CreateGroupSuccess | NameAlreadyExistsError;

export type CreateGroupSuccess = {
    __typename: 'CreateGroupSuccess';
    group: GroupNode;
};

export type CreateOrderInput = {
    allowedMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
    amountToPay: Scalars['String']['input'];
    currencyId: Scalars['String']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    /** In minutes */
    expirationTime?: InputMaybe<Scalars['Int']['input']>;
    failedPaymentRedirectUrl?: InputMaybe<Scalars['String']['input']>;
    isTest?: InputMaybe<Scalars['Boolean']['input']>;
    merchantOrderId?: InputMaybe<Scalars['String']['input']>;
    responseUrl: Scalars['String']['input'];
    storeId: Scalars['String']['input'];
    successPaymentRedirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderOutput =
    | CreateOrderSuccess
    | CurrencyNotFoundError
    | InvalidParamsError
    | ServerProblemError
    | StoreNotFoundError;

export type CreateOrderSuccess = {
    __typename: 'CreateOrderSuccess';
    order: OrderNode;
};

export type CreateOrderSuccessOrderArgs = {
    method?: InputMaybe<PaymentMethod>;
};

export type CreateStoreInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    password?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStoreOutput = ConfirmationFailedError | CreateStoreSuccess | NameAlreadyExistsError;

export type CreateStoreSuccess = {
    __typename: 'CreateStoreSuccess';
    me?: Maybe<UserNode>;
    privateKey: Scalars['String']['output'];
    store: StoreNode;
    storeSecret: Scalars['String']['output'];
};

export type CurrencyNode = {
    __typename: 'CurrencyNode';
    accountCap?: Maybe<Scalars['String']['output']>;
    accountLimit?: Maybe<Scalars['String']['output']>;
    blockchain?: Maybe<Scalars['String']['output']>;
    dayCap?: Maybe<Scalars['String']['output']>;
    dayLimit?: Maybe<Scalars['String']['output']>;
    feeFix?: Maybe<Scalars['String']['output']>;
    feeIn?: Maybe<FeeDataNode>;
    feeOut?: Maybe<FeeDataNode>;
    feePercentage?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isAccountCapEnabled?: Maybe<Scalars['Boolean']['output']>;
    isAccountLimitEnabled?: Maybe<Scalars['Boolean']['output']>;
    isDayCapEnabled?: Maybe<Scalars['Boolean']['output']>;
    isDayLimitEnabled?: Maybe<Scalars['Boolean']['output']>;
    isEnabled?: Maybe<Scalars['Boolean']['output']>;
    isFeeEnabled?: Maybe<Scalars['Boolean']['output']>;
    isIncomeEnabled?: Maybe<Scalars['Boolean']['output']>;
    isOutcomeEnabled?: Maybe<Scalars['Boolean']['output']>;
    isTxnCapEnabled?: Maybe<Scalars['Boolean']['output']>;
    isTxnLimitEnabled?: Maybe<Scalars['Boolean']['output']>;
    isWithdrawFeeEnabled?: Maybe<Scalars['Boolean']['output']>;
    minTransferAmount?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    precission: Scalars['Int']['output'];
    smartContract: Scalars['String']['output'];
    txnCap?: Maybe<Scalars['String']['output']>;
    txnLimit?: Maybe<Scalars['String']['output']>;
    withdrawFeeFix?: Maybe<Scalars['String']['output']>;
    withdrawFeePercentage?: Maybe<Scalars['String']['output']>;
};

export type CurrencyNotFoundError = {
    __typename: 'CurrencyNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type CurrencyPeriodStatsNode = {
    __typename: 'CurrencyPeriodStatsNode';
    fee?: Maybe<Scalars['String']['output']>;
    feeDeposit?: Maybe<Scalars['String']['output']>;
    feeWithdraw?: Maybe<Scalars['String']['output']>;
    income?: Maybe<Scalars['String']['output']>;
    outcome?: Maybe<Scalars['String']['output']>;
};

export type CurrencyStatsNode = {
    __typename: 'CurrencyStatsNode';
    currency?: Maybe<CurrencyNode>;
    day?: Maybe<CurrencyPeriodStatsNode>;
    month?: Maybe<CurrencyPeriodStatsNode>;
    week?: Maybe<CurrencyPeriodStatsNode>;
};

export type CustomFeeNode = {
    __typename: 'CustomFeeNode';
    currencyId?: Maybe<Scalars['String']['output']>;
    direction: Direction;
    feeFix?: Maybe<Scalars['String']['output']>;
    feePercentage?: Maybe<Scalars['String']['output']>;
    feeType: FeeType;
    fiatMethodId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive: Scalars['Boolean']['output'];
    priority: Scalars['Int']['output'];
    storeId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

export type DeactivateFiatMethodInput = {
    direction: Direction;
    location: FiatMethodLocation;
};

export type DeactivateFiatMethodOutput = DeactivateFiatMethodSuccess;

export type DeactivateFiatMethodSuccess = {
    __typename: 'DeactivateFiatMethodSuccess';
    direction?: Maybe<Direction>;
    location?: Maybe<FiatMethodLocation>;
};

export type DeclineReasonNode = {
    __typename: 'DeclineReasonNode';
    code?: Maybe<Scalars['String']['output']>;
    message?: Maybe<MultiLanguageMessage>;
};

export type DeeplinkNode = {
    __typename: 'DeeplinkNode';
    link?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
};

export type DeeplinkNotGeneratedError = {
    __typename: 'DeeplinkNotGeneratedError';
    errorMessage: Scalars['String']['output'];
};

export type DeleteFeeInput = {
    id: Scalars['String']['input'];
};

export type DeleteFeeOutput = DeleteFeeSuccess | FeeEntryNotFoundError;

export type DeleteFeeSuccess = {
    __typename: 'DeleteFeeSuccess';
    success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteFiatBlackListInput = {
    id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteFiatBlackListOutput = DeleteFiatBlackListSuccess | FiatBlackListEntryNotFoundError;

export type DeleteFiatBlackListSuccess = {
    __typename: 'DeleteFiatBlackListSuccess';
    success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteGroupInput = {
    id: Scalars['String']['input'];
};

export type DeleteGroupOutput = DeleteGroupSuccess | GroupNotFoundError;

export type DeleteGroupSuccess = {
    __typename: 'DeleteGroupSuccess';
    id?: Maybe<Scalars['String']['output']>;
};

export type DeleteShopifyIntegrationOutput = DeleteShopifyIntegrationSuccess | ShopifyIntegrationNotFoundError;

export type DeleteShopifyIntegrationSuccess = {
    __typename: 'DeleteShopifyIntegrationSuccess';
    success: Scalars['Boolean']['output'];
};

export type DepositInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    currencyId: Scalars['String']['input'];
    /** In minutes */
    expirationTime?: InputMaybe<Scalars['Int']['input']>;
};

export type DepositLimitExceededError = {
    __typename: 'DepositLimitExceededError';
    errorMessage: Scalars['String']['output'];
};

export type DepositOutput =
    | CurrencyNotFoundError
    | DepositLimitExceededError
    | DepositParams
    | InvalidParamsError
    | ServerProblemError
    | StoreNotFoundError;

export type DepositParams = {
    __typename: 'DepositParams';
    /** @deprecated This field is no more used */
    amount?: Maybe<AmountNode>;
    currencyId: Scalars['String']['output'];
    /** @deprecated Use 'paymentMethods' field instead */
    deeplink: Scalars['String']['output'];
    /** @deprecated Use 'paymentMethods' field instead */
    deeplinkMlnk?: Maybe<Scalars['String']['output']>;
    deeplinks?: Maybe<Array<Maybe<DeeplinkNode>>>;
    expirationDate?: Maybe<Scalars['DateTime']['output']>;
    memo: Scalars['String']['output'];
    /** JSON in valid for payment systems form */
    metaInfo: Scalars['String']['output'];
    receiver: Scalars['String']['output'];
    requisiteId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

export enum Direction {
    In = 'IN',
    Out = 'OUT'
}

export type DuplicateTransferError = {
    __typename: 'DuplicateTransferError';
    errorMessage: Scalars['String']['output'];
};

export type EosAccountAlreadyTakenError = {
    __typename: 'EosAccountAlreadyTakenError';
    errorMessage: Scalars['String']['output'];
};

export type EosAccountNotExistsError = {
    __typename: 'EosAccountNotExistsError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangePairNotFoundError = {
    __typename: 'ExchangePairNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangeRateNode = {
    __typename: 'ExchangeRateNode';
    currency: Scalars['String']['output'];
    currencyName: Scalars['String']['output'];
    fiatMethod: Scalars['String']['output'];
    rate: Scalars['Decimal']['output'];
};

export type FailTransferOutput =
    | ConfirmationFailedError
    | FailTransferSuccess
    | TransferNotFoundError
    | WrongTransferStatusError
    | WrongTransferTypeError;

export type FailTransferSuccess = {
    __typename: 'FailTransferSuccess';
    transferId: Scalars['String']['output'];
};

export type FeeDataNode = {
    __typename: 'FeeDataNode';
    feeFix?: Maybe<Scalars['String']['output']>;
    feePercentage?: Maybe<Scalars['String']['output']>;
};

export type FeeEntryNotFoundError = {
    __typename: 'FeeEntryNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export enum FeeLimitBlockchain {
    Ether = 'ETHER',
    Tron = 'TRON'
}

export type FeeLimitNode = {
    __typename: 'FeeLimitNode';
    feeLimit?: Maybe<Scalars['String']['output']>;
};

export type FeeLimitsNode = {
    __typename: 'FeeLimitsNode';
    ether?: Maybe<FeeLimitNode>;
    tron?: Maybe<FeeLimitNode>;
};

export enum FeeType {
    Crypto = 'CRYPTO',
    Fiat = 'FIAT'
}

export type FiatBlackListEntryNotFoundError = {
    __typename: 'FiatBlackListEntryNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type FiatBlackListNode = {
    __typename: 'FiatBlackListNode';
    bankName?: Maybe<Scalars['String']['output']>;
    bin?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    fiatMethodId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    isWhitelist?: Maybe<Scalars['Boolean']['output']>;
    paymentSystem?: Maybe<Scalars['String']['output']>;
    priority?: Maybe<Scalars['Int']['output']>;
};

export enum FiatMethodLocation {
    RusPay = 'RUS_PAY',
    WorldPay = 'WORLD_PAY'
}

export type FiatMethodNode = {
    __typename: 'FiatMethodNode';
    balance?: Maybe<Scalars['String']['output']>;
    currencyName?: Maybe<Scalars['String']['output']>;
    direction?: Maybe<Direction>;
    fee?: Maybe<FeeDataNode>;
    feeFix?: Maybe<Scalars['String']['output']>;
    feePercentage?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive?: Maybe<Scalars['Boolean']['output']>;
    location?: Maybe<FiatMethodLocation>;
    maximalAmount?: Maybe<Scalars['String']['output']>;
    minimalAmount?: Maybe<Scalars['String']['output']>;
    minimalCoin?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    rates?: Maybe<Array<Maybe<ExchangeRateNode>>>;
    requiredExtraFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FiatMethodNotFoundError = {
    __typename: 'FiatMethodNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type FiatMethodPeriodStatsNode = {
    __typename: 'FiatMethodPeriodStatsNode';
    fee?: Maybe<Scalars['String']['output']>;
    feeDeposit?: Maybe<Scalars['String']['output']>;
    feeWithdraw?: Maybe<Scalars['String']['output']>;
    income?: Maybe<Scalars['String']['output']>;
    outcome?: Maybe<Scalars['String']['output']>;
};

export type FiatMethodStatsNode = {
    __typename: 'FiatMethodStatsNode';
    day?: Maybe<FiatMethodPeriodStatsNode>;
    fiatMethod?: Maybe<FiatMethodNode>;
    month?: Maybe<FiatMethodPeriodStatsNode>;
    week?: Maybe<FiatMethodPeriodStatsNode>;
};

export type FiatStatsNode = {
    __typename: 'FiatStatsNode';
    methods?: Maybe<Array<Maybe<FiatMethodStatsNode>>>;
    total?: Maybe<FiatTotalStatsNode>;
};

export type FiatTotalPeriodStatsNode = {
    __typename: 'FiatTotalPeriodStatsNode';
    deposits?: Maybe<Scalars['Int']['output']>;
    paybacks?: Maybe<Scalars['Int']['output']>;
    payments?: Maybe<Scalars['Int']['output']>;
    totalTransfers?: Maybe<Scalars['Int']['output']>;
    withdraws?: Maybe<Scalars['Int']['output']>;
};

export type FiatTotalStatsNode = {
    __typename: 'FiatTotalStatsNode';
    day?: Maybe<FiatTotalPeriodStatsNode>;
    month?: Maybe<FiatTotalPeriodStatsNode>;
    week?: Maybe<FiatTotalPeriodStatsNode>;
};

export type FiatTransferNotPossibleError = {
    __typename: 'FiatTransferNotPossibleError';
    errorMessage: Scalars['String']['output'];
};

export type FiatWithdrawalsNotAllowedError = {
    __typename: 'FiatWithdrawalsNotAllowedError';
    errorMessage: Scalars['String']['output'];
};

export type FinalizeTransferOutput =
    | ConfirmationFailedError
    | FinalizeTransferSuccess
    | TransferNotFoundError
    | WrongTransferStatusError;

export type FinalizeTransferSuccess = {
    __typename: 'FinalizeTransferSuccess';
    transferId: Scalars['String']['output'];
};

export type ForbiddenWithdrawalReceiverError = {
    __typename: 'ForbiddenWithdrawalReceiverError';
    errorMessage: Scalars['String']['output'];
};

export type FundReceipt = {
    __typename: 'FundReceipt';
    chartPoints?: Maybe<Array<Maybe<ChartNode>>>;
    currency?: Maybe<CurrencyNode>;
    currencyId: Scalars['String']['output'];
    dateFrom?: Maybe<Scalars['DateTime']['output']>;
    dateTo?: Maybe<Scalars['DateTime']['output']>;
    total: Scalars['String']['output'];
};

export type FundReceiptChartPointsArgs = {
    interval?: InputMaybe<ChartInterval>;
};

export type GetNewKeyInput = {
    password: Scalars['String']['input'];
    storeId: Scalars['String']['input'];
};

export type GetNewKeyOutput = ConfirmationFailedError | GetNewKeySuccess | StoreNotFoundError;

export type GetNewKeySuccess = {
    __typename: 'GetNewKeySuccess';
    privateKey: Scalars['String']['output'];
    storeSecret: Scalars['String']['output'];
};

export type GiveInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    memo?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    recipient?: InputMaybe<Scalars['String']['input']>;
};

export type GiveOutput =
    | ConfirmationFailedError
    | CurrencyNotFoundError
    | EosAccountNotExistsError
    | ForbiddenWithdrawalReceiverError
    | InsufficientFundsError
    | InvalidParamsError
    | WithdrawDuplicationError
    | WithdrawSuccess;

export type GroupNode = {
    __typename: 'GroupNode';
    id?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    permissions?: Maybe<Array<Maybe<PermissionNode>>>;
    users?: Maybe<Array<Maybe<UserNode>>>;
};

export type GroupNotFoundError = {
    __typename: 'GroupNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export enum GroupSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    Name = 'NAME',
    NameDesc = 'NAME_DESC'
}

export type InsufficientFundsError = {
    __typename: 'InsufficientFundsError';
    errorMessage: Scalars['String']['output'];
    missingAmount?: Maybe<Scalars['String']['output']>;
};

export type InvalidCredentialsError = {
    __typename: 'InvalidCredentialsError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidEmailError = {
    __typename: 'InvalidEmailError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidJwtError = {
    __typename: 'InvalidJWTError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidParamsError = {
    __typename: 'InvalidParamsError';
    errorMessage: Scalars['String']['output'];
    paramName?: Maybe<Scalars['String']['output']>;
};

export type InvalidPasswordError = {
    __typename: 'InvalidPasswordError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidPhoneError = {
    __typename: 'InvalidPhoneError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidVerifyCodeError = {
    __typename: 'InvalidVerifyCodeError';
    errorMessage: Scalars['String']['output'];
    hasResendAttempts: Scalars['Boolean']['output'];
};

export type LoginIsEmptyError = {
    __typename: 'LoginIsEmptyError';
    errorMessage: Scalars['String']['output'];
};

export type MerchantFilterInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
};

export type MultiLanguageMessage = {
    __typename: 'MultiLanguageMessage';
    en?: Maybe<Scalars['String']['output']>;
    ru?: Maybe<Scalars['String']['output']>;
};

export type Mutations = {
    __typename: 'Mutations';
    abortTransfer?: Maybe<AbortTransferOutput>;
    activateFiatMethod?: Maybe<ActivateFiatMethodOutput>;
    cancelOrder?: Maybe<CancelOrderOutput>;
    changeLogin?: Maybe<ChangeLoginOutput>;
    changePassword?: Maybe<ChangePasswordOutput>;
    changeUserGroup?: Maybe<ChangeUserGroupOutput>;
    checkPassword?: Maybe<CheckPasswordOutput>;
    checkTransfer?: Maybe<CheckTransferOutput>;
    clientWithdraw?: Maybe<ClientWithdrawOutput>;
    collectWallet?: Maybe<CollectWalletOutput>;
    confirmPasswordReset?: Maybe<ConfirmPasswordResetOutput>;
    connectByWallet?: Maybe<ConnectByWalletOutput>;
    connectShopify?: Maybe<ConnectShopifyOutput>;
    conversion?: Maybe<ConversionOutput>;
    coreWithdraw?: Maybe<CoreWithdrawOutput>;
    createFee?: Maybe<CreateFeeOutput>;
    createFiatBlackList?: Maybe<CreateFiatBlackListOutput>;
    createGroup?: Maybe<CreateGroupOutput>;
    createOrder?: Maybe<CreateOrderOutput>;
    createStore?: Maybe<CreateStoreOutput>;
    deactivateFiatMethod?: Maybe<DeactivateFiatMethodOutput>;
    deleteFee?: Maybe<DeleteFeeOutput>;
    deleteFiatBlackList?: Maybe<DeleteFiatBlackListOutput>;
    deleteGroup?: Maybe<DeleteGroupOutput>;
    deleteShopifyIntegration?: Maybe<DeleteShopifyIntegrationOutput>;
    deposit?: Maybe<DepositOutput>;
    failTransfer?: Maybe<FailTransferOutput>;
    finalizeTransfer?: Maybe<FinalizeTransferOutput>;
    getNewKey?: Maybe<GetNewKeyOutput>;
    give?: Maybe<GiveOutput>;
    notifyOrderPaid?: Maybe<NotifyOrderPaidOutput>;
    paybackOrder?: Maybe<PaybackOrderOutput>;
    refreshToken?: Maybe<RefreshTokenOutput>;
    reportIncomingTransfers?: Maybe<ReportSuccess>;
    reportMyIncomingTransfers?: Maybe<ReportSuccess>;
    reportMyOutgoingTransfers?: Maybe<ReportSuccess>;
    reportOutgoingTransfers?: Maybe<ReportSuccess>;
    resendCode?: Maybe<ResendCodeOutput>;
    resetPassword?: Maybe<ResetPasswordOutput>;
    retryTransfer?: Maybe<RetryTransferOutput>;
    setExchangeRate?: Maybe<SetExchangeRateOutput>;
    setFee?: Maybe<SetFeeOutput>;
    setFeeLimit?: Maybe<SetFeeLimitOutput>;
    setFiatFee?: Maybe<SetFiatFeeOutput>;
    setLimits?: Maybe<SetLimitsOutput>;
    setWithdrawFee?: Maybe<SetWithdrawFeeOutput>;
    signin?: Maybe<SigninOutput>;
    signup?: Maybe<SignupOutput>;
    switchCore?: Maybe<SwitchCoreOutput>;
    testCancelOrder?: Maybe<TestCancelOrderOutput>;
    testExpire?: Maybe<TestExpireOutput>;
    testPayment?: Maybe<TestPaymentOutput>;
    toggleCurrency?: Maybe<ToggleCurrencyOutput>;
    togglePermission?: Maybe<TogglePermissionOutput>;
    toggleUserFiat?: Maybe<ToggleUserFiatOutput>;
    updateFiatLimits?: Maybe<Output>;
    updateGroup?: Maybe<UpdateGroupOutput>;
    updateShopifyIntegration?: Maybe<UpdateShopifyIntegrationOutput>;
    updateStore?: Maybe<UpdateStoreOutput>;
    validateWithdrawal?: Maybe<WithdrawOutput>;
    verifyCode?: Maybe<VerifyCodeOutput>;
    withdraw?: Maybe<WithdrawOutput>;
    withdrawFunds?: Maybe<WithdrawFundsOutput>;
};

export type MutationsAbortTransferArgs = {
    password: Scalars['String']['input'];
    transferId: Scalars['String']['input'];
};

export type MutationsActivateFiatMethodArgs = {
    input: ActivateFiatMethodInput;
};

export type MutationsCancelOrderArgs = {
    input: CancelOrderInput;
};

export type MutationsChangeLoginArgs = {
    input: ChangeLoginInput;
};

export type MutationsChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationsChangeUserGroupArgs = {
    input: ChangeUserGroupInput;
};

export type MutationsCheckPasswordArgs = {
    input: CheckPasswordInput;
};

export type MutationsCheckTransferArgs = {
    requisiteId?: InputMaybe<Scalars['String']['input']>;
    transferId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsClientWithdrawArgs = {
    input: ClientWithdrawInput;
};

export type MutationsCollectWalletArgs = {
    input: CollectWalletInput;
};

export type MutationsConfirmPasswordResetArgs = {
    input: ConfirmPasswordResetInput;
};

export type MutationsConnectByWalletArgs = {
    input: ConnectByWalletInput;
};

export type MutationsConnectShopifyArgs = {
    input: ConnectShopifyInput;
};

export type MutationsConversionArgs = {
    input: ConversionInput;
};

export type MutationsCoreWithdrawArgs = {
    input: CoreWithdrawInput;
};

export type MutationsCreateFeeArgs = {
    input: CreateFeeInput;
};

export type MutationsCreateFiatBlackListArgs = {
    input: CreateFiatBlackListInput;
};

export type MutationsCreateGroupArgs = {
    input: CreateGroupInput;
};

export type MutationsCreateOrderArgs = {
    input: CreateOrderInput;
};

export type MutationsCreateStoreArgs = {
    input: CreateStoreInput;
};

export type MutationsDeactivateFiatMethodArgs = {
    input: DeactivateFiatMethodInput;
};

export type MutationsDeleteFeeArgs = {
    input: DeleteFeeInput;
};

export type MutationsDeleteFiatBlackListArgs = {
    input: DeleteFiatBlackListInput;
};

export type MutationsDeleteGroupArgs = {
    input: DeleteGroupInput;
};

export type MutationsDeleteShopifyIntegrationArgs = {
    integration_id: Scalars['String']['input'];
};

export type MutationsDepositArgs = {
    input: DepositInput;
};

export type MutationsFailTransferArgs = {
    password: Scalars['String']['input'];
    transferId: Scalars['String']['input'];
};

export type MutationsFinalizeTransferArgs = {
    password: Scalars['String']['input'];
    transferId: Scalars['String']['input'];
};

export type MutationsGetNewKeyArgs = {
    input: GetNewKeyInput;
};

export type MutationsGiveArgs = {
    input: GiveInput;
};

export type MutationsNotifyOrderPaidArgs = {
    requisiteId: Scalars['String']['input'];
};

export type MutationsPaybackOrderArgs = {
    input: PaybackOrderInput;
};

export type MutationsReportIncomingTransfersArgs = {
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type MutationsReportMyIncomingTransfersArgs = {
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type MutationsReportMyOutgoingTransfersArgs = {
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type MutationsReportOutgoingTransfersArgs = {
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type MutationsResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationsRetryTransferArgs = {
    password: Scalars['String']['input'];
    transferId: Scalars['String']['input'];
};

export type MutationsSetExchangeRateArgs = {
    input: SetExchangeRateInput;
};

export type MutationsSetFeeArgs = {
    input: SetFeeInput;
};

export type MutationsSetFeeLimitArgs = {
    input: SetFeeLimitInput;
};

export type MutationsSetFiatFeeArgs = {
    input: SetFiatFeeInput;
};

export type MutationsSetLimitsArgs = {
    input: SetLimitsInput;
};

export type MutationsSetWithdrawFeeArgs = {
    input: SetWithdrawFeeInput;
};

export type MutationsSigninArgs = {
    input: SigninInput;
};

export type MutationsSignupArgs = {
    input: SignupInput;
};

export type MutationsSwitchCoreArgs = {
    input: SwitchCoreInput;
};

export type MutationsTestCancelOrderArgs = {
    input: CancelOrderInput;
};

export type MutationsTestExpireArgs = {
    input: TestExpireInput;
};

export type MutationsTestPaymentArgs = {
    input: TestPaymentInput;
};

export type MutationsToggleCurrencyArgs = {
    input: ToggleCurrencyInput;
};

export type MutationsTogglePermissionArgs = {
    input: PermissionInput;
};

export type MutationsToggleUserFiatArgs = {
    input: ToggleUserFiatInput;
};

export type MutationsUpdateFiatLimitsArgs = {
    input: UpdateFiatLimitsInput;
};

export type MutationsUpdateGroupArgs = {
    input: UpdateGroupInput;
};

export type MutationsUpdateShopifyIntegrationArgs = {
    input: UpdateShopifyIntegrationInput;
};

export type MutationsUpdateStoreArgs = {
    input: UpdateStoreInput;
};

export type MutationsValidateWithdrawalArgs = {
    input: WithdrawInput;
    isCore?: InputMaybe<Scalars['Boolean']['input']>;
    method?: InputMaybe<PaymentMethod>;
};

export type MutationsVerifyCodeArgs = {
    input: VerifyCodeInput;
};

export type MutationsWithdrawArgs = {
    input: WithdrawInput;
};

export type MutationsWithdrawFundsArgs = {
    input: WithdrawFundsInput;
};

export type NameAlreadyExistsError = {
    __typename: 'NameAlreadyExistsError';
    errorMessage: Scalars['String']['output'];
};

export type NoConfirmationAttemptsError = {
    __typename: 'NoConfirmationAttemptsError';
    errorMessage: Scalars['String']['output'];
    timeout: Scalars['DateTime']['output'];
};

export type NoResendAttemptsError = {
    __typename: 'NoResendAttemptsError';
    errorMessage: Scalars['String']['output'];
    timeout: Scalars['DateTime']['output'];
};

export type NotifyOrderPaidOutput =
    | ActionLockedError
    | CheckTransferSuccess
    | FiatMethodNotFoundError
    | OrderInactiveError
    | OrderNotFoundError
    | OrderPaidError;

export type OrderFilterInput = {
    createdDate?: InputMaybe<RangeDateFilterInput>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    idOrSequentOrSender?: InputMaybe<Scalars['String']['input']>;
    paymentDate?: InputMaybe<RangeDateFilterInput>;
    receivedAmount?: InputMaybe<RangeMoneyFilterInput>;
    sender?: InputMaybe<Scalars['String']['input']>;
    sequentNumber?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<OrderStatus>;
};

export type OrderInactiveError = {
    __typename: 'OrderInactiveError';
    errorMessage: Scalars['String']['output'];
};

export type OrderNode = {
    __typename: 'OrderNode';
    /** @deprecated Use `payment_options` field */
    allowedMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
    amount: AmountNode;
    cancellationDate?: Maybe<Scalars['DateTime']['output']>;
    created: Scalars['DateTime']['output'];
    currency: CurrencyNode;
    currencyId: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    expirationDate: Scalars['DateTime']['output'];
    failedPaymentRedirectUrl?: Maybe<Scalars['String']['output']>;
    hasPaybacks: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    isTest: Scalars['Boolean']['output'];
    merchantOrderId?: Maybe<Scalars['String']['output']>;
    paymentDate?: Maybe<Scalars['DateTime']['output']>;
    paymentOptions?: Maybe<Array<Maybe<PaymentOptionNode>>>;
    paymentParams?: Maybe<PaymentParams>;
    paymentTransfer?: Maybe<TransferNode>;
    responseUrl: Scalars['String']['output'];
    sequentNumber: Scalars['Int']['output'];
    status: OrderStatus;
    store: StoreNode;
    storeId: Scalars['String']['output'];
    successPaymentRedirectUrl?: Maybe<Scalars['String']['output']>;
    totalPaybackAmount: AmountNode;
    transfers: Array<Maybe<TransferNode>>;
};

export type OrderNotFoundError = {
    __typename: 'OrderNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type OrderNotPaidError = {
    __typename: 'OrderNotPaidError';
    errorMessage: Scalars['String']['output'];
};

export type OrderNotRefundableError = {
    __typename: 'OrderNotRefundableError';
    errorMessage: Scalars['String']['output'];
};

export type OrderPaidError = {
    __typename: 'OrderPaidError';
    errorMessage: Scalars['String']['output'];
};

export type OrderPeriodStatsNode = {
    __typename: 'OrderPeriodStatsNode';
    cancelledOrders?: Maybe<Scalars['Int']['output']>;
    incompleteOrders?: Maybe<Scalars['Int']['output']>;
    paidOrders?: Maybe<Scalars['Int']['output']>;
    totalOrders?: Maybe<Scalars['Int']['output']>;
};

export enum OrderSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    PaymentDate = 'PAYMENT_DATE',
    PaymentDateDesc = 'PAYMENT_DATE_DESC',
    ReceivedAmount = 'RECEIVED_AMOUNT',
    ReceivedAmountDesc = 'RECEIVED_AMOUNT_DESC',
    SequentNumber = 'SEQUENT_NUMBER',
    SequentNumberDesc = 'SEQUENT_NUMBER_DESC'
}

export type OrderStatFilterInput = {
    storeId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type OrderStatsNode = {
    __typename: 'OrderStatsNode';
    day?: Maybe<OrderPeriodStatsNode>;
    month?: Maybe<OrderPeriodStatsNode>;
    week?: Maybe<OrderPeriodStatsNode>;
};

export enum OrderStatus {
    Cancelled = 'CANCELLED',
    Created = 'CREATED',
    Expired = 'EXPIRED',
    Frozen = 'FROZEN',
    MoneyReceived = 'MONEY_RECEIVED',
    Paid = 'PAID'
}

export type OrdersList = {
    __typename: 'OrdersList';
    orders: Array<Maybe<OrderNode>>;
    pageInfo: PageInfo;
};

export type OrdersStatisticNode = {
    __typename: 'OrdersStatisticNode';
    cancellations: Scalars['Int']['output'];
    chartPoints?: Maybe<Array<Maybe<ChartNode>>>;
    dateFrom?: Maybe<Scalars['DateTime']['output']>;
    dateTo?: Maybe<Scalars['DateTime']['output']>;
    expirations: Scalars['Int']['output'];
    payments: Scalars['Int']['output'];
    prevPeriod?: Maybe<OrdersStatisticNode>;
    refunds: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
};

export type OrdersStatisticNodeChartPointsArgs = {
    interval?: InputMaybe<ChartInterval>;
};

export type Output = FiatMethodNotFoundError | InvalidParamsError | UpdateFiatLimitsSuccess;

export type PageInfo = {
    __typename: 'PageInfo';
    total?: Maybe<Scalars['Int']['output']>;
    totalFiltered?: Maybe<Scalars['Int']['output']>;
};

export type PaybackOrderInput = {
    amount: Scalars['String']['input'];
    id: Scalars['String']['input'];
};

export type PaybackOrderOutput =
    | CurrencyNotFoundError
    | InsufficientFundsError
    | InvalidParamsError
    | OrderNotFoundError
    | OrderNotPaidError
    | OrderNotRefundableError
    | PaybackOrderSuccess;

export type PaybackOrderSuccess = {
    __typename: 'PaybackOrderSuccess';
    order: OrderNode;
};

export enum PaymentMethod {
    Eos = 'EOS',
    Ether = 'ETHER',
    Give = 'GIVE',
    RusPay = 'RUS_PAY',
    Tron = 'TRON',
    WorldPay = 'WORLD_PAY'
}

export type PaymentOptionNode = {
    __typename: 'PaymentOptionNode';
    currencyName: Scalars['String']['output'];
    method: PaymentMethod;
    methodId: Scalars['String']['output'];
    optionalFields: Array<Maybe<Scalars['String']['output']>>;
    requiredFields: Array<Maybe<Scalars['String']['output']>>;
    serviceName?: Maybe<Scalars['String']['output']>;
};

export type PaymentParams = {
    __typename: 'PaymentParams';
    amount: Scalars['String']['output'];
    deeplinks?: Maybe<Array<Maybe<DeeplinkNode>>>;
    expirationDate: Scalars['DateTime']['output'];
    infoLink?: Maybe<Scalars['String']['output']>;
    memo?: Maybe<Scalars['String']['output']>;
    /** JSON in valid for payment systems form */
    metaInfo?: Maybe<Scalars['String']['output']>;
    paymentLink?: Maybe<Scalars['String']['output']>;
    receiver?: Maybe<Scalars['String']['output']>;
    requiredExtraFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    requisiteId?: Maybe<Scalars['String']['output']>;
    serviceName?: Maybe<Scalars['String']['output']>;
};

export enum PaymentStatus {
    Canceled = 'CANCELED',
    Declined = 'DECLINED',
    Expired = 'EXPIRED',
    Frozen = 'FROZEN',
    Initiated = 'INITIATED',
    MoneyReceived = 'MONEY_RECEIVED',
    NotFound = 'NOT_FOUND',
    Paid = 'PAID',
    Pending = 'PENDING'
}

export type PaymentsSummaryNode = {
    __typename: 'PaymentsSummaryNode';
    currency?: Maybe<CurrencyNode>;
    paymentsAmount?: Maybe<Scalars['String']['output']>;
    paymentsCount?: Maybe<Scalars['Int']['output']>;
};

export enum Permission {
    CanAddAdmins = 'CAN_ADD_ADMINS',
    CanCreateReport = 'CAN_CREATE_REPORT',
    CanDeleteAdmins = 'CAN_DELETE_ADMINS',
    CanDeposit = 'CAN_DEPOSIT',
    CanEditAdmins = 'CAN_EDIT_ADMINS',
    CanEditCommissions = 'CAN_EDIT_COMMISSIONS',
    CanEditLimits = 'CAN_EDIT_LIMITS',
    CanEditRates = 'CAN_EDIT_RATES',
    CanFailTransfers = 'CAN_FAIL_TRANSFERS',
    CanRestartOrAbortTransfers = 'CAN_RESTART_OR_ABORT_TRANSFERS',
    CanShutDown = 'CAN_SHUT_DOWN',
    CanToggleCurrencies = 'CAN_TOGGLE_CURRENCIES',
    CanToggleFiat = 'CAN_TOGGLE_FIAT',
    CanViewAdmins = 'CAN_VIEW_ADMINS',
    CanViewBalancesAndResources = 'CAN_VIEW_BALANCES_AND_RESOURCES',
    CanViewDashboard = 'CAN_VIEW_DASHBOARD',
    CanViewShopInfo = 'CAN_VIEW_SHOP_INFO',
    CanViewTransactions = 'CAN_VIEW_TRANSACTIONS',
    CanViewUserInfo = 'CAN_VIEW_USER_INFO',
    CanWithdraw = 'CAN_WITHDRAW',
    CanWithdrawFiat = 'CAN_WITHDRAW_FIAT'
}

export type PermissionInput = {
    groupId: Scalars['String']['input'];
    permission: Permission;
    toggle: Scalars['Boolean']['input'];
};

export type PermissionNode = {
    __typename: 'PermissionNode';
    name?: Maybe<Scalars['String']['output']>;
};

export type PermissionNotFoundError = {
    __typename: 'PermissionNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type Query = {
    __typename: 'Query';
    allTransfers?: Maybe<CombinedTransfersList>;
    coreBalances?: Maybe<Array<Maybe<CoreBalanceNode>>>;
    coreDepositParams?: Maybe<DepositParams>;
    coreFiatBalances?: Maybe<Scalars['Decimal']['output']>;
    coreResources?: Maybe<ResourcesNode>;
    coreWallets?: Maybe<BlockchainBalancesNode>;
    currencies?: Maybe<Array<Maybe<CurrencyNode>>>;
    currency?: Maybe<CurrencyNode>;
    fees?: Maybe<Array<Maybe<CustomFeeNode>>>;
    fiatBlackList?: Maybe<Array<Maybe<FiatBlackListNode>>>;
    fiatMethodStats?: Maybe<FiatStatsNode>;
    fiatMethods?: Maybe<Array<Maybe<FiatMethodNode>>>;
    group?: Maybe<GroupNode>;
    groups?: Maybe<Array<Maybe<GroupNode>>>;
    incomeLog?: Maybe<CombinedTransfersList>;
    isAccountExists?: Maybe<Scalars['Boolean']['output']>;
    isBalanceEnough?: Maybe<Scalars['Boolean']['output']>;
    me?: Maybe<UserNode>;
    merchants?: Maybe<UserList>;
    merchantsBalances?: Maybe<Array<Maybe<CoreBalanceNode>>>;
    myOrderStats?: Maybe<OrderStatsNode>;
    myShopifyIntegrations?: Maybe<Array<Maybe<ShopifyIntegrationNode>>>;
    myTransferStats?: Maybe<TransferStatsNode>;
    order?: Maybe<OrderNode>;
    orderRedirectUrl?: Maybe<Scalars['String']['output']>;
    orderStats?: Maybe<OrderStatsNode>;
    outcomeLog?: Maybe<CombinedTransfersList>;
    settings?: Maybe<SettingsNode>;
    shopifyIntegration?: Maybe<ShopifyIntegrationNode>;
    store?: Maybe<StoreNode>;
    storeStatistic?: Maybe<StoreStatistic>;
    storeStats?: Maybe<StoreStatsNode>;
    stores?: Maybe<StoresList>;
    transfer?: Maybe<TransferNode>;
    transferStats?: Maybe<TransferStatsNode>;
    userStats?: Maybe<UserStatsNode>;
    users?: Maybe<UserList>;
};

export type QueryAllTransfersArgs = {
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type QueryCoreDepositParamsArgs = {
    currencyId: Scalars['String']['input'];
};

export type QueryCoreWalletsArgs = {
    filters: WalletsFilterInput;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<WalletSort>;
};

export type QueryCurrencyArgs = {
    id: Scalars['String']['input'];
};

export type QueryFiatMethodsArgs = {
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGroupArgs = {
    id: Scalars['String']['input'];
};

export type QueryGroupsArgs = {
    sort?: InputMaybe<Array<InputMaybe<GroupSortEnum>>>;
};

export type QueryIncomeLogArgs = {
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type QueryIsAccountExistsArgs = {
    account: Scalars['String']['input'];
    method: PaymentMethod;
};

export type QueryIsBalanceEnoughArgs = {
    amount: Scalars['String']['input'];
    currencyId: Scalars['String']['input'];
};

export type QueryMerchantsArgs = {
    filters?: InputMaybe<MerchantFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>>>;
};

export type QueryMyOrderStatsArgs = {
    filters?: InputMaybe<OrderStatFilterInput>;
};

export type QueryMyTransferStatsArgs = {
    filters?: InputMaybe<TransferStatFilterInput>;
};

export type QueryOrderArgs = {
    extraFields?: InputMaybe<RequisiteExtraFieldsInput>;
    id: Scalars['String']['input'];
    method?: InputMaybe<PaymentMethod>;
};

export type QueryOrderRedirectUrlArgs = {
    id: Scalars['String']['input'];
};

export type QueryOrderStatsArgs = {
    filters?: InputMaybe<OrderStatFilterInput>;
};

export type QueryOutcomeLogArgs = {
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>>>;
};

export type QueryShopifyIntegrationArgs = {
    id: Scalars['String']['input'];
};

export type QueryStoreArgs = {
    id: Scalars['String']['input'];
};

export type QueryStoreStatisticArgs = {
    dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
    dateTo?: InputMaybe<Scalars['DateTime']['input']>;
    storeId: Scalars['String']['input'];
    userTimezoneShift?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStoresArgs = {
    filters?: InputMaybe<StoreFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<StoreSortEnum>>>;
};

export type QueryTransferArgs = {
    id: Scalars['String']['input'];
};

export type QueryTransferStatsArgs = {
    filters?: InputMaybe<TransferStatFilterInput>;
};

export type QueryUsersArgs = {
    filters?: InputMaybe<UserFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>>>;
};

export type RangeDateFilterInput = {
    from?: InputMaybe<Scalars['DateTime']['input']>;
    to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RangeMoneyFilterInput = {
    from?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshTokenOutput = RefreshTokenSuccess;

export type RefreshTokenSuccess = {
    __typename: 'RefreshTokenSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type ReportSuccess = {
    __typename: 'ReportSuccess';
    type: Scalars['String']['output'];
    uid: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type RequisiteExtraFieldsInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    surname?: InputMaybe<Scalars['String']['input']>;
};

export type ResendCodeOutput = InvalidJwtError | NoResendAttemptsError | ResendCodeSuccess;

export type ResendCodeSuccess = {
    __typename: 'ResendCodeSuccess';
    token: Scalars['String']['output'];
};

export type ResetPasswordInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordOutput = LoginIsEmptyError | NoResendAttemptsError | ResetPasswordSuccess | UserNotFoundError;

export type ResetPasswordSuccess = {
    __typename: 'ResetPasswordSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    token: Scalars['String']['output'];
};

export type ResourcesLimitNode = {
    __typename: 'ResourcesLimitNode';
    available: Scalars['String']['output'];
    max: Scalars['String']['output'];
    percent: Scalars['String']['output'];
};

export type ResourcesNode = {
    __typename: 'ResourcesNode';
    cpuLimit: ResourcesLimitNode;
    netLimit: ResourcesLimitNode;
    ramPercent: Scalars['String']['output'];
    ramQuota: Scalars['Int']['output'];
    ramUsage: Scalars['Int']['output'];
};

export type RetryTransferOutput =
    | ConfirmationFailedError
    | RetryTransferSuccess
    | TransferNotFoundError
    | WrongTransferStatusError
    | WrongTransferTypeError;

export type RetryTransferSuccess = {
    __typename: 'RetryTransferSuccess';
    transferId: Scalars['String']['output'];
};

export type ServerProblemError = {
    __typename: 'ServerProblemError';
    errorMessage: Scalars['String']['output'];
};

export type SetExchangeRateInput = {
    currencyId: Scalars['String']['input'];
    fiatMethodId: Scalars['String']['input'];
    rate: Scalars['String']['input'];
};

export type SetExchangeRateOutput = ExchangePairNotFoundError | InvalidParamsError | UpdateExchangeRateSuccess;

export type SetFeeInput = {
    currencyId: Scalars['String']['input'];
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
    isFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetFeeLimitInput = {
    blockchain: FeeLimitBlockchain;
    feeLimit: Scalars['String']['input'];
};

export type SetFeeLimitOutput = InvalidParamsError | SetFeeLimitSuccess;

export type SetFeeLimitSuccess = {
    __typename: 'SetFeeLimitSuccess';
    blockchain?: Maybe<FeeLimitBlockchain>;
};

export type SetFeeOutput = CurrencyNotFoundError | InvalidParamsError | SetFeeSuccess;

export type SetFeeSuccess = {
    __typename: 'SetFeeSuccess';
    currency?: Maybe<CurrencyNode>;
};

export type SetFiatFeeInput = {
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
    fiatMethodId: Scalars['String']['input'];
};

export type SetFiatFeeOutput = FiatMethodNotFoundError | InvalidParamsError | UpdateFiatMethodSuccess;

export type SetLimitsInput = {
    accountCap?: InputMaybe<Scalars['String']['input']>;
    accountLimit?: InputMaybe<Scalars['String']['input']>;
    currencyId: Scalars['String']['input'];
    dayCap?: InputMaybe<Scalars['String']['input']>;
    dayLimit?: InputMaybe<Scalars['String']['input']>;
    isAccountCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isAccountLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isDayCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isDayLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isTxnCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isTxnLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    txnCap?: InputMaybe<Scalars['String']['input']>;
    txnLimit?: InputMaybe<Scalars['String']['input']>;
};

export type SetLimitsOutput = CurrencyNotFoundError | InvalidParamsError | SetLimitsSuccess;

export type SetLimitsSuccess = {
    __typename: 'SetLimitsSuccess';
    currency?: Maybe<CurrencyNode>;
};

export type SetWithdrawFeeInput = {
    currencyId: Scalars['String']['input'];
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
    isFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetWithdrawFeeOutput = CurrencyNotFoundError | InvalidParamsError | SetWithdrawFeeSuccess;

export type SetWithdrawFeeSuccess = {
    __typename: 'SetWithdrawFeeSuccess';
    currency?: Maybe<CurrencyNode>;
};

export type SettingsNode = {
    __typename: 'SettingsNode';
    coreEnabled?: Maybe<Scalars['Boolean']['output']>;
    feeLimits?: Maybe<FeeLimitsNode>;
    internalTransferFee?: Maybe<Scalars['String']['output']>;
};

export type ShopifyIntegrationExistsError = {
    __typename: 'ShopifyIntegrationExistsError';
    errorMessage: Scalars['String']['output'];
};

export type ShopifyIntegrationNode = {
    __typename: 'ShopifyIntegrationNode';
    created: Scalars['DateTime']['output'];
    currency?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isActive?: Maybe<Scalars['Boolean']['output']>;
    shopName: Scalars['String']['output'];
    store: StoreNode;
};

export type ShopifyIntegrationNotFoundError = {
    __typename: 'ShopifyIntegrationNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type ShopifyPermissionRequiredError = {
    __typename: 'ShopifyPermissionRequiredError';
    errorMessage: Scalars['String']['output'];
    permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SigninInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    phone?: InputMaybe<Scalars['String']['input']>;
};

export type SigninOutput = InvalidCredentialsError | InvalidPhoneError | LoginIsEmptyError | SigninSuccess;

export type SigninSuccess = {
    __typename: 'SigninSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    token: Scalars['String']['output'];
};

export type SignupInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    phone?: InputMaybe<Scalars['String']['input']>;
};

export type SignupOutput =
    | InvalidEmailError
    | InvalidPasswordError
    | InvalidPhoneError
    | LoginIsEmptyError
    | NoResendAttemptsError
    | SignupSuccess
    | UserAlreadyExistsError;

export type SignupSuccess = {
    __typename: 'SignupSuccess';
    me?: Maybe<UserNode>;
    nextStep: AuthStep;
    token: Scalars['String']['output'];
};

export type SimpleGroupNode = {
    __typename: 'SimpleGroupNode';
    id?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
};

export type StoreAdminNode = {
    __typename: 'StoreAdminNode';
    balances: Array<Maybe<UserCurrencyBalanceNode>>;
    id?: Maybe<Scalars['String']['output']>;
};

export type StoreFilterInput = {
    id?: InputMaybe<Scalars['String']['input']>;
    idOrName?: InputMaybe<Scalars['String']['input']>;
    isTest?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type StoreNode = {
    __typename: 'StoreNode';
    admin?: Maybe<StoreAdminNode>;
    created: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isTestMode: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    orders?: Maybe<OrdersList>;
    paymentStats?: Maybe<Array<Maybe<PaymentsSummaryNode>>>;
    status: StoreStatus;
};

export type StoreNodeOrdersArgs = {
    filters?: InputMaybe<OrderFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<OrderSortEnum>>>;
};

export type StoreNotFoundError = {
    __typename: 'StoreNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type StorePeriodStatsNode = {
    __typename: 'StorePeriodStatsNode';
    created?: Maybe<Scalars['Int']['output']>;
    enabled?: Maybe<Scalars['Int']['output']>;
};

export enum StoreSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    Mode = 'MODE',
    ModeDesc = 'MODE_DESC',
    Name = 'NAME',
    NameDesc = 'NAME_DESC'
}

export type StoreStatistic = {
    __typename: 'StoreStatistic';
    avgBuyersBills?: Maybe<Array<Maybe<BuyerBillNode>>>;
    fundsReceipts?: Maybe<Array<Maybe<FundReceipt>>>;
    orders?: Maybe<OrdersStatisticNode>;
    storeId: Scalars['String']['output'];
};

export type StoreStatsNode = {
    __typename: 'StoreStatsNode';
    day?: Maybe<StorePeriodStatsNode>;
    month?: Maybe<StorePeriodStatsNode>;
    week?: Maybe<StorePeriodStatsNode>;
};

export enum StoreStatus {
    Blocked = 'BLOCKED',
    Disabled = 'DISABLED',
    Draft = 'DRAFT',
    Enabled = 'ENABLED',
    Moderation = 'MODERATION',
    Suspent = 'SUSPENT',
    Test = 'TEST'
}

export type StoresList = {
    __typename: 'StoresList';
    pageInfo: PageInfo;
    stores: Array<Maybe<StoreNode>>;
};

export type Subscription = {
    __typename: 'Subscription';
    connectByWallet?: Maybe<WalletConnectiontUpdate>;
    publicOrder?: Maybe<OrderNode>;
    transfer?: Maybe<TransferNode>;
    user?: Maybe<UserNode>;
};

export type SubscriptionConnectByWalletArgs = {
    verificationCode: Scalars['String']['input'];
};

export type SubscriptionPublicOrderArgs = {
    orderId: Scalars['String']['input'];
};

export type SubscriptionTransferArgs = {
    id: Scalars['String']['input'];
};

export type SwitchCoreInput = {
    isEnabled: Scalars['Boolean']['input'];
};

export type SwitchCoreOutput = SwitchCoreSuccess;

export type SwitchCoreSuccess = {
    __typename: 'SwitchCoreSuccess';
    isEnabled: Scalars['Boolean']['output'];
};

export type TestCancelOrderOutput = CancelOrderSuccess | OrderInactiveError | OrderNotFoundError | OrderPaidError;

export type TestExpireInput = {
    id: Scalars['String']['input'];
};

export type TestExpireOutput = CantExpireError | OrderNotFoundError | TestExpireSuccess;

export type TestExpireSuccess = {
    __typename: 'TestExpireSuccess';
    order: OrderNode;
};

export type TestPaymentInput = {
    id: Scalars['String']['input'];
};

export type TestPaymentOutput = OrderNotFoundError | TestPaymentSuccess;

export type TestPaymentSuccess = {
    __typename: 'TestPaymentSuccess';
    order: OrderNode;
};

export type ToggleCurrencyInput = {
    currencyId: Scalars['String']['input'];
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isIncomeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isOutcomeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ToggleCurrencyOutput = CurrencyNotFoundError | ToggleCurrencySuccess;

export type ToggleCurrencySuccess = {
    __typename: 'ToggleCurrencySuccess';
    currency?: Maybe<CurrencyNode>;
};

export type TogglePermissionOutput = GroupNotFoundError | PermissionNotFoundError | TogglePermissionSuccess;

export type TogglePermissionSuccess = {
    __typename: 'TogglePermissionSuccess';
    permission?: Maybe<Scalars['String']['output']>;
};

export type ToggleUserFiatInput = {
    allowConversions?: InputMaybe<Scalars['Boolean']['input']>;
    allowFiatPayments?: InputMaybe<Scalars['Boolean']['input']>;
    allowFiatWithdrawals?: InputMaybe<Scalars['Boolean']['input']>;
    userId: Scalars['String']['input'];
};

export type ToggleUserFiatOutput = InvalidParamsError | ToggleUserFiatSuccess | UserNotFoundError;

export type ToggleUserFiatSuccess = {
    __typename: 'ToggleUserFiatSuccess';
    user?: Maybe<UserNode>;
};

export type TotalStatsNode = {
    __typename: 'TotalStatsNode';
    day?: Maybe<TransferPeriodStatsNode>;
    month?: Maybe<TransferPeriodStatsNode>;
    week?: Maybe<TransferPeriodStatsNode>;
};

export type TransferFilterInput = {
    account?: InputMaybe<Scalars['String']['input']>;
    amountAfterTax?: InputMaybe<RangeMoneyFilterInput>;
    billAmount?: InputMaybe<RangeMoneyFilterInput>;
    createdDate?: InputMaybe<RangeDateFilterInput>;
    currencyId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    currencyName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    fee?: InputMaybe<RangeMoneyFilterInput>;
    fiatAmount?: InputMaybe<RangeMoneyFilterInput>;
    fiatFee?: InputMaybe<RangeMoneyFilterInput>;
    fiatMethodId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    fiatMethodName?: InputMaybe<Scalars['String']['input']>;
    fullAmount?: InputMaybe<RangeMoneyFilterInput>;
    id?: InputMaybe<Scalars['String']['input']>;
    isTest?: InputMaybe<Scalars['Boolean']['input']>;
    paymentDate?: InputMaybe<RangeDateFilterInput>;
    search?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Array<InputMaybe<CombinedStatusEnum>>>;
    storeId?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Array<InputMaybe<CombinedTypeEnum>>>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type TransferNode = {
    __typename: 'TransferNode';
    action?: Maybe<ActionNode>;
    actionId?: Maybe<Scalars['String']['output']>;
    amount: AmountNode;
    created: Scalars['DateTime']['output'];
    currency: CurrencyNode;
    currencyId: Scalars['String']['output'];
    id: Scalars['String']['output'];
    isAutopayback: Scalars['Boolean']['output'];
    isClientWithdraw: Scalars['Boolean']['output'];
    memo?: Maybe<Scalars['String']['output']>;
    receiver: Scalars['String']['output'];
    sender: Scalars['String']['output'];
    status: TxnStatus;
    txnType: TxnType;
};

export type TransferNotFoundError = {
    __typename: 'TransferNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type TransferPeriodStatsNode = {
    __typename: 'TransferPeriodStatsNode';
    clientWithdraws?: Maybe<Scalars['Int']['output']>;
    conversions?: Maybe<Scalars['Int']['output']>;
    deposits?: Maybe<Scalars['Int']['output']>;
    gives?: Maybe<Scalars['Int']['output']>;
    paybacks?: Maybe<Scalars['Int']['output']>;
    payments?: Maybe<Scalars['Int']['output']>;
    takes?: Maybe<Scalars['Int']['output']>;
    totalTransfers?: Maybe<Scalars['Int']['output']>;
    withdraws?: Maybe<Scalars['Int']['output']>;
};

export enum TransferSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    Fee = 'FEE',
    FeeDesc = 'FEE_DESC',
    FullAmount = 'FULL_AMOUNT',
    FullAmountDesc = 'FULL_AMOUNT_DESC',
    PaymentDate = 'PAYMENT_DATE',
    PaymentDateDesc = 'PAYMENT_DATE_DESC',
    ReceiveAmount = 'RECEIVE_AMOUNT',
    ReceiveAmountDesc = 'RECEIVE_AMOUNT_DESC',
    SequentNumber = 'SEQUENT_NUMBER',
    SequentNumberDesc = 'SEQUENT_NUMBER_DESC'
}

export type TransferStatFilterInput = {
    storeId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type TransferStatsNode = {
    __typename: 'TransferStatsNode';
    currencies?: Maybe<Array<Maybe<CurrencyStatsNode>>>;
    total?: Maybe<TotalStatsNode>;
};

export enum TxnStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Expired = 'EXPIRED',
    Failed = 'FAILED',
    Initiated = 'INITIATED',
    Pending = 'PENDING',
    Received = 'RECEIVED',
    Retry = 'RETRY',
    Sended = 'SENDED'
}

export enum TxnType {
    Conversion = 'CONVERSION',
    Deposit = 'DEPOSIT',
    Give = 'GIVE',
    OrderPayment = 'ORDER_PAYMENT',
    Payback = 'PAYBACK',
    Take = 'TAKE',
    Withdraw = 'WITHDRAW'
}

export type UpdateExchangeRateSuccess = {
    __typename: 'UpdateExchangeRateSuccess';
    success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateFiatLimitsInput = {
    fiatMethodId: Scalars['String']['input'];
    maximalAmount?: InputMaybe<Scalars['String']['input']>;
    minimalAmount?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFiatLimitsSuccess = {
    __typename: 'UpdateFiatLimitsSuccess';
    success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateFiatMethodSuccess = {
    __typename: 'UpdateFiatMethodSuccess';
    fiatMethod?: Maybe<FiatMethodNode>;
};

export type UpdateGroupInput = {
    id: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type UpdateGroupOutput = GroupNotFoundError | NameAlreadyExistsError | UpdateGroupSuccess;

export type UpdateGroupSuccess = {
    __typename: 'UpdateGroupSuccess';
    group: GroupNode;
};

export type UpdateShopifyIntegrationInput = {
    accessToken?: InputMaybe<Scalars['String']['input']>;
    clientSecret?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Scalars['String']['input']>;
    integrationId: Scalars['String']['input'];
    shopName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShopifyIntegrationOutput =
    | InvalidCredentialsError
    | InvalidParamsError
    | ShopifyIntegrationExistsError
    | ShopifyIntegrationNotFoundError
    | ShopifyPermissionRequiredError
    | UpdateShopifyIntegrationSuccess;

export type UpdateShopifyIntegrationSuccess = {
    __typename: 'UpdateShopifyIntegrationSuccess';
    integration: ShopifyIntegrationNode;
};

export type UpdateStoreInput = {
    isDefault?: InputMaybe<Scalars['Boolean']['input']>;
    isTestMode?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    storeId: Scalars['String']['input'];
};

export type UpdateStoreOutput = NameAlreadyExistsError | StoreNotFoundError | UpdateStoreSuccess;

export type UpdateStoreSuccess = {
    __typename: 'UpdateStoreSuccess';
    me?: Maybe<UserNode>;
    store: StoreNode;
};

export type UserAlreadyExistsError = {
    __typename: 'UserAlreadyExistsError';
    errorMessage: Scalars['String']['output'];
};

export type UserCurrencyBalanceNode = {
    __typename: 'UserCurrencyBalanceNode';
    amount: Scalars['String']['output'];
    awaitedIncome: Scalars['String']['output'];
    currencies?: Maybe<Array<Maybe<CurrencyNode>>>;
    /** @deprecated Balance could have multiple currencies, use 'currencies' field instead */
    currency: CurrencyNode;
    /** @deprecated Balance could have multiple currencies, use 'currencies' field instead */
    currencyId: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type UserFilterInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    groupId?: InputMaybe<Scalars['String']['input']>;
    hasGroup?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isMerchant?: InputMaybe<Scalars['Boolean']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
};

export type UserHasNoPasswordError = {
    __typename: 'UserHasNoPasswordError';
    errorMessage: Scalars['String']['output'];
};

export type UserList = {
    __typename: 'UserList';
    pageInfo: PageInfo;
    users: Array<Maybe<UserNode>>;
};

export type UserNode = {
    __typename: 'UserNode';
    allowConversions?: Maybe<Scalars['Boolean']['output']>;
    allowFiatPayments?: Maybe<Scalars['Boolean']['output']>;
    allowFiatWithdrawals?: Maybe<Scalars['Boolean']['output']>;
    created: Scalars['DateTime']['output'];
    defaultStore?: Maybe<StoreNode>;
    email?: Maybe<Scalars['String']['output']>;
    eosAccount?: Maybe<Scalars['String']['output']>;
    group?: Maybe<SimpleGroupNode>;
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    myBalance?: Maybe<UserCurrencyBalanceNode>;
    myBalances: Array<Maybe<UserCurrencyBalanceNode>>;
    permissions?: Maybe<Array<Maybe<PermissionNode>>>;
    phone?: Maybe<Scalars['String']['output']>;
    stores?: Maybe<Array<Maybe<StoreNode>>>;
};

export type UserNodeMyBalanceArgs = {
    currencyId: Scalars['String']['input'];
};

export type UserNotFoundError = {
    __typename: 'UserNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type UserPeriodStatsNode = {
    __typename: 'UserPeriodStatsNode';
    activated?: Maybe<Scalars['Int']['output']>;
    registered?: Maybe<Scalars['Int']['output']>;
};

export enum UserSortEnum {
    BalanceRubcash = 'BALANCE_RUBCASH',
    BalanceRubcashDesc = 'BALANCE_RUBCASH_DESC',
    BalanceUsdcash = 'BALANCE_USDCASH',
    BalanceUsdcashDesc = 'BALANCE_USDCASH_DESC',
    BalanceUsdt = 'BALANCE_USDT',
    BalanceUsdtDesc = 'BALANCE_USDT_DESC',
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    EosAccount = 'EOS_ACCOUNT',
    EosAccountDesc = 'EOS_ACCOUNT_DESC'
}

export type UserStatsNode = {
    __typename: 'UserStatsNode';
    day?: Maybe<UserPeriodStatsNode>;
    month?: Maybe<UserPeriodStatsNode>;
    week?: Maybe<UserPeriodStatsNode>;
};

export type VerifyCodeExpiredError = {
    __typename: 'VerifyCodeExpiredError';
    errorMessage: Scalars['String']['output'];
    hasResendAttempts: Scalars['Boolean']['output'];
};

export type VerifyCodeInput = {
    code: Scalars['String']['input'];
};

export type VerifyCodeOutput =
    | InvalidJwtError
    | InvalidVerifyCodeError
    | NoConfirmationAttemptsError
    | UserAlreadyExistsError
    | VerifyCodeExpiredError
    | VerifyCodeSuccess;

export type VerifyCodeSuccess = {
    __typename: 'VerifyCodeSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type WalletBalancesNode = {
    __typename: 'WalletBalancesNode';
    address: Scalars['String']['output'];
    amount: Scalars['String']['output'];
    currency: CurrencyNode;
    isActive: Scalars['Boolean']['output'];
    isIncoming: Scalars['Boolean']['output'];
    isOutgoing: Scalars['Boolean']['output'];
    nativeAmount: Scalars['String']['output'];
    number: Scalars['Int']['output'];
    smartContract: Scalars['String']['output'];
    updated: Scalars['DateTime']['output'];
};

export enum WalletBlockchainEnum {
    Ether = 'ETHER',
    Tron = 'TRON'
}

export type WalletConnectionSuccess = {
    __typename: 'WalletConnectionSuccess';
    me: UserNode;
    token?: Maybe<Scalars['String']['output']>;
};

export type WalletConnectiontUpdate =
    | ConfirmationFailedError
    | EosAccountAlreadyTakenError
    | UserNotFoundError
    | WalletConnectionSuccess
    | WalletLinkOutdatedError;

export type WalletLinkOutdatedError = {
    __typename: 'WalletLinkOutdatedError';
    errorMessage: Scalars['String']['output'];
};

export enum WalletName {
    Anchor = 'ANCHOR',
    Kalyna = 'KALYNA',
    Malinka = 'MALINKA',
    Paycash = 'PAYCASH',
    TokenPoket = 'TOKEN_POKET'
}

export enum WalletSort {
    BalanceNative = 'BALANCE_NATIVE',
    BalanceNativeDesc = 'BALANCE_NATIVE_DESC',
    BalanceTs = 'BALANCE_TS',
    BalanceTsDesc = 'BALANCE_TS_DESC',
    BalanceUsdt = 'BALANCE_USDT',
    BalanceUsdtDesc = 'BALANCE_USDT_DESC',
    Number = 'NUMBER',
    NumberDesc = 'NUMBER_DESC'
}

export enum WalletType {
    Incoming = 'INCOMING',
    Outgoing = 'OUTGOING'
}

export type WalletsFilterInput = {
    blockchain: WalletBlockchainEnum;
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<WalletType>;
};

export type WithdrawDuplicationError = {
    __typename: 'WithdrawDuplicationError';
    errorMessage: Scalars['String']['output'];
};

export type WithdrawFundsInput = {
    amount: Scalars['String']['input'];
    fiatMethodId?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
};

export type WithdrawFundsOutput =
    | ConfirmationFailedError
    | FiatTransferNotPossibleError
    | InvalidParamsError
    | WithdrawDuplicationError
    | WithdrawFundsResult
    | WithdrawalAmountTooHighError
    | WithdrawalAmountTooLowError;

export type WithdrawFundsResult = {
    __typename: 'WithdrawFundsResult';
    reference?: Maybe<Scalars['String']['output']>;
    status: Scalars['String']['output'];
};

export type WithdrawInput = {
    amount?: InputMaybe<Scalars['String']['input']>;
    amountToReceive?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    fiatMethodId?: InputMaybe<Scalars['String']['input']>;
    memo?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    recipient?: InputMaybe<Scalars['String']['input']>;
};

export type WithdrawLimitExceededError = {
    __typename: 'WithdrawLimitExceededError';
    errorMessage: Scalars['String']['output'];
};

export type WithdrawOutput =
    | ConfirmationFailedError
    | CurrencyNotFoundError
    | DuplicateTransferError
    | EosAccountNotExistsError
    | ExchangePairNotFoundError
    | FiatMethodNotFoundError
    | FiatTransferNotPossibleError
    | FiatWithdrawalsNotAllowedError
    | ForbiddenWithdrawalReceiverError
    | InsufficientFundsError
    | InvalidParamsError
    | WithdrawDuplicationError
    | WithdrawLimitExceededError
    | WithdrawSuccess
    | WithdrawalAmountTooHighError
    | WithdrawalAmountTooLowError;

export type WithdrawSuccess = {
    __typename: 'WithdrawSuccess';
    me: UserNode;
    transferId: Scalars['String']['output'];
    /** @deprecated OBSOLETE, always empty */
    txid?: Maybe<Scalars['String']['output']>;
};

export type WithdrawalAmountTooHighError = {
    __typename: 'WithdrawalAmountTooHighError';
    errorMessage: Scalars['String']['output'];
    maximalAmount?: Maybe<Scalars['String']['output']>;
};

export type WithdrawalAmountTooLowError = {
    __typename: 'WithdrawalAmountTooLowError';
    errorMessage: Scalars['String']['output'];
    minimalAmount?: Maybe<Scalars['String']['output']>;
};

export type WrongTransferStatusError = {
    __typename: 'WrongTransferStatusError';
    errorMessage: Scalars['String']['output'];
    status?: Maybe<Scalars['String']['output']>;
};

export type WrongTransferTypeError = {
    __typename: 'WrongTransferTypeError';
    errorMessage: Scalars['String']['output'];
    type?: Maybe<Scalars['String']['output']>;
};

export type CurrencyFragment = {
    __typename: 'CurrencyNode';
    id: string;
    name: string;
    blockchain?: string | null;
    smartContract: string;
    minTransferAmount?: string | null;
    precission: number;
    isIncomeEnabled?: boolean | null;
    isOutcomeEnabled?: boolean | null;
    isDayLimitEnabled?: boolean | null;
    dayLimit?: string | null;
    isAccountLimitEnabled?: boolean | null;
    accountLimit?: string | null;
    isTxnLimitEnabled?: boolean | null;
    txnLimit?: string | null;
    isDayCapEnabled?: boolean | null;
    dayCap?: string | null;
    isAccountCapEnabled?: boolean | null;
    accountCap?: string | null;
    isTxnCapEnabled?: boolean | null;
    txnCap?: string | null;
    isFeeEnabled?: boolean | null;
    feePercentage?: string | null;
    feeFix?: string | null;
    isWithdrawFeeEnabled?: boolean | null;
    withdrawFeeFix?: string | null;
    withdrawFeePercentage?: string | null;
    feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
    feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
};

export type MyBalanceListItemFragment = {
    __typename: 'UserCurrencyBalanceNode';
    amount: string;
    awaitedIncome: string;
    name: string;
    currencies?: Array<{
        __typename: 'CurrencyNode';
        id: string;
        name: string;
        blockchain?: string | null;
        precission: number;
        isIncomeEnabled?: boolean | null;
        isOutcomeEnabled?: boolean | null;
    } | null> | null;
};

export type MyBalancesDataFragment = {
    __typename: 'UserNode';
    myBalances: Array<{
        __typename: 'UserCurrencyBalanceNode';
        amount: string;
        awaitedIncome: string;
        name: string;
        currencies?: Array<{
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            blockchain?: string | null;
            precission: number;
            isIncomeEnabled?: boolean | null;
            isOutcomeEnabled?: boolean | null;
        } | null> | null;
    } | null>;
};

export type OrderDynamicDataFragment = {
    __typename: 'OrderNode';
    id: string;
    status: OrderStatus;
    expirationDate: any;
    paymentDate?: any | null;
    cancellationDate?: any | null;
    hasPaybacks: boolean;
    totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
    paymentTransfer?: {
        __typename: 'TransferNode';
        sender: string;
        id: string;
        action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
    } | null;
    transfers: Array<{
        __typename: 'TransferNode';
        id: string;
        status: TxnStatus;
        sender: string;
        receiver: string;
        created: any;
        txnType: TxnType;
        amount: { __typename: 'AmountNode'; receive: string };
        currency: {
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            smartContract: string;
            precission: number;
            blockchain?: string | null;
        };
    } | null>;
};

export type OrderListItemFragment = {
    __typename: 'OrderNode';
    id: string;
    sequentNumber: number;
    created: any;
    isTest: boolean;
    status: OrderStatus;
    paymentDate?: any | null;
    cancellationDate?: any | null;
    hasPaybacks: boolean;
    amount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
    currency: {
        __typename: 'CurrencyNode';
        id: string;
        name: string;
        precission: number;
        blockchain?: string | null;
        smartContract: string;
        isFeeEnabled?: boolean | null;
        feePercentage?: string | null;
        feeFix?: string | null;
        withdrawFeeFix?: string | null;
        isWithdrawFeeEnabled?: boolean | null;
        withdrawFeePercentage?: string | null;
    };
    paymentTransfer?: { __typename: 'TransferNode'; id: string; sender: string } | null;
    totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
};

export type OrderListItemDynamicDataFragment = {
    __typename: 'OrderNode';
    id: string;
    status: OrderStatus;
    paymentDate?: any | null;
    cancellationDate?: any | null;
    hasPaybacks: boolean;
    paymentTransfer?: { __typename: 'TransferNode'; id: string; sender: string } | null;
    totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
};

export type PaymentOrderDynamicDataFragment = {
    __typename: 'OrderNode';
    id: string;
    status: OrderStatus;
    paymentTransfer?: {
        __typename: 'TransferNode';
        id: string;
        action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
    } | null;
};

export type OrderStatPartsFragment = {
    __typename: 'OrderPeriodStatsNode';
    totalOrders?: number | null;
    incompleteOrders?: number | null;
    cancelledOrders?: number | null;
    paidOrders?: number | null;
};

export type CurrPeriodStatFragment = {
    __typename: 'CurrencyPeriodStatsNode';
    income?: string | null;
    outcome?: string | null;
    fee?: string | null;
    feeDeposit?: string | null;
    feeWithdraw?: string | null;
};

export type CurrStatFragment = {
    __typename: 'CurrencyStatsNode';
    currency?: {
        __typename: 'CurrencyNode';
        id: string;
        name: string;
        blockchain?: string | null;
        smartContract: string;
        minTransferAmount?: string | null;
        precission: number;
        isIncomeEnabled?: boolean | null;
        isOutcomeEnabled?: boolean | null;
        isDayLimitEnabled?: boolean | null;
        dayLimit?: string | null;
        isAccountLimitEnabled?: boolean | null;
        accountLimit?: string | null;
        isTxnLimitEnabled?: boolean | null;
        txnLimit?: string | null;
        isDayCapEnabled?: boolean | null;
        dayCap?: string | null;
        isAccountCapEnabled?: boolean | null;
        accountCap?: string | null;
        isTxnCapEnabled?: boolean | null;
        txnCap?: string | null;
        isFeeEnabled?: boolean | null;
        feePercentage?: string | null;
        feeFix?: string | null;
        isWithdrawFeeEnabled?: boolean | null;
        withdrawFeeFix?: string | null;
        withdrawFeePercentage?: string | null;
        feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
        feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
    } | null;
    day?: {
        __typename: 'CurrencyPeriodStatsNode';
        income?: string | null;
        outcome?: string | null;
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
    } | null;
    week?: {
        __typename: 'CurrencyPeriodStatsNode';
        income?: string | null;
        outcome?: string | null;
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
    } | null;
    month?: {
        __typename: 'CurrencyPeriodStatsNode';
        income?: string | null;
        outcome?: string | null;
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
    } | null;
};

export type TotalStatFragment = {
    __typename: 'TransferPeriodStatsNode';
    totalTransfers?: number | null;
    deposits?: number | null;
    payments?: number | null;
    withdraws?: number | null;
    clientWithdraws?: number | null;
    paybacks?: number | null;
};

export type FiatMethodPeriodStatFragment = {
    __typename: 'FiatMethodPeriodStatsNode';
    fee?: string | null;
    feeDeposit?: string | null;
    feeWithdraw?: string | null;
    income?: string | null;
    outcome?: string | null;
};

export type FiatMethodTotalStatFragment = {
    __typename: 'FiatTotalPeriodStatsNode';
    deposits?: number | null;
    paybacks?: number | null;
    payments?: number | null;
    totalTransfers?: number | null;
    withdraws?: number | null;
};

export type FiatTotalStatFragment = {
    __typename: 'FiatTotalStatsNode';
    day?: {
        __typename: 'FiatTotalPeriodStatsNode';
        deposits?: number | null;
        paybacks?: number | null;
        payments?: number | null;
        totalTransfers?: number | null;
        withdraws?: number | null;
    } | null;
    week?: {
        __typename: 'FiatTotalPeriodStatsNode';
        deposits?: number | null;
        paybacks?: number | null;
        payments?: number | null;
        totalTransfers?: number | null;
        withdraws?: number | null;
    } | null;
    month?: {
        __typename: 'FiatTotalPeriodStatsNode';
        deposits?: number | null;
        paybacks?: number | null;
        payments?: number | null;
        totalTransfers?: number | null;
        withdraws?: number | null;
    } | null;
};

export type FiatPeriodStatFragment = {
    __typename: 'FiatMethodStatsNode';
    fiatMethod?: {
        __typename: 'FiatMethodNode';
        id: string;
        name: string;
        location?: FiatMethodLocation | null;
        direction?: Direction | null;
        isActive?: boolean | null;
    } | null;
    day?: {
        __typename: 'FiatMethodPeriodStatsNode';
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
        income?: string | null;
        outcome?: string | null;
    } | null;
    week?: {
        __typename: 'FiatMethodPeriodStatsNode';
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
        income?: string | null;
        outcome?: string | null;
    } | null;
    month?: {
        __typename: 'FiatMethodPeriodStatsNode';
        fee?: string | null;
        feeDeposit?: string | null;
        feeWithdraw?: string | null;
        income?: string | null;
        outcome?: string | null;
    } | null;
};

export type UserFragment = {
    __typename: 'UserNode';
    id?: string | null;
    eosAccount?: string | null;
    email?: string | null;
    phone?: string | null;
    created: any;
    allowFiatPayments?: boolean | null;
    allowFiatWithdrawals?: boolean | null;
    defaultStore?: { __typename: 'StoreNode'; id: string; created: any; name: string } | null;
    myBalances: Array<{
        __typename: 'UserCurrencyBalanceNode';
        amount: string;
        awaitedIncome: string;
        name: string;
        currencies?: Array<{
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            blockchain?: string | null;
            precission: number;
            isIncomeEnabled?: boolean | null;
            isOutcomeEnabled?: boolean | null;
        } | null> | null;
    } | null>;
};

export type UserStoresFragment = {
    __typename: 'UserNode';
    stores?: Array<{ __typename: 'StoreNode'; id: string; isTestMode: boolean; name: string } | null> | null;
    defaultStore?: { __typename: 'StoreNode'; id: string; created: any; isTestMode: boolean; name: string } | null;
};

export type AbortTransferMutationVariables = Exact<{
    transferId: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type AbortTransferMutation = {
    __typename: 'Mutations';
    abortTransfer?:
        | { __typename: 'AbortTransferSuccess'; transferId: string }
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'TransferNotFoundError'; errorMessage: string }
        | { __typename: 'WrongTransferStatusError'; errorMessage: string }
        | { __typename: 'WrongTransferTypeError'; errorMessage: string }
        | null;
};

export type ActivateFiatMethodMutationVariables = Exact<{
    input: ActivateFiatMethodInput;
}>;

export type ActivateFiatMethodMutation = {
    __typename: 'Mutations';
    activateFiatMethod?:
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdateFiatMethodSuccess';
              fiatMethod?: { __typename: 'FiatMethodNode'; id: string; name: string } | null;
          }
        | null;
};

export type CancelOrderMutationVariables = Exact<{
    input: CancelOrderInput;
}>;

export type CancelOrderMutation = {
    __typename: 'Mutations';
    cancelOrder?:
        | {
              __typename: 'CancelOrderSuccess';
              order: { __typename: 'OrderNode'; id: string; status: OrderStatus; cancellationDate?: any | null };
          }
        | { __typename: 'OrderInactiveError'; errorMessage: string }
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'OrderPaidError'; errorMessage: string }
        | null;
};

export type ChangeLoginMutationVariables = Exact<{
    input: ChangeLoginInput;
}>;

export type ChangeLoginMutation = {
    __typename: 'Mutations';
    changeLogin?:
        | { __typename: 'ChangeLoginSuccess'; token: string }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidEmailError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | { __typename: 'InvalidPhoneError'; errorMessage: string }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | null;
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
    __typename: 'Mutations';
    changePassword?:
        | { __typename: 'ChangePasswordSuccess'; me: { __typename: 'UserNode'; id?: string | null } }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | null;
};

export type ChangeUserGroupMutationVariables = Exact<{
    groupid?: InputMaybe<Scalars['String']['input']>;
    userid: Scalars['String']['input'];
}>;

export type ChangeUserGroupMutation = {
    __typename: 'Mutations';
    changeUserGroup?:
        | {
              __typename: 'ChangeUserGroupSuccess';
              group?: { __typename: 'GroupNode'; id?: string | null; name?: string | null } | null;
          }
        | { __typename: 'GroupNotFoundError'; errorMessage: string }
        | { __typename: 'UserHasNoPasswordError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type CheckPasswordMutationVariables = Exact<{
    input: CheckPasswordInput;
}>;

export type CheckPasswordMutation = {
    __typename: 'Mutations';
    checkPassword?:
        | { __typename: 'CheckPasswordSuccess'; me: { __typename: 'UserNode'; id?: string | null } }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | null;
};

export type ClientWithdrawMutationVariables = Exact<{
    input: ClientWithdrawInput;
}>;

export type ClientWithdrawMutation = {
    __typename: 'Mutations';
    clientWithdraw?:
        | {
              __typename: 'ClientWithdrawSuccess';
              transferId: string;
              me: {
                  __typename: 'UserNode';
                  id?: string | null;
                  myBalances: Array<{
                      __typename: 'UserCurrencyBalanceNode';
                      amount: string;
                      awaitedIncome: string;
                      name: string;
                      currencies?: Array<{
                          __typename: 'CurrencyNode';
                          id: string;
                          name: string;
                          blockchain?: string | null;
                          precission: number;
                          isIncomeEnabled?: boolean | null;
                          isOutcomeEnabled?: boolean | null;
                      } | null> | null;
                  } | null>;
              };
          }
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'DuplicateTransferError'; errorMessage: string }
        | { __typename: 'EosAccountNotExistsError'; errorMessage: string }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'FiatTransferNotPossibleError'; errorMessage: string }
        | { __typename: 'FiatWithdrawalsNotAllowedError'; errorMessage: string }
        | { __typename: 'ForbiddenWithdrawalReceiverError'; errorMessage: string }
        | { __typename: 'InsufficientFundsError'; missingAmount?: string | null; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'StoreNotFoundError'; errorMessage: string }
        | { __typename: 'WithdrawDuplicationError'; errorMessage: string }
        | { __typename: 'WithdrawLimitExceededError'; errorMessage: string }
        | { __typename: 'WithdrawalAmountTooHighError'; errorMessage: string; maximalAmount?: string | null }
        | { __typename: 'WithdrawalAmountTooLowError'; errorMessage: string; minimalAmount?: string | null }
        | null;
};

export type CollectWalletMutationVariables = Exact<{
    input: CollectWalletInput;
}>;

export type CollectWalletMutation = {
    __typename: 'Mutations';
    collectWallet?:
        | { __typename: 'CollectWalletSuccess'; txid?: string | null }
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; paramName?: string | null; errorMessage: string }
        | null;
};

export type ConfirmPasswordResetMutationVariables = Exact<{
    input: ConfirmPasswordResetInput;
}>;

export type ConfirmPasswordResetMutation = {
    __typename: 'Mutations';
    confirmPasswordReset?:
        | { __typename: 'ConfirmPasswordResetSuccess'; token: string }
        | { __typename: 'InvalidJWTError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | { __typename: 'InvalidVerifyCodeError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type ConnectByWalletMutationVariables = Exact<{
    input: ConnectByWalletInput;
}>;

export type ConnectByWalletMutation = {
    __typename: 'Mutations';
    connectByWallet?:
        | {
              __typename: 'ConnectByWalletSuccess';
              qrData?: string | null;
              deepLink?: string | null;
              verificationCode: string;
          }
        | { __typename: 'DeeplinkNotGeneratedError'; errorMessage: string }
        | null;
};

export type ConnectShopifyMutationVariables = Exact<{
    input: ConnectShopifyInput;
}>;

export type ConnectShopifyMutation = {
    __typename: 'Mutations';
    connectShopify?:
        | {
              __typename: 'ConnectShopifySuccess';
              integration: {
                  __typename: 'ShopifyIntegrationNode';
                  id: string;
                  shopName: string;
                  created: any;
                  isActive?: boolean | null;
                  store: { __typename: 'StoreNode'; id: string; name: string };
              };
          }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'ShopifyIntegrationExistsError'; errorMessage: string }
        | {
              __typename: 'ShopifyPermissionRequiredError';
              permissions?: Array<string | null> | null;
              errorMessage: string;
          }
        | null;
};

export type CoreWithdrawMutationVariables = Exact<{
    input: CoreWithdrawInput;
}>;

export type CoreWithdrawMutation = {
    __typename: 'Mutations';
    coreWithdraw?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'EosAccountNotExistsError'; errorMessage: string }
        | { __typename: 'FiatTransferNotPossibleError' }
        | { __typename: 'ForbiddenWithdrawalReceiverError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'WithdrawDuplicationError'; errorMessage: string }
        | { __typename: 'WithdrawSuccess'; transferId: string }
        | null;
};

export type CreateFeeMutationVariables = Exact<{
    input: CreateFeeInput;
}>;

export type CreateFeeMutation = {
    __typename: 'Mutations';
    createFee?:
        | {
              __typename: 'CreateFeeSuccess';
              fee?: { __typename: 'CustomFeeNode'; feeFix?: string | null; feePercentage?: string | null } | null;
          }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'StoreNotFoundError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type CreateGroupMutationVariables = Exact<{
    name: Scalars['String']['input'];
    permissions?: InputMaybe<Array<InputMaybe<Permission>> | InputMaybe<Permission>>;
}>;

export type CreateGroupMutation = {
    __typename: 'Mutations';
    createGroup?:
        | {
              __typename: 'CreateGroupSuccess';
              group: { __typename: 'GroupNode'; id?: string | null; name?: string | null };
          }
        | { __typename: 'NameAlreadyExistsError' }
        | null;
};

export type CreateOrderMutationVariables = Exact<{
    input: CreateOrderInput;
}>;

export type CreateOrderMutation = {
    __typename: 'Mutations';
    createOrder?:
        | {
              __typename: 'CreateOrderSuccess';
              order: {
                  __typename: 'OrderNode';
                  id: string;
                  sequentNumber: number;
                  created: any;
                  isTest: boolean;
                  status: OrderStatus;
                  paymentDate?: any | null;
                  cancellationDate?: any | null;
                  hasPaybacks: boolean;
                  amount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
                  currency: {
                      __typename: 'CurrencyNode';
                      id: string;
                      name: string;
                      precission: number;
                      blockchain?: string | null;
                      smartContract: string;
                      isFeeEnabled?: boolean | null;
                      feePercentage?: string | null;
                      feeFix?: string | null;
                      withdrawFeeFix?: string | null;
                      isWithdrawFeeEnabled?: boolean | null;
                      withdrawFeePercentage?: string | null;
                  };
                  paymentTransfer?: { __typename: 'TransferNode'; id: string; sender: string } | null;
                  totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
              };
          }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; paramName?: string | null; errorMessage: string }
        | { __typename: 'ServerProblemError'; errorMessage: string }
        | { __typename: 'StoreNotFoundError'; errorMessage: string }
        | null;
};

export type CreateStoreMutationVariables = Exact<{
    input: CreateStoreInput;
}>;

export type CreateStoreMutation = {
    __typename: 'Mutations';
    createStore?:
        | { __typename: 'ConfirmationFailedError' }
        | {
              __typename: 'CreateStoreSuccess';
              privateKey: string;
              me?: {
                  __typename: 'UserNode';
                  id?: string | null;
                  stores?: Array<{
                      __typename: 'StoreNode';
                      id: string;
                      isTestMode: boolean;
                      name: string;
                  } | null> | null;
                  defaultStore?: {
                      __typename: 'StoreNode';
                      id: string;
                      created: any;
                      isTestMode: boolean;
                      name: string;
                  } | null;
              } | null;
          }
        | { __typename: 'NameAlreadyExistsError'; errorMessage: string }
        | null;
};

export type DeactivateFiatMethodMutationVariables = Exact<{
    input: DeactivateFiatMethodInput;
}>;

export type DeactivateFiatMethodMutation = {
    __typename: 'Mutations';
    deactivateFiatMethod?: {
        __typename: 'DeactivateFiatMethodSuccess';
        direction?: Direction | null;
        location?: FiatMethodLocation | null;
    } | null;
};

export type DeleteGroupMutationVariables = Exact<{
    input: DeleteGroupInput;
}>;

export type DeleteGroupMutation = {
    __typename: 'Mutations';
    deleteGroup?:
        | { __typename: 'DeleteGroupSuccess'; id?: string | null }
        | { __typename: 'GroupNotFoundError'; errorMessage: string }
        | null;
};

export type DeleteShopifyIntegrationMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteShopifyIntegrationMutation = {
    __typename: 'Mutations';
    deleteShopifyIntegration?:
        | { __typename: 'DeleteShopifyIntegrationSuccess'; success: boolean }
        | { __typename: 'ShopifyIntegrationNotFoundError'; errorMessage: string }
        | null;
};

export type DepositMutationVariables = Exact<{
    input: DepositInput;
}>;

export type DepositMutation = {
    __typename: 'Mutations';
    deposit?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'DepositLimitExceededError'; errorMessage: string }
        | {
              __typename: 'DepositParams';
              memo: string;
              receiver: string;
              metaInfo: string;
              requisiteId?: string | null;
              expirationDate?: any | null;
              currencyId: string;
              userId?: string | null;
              deeplinks?: Array<{ __typename: 'DeeplinkNode'; name: string; link?: string | null } | null> | null;
          }
        | { __typename: 'InvalidParamsError'; paramName?: string | null; errorMessage: string }
        | { __typename: 'ServerProblemError'; errorMessage: string }
        | { __typename: 'StoreNotFoundError'; errorMessage: string }
        | null;
};

export type FailTransferMutationVariables = Exact<{
    transferId: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type FailTransferMutation = {
    __typename: 'Mutations';
    failTransfer?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'FailTransferSuccess'; transferId: string }
        | { __typename: 'TransferNotFoundError'; errorMessage: string }
        | { __typename: 'WrongTransferStatusError'; errorMessage: string }
        | { __typename: 'WrongTransferTypeError'; errorMessage: string }
        | null;
};

export type FinalizeTransferMutationVariables = Exact<{
    transferId: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type FinalizeTransferMutation = {
    __typename: 'Mutations';
    finalizeTransfer?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'FinalizeTransferSuccess'; transferId: string }
        | { __typename: 'TransferNotFoundError'; errorMessage: string }
        | { __typename: 'WrongTransferStatusError'; errorMessage: string }
        | null;
};

export type GetNewKeyMutationVariables = Exact<{
    input: GetNewKeyInput;
}>;

export type GetNewKeyMutation = {
    __typename: 'Mutations';
    getNewKey?:
        | { __typename: 'ConfirmationFailedError' }
        | { __typename: 'GetNewKeySuccess'; privateKey: string; storeSecret: string }
        | { __typename: 'StoreNotFoundError' }
        | null;
};

export type GiveMutationVariables = Exact<{
    input: GiveInput;
}>;

export type GiveMutation = {
    __typename: 'Mutations';
    give?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'EosAccountNotExistsError'; errorMessage: string }
        | { __typename: 'ForbiddenWithdrawalReceiverError'; errorMessage: string }
        | { __typename: 'InsufficientFundsError'; missingAmount?: string | null; errorMessage: string }
        | { __typename: 'InvalidParamsError'; paramName?: string | null; errorMessage: string }
        | { __typename: 'WithdrawDuplicationError'; errorMessage: string }
        | { __typename: 'WithdrawSuccess'; transferId: string; me: { __typename: 'UserNode'; id?: string | null } }
        | null;
};

export type NotifyOrderPaidMutationVariables = Exact<{
    requisiteId: Scalars['String']['input'];
}>;

export type NotifyOrderPaidMutation = {
    __typename: 'Mutations';
    notifyOrderPaid?:
        | { __typename: 'ActionLockedError'; errorMessage: string; cooldown?: any | null }
        | { __typename: 'CheckTransferSuccess'; status: PaymentStatus }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'OrderInactiveError'; errorMessage: string }
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'OrderPaidError'; errorMessage: string }
        | null;
};

export type PaybackOrderMutationVariables = Exact<{
    input: PaybackOrderInput;
}>;

export type PaybackOrderMutation = {
    __typename: 'Mutations';
    paybackOrder?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InsufficientFundsError'; missingAmount?: string | null; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'OrderNotPaidError'; errorMessage: string }
        | { __typename: 'OrderNotRefundableError'; errorMessage: string }
        | {
              __typename: 'PaybackOrderSuccess';
              order: {
                  __typename: 'OrderNode';
                  id: string;
                  hasPaybacks: boolean;
                  totalPaybackAmount: { __typename: 'AmountNode'; send: string; receive: string; fee: string };
              };
          }
        | null;
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = {
    __typename: 'Mutations';
    refreshToken?: { __typename: 'RefreshTokenSuccess'; token: string } | null;
};

export type ReportIncomingTransfersMutationVariables = Exact<{
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<TransferSortEnum> | TransferSortEnum>;
}>;

export type ReportIncomingTransfersMutation = {
    __typename: 'Mutations';
    reportIncomingTransfers?: { __typename: 'ReportSuccess'; uid: string; type: string } | null;
};

export type ReportMyIncomingTransfersMutationVariables = Exact<{
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<TransferSortEnum> | TransferSortEnum>;
}>;

export type ReportMyIncomingTransfersMutation = {
    __typename: 'Mutations';
    reportMyIncomingTransfers?: { __typename: 'ReportSuccess'; uid: string; type: string } | null;
};

export type ReportMyOutgoingTransfersMutationVariables = Exact<{
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<TransferSortEnum> | TransferSortEnum>;
}>;

export type ReportMyOutgoingTransfersMutation = {
    __typename: 'Mutations';
    reportMyOutgoingTransfers?: { __typename: 'ReportSuccess'; uid: string; type: string } | null;
};

export type ReportOutgoingTransfersMutationVariables = Exact<{
    filters: TransferFilterInput;
    sort?: InputMaybe<Array<TransferSortEnum> | TransferSortEnum>;
}>;

export type ReportOutgoingTransfersMutation = {
    __typename: 'Mutations';
    reportOutgoingTransfers?: { __typename: 'ReportSuccess'; uid: string; type: string } | null;
};

export type ResendCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ResendCodeMutation = {
    __typename: 'Mutations';
    resendCode?:
        | { __typename: 'InvalidJWTError'; errorMessage: string }
        | { __typename: 'NoResendAttemptsError'; errorMessage: string }
        | { __typename: 'ResendCodeSuccess'; token: string }
        | null;
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
    __typename: 'Mutations';
    resetPassword?:
        | { __typename: 'LoginIsEmptyError'; errorMessage: string }
        | { __typename: 'NoResendAttemptsError'; errorMessage: string }
        | { __typename: 'ResetPasswordSuccess'; token: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type RetryTransferMutationVariables = Exact<{
    transferId: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type RetryTransferMutation = {
    __typename: 'Mutations';
    retryTransfer?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'RetryTransferSuccess'; transferId: string }
        | { __typename: 'TransferNotFoundError'; errorMessage: string }
        | { __typename: 'WrongTransferStatusError'; errorMessage: string }
        | { __typename: 'WrongTransferTypeError'; errorMessage: string }
        | null;
};

export type SetExchangeRateMutationVariables = Exact<{
    input: SetExchangeRateInput;
}>;

export type SetExchangeRateMutation = {
    __typename: 'Mutations';
    setExchangeRate?:
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'UpdateExchangeRateSuccess'; success?: boolean | null }
        | null;
};

export type SetFeeMutationVariables = Exact<{
    currencyId: Scalars['String']['input'];
    isFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetFeeMutation = {
    __typename: 'Mutations';
    setFee?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | {
              __typename: 'SetFeeSuccess';
              currency?: {
                  __typename: 'CurrencyNode';
                  id: string;
                  isFeeEnabled?: boolean | null;
                  feeFix?: string | null;
                  feePercentage?: string | null;
              } | null;
          }
        | null;
};

export type SetFeeLimitMutationVariables = Exact<{
    blockchain: FeeLimitBlockchain;
    feeLimit: Scalars['String']['input'];
}>;

export type SetFeeLimitMutation = {
    __typename: 'Mutations';
    setFeeLimit?:
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'SetFeeLimitSuccess'; blockchain?: FeeLimitBlockchain | null }
        | null;
};

export type SetFiatFeeMutationVariables = Exact<{
    input: SetFiatFeeInput;
}>;

export type SetFiatFeeMutation = {
    __typename: 'Mutations';
    setFiatFee?:
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'UpdateFiatMethodSuccess'; fiatMethod?: { __typename: 'FiatMethodNode'; id: string } | null }
        | null;
};

export type SetLimitsMutationVariables = Exact<{
    currencyId: Scalars['String']['input'];
    isDayLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    dayLimit?: InputMaybe<Scalars['String']['input']>;
    isAccountLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    accountLimit?: InputMaybe<Scalars['String']['input']>;
    isTxnLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    txnLimit?: InputMaybe<Scalars['String']['input']>;
    accountCap?: InputMaybe<Scalars['String']['input']>;
    dayCap?: InputMaybe<Scalars['String']['input']>;
    isAccountCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isDayCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    isTxnCapEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    txnCap?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetLimitsMutation = {
    __typename: 'Mutations';
    setLimits?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | {
              __typename: 'SetLimitsSuccess';
              currency?: {
                  __typename: 'CurrencyNode';
                  id: string;
                  isDayLimitEnabled?: boolean | null;
                  dayLimit?: string | null;
                  isAccountLimitEnabled?: boolean | null;
                  accountLimit?: string | null;
                  isTxnLimitEnabled?: boolean | null;
                  txnLimit?: string | null;
              } | null;
          }
        | null;
};

export type SetWithdrawFeeMutationVariables = Exact<{
    currencyId: Scalars['String']['input'];
    isFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetWithdrawFeeMutation = {
    __typename: 'Mutations';
    setWithdrawFee?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | {
              __typename: 'SetWithdrawFeeSuccess';
              currency?: {
                  __typename: 'CurrencyNode';
                  id: string;
                  isFeeEnabled?: boolean | null;
                  feeFix?: string | null;
                  feePercentage?: string | null;
              } | null;
          }
        | null;
};

export type SigninMutationVariables = Exact<{
    input: SigninInput;
}>;

export type SigninMutation = {
    __typename: 'Mutations';
    signin?:
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidPhoneError' }
        | { __typename: 'LoginIsEmptyError'; errorMessage: string }
        | {
              __typename: 'SigninSuccess';
              token: string;
              nextStep: AuthStep;
              me?: {
                  __typename: 'UserNode';
                  permissions?: Array<{ __typename: 'PermissionNode'; name?: string | null } | null> | null;
              } | null;
          }
        | null;
};

export type SignupMutationVariables = Exact<{
    input: SignupInput;
}>;

export type SignupMutation = {
    __typename: 'Mutations';
    signup?:
        | { __typename: 'InvalidEmailError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | { __typename: 'InvalidPhoneError'; errorMessage: string }
        | { __typename: 'LoginIsEmptyError'; errorMessage: string }
        | { __typename: 'NoResendAttemptsError'; errorMessage: string }
        | { __typename: 'SignupSuccess'; token: string; nextStep: AuthStep }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | null;
};

export type SwitchCoreMutationVariables = Exact<{
    input: SwitchCoreInput;
}>;

export type SwitchCoreMutation = {
    __typename: 'Mutations';
    switchCore?: { __typename: 'SwitchCoreSuccess'; isEnabled: boolean } | null;
};

export type TestCancelOrderMutationVariables = Exact<{
    input: CancelOrderInput;
}>;

export type TestCancelOrderMutation = {
    __typename: 'Mutations';
    testCancelOrder?:
        | { __typename: 'CancelOrderSuccess'; order: { __typename: 'OrderNode'; id: string; status: OrderStatus } }
        | { __typename: 'OrderInactiveError'; errorMessage: string }
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'OrderPaidError'; errorMessage: string }
        | null;
};

export type TestExpireMutationVariables = Exact<{
    input: TestExpireInput;
}>;

export type TestExpireMutation = {
    __typename: 'Mutations';
    testExpire?:
        | { __typename: 'CantExpireError'; errorMessage: string }
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'TestExpireSuccess'; order: { __typename: 'OrderNode'; id: string; status: OrderStatus } }
        | null;
};

export type TestPaymentMutationVariables = Exact<{
    input: TestPaymentInput;
}>;

export type TestPaymentMutation = {
    __typename: 'Mutations';
    testPayment?:
        | { __typename: 'OrderNotFoundError'; errorMessage: string }
        | { __typename: 'TestPaymentSuccess'; order: { __typename: 'OrderNode'; id: string; status: OrderStatus } }
        | null;
};

export type ToggleCurrencyMutationVariables = Exact<{
    input: ToggleCurrencyInput;
}>;

export type ToggleCurrencyMutation = {
    __typename: 'Mutations';
    toggleCurrency?:
        | { __typename: 'CurrencyNotFoundError' }
        | {
              __typename: 'ToggleCurrencySuccess';
              currency?: {
                  __typename: 'CurrencyNode';
                  id: string;
                  isEnabled?: boolean | null;
                  isIncomeEnabled?: boolean | null;
                  isOutcomeEnabled?: boolean | null;
              } | null;
          }
        | null;
};

export type TogglePermissionMutationVariables = Exact<{
    groupid: Scalars['String']['input'];
    perm: Permission;
    toggle: Scalars['Boolean']['input'];
}>;

export type TogglePermissionMutation = {
    __typename: 'Mutations';
    togglePermission?:
        | { __typename: 'GroupNotFoundError'; errorMessage: string }
        | { __typename: 'PermissionNotFoundError' }
        | { __typename: 'TogglePermissionSuccess'; permission?: string | null }
        | null;
};

export type ToggleUserFiatMutationVariables = Exact<{
    input: ToggleUserFiatInput;
}>;

export type ToggleUserFiatMutation = {
    __typename: 'Mutations';
    toggleUserFiat?:
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'ToggleUserFiatSuccess'; user?: { __typename: 'UserNode'; id?: string | null } | null }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type UpdateFiatLimitsMutationVariables = Exact<{
    input: UpdateFiatLimitsInput;
}>;

export type UpdateFiatLimitsMutation = {
    __typename: 'Mutations';
    updateFiatLimits?:
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'UpdateFiatLimitsSuccess'; success?: boolean | null }
        | null;
};

export type UpdateGroupMutationVariables = Exact<{
    id: Scalars['String']['input'];
    name: Scalars['String']['input'];
}>;

export type UpdateGroupMutation = {
    __typename: 'Mutations';
    updateGroup?:
        | { __typename: 'GroupNotFoundError' }
        | { __typename: 'NameAlreadyExistsError' }
        | {
              __typename: 'UpdateGroupSuccess';
              group: { __typename: 'GroupNode'; id?: string | null; name?: string | null };
          }
        | null;
};

export type UpdateShopifyIntegrationMutationVariables = Exact<{
    input: UpdateShopifyIntegrationInput;
}>;

export type UpdateShopifyIntegrationMutation = {
    __typename: 'Mutations';
    updateShopifyIntegration?:
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; paramName?: string | null; errorMessage: string }
        | { __typename: 'ShopifyIntegrationExistsError' }
        | { __typename: 'ShopifyIntegrationNotFoundError'; errorMessage: string }
        | {
              __typename: 'ShopifyPermissionRequiredError';
              permissions?: Array<string | null> | null;
              errorMessage: string;
          }
        | {
              __typename: 'UpdateShopifyIntegrationSuccess';
              integration: {
                  __typename: 'ShopifyIntegrationNode';
                  id: string;
                  shopName: string;
                  created: any;
                  isActive?: boolean | null;
                  currency?: string | null;
                  store: { __typename: 'StoreNode'; id: string; name: string };
              };
          }
        | null;
};

export type UpdateStoreMutationVariables = Exact<{
    input: UpdateStoreInput;
}>;

export type UpdateStoreMutation = {
    __typename: 'Mutations';
    updateStore?:
        | { __typename: 'NameAlreadyExistsError'; errorMessage: string }
        | { __typename: 'StoreNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdateStoreSuccess';
              me?: {
                  __typename: 'UserNode';
                  id?: string | null;
                  stores?: Array<{
                      __typename: 'StoreNode';
                      id: string;
                      isTestMode: boolean;
                      name: string;
                  } | null> | null;
                  defaultStore?: {
                      __typename: 'StoreNode';
                      id: string;
                      created: any;
                      isTestMode: boolean;
                      name: string;
                  } | null;
              } | null;
          }
        | null;
};

export type ValidateWithdrawMutationVariables = Exact<{
    input: WithdrawInput;
    method?: InputMaybe<PaymentMethod>;
}>;

export type ValidateWithdrawMutation = {
    __typename: 'Mutations';
    validateWithdrawal?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'DuplicateTransferError'; errorMessage: string }
        | { __typename: 'EosAccountNotExistsError'; errorMessage: string }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'FiatTransferNotPossibleError'; errorMessage: string }
        | { __typename: 'FiatWithdrawalsNotAllowedError'; errorMessage: string }
        | { __typename: 'ForbiddenWithdrawalReceiverError'; errorMessage: string }
        | { __typename: 'InsufficientFundsError'; errorMessage: string; missingAmount?: string | null }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'WithdrawDuplicationError'; errorMessage: string }
        | { __typename: 'WithdrawLimitExceededError'; errorMessage: string }
        | { __typename: 'WithdrawSuccess'; transferId: string }
        | { __typename: 'WithdrawalAmountTooHighError'; errorMessage: string; maximalAmount?: string | null }
        | { __typename: 'WithdrawalAmountTooLowError'; errorMessage: string; minimalAmount?: string | null }
        | null;
};

export type VerifyCodeMutationVariables = Exact<{
    input: VerifyCodeInput;
}>;

export type VerifyCodeMutation = {
    __typename: 'Mutations';
    verifyCode?:
        | { __typename: 'InvalidJWTError'; errorMessage: string }
        | { __typename: 'InvalidVerifyCodeError'; errorMessage: string }
        | { __typename: 'NoConfirmationAttemptsError' }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | { __typename: 'VerifyCodeExpiredError'; errorMessage: string }
        | {
              __typename: 'VerifyCodeSuccess';
              token: string;
              me?: { __typename: 'UserNode'; id?: string | null; phone?: string | null; email?: string | null } | null;
          }
        | null;
};

export type WithdrawMutationVariables = Exact<{
    input: WithdrawInput;
}>;

export type WithdrawMutation = {
    __typename: 'Mutations';
    withdraw?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'DuplicateTransferError'; errorMessage: string }
        | { __typename: 'EosAccountNotExistsError'; errorMessage: string }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'FiatTransferNotPossibleError'; errorMessage: string }
        | { __typename: 'FiatWithdrawalsNotAllowedError'; errorMessage: string }
        | { __typename: 'ForbiddenWithdrawalReceiverError'; errorMessage: string }
        | { __typename: 'InsufficientFundsError'; errorMessage: string; missingAmount?: string | null }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'WithdrawDuplicationError'; errorMessage: string }
        | { __typename: 'WithdrawLimitExceededError'; errorMessage: string }
        | {
              __typename: 'WithdrawSuccess';
              transferId: string;
              me: {
                  __typename: 'UserNode';
                  id?: string | null;
                  myBalances: Array<{
                      __typename: 'UserCurrencyBalanceNode';
                      amount: string;
                      awaitedIncome: string;
                      name: string;
                      currencies?: Array<{
                          __typename: 'CurrencyNode';
                          id: string;
                          name: string;
                          blockchain?: string | null;
                          precission: number;
                          isIncomeEnabled?: boolean | null;
                          isOutcomeEnabled?: boolean | null;
                      } | null> | null;
                  } | null>;
              };
          }
        | { __typename: 'WithdrawalAmountTooHighError'; errorMessage: string; maximalAmount?: string | null }
        | { __typename: 'WithdrawalAmountTooLowError'; errorMessage: string; minimalAmount?: string | null }
        | null;
};

export type AcquiringFiatMethodsQueryVariables = Exact<{
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AcquiringFiatMethodsQuery = {
    __typename: 'Query';
    fiatMethods?: Array<{
        __typename: 'FiatMethodNode';
        id: string;
        name: string;
        feeFix?: string | null;
        balance?: string | null;
        location?: FiatMethodLocation | null;
        isActive?: boolean | null;
        direction?: Direction | null;
        currencyName?: string | null;
        feePercentage?: string | null;
        minimalAmount?: string | null;
        maximalAmount?: string | null;
        rates?: Array<{
            __typename: 'ExchangeRateNode';
            currency: string;
            currencyName: string;
            fiatMethod: string;
            rate: any;
        } | null> | null;
    } | null> | null;
};

export type AllTransfersQueryVariables = Exact<{
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>> | InputMaybe<TransferSortEnum>>;
}>;

export type AllTransfersQuery = {
    __typename: 'Query';
    allTransfers?: {
        __typename: 'CombinedTransfersList';
        transfers: Array<{
            __typename: 'CombinedTransferNode';
            transferId?: string | null;
            orderId?: string | null;
            storeId?: string | null;
            status: CombinedStatusEnum;
            sequentNumber?: number | null;
            type?: string | null;
            createdDate: any;
            paymentDate?: any | null;
            account?: string | null;
            isTest?: boolean | null;
            userId?: string | null;
            originId?: string | null;
            fiatFee?: string | null;
            fiatAmount?: string | null;
            storeName?: string | null;
            declineReason?: {
                __typename: 'DeclineReasonNode';
                code?: string | null;
                message?: { __typename: 'MultiLanguageMessage'; ru?: string | null; en?: string | null } | null;
            } | null;
            fiatMethod?: {
                __typename: 'FiatMethodNode';
                id: string;
                name: string;
                currencyName?: string | null;
            } | null;
            currency?: {
                __typename: 'CurrencyNode';
                id: string;
                smartContract: string;
                name: string;
                precission: number;
                blockchain?: string | null;
            } | null;
            amount: { __typename: 'AmountNode'; send: string; receive: string; fee: string };
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type CoreBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type CoreBalancesQuery = {
    __typename: 'Query';
    coreBalances?: Array<{
        __typename: 'CoreBalanceNode';
        amount: string;
        currency: { __typename: 'CurrencyNode'; id: string; name: string };
    } | null> | null;
};

export type CoreDepositParamsQueryVariables = Exact<{
    currencyId: Scalars['String']['input'];
}>;

export type CoreDepositParamsQuery = {
    __typename: 'Query';
    coreDepositParams?: {
        __typename: 'DepositParams';
        memo: string;
        metaInfo: string;
        deeplink: string;
        receiver: string;
    } | null;
};

export type CoreResourcesQueryVariables = Exact<{ [key: string]: never }>;

export type CoreResourcesQuery = {
    __typename: 'Query';
    coreResources?: {
        __typename: 'ResourcesNode';
        ramQuota: number;
        ramUsage: number;
        ramPercent: string;
        netLimit: { __typename: 'ResourcesLimitNode'; available: string; max: string; percent: string };
        cpuLimit: { __typename: 'ResourcesLimitNode'; available: string; max: string; percent: string };
    } | null;
};

export type CoreWalletsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters: WalletsFilterInput;
    sort?: InputMaybe<WalletSort>;
}>;

export type CoreWalletsQuery = {
    __typename: 'Query';
    coreWallets?: {
        __typename: 'BlockchainBalancesNode';
        blockchain?: string | null;
        nativeAmount?: string | null;
        usdtAmount?: string | null;
        count?: number | null;
        wallets?: Array<{
            __typename: 'WalletBalancesNode';
            address: string;
            number: number;
            smartContract: string;
            amount: string;
            nativeAmount: string;
            updated: any;
            isOutgoing: boolean;
            isIncoming: boolean;
            isActive: boolean;
            currency: {
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                smartContract: string;
                minTransferAmount?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
                isDayLimitEnabled?: boolean | null;
                dayLimit?: string | null;
                isAccountLimitEnabled?: boolean | null;
                accountLimit?: string | null;
                isTxnLimitEnabled?: boolean | null;
                txnLimit?: string | null;
                isDayCapEnabled?: boolean | null;
                dayCap?: string | null;
                isAccountCapEnabled?: boolean | null;
                accountCap?: string | null;
                isTxnCapEnabled?: boolean | null;
                txnCap?: string | null;
                isFeeEnabled?: boolean | null;
                feePercentage?: string | null;
                feeFix?: string | null;
                isWithdrawFeeEnabled?: boolean | null;
                withdrawFeeFix?: string | null;
                withdrawFeePercentage?: string | null;
                feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
                feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
            };
        } | null> | null;
    } | null;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
    __typename: 'Query';
    currencies?: Array<{
        __typename: 'CurrencyNode';
        id: string;
        name: string;
        blockchain?: string | null;
        smartContract: string;
        minTransferAmount?: string | null;
        precission: number;
        isIncomeEnabled?: boolean | null;
        isOutcomeEnabled?: boolean | null;
        isDayLimitEnabled?: boolean | null;
        dayLimit?: string | null;
        isAccountLimitEnabled?: boolean | null;
        accountLimit?: string | null;
        isTxnLimitEnabled?: boolean | null;
        txnLimit?: string | null;
        isDayCapEnabled?: boolean | null;
        dayCap?: string | null;
        isAccountCapEnabled?: boolean | null;
        accountCap?: string | null;
        isTxnCapEnabled?: boolean | null;
        txnCap?: string | null;
        isFeeEnabled?: boolean | null;
        feePercentage?: string | null;
        feeFix?: string | null;
        isWithdrawFeeEnabled?: boolean | null;
        withdrawFeeFix?: string | null;
        withdrawFeePercentage?: string | null;
        feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
        feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
    } | null> | null;
};

export type FiatMethodsQueryVariables = Exact<{
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FiatMethodsQuery = {
    __typename: 'Query';
    fiatMethods?: Array<{
        __typename: 'FiatMethodNode';
        id: string;
        name: string;
        direction?: Direction | null;
        location?: FiatMethodLocation | null;
        currencyName?: string | null;
        feePercentage?: string | null;
        feeFix?: string | null;
        isActive?: boolean | null;
        minimalAmount?: string | null;
        maximalAmount?: string | null;
        rates?: Array<{
            __typename: 'ExchangeRateNode';
            currency: string;
            currencyName: string;
            fiatMethod: string;
            rate: any;
        } | null> | null;
        fee?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
    } | null> | null;
};

export type FiatMethodStatsQueryVariables = Exact<{ [key: string]: never }>;

export type FiatMethodStatsQuery = {
    __typename: 'Query';
    fiatMethodStats?: {
        __typename: 'FiatStatsNode';
        methods?: Array<{
            __typename: 'FiatMethodStatsNode';
            fiatMethod?: {
                __typename: 'FiatMethodNode';
                id: string;
                name: string;
                location?: FiatMethodLocation | null;
                direction?: Direction | null;
                isActive?: boolean | null;
            } | null;
            day?: {
                __typename: 'FiatMethodPeriodStatsNode';
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
                income?: string | null;
                outcome?: string | null;
            } | null;
            week?: {
                __typename: 'FiatMethodPeriodStatsNode';
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
                income?: string | null;
                outcome?: string | null;
            } | null;
            month?: {
                __typename: 'FiatMethodPeriodStatsNode';
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
                income?: string | null;
                outcome?: string | null;
            } | null;
        } | null> | null;
        total?: {
            __typename: 'FiatTotalStatsNode';
            day?: {
                __typename: 'FiatTotalPeriodStatsNode';
                deposits?: number | null;
                paybacks?: number | null;
                payments?: number | null;
                totalTransfers?: number | null;
                withdraws?: number | null;
            } | null;
            week?: {
                __typename: 'FiatTotalPeriodStatsNode';
                deposits?: number | null;
                paybacks?: number | null;
                payments?: number | null;
                totalTransfers?: number | null;
                withdraws?: number | null;
            } | null;
            month?: {
                __typename: 'FiatTotalPeriodStatsNode';
                deposits?: number | null;
                paybacks?: number | null;
                payments?: number | null;
                totalTransfers?: number | null;
                withdraws?: number | null;
            } | null;
        } | null;
    } | null;
};

export type GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsQuery = {
    __typename: 'Query';
    groups?: Array<{
        __typename: 'GroupNode';
        id?: string | null;
        name?: string | null;
        users?: Array<{ __typename: 'UserNode'; id?: string | null; email?: string | null } | null> | null;
        permissions?: Array<{ __typename: 'PermissionNode'; name?: string | null } | null> | null;
    } | null> | null;
};

export type IncomingTransfersQueryVariables = Exact<{
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>> | InputMaybe<TransferSortEnum>>;
}>;

export type IncomingTransfersQuery = {
    __typename: 'Query';
    incomeLog?: {
        __typename: 'CombinedTransfersList';
        transfers: Array<{
            __typename: 'CombinedTransferNode';
            transferId?: string | null;
            orderId?: string | null;
            storeId?: string | null;
            status: CombinedStatusEnum;
            sequentNumber?: number | null;
            type?: string | null;
            createdDate: any;
            paymentDate?: any | null;
            account?: string | null;
            isTest?: boolean | null;
            userId?: string | null;
            originId?: string | null;
            fiatFee?: string | null;
            fiatAmount?: string | null;
            storeName?: string | null;
            declineReason?: {
                __typename: 'DeclineReasonNode';
                code?: string | null;
                message?: { __typename: 'MultiLanguageMessage'; en?: string | null; ru?: string | null } | null;
            } | null;
            fiatMethod?: {
                __typename: 'FiatMethodNode';
                id: string;
                name: string;
                currencyName?: string | null;
            } | null;
            currency?: {
                __typename: 'CurrencyNode';
                id: string;
                smartContract: string;
                name: string;
                precission: number;
                blockchain?: string | null;
            } | null;
            amount: { __typename: 'AmountNode'; send: string; receive: string; fee: string };
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename: 'Query';
    me?: {
        __typename: 'UserNode';
        id?: string | null;
        phone?: string | null;
        email?: string | null;
        allowFiatWithdrawals?: boolean | null;
        allowFiatPayments?: boolean | null;
        hasPassword?: boolean | null;
        permissions?: Array<{ __typename: 'PermissionNode'; name?: string | null } | null> | null;
        stores?: Array<{ __typename: 'StoreNode'; id: string; isTestMode: boolean; name: string } | null> | null;
        defaultStore?: { __typename: 'StoreNode'; id: string; created: any; isTestMode: boolean; name: string } | null;
        myBalances: Array<{
            __typename: 'UserCurrencyBalanceNode';
            amount: string;
            awaitedIncome: string;
            name: string;
            currencies?: Array<{
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
            } | null> | null;
        } | null>;
    } | null;
    settings?: { __typename: 'SettingsNode'; coreEnabled?: boolean | null; internalTransferFee?: string | null } | null;
};

export type MerchantsQueryVariables = Exact<{
    filters?: InputMaybe<MerchantFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>> | InputMaybe<UserSortEnum>>;
}>;

export type MerchantsQuery = {
    __typename: 'Query';
    merchants?: {
        __typename: 'UserList';
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
        users: Array<{
            __typename: 'UserNode';
            id?: string | null;
            eosAccount?: string | null;
            email?: string | null;
            phone?: string | null;
            created: any;
            allowFiatPayments?: boolean | null;
            allowFiatWithdrawals?: boolean | null;
            defaultStore?: { __typename: 'StoreNode'; id: string; created: any; name: string } | null;
            myBalances: Array<{
                __typename: 'UserCurrencyBalanceNode';
                amount: string;
                awaitedIncome: string;
                name: string;
                currencies?: Array<{
                    __typename: 'CurrencyNode';
                    id: string;
                    name: string;
                    blockchain?: string | null;
                    precission: number;
                    isIncomeEnabled?: boolean | null;
                    isOutcomeEnabled?: boolean | null;
                } | null> | null;
            } | null>;
        } | null>;
    } | null;
};

export type MerchantsBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type MerchantsBalancesQuery = {
    __typename: 'Query';
    merchantsBalances?: Array<{
        __typename: 'CoreBalanceNode';
        amount: string;
        currency: { __typename: 'CurrencyNode'; id: string; name: string; blockchain?: string | null };
    } | null> | null;
};

export type MyBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type MyBalancesQuery = {
    __typename: 'Query';
    me?: {
        __typename: 'UserNode';
        id?: string | null;
        allowFiatWithdrawals?: boolean | null;
        allowFiatPayments?: boolean | null;
        myBalances: Array<{
            __typename: 'UserCurrencyBalanceNode';
            amount: string;
            awaitedIncome: string;
            name: string;
            currencies?: Array<{
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
            } | null> | null;
        } | null>;
    } | null;
};

export type MyShopifyIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type MyShopifyIntegrationsQuery = {
    __typename: 'Query';
    myShopifyIntegrations?: Array<{
        __typename: 'ShopifyIntegrationNode';
        id: string;
        shopName: string;
        created: any;
        isActive?: boolean | null;
        currency?: string | null;
        store: {
            __typename: 'StoreNode';
            id: string;
            name: string;
            created: any;
            isTestMode: boolean;
            status: StoreStatus;
        };
    } | null> | null;
};

export type MyTransferStatisticsQueryVariables = Exact<{
    storeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyTransferStatisticsQuery = {
    __typename: 'Query';
    myOrderStats?: {
        __typename: 'OrderStatsNode';
        day?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
        week?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
        month?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
    } | null;
    myTransferStats?: {
        __typename: 'TransferStatsNode';
        currencies?: Array<{
            __typename: 'CurrencyStatsNode';
            currency?: {
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                smartContract: string;
                minTransferAmount?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
                isDayLimitEnabled?: boolean | null;
                dayLimit?: string | null;
                isAccountLimitEnabled?: boolean | null;
                accountLimit?: string | null;
                isTxnLimitEnabled?: boolean | null;
                txnLimit?: string | null;
                isDayCapEnabled?: boolean | null;
                dayCap?: string | null;
                isAccountCapEnabled?: boolean | null;
                accountCap?: string | null;
                isTxnCapEnabled?: boolean | null;
                txnCap?: string | null;
                isFeeEnabled?: boolean | null;
                feePercentage?: string | null;
                feeFix?: string | null;
                isWithdrawFeeEnabled?: boolean | null;
                withdrawFeeFix?: string | null;
                withdrawFeePercentage?: string | null;
                feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
                feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
            } | null;
            day?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
            week?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
            month?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
        } | null> | null;
        total?: {
            __typename: 'TotalStatsNode';
            day?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
            week?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
            month?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
        } | null;
    } | null;
};

export type OrderQueryVariables = Exact<{
    id: Scalars['String']['input'];
    method?: InputMaybe<PaymentMethod>;
    extraFields?: InputMaybe<RequisiteExtraFieldsInput>;
}>;

export type OrderQuery = {
    __typename: 'Query';
    order?: {
        __typename: 'OrderNode';
        id: string;
        sequentNumber: number;
        storeId: string;
        isTest: boolean;
        status: OrderStatus;
        created: any;
        merchantOrderId?: string | null;
        description?: string | null;
        successPaymentRedirectUrl?: string | null;
        failedPaymentRedirectUrl?: string | null;
        responseUrl: string;
        expirationDate: any;
        paymentDate?: any | null;
        cancellationDate?: any | null;
        hasPaybacks: boolean;
        store: { __typename: 'StoreNode'; id: string; name: string };
        currency: {
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            smartContract: string;
            precission: number;
            blockchain?: string | null;
            isFeeEnabled?: boolean | null;
            feePercentage?: string | null;
            feeFix?: string | null;
        };
        paymentOptions?: Array<{
            __typename: 'PaymentOptionNode';
            method: PaymentMethod;
            methodId: string;
            currencyName: string;
            serviceName?: string | null;
            requiredFields: Array<string | null>;
            optionalFields: Array<string | null>;
        } | null> | null;
        paymentParams?: { __typename: 'PaymentParams'; memo?: string | null } | null;
        paymentTransfer?: {
            __typename: 'TransferNode';
            sender: string;
            status: TxnStatus;
            id: string;
            memo?: string | null;
            action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
        } | null;
        amount: { __typename: 'AmountNode'; fee: string; send: string; receive: string };
        transfers: Array<{
            __typename: 'TransferNode';
            id: string;
            status: TxnStatus;
            sender: string;
            receiver: string;
            created: any;
            txnType: TxnType;
            amount: { __typename: 'AmountNode'; receive: string };
            currency: {
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                precission: number;
                smartContract: string;
                blockchain?: string | null;
            };
        } | null>;
        totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
    } | null;
};

export type OrderListQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters?: InputMaybe<OrderFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<OrderSortEnum>> | InputMaybe<OrderSortEnum>>;
}>;

export type OrderListQuery = {
    __typename: 'Query';
    me?: {
        __typename: 'UserNode';
        id?: string | null;
        defaultStore?: {
            __typename: 'StoreNode';
            id: string;
            orders?: {
                __typename: 'OrdersList';
                pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
                orders: Array<{
                    __typename: 'OrderNode';
                    id: string;
                    sequentNumber: number;
                    created: any;
                    isTest: boolean;
                    status: OrderStatus;
                    paymentDate?: any | null;
                    cancellationDate?: any | null;
                    hasPaybacks: boolean;
                    amount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
                    currency: {
                        __typename: 'CurrencyNode';
                        id: string;
                        name: string;
                        precission: number;
                        blockchain?: string | null;
                        smartContract: string;
                        isFeeEnabled?: boolean | null;
                        feePercentage?: string | null;
                        feeFix?: string | null;
                        withdrawFeeFix?: string | null;
                        isWithdrawFeeEnabled?: boolean | null;
                        withdrawFeePercentage?: string | null;
                    };
                    paymentTransfer?: { __typename: 'TransferNode'; id: string; sender: string } | null;
                    totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
                } | null>;
            } | null;
        } | null;
    } | null;
};

export type OrderRedirectUrlQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type OrderRedirectUrlQuery = { __typename: 'Query'; orderRedirectUrl?: string | null };

export type OutcomTransfersQueryVariables = Exact<{
    filters?: InputMaybe<TransferFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<TransferSortEnum>> | InputMaybe<TransferSortEnum>>;
}>;

export type OutcomTransfersQuery = {
    __typename: 'Query';
    outcomeLog?: {
        __typename: 'CombinedTransfersList';
        transfers: Array<{
            __typename: 'CombinedTransferNode';
            transferId?: string | null;
            orderId?: string | null;
            storeId?: string | null;
            status: CombinedStatusEnum;
            sequentNumber?: number | null;
            type?: string | null;
            createdDate: any;
            paymentDate?: any | null;
            account?: string | null;
            isTest?: boolean | null;
            userId?: string | null;
            originId?: string | null;
            fiatFee?: string | null;
            fiatAmount?: string | null;
            storeName?: string | null;
            declineReason?: {
                __typename: 'DeclineReasonNode';
                code?: string | null;
                message?: { __typename: 'MultiLanguageMessage'; en?: string | null; ru?: string | null } | null;
            } | null;
            fiatMethod?: {
                __typename: 'FiatMethodNode';
                id: string;
                name: string;
                currencyName?: string | null;
            } | null;
            currency?: {
                __typename: 'CurrencyNode';
                id: string;
                smartContract: string;
                name: string;
                precission: number;
                blockchain?: string | null;
            } | null;
            amount: { __typename: 'AmountNode'; send: string; receive: string; fee: string };
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type PaymentOrderQueryVariables = Exact<{
    id: Scalars['String']['input'];
    method?: InputMaybe<PaymentMethod>;
    extraFields?: InputMaybe<RequisiteExtraFieldsInput>;
}>;

export type PaymentOrderQuery = {
    __typename: 'Query';
    order?: {
        __typename: 'OrderNode';
        id: string;
        sequentNumber: number;
        isTest: boolean;
        expirationDate: any;
        created: any;
        status: OrderStatus;
        store: { __typename: 'StoreNode'; id: string; name: string };
        amount: { __typename: 'AmountNode'; fee: string; send: string; receive: string };
        currency: {
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            precission: number;
            blockchain?: string | null;
        };
        paymentParams?: {
            __typename: 'PaymentParams';
            memo?: string | null;
            receiver?: string | null;
            metaInfo?: string | null;
            amount: string;
            requiredExtraFields?: Array<string | null> | null;
            paymentLink?: string | null;
            serviceName?: string | null;
            infoLink?: string | null;
            requisiteId?: string | null;
            deeplinks?: Array<{ __typename: 'DeeplinkNode'; name: string; link?: string | null } | null> | null;
        } | null;
        paymentOptions?: Array<{
            __typename: 'PaymentOptionNode';
            method: PaymentMethod;
            methodId: string;
            currencyName: string;
            serviceName?: string | null;
            requiredFields: Array<string | null>;
            optionalFields: Array<string | null>;
        } | null> | null;
        paymentTransfer?: {
            __typename: 'TransferNode';
            id: string;
            action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
        } | null;
    } | null;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
    __typename: 'Query';
    settings?: {
        __typename: 'SettingsNode';
        coreEnabled?: boolean | null;
        feeLimits?: {
            __typename: 'FeeLimitsNode';
            ether?: { __typename: 'FeeLimitNode'; feeLimit?: string | null } | null;
            tron?: { __typename: 'FeeLimitNode'; feeLimit?: string | null } | null;
        } | null;
    } | null;
};

export type ShopifyIntegrationQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type ShopifyIntegrationQuery = {
    __typename: 'Query';
    shopifyIntegration?: {
        __typename: 'ShopifyIntegrationNode';
        id: string;
        shopName: string;
        created: any;
        isActive?: boolean | null;
        currency?: string | null;
        store: {
            __typename: 'StoreNode';
            id: string;
            name: string;
            created: any;
            isTestMode: boolean;
            status: StoreStatus;
        };
    } | null;
};

export type StoreStatFragment = {
    __typename: 'StorePeriodStatsNode';
    created?: number | null;
    enabled?: number | null;
};

export type UserStatFragment = {
    __typename: 'UserPeriodStatsNode';
    activated?: number | null;
    registered?: number | null;
};

export type StatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type StatisticsQuery = {
    __typename: 'Query';
    userStats?: {
        __typename: 'UserStatsNode';
        day?: { __typename: 'UserPeriodStatsNode'; activated?: number | null; registered?: number | null } | null;
        week?: { __typename: 'UserPeriodStatsNode'; activated?: number | null; registered?: number | null } | null;
        month?: { __typename: 'UserPeriodStatsNode'; activated?: number | null; registered?: number | null } | null;
    } | null;
    storeStats?: {
        __typename: 'StoreStatsNode';
        day?: { __typename: 'StorePeriodStatsNode'; created?: number | null; enabled?: number | null } | null;
        week?: { __typename: 'StorePeriodStatsNode'; created?: number | null; enabled?: number | null } | null;
        month?: { __typename: 'StorePeriodStatsNode'; created?: number | null; enabled?: number | null } | null;
    } | null;
};

export type StoresQueryVariables = Exact<{
    filters?: InputMaybe<StoreFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<StoreSortEnum>> | InputMaybe<StoreSortEnum>>;
}>;

export type StoresQuery = {
    __typename: 'Query';
    stores?: {
        __typename: 'StoresList';
        stores: Array<{
            __typename: 'StoreNode';
            id: string;
            name: string;
            created: any;
            isTestMode: boolean;
            paymentStats?: Array<{
                __typename: 'PaymentsSummaryNode';
                paymentsCount?: number | null;
                paymentsAmount?: string | null;
                currency?: { __typename: 'CurrencyNode'; blockchain?: string | null; name: string; id: string } | null;
            } | null> | null;
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type StoresWithStatsQueryVariables = Exact<{
    filters?: InputMaybe<StoreFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<StoreSortEnum>> | InputMaybe<StoreSortEnum>>;
}>;

export type StoresWithStatsQuery = {
    __typename: 'Query';
    stores?: {
        __typename: 'StoresList';
        stores: Array<{
            __typename: 'StoreNode';
            id: string;
            name: string;
            created: any;
            isTestMode: boolean;
            paymentStats?: Array<{
                __typename: 'PaymentsSummaryNode';
                paymentsCount?: number | null;
                paymentsAmount?: string | null;
                currency?: {
                    __typename: 'CurrencyNode';
                    id: string;
                    name: string;
                    isFeeEnabled?: boolean | null;
                } | null;
            } | null> | null;
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type StoreBalancesQueryVariables = Exact<{
    storeId: Scalars['String']['input'];
}>;

export type StoreBalancesQuery = {
    __typename: 'Query';
    store?: {
        __typename: 'StoreNode';
        name: string;
        admin?: {
            __typename: 'StoreAdminNode';
            id?: string | null;
            balances: Array<{
                __typename: 'UserCurrencyBalanceNode';
                amount: string;
                awaitedIncome: string;
                name: string;
                currencies?: Array<{
                    __typename: 'CurrencyNode';
                    id: string;
                    name: string;
                    blockchain?: string | null;
                    precission: number;
                    isIncomeEnabled?: boolean | null;
                    isOutcomeEnabled?: boolean | null;
                } | null> | null;
            } | null>;
        } | null;
    } | null;
};

export type StoreStatisticQueryVariables = Exact<{
    storeId: Scalars['String']['input'];
    dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
    dateTo?: InputMaybe<Scalars['DateTime']['input']>;
    todayFrom?: InputMaybe<Scalars['DateTime']['input']>;
    userTimezoneShift?: InputMaybe<Scalars['Int']['input']>;
    todayTo?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type StoreStatisticQuery = {
    __typename: 'Query';
    storeStatistic?: {
        __typename: 'StoreStatistic';
        storeId: string;
        fundsReceipts?: Array<{
            __typename: 'FundReceipt';
            dateFrom?: any | null;
            dateTo?: any | null;
            total: string;
            currency?: { __typename: 'CurrencyNode'; id: string; name: string; precission: number } | null;
            chartPoints?: Array<{
                __typename: 'ChartNode';
                dateFrom: string;
                dateTo: string;
                value: string;
            } | null> | null;
        } | null> | null;
        orders?: {
            __typename: 'OrdersStatisticNode';
            dateFrom?: any | null;
            dateTo?: any | null;
            total: number;
            cancellations: number;
            refunds: number;
            payments: number;
            expirations: number;
            chartPoints?: Array<{
                __typename: 'ChartNode';
                dateFrom: string;
                dateTo: string;
                value: string;
            } | null> | null;
            prevPeriod?: {
                __typename: 'OrdersStatisticNode';
                total: number;
                cancellations: number;
                refunds: number;
                payments: number;
                expirations: number;
            } | null;
        } | null;
        avgBuyersBills?: Array<{
            __typename: 'BuyerBillNode';
            dateFrom?: any | null;
            dateTo?: any | null;
            averageValue: string;
            prevPeriod?: { __typename: 'BuyerBillNode'; averageValue: string } | null;
            currency?: { __typename: 'CurrencyNode'; id: string; name: string; precission: number } | null;
            chartPoints?: Array<{
                __typename: 'ChartNode';
                dateFrom: string;
                dateTo: string;
                value: string;
            } | null> | null;
        } | null> | null;
    } | null;
    todayStoreStatistic?: {
        __typename: 'StoreStatistic';
        storeId: string;
        orders?: {
            __typename: 'OrdersStatisticNode';
            total: number;
            prevPeriod?: { __typename: 'OrdersStatisticNode'; total: number } | null;
        } | null;
    } | null;
};

export type StoreWithStatsQueryVariables = Exact<{
    storeId: Scalars['String']['input'];
}>;

export type StoreWithStatsQuery = {
    __typename: 'Query';
    store?: {
        __typename: 'StoreNode';
        id: string;
        name: string;
        created: any;
        isTestMode: boolean;
        paymentStats?: Array<{
            __typename: 'PaymentsSummaryNode';
            paymentsCount?: number | null;
            paymentsAmount?: string | null;
            currency?: { __typename: 'CurrencyNode'; id: string; name: string; isFeeEnabled?: boolean | null } | null;
        } | null> | null;
    } | null;
};

export type TransferQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type TransferQuery = {
    __typename: 'Query';
    transfer?: {
        __typename: 'TransferNode';
        id: string;
        status: TxnStatus;
        sender: string;
        receiver: string;
        memo?: string | null;
        created: any;
        txnType: TxnType;
        amount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
        action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
        currency: {
            __typename: 'CurrencyNode';
            id: string;
            name: string;
            precission: number;
            blockchain?: string | null;
        };
    } | null;
};

export type TransferStatisticsQueryVariables = Exact<{
    storeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type TransferStatisticsQuery = {
    __typename: 'Query';
    orderStats?: {
        __typename: 'OrderStatsNode';
        day?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
        week?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
        month?: {
            __typename: 'OrderPeriodStatsNode';
            totalOrders?: number | null;
            incompleteOrders?: number | null;
            cancelledOrders?: number | null;
            paidOrders?: number | null;
        } | null;
    } | null;
    transferStats?: {
        __typename: 'TransferStatsNode';
        currencies?: Array<{
            __typename: 'CurrencyStatsNode';
            currency?: {
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                smartContract: string;
                minTransferAmount?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
                isDayLimitEnabled?: boolean | null;
                dayLimit?: string | null;
                isAccountLimitEnabled?: boolean | null;
                accountLimit?: string | null;
                isTxnLimitEnabled?: boolean | null;
                txnLimit?: string | null;
                isDayCapEnabled?: boolean | null;
                dayCap?: string | null;
                isAccountCapEnabled?: boolean | null;
                accountCap?: string | null;
                isTxnCapEnabled?: boolean | null;
                txnCap?: string | null;
                isFeeEnabled?: boolean | null;
                feePercentage?: string | null;
                feeFix?: string | null;
                isWithdrawFeeEnabled?: boolean | null;
                withdrawFeeFix?: string | null;
                withdrawFeePercentage?: string | null;
                feeOut?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
                feeIn?: { __typename: 'FeeDataNode'; feeFix?: string | null; feePercentage?: string | null } | null;
            } | null;
            day?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
            week?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
            month?: {
                __typename: 'CurrencyPeriodStatsNode';
                income?: string | null;
                outcome?: string | null;
                fee?: string | null;
                feeDeposit?: string | null;
                feeWithdraw?: string | null;
            } | null;
        } | null> | null;
        total?: {
            __typename: 'TotalStatsNode';
            day?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
            week?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
            month?: {
                __typename: 'TransferPeriodStatsNode';
                totalTransfers?: number | null;
                deposits?: number | null;
                payments?: number | null;
                withdraws?: number | null;
                clientWithdraws?: number | null;
                paybacks?: number | null;
            } | null;
        } | null;
    } | null;
};

export type UserListQueryVariables = Exact<{
    filters?: InputMaybe<UserFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>> | InputMaybe<UserSortEnum>>;
}>;

export type UserListQuery = {
    __typename: 'Query';
    users?: {
        __typename: 'UserList';
        users: Array<{
            __typename: 'UserNode';
            id?: string | null;
            email?: string | null;
            phone?: string | null;
            eosAccount?: string | null;
            allowFiatPayments?: boolean | null;
            allowFiatWithdrawals?: boolean | null;
            created: any;
            group?: { __typename: 'SimpleGroupNode'; id?: string | null; name?: string | null } | null;
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type ConnectByWalletWsSubscriptionVariables = Exact<{
    verificationCode: Scalars['String']['input'];
}>;

export type ConnectByWalletWsSubscription = {
    __typename: 'Subscription';
    connectByWallet?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'EosAccountAlreadyTakenError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | { __typename: 'WalletConnectionSuccess'; token?: string | null }
        | { __typename: 'WalletLinkOutdatedError'; errorMessage: string }
        | null;
};

export type MeWsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MeWsSubscription = {
    __typename: 'Subscription';
    user?: {
        __typename: 'UserNode';
        id?: string | null;
        myBalances: Array<{
            __typename: 'UserCurrencyBalanceNode';
            amount: string;
            awaitedIncome: string;
            name: string;
            currencies?: Array<{
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                blockchain?: string | null;
                precission: number;
                isIncomeEnabled?: boolean | null;
                isOutcomeEnabled?: boolean | null;
            } | null> | null;
        } | null>;
    } | null;
};

export type OrderWsSubscriptionVariables = Exact<{
    orderId: Scalars['String']['input'];
}>;

export type OrderWsSubscription = {
    __typename: 'Subscription';
    publicOrder?: {
        __typename: 'OrderNode';
        id: string;
        status: OrderStatus;
        expirationDate: any;
        paymentDate?: any | null;
        cancellationDate?: any | null;
        hasPaybacks: boolean;
        totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
        paymentTransfer?: {
            __typename: 'TransferNode';
            sender: string;
            id: string;
            action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
        } | null;
        transfers: Array<{
            __typename: 'TransferNode';
            id: string;
            status: TxnStatus;
            sender: string;
            receiver: string;
            created: any;
            txnType: TxnType;
            amount: { __typename: 'AmountNode'; receive: string };
            currency: {
                __typename: 'CurrencyNode';
                id: string;
                name: string;
                smartContract: string;
                precission: number;
                blockchain?: string | null;
            };
        } | null>;
    } | null;
};

export type OrderListItemWsSubscriptionVariables = Exact<{
    orderId: Scalars['String']['input'];
}>;

export type OrderListItemWsSubscription = {
    __typename: 'Subscription';
    publicOrder?: {
        __typename: 'OrderNode';
        id: string;
        status: OrderStatus;
        paymentDate?: any | null;
        cancellationDate?: any | null;
        hasPaybacks: boolean;
        paymentTransfer?: { __typename: 'TransferNode'; id: string; sender: string } | null;
        totalPaybackAmount: { __typename: 'AmountNode'; fee: string; receive: string; send: string };
    } | null;
};

export type PaymentOrderWsSubscriptionVariables = Exact<{
    orderId: Scalars['String']['input'];
}>;

export type PaymentOrderWsSubscription = {
    __typename: 'Subscription';
    publicOrder?: {
        __typename: 'OrderNode';
        id: string;
        status: OrderStatus;
        paymentTransfer?: {
            __typename: 'TransferNode';
            id: string;
            action?: { __typename: 'ActionNode'; id: string; txid: string } | null;
        } | null;
    } | null;
};

export const OrderDynamicDataFragmentDoc = gql`
    fragment OrderDynamicData on OrderNode {
        id
        status
        expirationDate
        paymentDate
        cancellationDate
        hasPaybacks
        totalPaybackAmount {
            fee
            receive
            send
        }
        paymentTransfer {
            sender
            id
            action {
                id
                txid
            }
        }
        transfers {
            id
            amount {
                receive
            }
            currency {
                id
                name
                smartContract
                precission
                blockchain
            }
            status
            sender
            receiver
            created
            txnType
        }
    }
`;
export const OrderListItemDynamicDataFragmentDoc = gql`
    fragment OrderListItemDynamicData on OrderNode {
        id
        status
        paymentDate
        cancellationDate
        hasPaybacks
        paymentTransfer {
            id
            sender
        }
        totalPaybackAmount {
            fee
            receive
            send
        }
    }
`;
export const OrderListItemFragmentDoc = gql`
    fragment OrderListItem on OrderNode {
        id
        amount {
            fee
            receive
            send
        }
        sequentNumber
        created
        isTest
        currency {
            id
            name
            precission
            blockchain
            smartContract
            isFeeEnabled
            feePercentage
            feeFix
            withdrawFeeFix
            isWithdrawFeeEnabled
            withdrawFeePercentage
        }
        ...OrderListItemDynamicData
    }
    ${OrderListItemDynamicDataFragmentDoc}
`;
export const PaymentOrderDynamicDataFragmentDoc = gql`
    fragment PaymentOrderDynamicData on OrderNode {
        id
        paymentTransfer {
            id
            action {
                id
                txid
            }
        }
        status
    }
`;
export const OrderStatPartsFragmentDoc = gql`
    fragment OrderStatParts on OrderPeriodStatsNode {
        totalOrders
        incompleteOrders
        cancelledOrders
        paidOrders
    }
`;
export const CurrencyFragmentDoc = gql`
    fragment Currency on CurrencyNode {
        id
        name
        blockchain
        smartContract
        minTransferAmount
        precission
        isIncomeEnabled
        isOutcomeEnabled
        isDayLimitEnabled
        dayLimit
        isAccountLimitEnabled
        accountLimit
        isTxnLimitEnabled
        txnLimit
        isDayCapEnabled
        dayCap
        isAccountCapEnabled
        accountCap
        isTxnCapEnabled
        txnCap
        feeOut {
            feeFix
            feePercentage
        }
        feeIn {
            feeFix
            feePercentage
        }
        isFeeEnabled
        feePercentage
        feeFix
        isWithdrawFeeEnabled
        withdrawFeeFix
        withdrawFeePercentage
    }
`;
export const CurrPeriodStatFragmentDoc = gql`
    fragment CurrPeriodStat on CurrencyPeriodStatsNode {
        income
        outcome
        fee
        feeDeposit
        feeWithdraw
    }
`;
export const CurrStatFragmentDoc = gql`
    fragment CurrStat on CurrencyStatsNode {
        currency {
            ...Currency
        }
        day {
            ...CurrPeriodStat
        }
        week {
            ...CurrPeriodStat
        }
        month {
            ...CurrPeriodStat
        }
    }
    ${CurrencyFragmentDoc}
    ${CurrPeriodStatFragmentDoc}
`;
export const TotalStatFragmentDoc = gql`
    fragment TotalStat on TransferPeriodStatsNode {
        totalTransfers
        deposits
        payments
        withdraws
        clientWithdraws
        paybacks
    }
`;
export const FiatMethodTotalStatFragmentDoc = gql`
    fragment FiatMethodTotalStat on FiatTotalPeriodStatsNode {
        deposits
        paybacks
        payments
        totalTransfers
        withdraws
    }
`;
export const FiatTotalStatFragmentDoc = gql`
    fragment FiatTotalStat on FiatTotalStatsNode {
        day {
            ...FiatMethodTotalStat
        }
        week {
            ...FiatMethodTotalStat
        }
        month {
            ...FiatMethodTotalStat
        }
    }
    ${FiatMethodTotalStatFragmentDoc}
`;
export const FiatMethodPeriodStatFragmentDoc = gql`
    fragment FiatMethodPeriodStat on FiatMethodPeriodStatsNode {
        fee
        feeDeposit
        feeWithdraw
        income
        outcome
    }
`;
export const FiatPeriodStatFragmentDoc = gql`
    fragment FiatPeriodStat on FiatMethodStatsNode {
        fiatMethod {
            id
            name
            location
            direction
            isActive
        }
        day {
            ...FiatMethodPeriodStat
        }
        week {
            ...FiatMethodPeriodStat
        }
        month {
            ...FiatMethodPeriodStat
        }
    }
    ${FiatMethodPeriodStatFragmentDoc}
`;
export const MyBalanceListItemFragmentDoc = gql`
    fragment MyBalanceListItem on UserCurrencyBalanceNode {
        currencies {
            id
            name
            blockchain
            precission
            isIncomeEnabled
            isOutcomeEnabled
        }
        amount
        awaitedIncome
        name
    }
`;
export const MyBalancesDataFragmentDoc = gql`
    fragment MyBalancesData on UserNode {
        myBalances {
            ...MyBalanceListItem
        }
    }
    ${MyBalanceListItemFragmentDoc}
`;
export const UserFragmentDoc = gql`
    fragment User on UserNode {
        id
        eosAccount
        email
        phone
        created
        allowFiatPayments
        allowFiatWithdrawals
        defaultStore {
            id
            created
            name
        }
        ...MyBalancesData
    }
    ${MyBalancesDataFragmentDoc}
`;
export const UserStoresFragmentDoc = gql`
    fragment UserStores on UserNode {
        stores {
            id
            isTestMode
            name
        }
        defaultStore {
            id
            created
            isTestMode
            name
        }
    }
`;
export const StoreStatFragmentDoc = gql`
    fragment StoreStat on StorePeriodStatsNode {
        created
        enabled
    }
`;
export const UserStatFragmentDoc = gql`
    fragment UserStat on UserPeriodStatsNode {
        activated
        registered
    }
`;
export const AbortTransferDocument = gql`
    mutation AbortTransfer($transferId: String!, $password: String!) {
        abortTransfer(transferId: $transferId, password: $password) {
            ... on AbortTransferSuccess {
                transferId
            }
            ... on TransferNotFoundError {
                errorMessage
            }
            ... on WrongTransferStatusError {
                errorMessage
            }
            ... on WrongTransferTypeError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
        }
    }
`;
export type AbortTransferMutationFn = Apollo.MutationFunction<AbortTransferMutation, AbortTransferMutationVariables>;

/**
 * __useAbortTransferMutation__
 *
 * To run a mutation, you first call `useAbortTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortTransferMutation, { data, loading, error }] = useAbortTransferMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAbortTransferMutation(
    baseOptions?: Apollo.MutationHookOptions<AbortTransferMutation, AbortTransferMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AbortTransferMutation, AbortTransferMutationVariables>(AbortTransferDocument, options);
}
export type AbortTransferMutationHookResult = ReturnType<typeof useAbortTransferMutation>;
export type AbortTransferMutationResult = Apollo.MutationResult<AbortTransferMutation>;
export type AbortTransferMutationOptions = Apollo.BaseMutationOptions<
    AbortTransferMutation,
    AbortTransferMutationVariables
>;
export const ActivateFiatMethodDocument = gql`
    mutation ActivateFiatMethod($input: ActivateFiatMethodInput!) {
        activateFiatMethod(input: $input) {
            ... on UpdateFiatMethodSuccess {
                fiatMethod {
                    id
                    name
                }
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
        }
    }
`;
export type ActivateFiatMethodMutationFn = Apollo.MutationFunction<
    ActivateFiatMethodMutation,
    ActivateFiatMethodMutationVariables
>;

/**
 * __useActivateFiatMethodMutation__
 *
 * To run a mutation, you first call `useActivateFiatMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateFiatMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateFiatMethodMutation, { data, loading, error }] = useActivateFiatMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateFiatMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateFiatMethodMutation, ActivateFiatMethodMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ActivateFiatMethodMutation, ActivateFiatMethodMutationVariables>(
        ActivateFiatMethodDocument,
        options
    );
}
export type ActivateFiatMethodMutationHookResult = ReturnType<typeof useActivateFiatMethodMutation>;
export type ActivateFiatMethodMutationResult = Apollo.MutationResult<ActivateFiatMethodMutation>;
export type ActivateFiatMethodMutationOptions = Apollo.BaseMutationOptions<
    ActivateFiatMethodMutation,
    ActivateFiatMethodMutationVariables
>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($input: CancelOrderInput!) {
        cancelOrder(input: $input) {
            ... on CancelOrderSuccess {
                order {
                    id
                    status
                    cancellationDate
                }
            }
            ... on OrderNotFoundError {
                errorMessage
            }
            ... on OrderInactiveError {
                errorMessage
            }
            ... on OrderPaidError {
                errorMessage
            }
        }
    }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const ChangeLoginDocument = gql`
    mutation ChangeLogin($input: ChangeLoginInput!) {
        changeLogin(input: $input) {
            ... on ChangeLoginSuccess {
                token
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
        }
    }
`;
export type ChangeLoginMutationFn = Apollo.MutationFunction<ChangeLoginMutation, ChangeLoginMutationVariables>;

/**
 * __useChangeLoginMutation__
 *
 * To run a mutation, you first call `useChangeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLoginMutation, { data, loading, error }] = useChangeLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangeLoginMutation, ChangeLoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangeLoginMutation, ChangeLoginMutationVariables>(ChangeLoginDocument, options);
}
export type ChangeLoginMutationHookResult = ReturnType<typeof useChangeLoginMutation>;
export type ChangeLoginMutationResult = Apollo.MutationResult<ChangeLoginMutation>;
export type ChangeLoginMutationOptions = Apollo.BaseMutationOptions<ChangeLoginMutation, ChangeLoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            ... on ChangePasswordSuccess {
                me {
                    id
                }
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const ChangeUserGroupDocument = gql`
    mutation ChangeUserGroup($groupid: String, $userid: String!) {
        changeUserGroup(input: { userId: $userid, groupId: $groupid }) {
            ... on ChangeUserGroupSuccess {
                group {
                    id
                    name
                }
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on GroupNotFoundError {
                errorMessage
            }
            ... on UserHasNoPasswordError {
                errorMessage
            }
        }
    }
`;
export type ChangeUserGroupMutationFn = Apollo.MutationFunction<
    ChangeUserGroupMutation,
    ChangeUserGroupMutationVariables
>;

/**
 * __useChangeUserGroupMutation__
 *
 * To run a mutation, you first call `useChangeUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserGroupMutation, { data, loading, error }] = useChangeUserGroupMutation({
 *   variables: {
 *      groupid: // value for 'groupid'
 *      userid: // value for 'userid'
 *   },
 * });
 */
export function useChangeUserGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangeUserGroupMutation, ChangeUserGroupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangeUserGroupMutation, ChangeUserGroupMutationVariables>(
        ChangeUserGroupDocument,
        options
    );
}
export type ChangeUserGroupMutationHookResult = ReturnType<typeof useChangeUserGroupMutation>;
export type ChangeUserGroupMutationResult = Apollo.MutationResult<ChangeUserGroupMutation>;
export type ChangeUserGroupMutationOptions = Apollo.BaseMutationOptions<
    ChangeUserGroupMutation,
    ChangeUserGroupMutationVariables
>;
export const CheckPasswordDocument = gql`
    mutation CheckPassword($input: CheckPasswordInput!) {
        checkPassword(input: $input) {
            ... on CheckPasswordSuccess {
                me {
                    id
                }
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type CheckPasswordMutationFn = Apollo.MutationFunction<CheckPasswordMutation, CheckPasswordMutationVariables>;

/**
 * __useCheckPasswordMutation__
 *
 * To run a mutation, you first call `useCheckPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPasswordMutation, { data, loading, error }] = useCheckPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<CheckPasswordMutation, CheckPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CheckPasswordMutation, CheckPasswordMutationVariables>(CheckPasswordDocument, options);
}
export type CheckPasswordMutationHookResult = ReturnType<typeof useCheckPasswordMutation>;
export type CheckPasswordMutationResult = Apollo.MutationResult<CheckPasswordMutation>;
export type CheckPasswordMutationOptions = Apollo.BaseMutationOptions<
    CheckPasswordMutation,
    CheckPasswordMutationVariables
>;
export const ClientWithdrawDocument = gql`
    mutation ClientWithdraw($input: ClientWithdrawInput!) {
        clientWithdraw(input: $input) {
            ... on ClientWithdrawSuccess {
                me {
                    id
                    ...MyBalancesData
                }
                transferId
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on StoreNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on InsufficientFundsError {
                missingAmount
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on WithdrawLimitExceededError {
                errorMessage
            }
            ... on WithdrawDuplicationError {
                errorMessage
            }
            ... on FiatWithdrawalsNotAllowedError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on ForbiddenWithdrawalReceiverError {
                errorMessage
            }
            ... on WithdrawalAmountTooLowError {
                errorMessage
                minimalAmount
            }
            ... on WithdrawalAmountTooHighError {
                errorMessage
                maximalAmount
            }
            ... on FiatTransferNotPossibleError {
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on DuplicateTransferError {
                errorMessage
            }
        }
    }
    ${MyBalancesDataFragmentDoc}
`;
export type ClientWithdrawMutationFn = Apollo.MutationFunction<ClientWithdrawMutation, ClientWithdrawMutationVariables>;

/**
 * __useClientWithdrawMutation__
 *
 * To run a mutation, you first call `useClientWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientWithdrawMutation, { data, loading, error }] = useClientWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientWithdrawMutation(
    baseOptions?: Apollo.MutationHookOptions<ClientWithdrawMutation, ClientWithdrawMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ClientWithdrawMutation, ClientWithdrawMutationVariables>(ClientWithdrawDocument, options);
}
export type ClientWithdrawMutationHookResult = ReturnType<typeof useClientWithdrawMutation>;
export type ClientWithdrawMutationResult = Apollo.MutationResult<ClientWithdrawMutation>;
export type ClientWithdrawMutationOptions = Apollo.BaseMutationOptions<
    ClientWithdrawMutation,
    ClientWithdrawMutationVariables
>;
export const CollectWalletDocument = gql`
    mutation CollectWallet($input: CollectWalletInput!) {
        collectWallet(input: $input) {
            ... on CollectWalletSuccess {
                txid
            }
            ... on InvalidParamsError {
                paramName
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
        }
    }
`;
export type CollectWalletMutationFn = Apollo.MutationFunction<CollectWalletMutation, CollectWalletMutationVariables>;

/**
 * __useCollectWalletMutation__
 *
 * To run a mutation, you first call `useCollectWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectWalletMutation, { data, loading, error }] = useCollectWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectWalletMutation(
    baseOptions?: Apollo.MutationHookOptions<CollectWalletMutation, CollectWalletMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CollectWalletMutation, CollectWalletMutationVariables>(CollectWalletDocument, options);
}
export type CollectWalletMutationHookResult = ReturnType<typeof useCollectWalletMutation>;
export type CollectWalletMutationResult = Apollo.MutationResult<CollectWalletMutation>;
export type CollectWalletMutationOptions = Apollo.BaseMutationOptions<
    CollectWalletMutation,
    CollectWalletMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
    mutation ConfirmPasswordReset($input: ConfirmPasswordResetInput!) {
        confirmPasswordReset(input: $input) {
            ... on ConfirmPasswordResetSuccess {
                token
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
        }
    }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(
        ConfirmPasswordResetDocument,
        options
    );
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<typeof useConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationResult = Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;
export const ConnectByWalletDocument = gql`
    mutation ConnectByWallet($input: ConnectByWalletInput!) {
        connectByWallet(input: $input) {
            ... on ConnectByWalletSuccess {
                qrData
                deepLink
                verificationCode
            }
            ... on DeeplinkNotGeneratedError {
                errorMessage
            }
        }
    }
`;
export type ConnectByWalletMutationFn = Apollo.MutationFunction<
    ConnectByWalletMutation,
    ConnectByWalletMutationVariables
>;

/**
 * __useConnectByWalletMutation__
 *
 * To run a mutation, you first call `useConnectByWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectByWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectByWalletMutation, { data, loading, error }] = useConnectByWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectByWalletMutation(
    baseOptions?: Apollo.MutationHookOptions<ConnectByWalletMutation, ConnectByWalletMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConnectByWalletMutation, ConnectByWalletMutationVariables>(
        ConnectByWalletDocument,
        options
    );
}
export type ConnectByWalletMutationHookResult = ReturnType<typeof useConnectByWalletMutation>;
export type ConnectByWalletMutationResult = Apollo.MutationResult<ConnectByWalletMutation>;
export type ConnectByWalletMutationOptions = Apollo.BaseMutationOptions<
    ConnectByWalletMutation,
    ConnectByWalletMutationVariables
>;
export const ConnectShopifyDocument = gql`
    mutation ConnectShopify($input: ConnectShopifyInput!) {
        connectShopify(input: $input) {
            ... on ConnectShopifySuccess {
                integration {
                    id
                    shopName
                    created
                    isActive
                    store {
                        id
                        name
                    }
                }
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
            ... on ShopifyPermissionRequiredError {
                permissions
                errorMessage
            }
            ... on ShopifyIntegrationExistsError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
        }
    }
`;
export type ConnectShopifyMutationFn = Apollo.MutationFunction<ConnectShopifyMutation, ConnectShopifyMutationVariables>;

/**
 * __useConnectShopifyMutation__
 *
 * To run a mutation, you first call `useConnectShopifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectShopifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectShopifyMutation, { data, loading, error }] = useConnectShopifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectShopifyMutation(
    baseOptions?: Apollo.MutationHookOptions<ConnectShopifyMutation, ConnectShopifyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConnectShopifyMutation, ConnectShopifyMutationVariables>(ConnectShopifyDocument, options);
}
export type ConnectShopifyMutationHookResult = ReturnType<typeof useConnectShopifyMutation>;
export type ConnectShopifyMutationResult = Apollo.MutationResult<ConnectShopifyMutation>;
export type ConnectShopifyMutationOptions = Apollo.BaseMutationOptions<
    ConnectShopifyMutation,
    ConnectShopifyMutationVariables
>;
export const CoreWithdrawDocument = gql`
    mutation CoreWithdraw($input: CoreWithdrawInput!) {
        coreWithdraw(input: $input) {
            ... on WithdrawSuccess {
                transferId
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on WithdrawDuplicationError {
                errorMessage
            }
            ... on ForbiddenWithdrawalReceiverError {
                errorMessage
            }
        }
    }
`;
export type CoreWithdrawMutationFn = Apollo.MutationFunction<CoreWithdrawMutation, CoreWithdrawMutationVariables>;

/**
 * __useCoreWithdrawMutation__
 *
 * To run a mutation, you first call `useCoreWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoreWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coreWithdrawMutation, { data, loading, error }] = useCoreWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoreWithdrawMutation(
    baseOptions?: Apollo.MutationHookOptions<CoreWithdrawMutation, CoreWithdrawMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CoreWithdrawMutation, CoreWithdrawMutationVariables>(CoreWithdrawDocument, options);
}
export type CoreWithdrawMutationHookResult = ReturnType<typeof useCoreWithdrawMutation>;
export type CoreWithdrawMutationResult = Apollo.MutationResult<CoreWithdrawMutation>;
export type CoreWithdrawMutationOptions = Apollo.BaseMutationOptions<
    CoreWithdrawMutation,
    CoreWithdrawMutationVariables
>;
export const CreateFeeDocument = gql`
    mutation CreateFee($input: CreateFeeInput!) {
        createFee(input: $input) {
            ... on CreateFeeSuccess {
                fee {
                    feeFix
                    feePercentage
                }
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on StoreNotFoundError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
        }
    }
`;
export type CreateFeeMutationFn = Apollo.MutationFunction<CreateFeeMutation, CreateFeeMutationVariables>;

/**
 * __useCreateFeeMutation__
 *
 * To run a mutation, you first call `useCreateFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeeMutation, { data, loading, error }] = useCreateFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeeMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateFeeMutation, CreateFeeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateFeeMutation, CreateFeeMutationVariables>(CreateFeeDocument, options);
}
export type CreateFeeMutationHookResult = ReturnType<typeof useCreateFeeMutation>;
export type CreateFeeMutationResult = Apollo.MutationResult<CreateFeeMutation>;
export type CreateFeeMutationOptions = Apollo.BaseMutationOptions<CreateFeeMutation, CreateFeeMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($name: String!, $permissions: [Permission]) {
        createGroup(input: { name: $name, permissions: $permissions }) {
            ... on CreateGroupSuccess {
                group {
                    id
                    name
                }
            }
        }
    }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
}
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderInput!) {
        createOrder(input: $input) {
            ... on CreateOrderSuccess {
                order {
                    ...OrderListItem
                }
            }
            ... on InvalidParamsError {
                paramName
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on StoreNotFoundError {
                errorMessage
            }
            ... on ServerProblemError {
                errorMessage
            }
        }
    }
    ${OrderListItemFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateStoreDocument = gql`
    mutation CreateStore($input: CreateStoreInput!) {
        createStore(input: $input) {
            ... on CreateStoreSuccess {
                privateKey
                me {
                    id
                    ...UserStores
                }
            }
            ... on NameAlreadyExistsError {
                errorMessage
            }
        }
    }
    ${UserStoresFragmentDoc}
`;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoreMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, options);
}
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const DeactivateFiatMethodDocument = gql`
    mutation DeactivateFiatMethod($input: DeactivateFiatMethodInput!) {
        deactivateFiatMethod(input: $input) {
            ... on DeactivateFiatMethodSuccess {
                direction
                location
            }
        }
    }
`;
export type DeactivateFiatMethodMutationFn = Apollo.MutationFunction<
    DeactivateFiatMethodMutation,
    DeactivateFiatMethodMutationVariables
>;

/**
 * __useDeactivateFiatMethodMutation__
 *
 * To run a mutation, you first call `useDeactivateFiatMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateFiatMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateFiatMethodMutation, { data, loading, error }] = useDeactivateFiatMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateFiatMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<DeactivateFiatMethodMutation, DeactivateFiatMethodMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeactivateFiatMethodMutation, DeactivateFiatMethodMutationVariables>(
        DeactivateFiatMethodDocument,
        options
    );
}
export type DeactivateFiatMethodMutationHookResult = ReturnType<typeof useDeactivateFiatMethodMutation>;
export type DeactivateFiatMethodMutationResult = Apollo.MutationResult<DeactivateFiatMethodMutation>;
export type DeactivateFiatMethodMutationOptions = Apollo.BaseMutationOptions<
    DeactivateFiatMethodMutation,
    DeactivateFiatMethodMutationVariables
>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
        deleteGroup(input: $input) {
            ... on DeleteGroupSuccess {
                id
            }
            ... on GroupNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
}
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteShopifyIntegrationDocument = gql`
    mutation DeleteShopifyIntegration($id: String!) {
        deleteShopifyIntegration(integration_id: $id) {
            ... on DeleteShopifyIntegrationSuccess {
                success
            }
            ... on ShopifyIntegrationNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteShopifyIntegrationMutationFn = Apollo.MutationFunction<
    DeleteShopifyIntegrationMutation,
    DeleteShopifyIntegrationMutationVariables
>;

/**
 * __useDeleteShopifyIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteShopifyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopifyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopifyIntegrationMutation, { data, loading, error }] = useDeleteShopifyIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShopifyIntegrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteShopifyIntegrationMutation,
        DeleteShopifyIntegrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteShopifyIntegrationMutation, DeleteShopifyIntegrationMutationVariables>(
        DeleteShopifyIntegrationDocument,
        options
    );
}
export type DeleteShopifyIntegrationMutationHookResult = ReturnType<typeof useDeleteShopifyIntegrationMutation>;
export type DeleteShopifyIntegrationMutationResult = Apollo.MutationResult<DeleteShopifyIntegrationMutation>;
export type DeleteShopifyIntegrationMutationOptions = Apollo.BaseMutationOptions<
    DeleteShopifyIntegrationMutation,
    DeleteShopifyIntegrationMutationVariables
>;
export const DepositDocument = gql`
    mutation Deposit($input: DepositInput!) {
        deposit(input: $input) {
            ... on DepositParams {
                memo
                receiver
                metaInfo
                deeplinks {
                    name
                    link
                }
                requisiteId
                expirationDate
                currencyId
                userId
            }
            ... on StoreNotFoundError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                paramName
                errorMessage
            }
            ... on ServerProblemError {
                errorMessage
            }
            ... on DepositLimitExceededError {
                errorMessage
            }
        }
    }
`;
export type DepositMutationFn = Apollo.MutationFunction<DepositMutation, DepositMutationVariables>;

/**
 * __useDepositMutation__
 *
 * To run a mutation, you first call `useDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositMutation, { data, loading, error }] = useDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepositMutation(
    baseOptions?: Apollo.MutationHookOptions<DepositMutation, DepositMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DepositMutation, DepositMutationVariables>(DepositDocument, options);
}
export type DepositMutationHookResult = ReturnType<typeof useDepositMutation>;
export type DepositMutationResult = Apollo.MutationResult<DepositMutation>;
export type DepositMutationOptions = Apollo.BaseMutationOptions<DepositMutation, DepositMutationVariables>;
export const FailTransferDocument = gql`
    mutation FailTransfer($transferId: String!, $password: String!) {
        failTransfer(transferId: $transferId, password: $password) {
            ... on FailTransferSuccess {
                transferId
            }
            ... on TransferNotFoundError {
                errorMessage
            }
            ... on WrongTransferStatusError {
                errorMessage
            }
            ... on WrongTransferTypeError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
        }
    }
`;
export type FailTransferMutationFn = Apollo.MutationFunction<FailTransferMutation, FailTransferMutationVariables>;

/**
 * __useFailTransferMutation__
 *
 * To run a mutation, you first call `useFailTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFailTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [failTransferMutation, { data, loading, error }] = useFailTransferMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useFailTransferMutation(
    baseOptions?: Apollo.MutationHookOptions<FailTransferMutation, FailTransferMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<FailTransferMutation, FailTransferMutationVariables>(FailTransferDocument, options);
}
export type FailTransferMutationHookResult = ReturnType<typeof useFailTransferMutation>;
export type FailTransferMutationResult = Apollo.MutationResult<FailTransferMutation>;
export type FailTransferMutationOptions = Apollo.BaseMutationOptions<
    FailTransferMutation,
    FailTransferMutationVariables
>;
export const FinalizeTransferDocument = gql`
    mutation FinalizeTransfer($transferId: String!, $password: String!) {
        finalizeTransfer(transferId: $transferId, password: $password) {
            ... on FinalizeTransferSuccess {
                transferId
            }
            ... on TransferNotFoundError {
                errorMessage
            }
            ... on WrongTransferStatusError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
        }
    }
`;
export type FinalizeTransferMutationFn = Apollo.MutationFunction<
    FinalizeTransferMutation,
    FinalizeTransferMutationVariables
>;

/**
 * __useFinalizeTransferMutation__
 *
 * To run a mutation, you first call `useFinalizeTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeTransferMutation, { data, loading, error }] = useFinalizeTransferMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useFinalizeTransferMutation(
    baseOptions?: Apollo.MutationHookOptions<FinalizeTransferMutation, FinalizeTransferMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<FinalizeTransferMutation, FinalizeTransferMutationVariables>(
        FinalizeTransferDocument,
        options
    );
}
export type FinalizeTransferMutationHookResult = ReturnType<typeof useFinalizeTransferMutation>;
export type FinalizeTransferMutationResult = Apollo.MutationResult<FinalizeTransferMutation>;
export type FinalizeTransferMutationOptions = Apollo.BaseMutationOptions<
    FinalizeTransferMutation,
    FinalizeTransferMutationVariables
>;
export const GetNewKeyDocument = gql`
    mutation GetNewKey($input: GetNewKeyInput!) {
        getNewKey(input: $input) {
            ... on GetNewKeySuccess {
                privateKey
                storeSecret
            }
        }
    }
`;
export type GetNewKeyMutationFn = Apollo.MutationFunction<GetNewKeyMutation, GetNewKeyMutationVariables>;

/**
 * __useGetNewKeyMutation__
 *
 * To run a mutation, you first call `useGetNewKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNewKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNewKeyMutation, { data, loading, error }] = useGetNewKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNewKeyMutation(
    baseOptions?: Apollo.MutationHookOptions<GetNewKeyMutation, GetNewKeyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GetNewKeyMutation, GetNewKeyMutationVariables>(GetNewKeyDocument, options);
}
export type GetNewKeyMutationHookResult = ReturnType<typeof useGetNewKeyMutation>;
export type GetNewKeyMutationResult = Apollo.MutationResult<GetNewKeyMutation>;
export type GetNewKeyMutationOptions = Apollo.BaseMutationOptions<GetNewKeyMutation, GetNewKeyMutationVariables>;
export const GiveDocument = gql`
    mutation Give($input: GiveInput!) {
        give(input: $input) {
            ... on WithdrawSuccess {
                me {
                    id
                }
                transferId
            }
            ... on InvalidParamsError {
                paramName
                errorMessage
            }
            ... on InsufficientFundsError {
                missingAmount
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on WithdrawDuplicationError {
                errorMessage
            }
            ... on ForbiddenWithdrawalReceiverError {
                errorMessage
            }
        }
    }
`;
export type GiveMutationFn = Apollo.MutationFunction<GiveMutation, GiveMutationVariables>;

/**
 * __useGiveMutation__
 *
 * To run a mutation, you first call `useGiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveMutation, { data, loading, error }] = useGiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiveMutation(baseOptions?: Apollo.MutationHookOptions<GiveMutation, GiveMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GiveMutation, GiveMutationVariables>(GiveDocument, options);
}
export type GiveMutationHookResult = ReturnType<typeof useGiveMutation>;
export type GiveMutationResult = Apollo.MutationResult<GiveMutation>;
export type GiveMutationOptions = Apollo.BaseMutationOptions<GiveMutation, GiveMutationVariables>;
export const NotifyOrderPaidDocument = gql`
    mutation NotifyOrderPaid($requisiteId: String!) {
        notifyOrderPaid(requisiteId: $requisiteId) {
            ... on CheckTransferSuccess {
                status
            }
            ... on ActionLockedError {
                errorMessage
                cooldown
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on OrderNotFoundError {
                errorMessage
            }
            ... on OrderPaidError {
                errorMessage
            }
            ... on OrderInactiveError {
                errorMessage
            }
        }
    }
`;
export type NotifyOrderPaidMutationFn = Apollo.MutationFunction<
    NotifyOrderPaidMutation,
    NotifyOrderPaidMutationVariables
>;

/**
 * __useNotifyOrderPaidMutation__
 *
 * To run a mutation, you first call `useNotifyOrderPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyOrderPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyOrderPaidMutation, { data, loading, error }] = useNotifyOrderPaidMutation({
 *   variables: {
 *      requisiteId: // value for 'requisiteId'
 *   },
 * });
 */
export function useNotifyOrderPaidMutation(
    baseOptions?: Apollo.MutationHookOptions<NotifyOrderPaidMutation, NotifyOrderPaidMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<NotifyOrderPaidMutation, NotifyOrderPaidMutationVariables>(
        NotifyOrderPaidDocument,
        options
    );
}
export type NotifyOrderPaidMutationHookResult = ReturnType<typeof useNotifyOrderPaidMutation>;
export type NotifyOrderPaidMutationResult = Apollo.MutationResult<NotifyOrderPaidMutation>;
export type NotifyOrderPaidMutationOptions = Apollo.BaseMutationOptions<
    NotifyOrderPaidMutation,
    NotifyOrderPaidMutationVariables
>;
export const PaybackOrderDocument = gql`
    mutation PaybackOrder($input: PaybackOrderInput!) {
        paybackOrder(input: $input) {
            ... on PaybackOrderSuccess {
                order {
                    id
                    hasPaybacks
                    totalPaybackAmount {
                        send
                        receive
                        fee
                    }
                }
            }
            ... on OrderNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on OrderNotPaidError {
                errorMessage
            }
            ... on InsufficientFundsError {
                missingAmount
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on OrderNotRefundableError {
                errorMessage
            }
        }
    }
`;
export type PaybackOrderMutationFn = Apollo.MutationFunction<PaybackOrderMutation, PaybackOrderMutationVariables>;

/**
 * __usePaybackOrderMutation__
 *
 * To run a mutation, you first call `usePaybackOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaybackOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paybackOrderMutation, { data, loading, error }] = usePaybackOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaybackOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<PaybackOrderMutation, PaybackOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<PaybackOrderMutation, PaybackOrderMutationVariables>(PaybackOrderDocument, options);
}
export type PaybackOrderMutationHookResult = ReturnType<typeof usePaybackOrderMutation>;
export type PaybackOrderMutationResult = Apollo.MutationResult<PaybackOrderMutation>;
export type PaybackOrderMutationOptions = Apollo.BaseMutationOptions<
    PaybackOrderMutation,
    PaybackOrderMutationVariables
>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
        refreshToken {
            ... on RefreshTokenSuccess {
                token
            }
        }
    }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>;
export const ReportIncomingTransfersDocument = gql`
    mutation ReportIncomingTransfers($filters: TransferFilterInput!, $sort: [TransferSortEnum!]) {
        reportIncomingTransfers(filters: $filters, sort: $sort) {
            uid
            type
        }
    }
`;
export type ReportIncomingTransfersMutationFn = Apollo.MutationFunction<
    ReportIncomingTransfersMutation,
    ReportIncomingTransfersMutationVariables
>;

/**
 * __useReportIncomingTransfersMutation__
 *
 * To run a mutation, you first call `useReportIncomingTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportIncomingTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportIncomingTransfersMutation, { data, loading, error }] = useReportIncomingTransfersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportIncomingTransfersMutation(
    baseOptions?: Apollo.MutationHookOptions<ReportIncomingTransfersMutation, ReportIncomingTransfersMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportIncomingTransfersMutation, ReportIncomingTransfersMutationVariables>(
        ReportIncomingTransfersDocument,
        options
    );
}
export type ReportIncomingTransfersMutationHookResult = ReturnType<typeof useReportIncomingTransfersMutation>;
export type ReportIncomingTransfersMutationResult = Apollo.MutationResult<ReportIncomingTransfersMutation>;
export type ReportIncomingTransfersMutationOptions = Apollo.BaseMutationOptions<
    ReportIncomingTransfersMutation,
    ReportIncomingTransfersMutationVariables
>;
export const ReportMyIncomingTransfersDocument = gql`
    mutation ReportMyIncomingTransfers($filters: TransferFilterInput!, $sort: [TransferSortEnum!]) {
        reportMyIncomingTransfers(filters: $filters, sort: $sort) {
            uid
            type
        }
    }
`;
export type ReportMyIncomingTransfersMutationFn = Apollo.MutationFunction<
    ReportMyIncomingTransfersMutation,
    ReportMyIncomingTransfersMutationVariables
>;

/**
 * __useReportMyIncomingTransfersMutation__
 *
 * To run a mutation, you first call `useReportMyIncomingTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMyIncomingTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMyIncomingTransfersMutation, { data, loading, error }] = useReportMyIncomingTransfersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportMyIncomingTransfersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ReportMyIncomingTransfersMutation,
        ReportMyIncomingTransfersMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportMyIncomingTransfersMutation, ReportMyIncomingTransfersMutationVariables>(
        ReportMyIncomingTransfersDocument,
        options
    );
}
export type ReportMyIncomingTransfersMutationHookResult = ReturnType<typeof useReportMyIncomingTransfersMutation>;
export type ReportMyIncomingTransfersMutationResult = Apollo.MutationResult<ReportMyIncomingTransfersMutation>;
export type ReportMyIncomingTransfersMutationOptions = Apollo.BaseMutationOptions<
    ReportMyIncomingTransfersMutation,
    ReportMyIncomingTransfersMutationVariables
>;
export const ReportMyOutgoingTransfersDocument = gql`
    mutation ReportMyOutgoingTransfers($filters: TransferFilterInput!, $sort: [TransferSortEnum!]) {
        reportMyOutgoingTransfers(filters: $filters, sort: $sort) {
            uid
            type
        }
    }
`;
export type ReportMyOutgoingTransfersMutationFn = Apollo.MutationFunction<
    ReportMyOutgoingTransfersMutation,
    ReportMyOutgoingTransfersMutationVariables
>;

/**
 * __useReportMyOutgoingTransfersMutation__
 *
 * To run a mutation, you first call `useReportMyOutgoingTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMyOutgoingTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMyOutgoingTransfersMutation, { data, loading, error }] = useReportMyOutgoingTransfersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportMyOutgoingTransfersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ReportMyOutgoingTransfersMutation,
        ReportMyOutgoingTransfersMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportMyOutgoingTransfersMutation, ReportMyOutgoingTransfersMutationVariables>(
        ReportMyOutgoingTransfersDocument,
        options
    );
}
export type ReportMyOutgoingTransfersMutationHookResult = ReturnType<typeof useReportMyOutgoingTransfersMutation>;
export type ReportMyOutgoingTransfersMutationResult = Apollo.MutationResult<ReportMyOutgoingTransfersMutation>;
export type ReportMyOutgoingTransfersMutationOptions = Apollo.BaseMutationOptions<
    ReportMyOutgoingTransfersMutation,
    ReportMyOutgoingTransfersMutationVariables
>;
export const ReportOutgoingTransfersDocument = gql`
    mutation ReportOutgoingTransfers($filters: TransferFilterInput!, $sort: [TransferSortEnum!]) {
        reportOutgoingTransfers(filters: $filters, sort: $sort) {
            uid
            type
        }
    }
`;
export type ReportOutgoingTransfersMutationFn = Apollo.MutationFunction<
    ReportOutgoingTransfersMutation,
    ReportOutgoingTransfersMutationVariables
>;

/**
 * __useReportOutgoingTransfersMutation__
 *
 * To run a mutation, you first call `useReportOutgoingTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportOutgoingTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportOutgoingTransfersMutation, { data, loading, error }] = useReportOutgoingTransfersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportOutgoingTransfersMutation(
    baseOptions?: Apollo.MutationHookOptions<ReportOutgoingTransfersMutation, ReportOutgoingTransfersMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportOutgoingTransfersMutation, ReportOutgoingTransfersMutationVariables>(
        ReportOutgoingTransfersDocument,
        options
    );
}
export type ReportOutgoingTransfersMutationHookResult = ReturnType<typeof useReportOutgoingTransfersMutation>;
export type ReportOutgoingTransfersMutationResult = Apollo.MutationResult<ReportOutgoingTransfersMutation>;
export type ReportOutgoingTransfersMutationOptions = Apollo.BaseMutationOptions<
    ReportOutgoingTransfersMutation,
    ReportOutgoingTransfersMutationVariables
>;
export const ResendCodeDocument = gql`
    mutation ResendCode {
        resendCode {
            ... on ResendCodeSuccess {
                token
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type ResendCodeMutationFn = Apollo.MutationFunction<ResendCodeMutation, ResendCodeMutationVariables>;

/**
 * __useResendCodeMutation__
 *
 * To run a mutation, you first call `useResendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCodeMutation, { data, loading, error }] = useResendCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<ResendCodeMutation, ResendCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResendCodeMutation, ResendCodeMutationVariables>(ResendCodeDocument, options);
}
export type ResendCodeMutationHookResult = ReturnType<typeof useResendCodeMutation>;
export type ResendCodeMutationResult = Apollo.MutationResult<ResendCodeMutation>;
export type ResendCodeMutationOptions = Apollo.BaseMutationOptions<ResendCodeMutation, ResendCodeMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            ... on ResetPasswordSuccess {
                token
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const RetryTransferDocument = gql`
    mutation RetryTransfer($transferId: String!, $password: String!) {
        retryTransfer(transferId: $transferId, password: $password) {
            ... on RetryTransferSuccess {
                transferId
            }
            ... on TransferNotFoundError {
                errorMessage
            }
            ... on WrongTransferStatusError {
                errorMessage
            }
            ... on WrongTransferTypeError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
        }
    }
`;
export type RetryTransferMutationFn = Apollo.MutationFunction<RetryTransferMutation, RetryTransferMutationVariables>;

/**
 * __useRetryTransferMutation__
 *
 * To run a mutation, you first call `useRetryTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryTransferMutation, { data, loading, error }] = useRetryTransferMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRetryTransferMutation(
    baseOptions?: Apollo.MutationHookOptions<RetryTransferMutation, RetryTransferMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RetryTransferMutation, RetryTransferMutationVariables>(RetryTransferDocument, options);
}
export type RetryTransferMutationHookResult = ReturnType<typeof useRetryTransferMutation>;
export type RetryTransferMutationResult = Apollo.MutationResult<RetryTransferMutation>;
export type RetryTransferMutationOptions = Apollo.BaseMutationOptions<
    RetryTransferMutation,
    RetryTransferMutationVariables
>;
export const SetExchangeRateDocument = gql`
    mutation SetExchangeRate($input: SetExchangeRateInput!) {
        setExchangeRate(input: $input) {
            ... on InvalidParamsError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on UpdateExchangeRateSuccess {
                success
            }
        }
    }
`;
export type SetExchangeRateMutationFn = Apollo.MutationFunction<
    SetExchangeRateMutation,
    SetExchangeRateMutationVariables
>;

/**
 * __useSetExchangeRateMutation__
 *
 * To run a mutation, you first call `useSetExchangeRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExchangeRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExchangeRateMutation, { data, loading, error }] = useSetExchangeRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExchangeRateMutation(
    baseOptions?: Apollo.MutationHookOptions<SetExchangeRateMutation, SetExchangeRateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetExchangeRateMutation, SetExchangeRateMutationVariables>(
        SetExchangeRateDocument,
        options
    );
}
export type SetExchangeRateMutationHookResult = ReturnType<typeof useSetExchangeRateMutation>;
export type SetExchangeRateMutationResult = Apollo.MutationResult<SetExchangeRateMutation>;
export type SetExchangeRateMutationOptions = Apollo.BaseMutationOptions<
    SetExchangeRateMutation,
    SetExchangeRateMutationVariables
>;
export const SetFeeDocument = gql`
    mutation SetFee($currencyId: String!, $isFeeEnabled: Boolean, $feeFix: String, $feePercentage: String) {
        setFee(
            input: {
                currencyId: $currencyId
                isFeeEnabled: $isFeeEnabled
                feeFix: $feeFix
                feePercentage: $feePercentage
            }
        ) {
            ... on SetFeeSuccess {
                currency {
                    id
                    isFeeEnabled
                    feeFix
                    feePercentage
                }
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type SetFeeMutationFn = Apollo.MutationFunction<SetFeeMutation, SetFeeMutationVariables>;

/**
 * __useSetFeeMutation__
 *
 * To run a mutation, you first call `useSetFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeeMutation, { data, loading, error }] = useSetFeeMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      isFeeEnabled: // value for 'isFeeEnabled'
 *      feeFix: // value for 'feeFix'
 *      feePercentage: // value for 'feePercentage'
 *   },
 * });
 */
export function useSetFeeMutation(baseOptions?: Apollo.MutationHookOptions<SetFeeMutation, SetFeeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetFeeMutation, SetFeeMutationVariables>(SetFeeDocument, options);
}
export type SetFeeMutationHookResult = ReturnType<typeof useSetFeeMutation>;
export type SetFeeMutationResult = Apollo.MutationResult<SetFeeMutation>;
export type SetFeeMutationOptions = Apollo.BaseMutationOptions<SetFeeMutation, SetFeeMutationVariables>;
export const SetFeeLimitDocument = gql`
    mutation SetFeeLimit($blockchain: FeeLimitBlockchain!, $feeLimit: String!) {
        setFeeLimit(input: { blockchain: $blockchain, feeLimit: $feeLimit }) {
            ... on InvalidParamsError {
                errorMessage
            }
            ... on SetFeeLimitSuccess {
                blockchain
            }
        }
    }
`;
export type SetFeeLimitMutationFn = Apollo.MutationFunction<SetFeeLimitMutation, SetFeeLimitMutationVariables>;

/**
 * __useSetFeeLimitMutation__
 *
 * To run a mutation, you first call `useSetFeeLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeeLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeeLimitMutation, { data, loading, error }] = useSetFeeLimitMutation({
 *   variables: {
 *      blockchain: // value for 'blockchain'
 *      feeLimit: // value for 'feeLimit'
 *   },
 * });
 */
export function useSetFeeLimitMutation(
    baseOptions?: Apollo.MutationHookOptions<SetFeeLimitMutation, SetFeeLimitMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetFeeLimitMutation, SetFeeLimitMutationVariables>(SetFeeLimitDocument, options);
}
export type SetFeeLimitMutationHookResult = ReturnType<typeof useSetFeeLimitMutation>;
export type SetFeeLimitMutationResult = Apollo.MutationResult<SetFeeLimitMutation>;
export type SetFeeLimitMutationOptions = Apollo.BaseMutationOptions<SetFeeLimitMutation, SetFeeLimitMutationVariables>;
export const SetFiatFeeDocument = gql`
    mutation SetFiatFee($input: SetFiatFeeInput!) {
        setFiatFee(input: $input) {
            ... on UpdateFiatMethodSuccess {
                fiatMethod {
                    id
                }
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
        }
    }
`;
export type SetFiatFeeMutationFn = Apollo.MutationFunction<SetFiatFeeMutation, SetFiatFeeMutationVariables>;

/**
 * __useSetFiatFeeMutation__
 *
 * To run a mutation, you first call `useSetFiatFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFiatFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFiatFeeMutation, { data, loading, error }] = useSetFiatFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFiatFeeMutation(
    baseOptions?: Apollo.MutationHookOptions<SetFiatFeeMutation, SetFiatFeeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetFiatFeeMutation, SetFiatFeeMutationVariables>(SetFiatFeeDocument, options);
}
export type SetFiatFeeMutationHookResult = ReturnType<typeof useSetFiatFeeMutation>;
export type SetFiatFeeMutationResult = Apollo.MutationResult<SetFiatFeeMutation>;
export type SetFiatFeeMutationOptions = Apollo.BaseMutationOptions<SetFiatFeeMutation, SetFiatFeeMutationVariables>;
export const SetLimitsDocument = gql`
    mutation SetLimits(
        $currencyId: String!
        $isDayLimitEnabled: Boolean
        $dayLimit: String
        $isAccountLimitEnabled: Boolean
        $accountLimit: String
        $isTxnLimitEnabled: Boolean
        $txnLimit: String
        $accountCap: String
        $dayCap: String
        $isAccountCapEnabled: Boolean
        $isDayCapEnabled: Boolean
        $isTxnCapEnabled: Boolean
        $txnCap: String
    ) {
        setLimits(
            input: {
                accountLimit: $accountLimit
                currencyId: $currencyId
                dayLimit: $dayLimit
                isDayLimitEnabled: $isDayLimitEnabled
                isAccountLimitEnabled: $isAccountLimitEnabled
                isTxnLimitEnabled: $isTxnLimitEnabled
                txnLimit: $txnLimit
                accountCap: $accountCap
                dayCap: $dayCap
                isAccountCapEnabled: $isAccountCapEnabled
                isDayCapEnabled: $isDayCapEnabled
                isTxnCapEnabled: $isTxnCapEnabled
                txnCap: $txnCap
            }
        ) {
            ... on SetLimitsSuccess {
                currency {
                    id
                    isDayLimitEnabled
                    dayLimit
                    isAccountLimitEnabled
                    accountLimit
                    isTxnLimitEnabled
                    txnLimit
                }
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type SetLimitsMutationFn = Apollo.MutationFunction<SetLimitsMutation, SetLimitsMutationVariables>;

/**
 * __useSetLimitsMutation__
 *
 * To run a mutation, you first call `useSetLimitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLimitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLimitsMutation, { data, loading, error }] = useSetLimitsMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      isDayLimitEnabled: // value for 'isDayLimitEnabled'
 *      dayLimit: // value for 'dayLimit'
 *      isAccountLimitEnabled: // value for 'isAccountLimitEnabled'
 *      accountLimit: // value for 'accountLimit'
 *      isTxnLimitEnabled: // value for 'isTxnLimitEnabled'
 *      txnLimit: // value for 'txnLimit'
 *      accountCap: // value for 'accountCap'
 *      dayCap: // value for 'dayCap'
 *      isAccountCapEnabled: // value for 'isAccountCapEnabled'
 *      isDayCapEnabled: // value for 'isDayCapEnabled'
 *      isTxnCapEnabled: // value for 'isTxnCapEnabled'
 *      txnCap: // value for 'txnCap'
 *   },
 * });
 */
export function useSetLimitsMutation(
    baseOptions?: Apollo.MutationHookOptions<SetLimitsMutation, SetLimitsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetLimitsMutation, SetLimitsMutationVariables>(SetLimitsDocument, options);
}
export type SetLimitsMutationHookResult = ReturnType<typeof useSetLimitsMutation>;
export type SetLimitsMutationResult = Apollo.MutationResult<SetLimitsMutation>;
export type SetLimitsMutationOptions = Apollo.BaseMutationOptions<SetLimitsMutation, SetLimitsMutationVariables>;
export const SetWithdrawFeeDocument = gql`
    mutation SetWithdrawFee($currencyId: String!, $isFeeEnabled: Boolean, $feeFix: String, $feePercentage: String) {
        setWithdrawFee(
            input: {
                currencyId: $currencyId
                isFeeEnabled: $isFeeEnabled
                feeFix: $feeFix
                feePercentage: $feePercentage
            }
        ) {
            ... on SetWithdrawFeeSuccess {
                currency {
                    id
                    isFeeEnabled
                    feeFix
                    feePercentage
                }
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type SetWithdrawFeeMutationFn = Apollo.MutationFunction<SetWithdrawFeeMutation, SetWithdrawFeeMutationVariables>;

/**
 * __useSetWithdrawFeeMutation__
 *
 * To run a mutation, you first call `useSetWithdrawFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWithdrawFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWithdrawFeeMutation, { data, loading, error }] = useSetWithdrawFeeMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      isFeeEnabled: // value for 'isFeeEnabled'
 *      feeFix: // value for 'feeFix'
 *      feePercentage: // value for 'feePercentage'
 *   },
 * });
 */
export function useSetWithdrawFeeMutation(
    baseOptions?: Apollo.MutationHookOptions<SetWithdrawFeeMutation, SetWithdrawFeeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetWithdrawFeeMutation, SetWithdrawFeeMutationVariables>(SetWithdrawFeeDocument, options);
}
export type SetWithdrawFeeMutationHookResult = ReturnType<typeof useSetWithdrawFeeMutation>;
export type SetWithdrawFeeMutationResult = Apollo.MutationResult<SetWithdrawFeeMutation>;
export type SetWithdrawFeeMutationOptions = Apollo.BaseMutationOptions<
    SetWithdrawFeeMutation,
    SetWithdrawFeeMutationVariables
>;
export const SigninDocument = gql`
    mutation Signin($input: SigninInput!) {
        signin(input: $input) {
            ... on SigninSuccess {
                token
                nextStep
                me {
                    permissions {
                        name
                    }
                }
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
        signup(input: $input) {
            ... on SignupSuccess {
                token
                nextStep
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on InvalidPhoneError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const SwitchCoreDocument = gql`
    mutation SwitchCore($input: SwitchCoreInput!) {
        switchCore(input: $input) {
            ... on SwitchCoreSuccess {
                isEnabled
            }
        }
    }
`;
export type SwitchCoreMutationFn = Apollo.MutationFunction<SwitchCoreMutation, SwitchCoreMutationVariables>;

/**
 * __useSwitchCoreMutation__
 *
 * To run a mutation, you first call `useSwitchCoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchCoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchCoreMutation, { data, loading, error }] = useSwitchCoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchCoreMutation(
    baseOptions?: Apollo.MutationHookOptions<SwitchCoreMutation, SwitchCoreMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SwitchCoreMutation, SwitchCoreMutationVariables>(SwitchCoreDocument, options);
}
export type SwitchCoreMutationHookResult = ReturnType<typeof useSwitchCoreMutation>;
export type SwitchCoreMutationResult = Apollo.MutationResult<SwitchCoreMutation>;
export type SwitchCoreMutationOptions = Apollo.BaseMutationOptions<SwitchCoreMutation, SwitchCoreMutationVariables>;
export const TestCancelOrderDocument = gql`
    mutation TestCancelOrder($input: CancelOrderInput!) {
        testCancelOrder(input: $input) {
            ... on CancelOrderSuccess {
                order {
                    id
                    status
                }
            }
            ... on OrderNotFoundError {
                errorMessage
            }
            ... on OrderInactiveError {
                errorMessage
            }
            ... on OrderPaidError {
                errorMessage
            }
        }
    }
`;
export type TestCancelOrderMutationFn = Apollo.MutationFunction<
    TestCancelOrderMutation,
    TestCancelOrderMutationVariables
>;

/**
 * __useTestCancelOrderMutation__
 *
 * To run a mutation, you first call `useTestCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCancelOrderMutation, { data, loading, error }] = useTestCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestCancelOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<TestCancelOrderMutation, TestCancelOrderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TestCancelOrderMutation, TestCancelOrderMutationVariables>(
        TestCancelOrderDocument,
        options
    );
}
export type TestCancelOrderMutationHookResult = ReturnType<typeof useTestCancelOrderMutation>;
export type TestCancelOrderMutationResult = Apollo.MutationResult<TestCancelOrderMutation>;
export type TestCancelOrderMutationOptions = Apollo.BaseMutationOptions<
    TestCancelOrderMutation,
    TestCancelOrderMutationVariables
>;
export const TestExpireDocument = gql`
    mutation TestExpire($input: TestExpireInput!) {
        testExpire(input: $input) {
            ... on TestExpireSuccess {
                order {
                    id
                    status
                }
            }
            ... on OrderNotFoundError {
                errorMessage
            }
            ... on CantExpireError {
                errorMessage
            }
        }
    }
`;
export type TestExpireMutationFn = Apollo.MutationFunction<TestExpireMutation, TestExpireMutationVariables>;

/**
 * __useTestExpireMutation__
 *
 * To run a mutation, you first call `useTestExpireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestExpireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testExpireMutation, { data, loading, error }] = useTestExpireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestExpireMutation(
    baseOptions?: Apollo.MutationHookOptions<TestExpireMutation, TestExpireMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TestExpireMutation, TestExpireMutationVariables>(TestExpireDocument, options);
}
export type TestExpireMutationHookResult = ReturnType<typeof useTestExpireMutation>;
export type TestExpireMutationResult = Apollo.MutationResult<TestExpireMutation>;
export type TestExpireMutationOptions = Apollo.BaseMutationOptions<TestExpireMutation, TestExpireMutationVariables>;
export const TestPaymentDocument = gql`
    mutation TestPayment($input: TestPaymentInput!) {
        testPayment(input: $input) {
            ... on TestPaymentSuccess {
                order {
                    id
                    status
                }
            }
            ... on OrderNotFoundError {
                errorMessage
            }
        }
    }
`;
export type TestPaymentMutationFn = Apollo.MutationFunction<TestPaymentMutation, TestPaymentMutationVariables>;

/**
 * __useTestPaymentMutation__
 *
 * To run a mutation, you first call `useTestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testPaymentMutation, { data, loading, error }] = useTestPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestPaymentMutation(
    baseOptions?: Apollo.MutationHookOptions<TestPaymentMutation, TestPaymentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TestPaymentMutation, TestPaymentMutationVariables>(TestPaymentDocument, options);
}
export type TestPaymentMutationHookResult = ReturnType<typeof useTestPaymentMutation>;
export type TestPaymentMutationResult = Apollo.MutationResult<TestPaymentMutation>;
export type TestPaymentMutationOptions = Apollo.BaseMutationOptions<TestPaymentMutation, TestPaymentMutationVariables>;
export const ToggleCurrencyDocument = gql`
    mutation ToggleCurrency($input: ToggleCurrencyInput!) {
        toggleCurrency(input: $input) {
            ... on ToggleCurrencySuccess {
                currency {
                    id
                    isEnabled
                    isIncomeEnabled
                    isOutcomeEnabled
                }
            }
        }
    }
`;
export type ToggleCurrencyMutationFn = Apollo.MutationFunction<ToggleCurrencyMutation, ToggleCurrencyMutationVariables>;

/**
 * __useToggleCurrencyMutation__
 *
 * To run a mutation, you first call `useToggleCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCurrencyMutation, { data, loading, error }] = useToggleCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCurrencyMutation(
    baseOptions?: Apollo.MutationHookOptions<ToggleCurrencyMutation, ToggleCurrencyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ToggleCurrencyMutation, ToggleCurrencyMutationVariables>(ToggleCurrencyDocument, options);
}
export type ToggleCurrencyMutationHookResult = ReturnType<typeof useToggleCurrencyMutation>;
export type ToggleCurrencyMutationResult = Apollo.MutationResult<ToggleCurrencyMutation>;
export type ToggleCurrencyMutationOptions = Apollo.BaseMutationOptions<
    ToggleCurrencyMutation,
    ToggleCurrencyMutationVariables
>;
export const TogglePermissionDocument = gql`
    mutation TogglePermission($groupid: String!, $perm: Permission!, $toggle: Boolean!) {
        togglePermission(input: { groupId: $groupid, permission: $perm, toggle: $toggle }) {
            ... on TogglePermissionSuccess {
                permission
            }
            ... on GroupNotFoundError {
                errorMessage
            }
        }
    }
`;
export type TogglePermissionMutationFn = Apollo.MutationFunction<
    TogglePermissionMutation,
    TogglePermissionMutationVariables
>;

/**
 * __useTogglePermissionMutation__
 *
 * To run a mutation, you first call `useTogglePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePermissionMutation, { data, loading, error }] = useTogglePermissionMutation({
 *   variables: {
 *      groupid: // value for 'groupid'
 *      perm: // value for 'perm'
 *      toggle: // value for 'toggle'
 *   },
 * });
 */
export function useTogglePermissionMutation(
    baseOptions?: Apollo.MutationHookOptions<TogglePermissionMutation, TogglePermissionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TogglePermissionMutation, TogglePermissionMutationVariables>(
        TogglePermissionDocument,
        options
    );
}
export type TogglePermissionMutationHookResult = ReturnType<typeof useTogglePermissionMutation>;
export type TogglePermissionMutationResult = Apollo.MutationResult<TogglePermissionMutation>;
export type TogglePermissionMutationOptions = Apollo.BaseMutationOptions<
    TogglePermissionMutation,
    TogglePermissionMutationVariables
>;
export const ToggleUserFiatDocument = gql`
    mutation toggleUserFiat($input: ToggleUserFiatInput!) {
        toggleUserFiat(input: $input) {
            ... on ToggleUserFiatSuccess {
                user {
                    id
                }
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
        }
    }
`;
export type ToggleUserFiatMutationFn = Apollo.MutationFunction<ToggleUserFiatMutation, ToggleUserFiatMutationVariables>;

/**
 * __useToggleUserFiatMutation__
 *
 * To run a mutation, you first call `useToggleUserFiatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserFiatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserFiatMutation, { data, loading, error }] = useToggleUserFiatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserFiatMutation(
    baseOptions?: Apollo.MutationHookOptions<ToggleUserFiatMutation, ToggleUserFiatMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ToggleUserFiatMutation, ToggleUserFiatMutationVariables>(ToggleUserFiatDocument, options);
}
export type ToggleUserFiatMutationHookResult = ReturnType<typeof useToggleUserFiatMutation>;
export type ToggleUserFiatMutationResult = Apollo.MutationResult<ToggleUserFiatMutation>;
export type ToggleUserFiatMutationOptions = Apollo.BaseMutationOptions<
    ToggleUserFiatMutation,
    ToggleUserFiatMutationVariables
>;
export const UpdateFiatLimitsDocument = gql`
    mutation UpdateFiatLimits($input: UpdateFiatLimitsInput!) {
        updateFiatLimits(input: $input) {
            ... on UpdateFiatLimitsSuccess {
                success
            }
            ... on InvalidParamsError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdateFiatLimitsMutationFn = Apollo.MutationFunction<
    UpdateFiatLimitsMutation,
    UpdateFiatLimitsMutationVariables
>;

/**
 * __useUpdateFiatLimitsMutation__
 *
 * To run a mutation, you first call `useUpdateFiatLimitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFiatLimitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFiatLimitsMutation, { data, loading, error }] = useUpdateFiatLimitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFiatLimitsMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateFiatLimitsMutation, UpdateFiatLimitsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateFiatLimitsMutation, UpdateFiatLimitsMutationVariables>(
        UpdateFiatLimitsDocument,
        options
    );
}
export type UpdateFiatLimitsMutationHookResult = ReturnType<typeof useUpdateFiatLimitsMutation>;
export type UpdateFiatLimitsMutationResult = Apollo.MutationResult<UpdateFiatLimitsMutation>;
export type UpdateFiatLimitsMutationOptions = Apollo.BaseMutationOptions<
    UpdateFiatLimitsMutation,
    UpdateFiatLimitsMutationVariables
>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($id: String!, $name: String!) {
        updateGroup(input: { id: $id, name: $name }) {
            ... on UpdateGroupSuccess {
                group {
                    id
                    name
                }
            }
        }
    }
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
}
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const UpdateShopifyIntegrationDocument = gql`
    mutation UpdateShopifyIntegration($input: UpdateShopifyIntegrationInput!) {
        updateShopifyIntegration(input: $input) {
            ... on UpdateShopifyIntegrationSuccess {
                integration {
                    id
                    shopName
                    created
                    isActive
                    store {
                        id
                        name
                    }
                    currency
                }
            }
            ... on ShopifyIntegrationNotFoundError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
            ... on InvalidParamsError {
                paramName
                errorMessage
            }
            ... on ShopifyPermissionRequiredError {
                permissions
                errorMessage
            }
        }
    }
`;
export type UpdateShopifyIntegrationMutationFn = Apollo.MutationFunction<
    UpdateShopifyIntegrationMutation,
    UpdateShopifyIntegrationMutationVariables
>;

/**
 * __useUpdateShopifyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyIntegrationMutation, { data, loading, error }] = useUpdateShopifyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopifyIntegrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateShopifyIntegrationMutation,
        UpdateShopifyIntegrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateShopifyIntegrationMutation, UpdateShopifyIntegrationMutationVariables>(
        UpdateShopifyIntegrationDocument,
        options
    );
}
export type UpdateShopifyIntegrationMutationHookResult = ReturnType<typeof useUpdateShopifyIntegrationMutation>;
export type UpdateShopifyIntegrationMutationResult = Apollo.MutationResult<UpdateShopifyIntegrationMutation>;
export type UpdateShopifyIntegrationMutationOptions = Apollo.BaseMutationOptions<
    UpdateShopifyIntegrationMutation,
    UpdateShopifyIntegrationMutationVariables
>;
export const UpdateStoreDocument = gql`
    mutation UpdateStore($input: UpdateStoreInput!) {
        updateStore(input: $input) {
            ... on UpdateStoreSuccess {
                me {
                    id
                    ...UserStores
                }
            }
            ... on StoreNotFoundError {
                errorMessage
            }
            ... on NameAlreadyExistsError {
                errorMessage
            }
        }
    }
    ${UserStoresFragmentDoc}
`;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoreMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, options);
}
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const ValidateWithdrawDocument = gql`
    mutation ValidateWithdraw($input: WithdrawInput!, $method: PaymentMethod) {
        validateWithdrawal(input: $input, method: $method) {
            ... on WithdrawSuccess {
                transferId
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on InsufficientFundsError {
                errorMessage
                missingAmount
            }
            ... on WithdrawalAmountTooLowError {
                errorMessage
                minimalAmount
            }
            ... on WithdrawalAmountTooHighError {
                errorMessage
                maximalAmount
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on WithdrawLimitExceededError {
                errorMessage
            }
            ... on WithdrawDuplicationError {
                errorMessage
            }
            ... on FiatWithdrawalsNotAllowedError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on ForbiddenWithdrawalReceiverError {
                errorMessage
            }
            ... on FiatTransferNotPossibleError {
                errorMessage
            }
            ... on DuplicateTransferError {
                errorMessage
            }
        }
    }
`;
export type ValidateWithdrawMutationFn = Apollo.MutationFunction<
    ValidateWithdrawMutation,
    ValidateWithdrawMutationVariables
>;

/**
 * __useValidateWithdrawMutation__
 *
 * To run a mutation, you first call `useValidateWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateWithdrawMutation, { data, loading, error }] = useValidateWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useValidateWithdrawMutation(
    baseOptions?: Apollo.MutationHookOptions<ValidateWithdrawMutation, ValidateWithdrawMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ValidateWithdrawMutation, ValidateWithdrawMutationVariables>(
        ValidateWithdrawDocument,
        options
    );
}
export type ValidateWithdrawMutationHookResult = ReturnType<typeof useValidateWithdrawMutation>;
export type ValidateWithdrawMutationResult = Apollo.MutationResult<ValidateWithdrawMutation>;
export type ValidateWithdrawMutationOptions = Apollo.BaseMutationOptions<
    ValidateWithdrawMutation,
    ValidateWithdrawMutationVariables
>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($input: VerifyCodeInput!) {
        verifyCode(input: $input) {
            ... on VerifyCodeSuccess {
                token
                me {
                    id
                    phone
                    email
                }
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on VerifyCodeExpiredError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
        }
    }
`;
export type VerifyCodeMutationFn = Apollo.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, options);
}
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const WithdrawDocument = gql`
    mutation Withdraw($input: WithdrawInput!) {
        withdraw(input: $input) {
            ... on WithdrawSuccess {
                me {
                    id
                    ...MyBalancesData
                }
                transferId
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on InsufficientFundsError {
                errorMessage
                missingAmount
            }
            ... on WithdrawalAmountTooLowError {
                errorMessage
                minimalAmount
            }
            ... on WithdrawalAmountTooHighError {
                errorMessage
                maximalAmount
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on WithdrawLimitExceededError {
                errorMessage
            }
            ... on WithdrawDuplicationError {
                errorMessage
            }
            ... on FiatWithdrawalsNotAllowedError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on EosAccountNotExistsError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on ForbiddenWithdrawalReceiverError {
                errorMessage
            }
            ... on FiatTransferNotPossibleError {
                errorMessage
            }
            ... on DuplicateTransferError {
                errorMessage
            }
        }
    }
    ${MyBalancesDataFragmentDoc}
`;
export type WithdrawMutationFn = Apollo.MutationFunction<WithdrawMutation, WithdrawMutationVariables>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawMutation(
    baseOptions?: Apollo.MutationHookOptions<WithdrawMutation, WithdrawMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(WithdrawDocument, options);
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<WithdrawMutation, WithdrawMutationVariables>;
export const AcquiringFiatMethodsDocument = gql`
    query acquiringFiatMethods($isActive: Boolean) {
        fiatMethods(isActive: $isActive) {
            id
            name
            feeFix
            balance
            location
            isActive
            direction
            currencyName
            feePercentage
            minimalAmount
            maximalAmount
            rates {
                currency
                currencyName
                fiatMethod
                rate
            }
        }
    }
`;

/**
 * __useAcquiringFiatMethodsQuery__
 *
 * To run a query within a React component, call `useAcquiringFiatMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquiringFiatMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiringFiatMethodsQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useAcquiringFiatMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<AcquiringFiatMethodsQuery, AcquiringFiatMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AcquiringFiatMethodsQuery, AcquiringFiatMethodsQueryVariables>(
        AcquiringFiatMethodsDocument,
        options
    );
}
export function useAcquiringFiatMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AcquiringFiatMethodsQuery, AcquiringFiatMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AcquiringFiatMethodsQuery, AcquiringFiatMethodsQueryVariables>(
        AcquiringFiatMethodsDocument,
        options
    );
}
export type AcquiringFiatMethodsQueryHookResult = ReturnType<typeof useAcquiringFiatMethodsQuery>;
export type AcquiringFiatMethodsLazyQueryHookResult = ReturnType<typeof useAcquiringFiatMethodsLazyQuery>;
export type AcquiringFiatMethodsQueryResult = Apollo.QueryResult<
    AcquiringFiatMethodsQuery,
    AcquiringFiatMethodsQueryVariables
>;
export const AllTransfersDocument = gql`
    query AllTransfers($filters: TransferFilterInput, $first: Int, $offset: Int, $sort: [TransferSortEnum]) {
        allTransfers(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            transfers {
                transferId
                orderId
                storeId
                status
                sequentNumber
                type
                createdDate
                paymentDate
                account
                isTest
                userId
                declineReason {
                    code
                    message {
                        ru
                        en
                    }
                }
                originId
                fiatFee
                fiatAmount
                fiatMethod {
                    id
                    name
                    currencyName
                }
                currency {
                    id
                    smartContract
                    name
                    precission
                    blockchain
                }
                storeName
                amount {
                    send
                    receive
                    fee
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useAllTransfersQuery__
 *
 * To run a query within a React component, call `useAllTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTransfersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAllTransfersQuery(
    baseOptions?: Apollo.QueryHookOptions<AllTransfersQuery, AllTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AllTransfersQuery, AllTransfersQueryVariables>(AllTransfersDocument, options);
}
export function useAllTransfersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AllTransfersQuery, AllTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AllTransfersQuery, AllTransfersQueryVariables>(AllTransfersDocument, options);
}
export type AllTransfersQueryHookResult = ReturnType<typeof useAllTransfersQuery>;
export type AllTransfersLazyQueryHookResult = ReturnType<typeof useAllTransfersLazyQuery>;
export type AllTransfersQueryResult = Apollo.QueryResult<AllTransfersQuery, AllTransfersQueryVariables>;
export const CoreBalancesDocument = gql`
    query CoreBalances {
        coreBalances {
            amount
            currency {
                id
                name
            }
        }
    }
`;

/**
 * __useCoreBalancesQuery__
 *
 * To run a query within a React component, call `useCoreBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoreBalancesQuery(
    baseOptions?: Apollo.QueryHookOptions<CoreBalancesQuery, CoreBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CoreBalancesQuery, CoreBalancesQueryVariables>(CoreBalancesDocument, options);
}
export function useCoreBalancesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CoreBalancesQuery, CoreBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CoreBalancesQuery, CoreBalancesQueryVariables>(CoreBalancesDocument, options);
}
export type CoreBalancesQueryHookResult = ReturnType<typeof useCoreBalancesQuery>;
export type CoreBalancesLazyQueryHookResult = ReturnType<typeof useCoreBalancesLazyQuery>;
export type CoreBalancesQueryResult = Apollo.QueryResult<CoreBalancesQuery, CoreBalancesQueryVariables>;
export const CoreDepositParamsDocument = gql`
    query CoreDepositParams($currencyId: String!) {
        coreDepositParams(currencyId: $currencyId) {
            memo
            metaInfo
            deeplink
            receiver
        }
    }
`;

/**
 * __useCoreDepositParamsQuery__
 *
 * To run a query within a React component, call `useCoreDepositParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreDepositParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreDepositParamsQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useCoreDepositParamsQuery(
    baseOptions: Apollo.QueryHookOptions<CoreDepositParamsQuery, CoreDepositParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CoreDepositParamsQuery, CoreDepositParamsQueryVariables>(CoreDepositParamsDocument, options);
}
export function useCoreDepositParamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CoreDepositParamsQuery, CoreDepositParamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CoreDepositParamsQuery, CoreDepositParamsQueryVariables>(
        CoreDepositParamsDocument,
        options
    );
}
export type CoreDepositParamsQueryHookResult = ReturnType<typeof useCoreDepositParamsQuery>;
export type CoreDepositParamsLazyQueryHookResult = ReturnType<typeof useCoreDepositParamsLazyQuery>;
export type CoreDepositParamsQueryResult = Apollo.QueryResult<CoreDepositParamsQuery, CoreDepositParamsQueryVariables>;
export const CoreResourcesDocument = gql`
    query CoreResources {
        coreResources {
            ramQuota
            ramUsage
            ramPercent
            netLimit {
                available
                max
                percent
            }
            cpuLimit {
                available
                max
                percent
            }
        }
    }
`;

/**
 * __useCoreResourcesQuery__
 *
 * To run a query within a React component, call `useCoreResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoreResourcesQuery(
    baseOptions?: Apollo.QueryHookOptions<CoreResourcesQuery, CoreResourcesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CoreResourcesQuery, CoreResourcesQueryVariables>(CoreResourcesDocument, options);
}
export function useCoreResourcesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CoreResourcesQuery, CoreResourcesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CoreResourcesQuery, CoreResourcesQueryVariables>(CoreResourcesDocument, options);
}
export type CoreResourcesQueryHookResult = ReturnType<typeof useCoreResourcesQuery>;
export type CoreResourcesLazyQueryHookResult = ReturnType<typeof useCoreResourcesLazyQuery>;
export type CoreResourcesQueryResult = Apollo.QueryResult<CoreResourcesQuery, CoreResourcesQueryVariables>;
export const CoreWalletsDocument = gql`
    query CoreWallets($first: Int, $offset: Int, $filters: WalletsFilterInput!, $sort: WalletSort) {
        coreWallets(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            blockchain
            wallets {
                address
                number
                smartContract
                amount
                nativeAmount
                currency {
                    ...Currency
                }
                updated
                isOutgoing
                isIncoming
                isActive
            }
            nativeAmount
            usdtAmount
            count
        }
    }
    ${CurrencyFragmentDoc}
`;

/**
 * __useCoreWalletsQuery__
 *
 * To run a query within a React component, call `useCoreWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreWalletsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCoreWalletsQuery(baseOptions: Apollo.QueryHookOptions<CoreWalletsQuery, CoreWalletsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CoreWalletsQuery, CoreWalletsQueryVariables>(CoreWalletsDocument, options);
}
export function useCoreWalletsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CoreWalletsQuery, CoreWalletsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CoreWalletsQuery, CoreWalletsQueryVariables>(CoreWalletsDocument, options);
}
export type CoreWalletsQueryHookResult = ReturnType<typeof useCoreWalletsQuery>;
export type CoreWalletsLazyQueryHookResult = ReturnType<typeof useCoreWalletsLazyQuery>;
export type CoreWalletsQueryResult = Apollo.QueryResult<CoreWalletsQuery, CoreWalletsQueryVariables>;
export const CurrenciesDocument = gql`
    query Currencies {
        currencies {
            ...Currency
        }
    }
    ${CurrencyFragmentDoc}
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
}
export function useCurrenciesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const FiatMethodsDocument = gql`
    query fiatMethods($isActive: Boolean) {
        fiatMethods(isActive: $isActive) {
            id
            name
            direction
            location
            currencyName
            feePercentage
            feeFix
            isActive
            minimalAmount
            maximalAmount
            rates {
                currency
                currencyName
                fiatMethod
                rate
            }
            fee {
                feeFix
                feePercentage
            }
        }
    }
`;

/**
 * __useFiatMethodsQuery__
 *
 * To run a query within a React component, call `useFiatMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiatMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiatMethodsQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useFiatMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<FiatMethodsQuery, FiatMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FiatMethodsQuery, FiatMethodsQueryVariables>(FiatMethodsDocument, options);
}
export function useFiatMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FiatMethodsQuery, FiatMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FiatMethodsQuery, FiatMethodsQueryVariables>(FiatMethodsDocument, options);
}
export type FiatMethodsQueryHookResult = ReturnType<typeof useFiatMethodsQuery>;
export type FiatMethodsLazyQueryHookResult = ReturnType<typeof useFiatMethodsLazyQuery>;
export type FiatMethodsQueryResult = Apollo.QueryResult<FiatMethodsQuery, FiatMethodsQueryVariables>;
export const FiatMethodStatsDocument = gql`
    query fiatMethodStats {
        fiatMethodStats {
            methods {
                ...FiatPeriodStat
            }
            total {
                ...FiatTotalStat
            }
        }
    }
    ${FiatPeriodStatFragmentDoc}
    ${FiatTotalStatFragmentDoc}
`;

/**
 * __useFiatMethodStatsQuery__
 *
 * To run a query within a React component, call `useFiatMethodStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiatMethodStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiatMethodStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFiatMethodStatsQuery(
    baseOptions?: Apollo.QueryHookOptions<FiatMethodStatsQuery, FiatMethodStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FiatMethodStatsQuery, FiatMethodStatsQueryVariables>(FiatMethodStatsDocument, options);
}
export function useFiatMethodStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FiatMethodStatsQuery, FiatMethodStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FiatMethodStatsQuery, FiatMethodStatsQueryVariables>(FiatMethodStatsDocument, options);
}
export type FiatMethodStatsQueryHookResult = ReturnType<typeof useFiatMethodStatsQuery>;
export type FiatMethodStatsLazyQueryHookResult = ReturnType<typeof useFiatMethodStatsLazyQuery>;
export type FiatMethodStatsQueryResult = Apollo.QueryResult<FiatMethodStatsQuery, FiatMethodStatsQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
        groups {
            id
            name
            users {
                id
                email
            }
            permissions {
                name
            }
        }
    }
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const IncomingTransfersDocument = gql`
    query IncomingTransfers($filters: TransferFilterInput, $first: Int, $offset: Int, $sort: [TransferSortEnum]) {
        incomeLog(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            transfers {
                transferId
                orderId
                storeId
                status
                sequentNumber
                type
                createdDate
                paymentDate
                account
                isTest
                userId
                declineReason {
                    code
                    message {
                        en
                        ru
                    }
                }
                originId
                fiatFee
                fiatAmount
                fiatMethod {
                    id
                    name
                    currencyName
                }
                currency {
                    id
                    smartContract
                    name
                    precission
                    blockchain
                }
                storeName
                amount {
                    send
                    receive
                    fee
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useIncomingTransfersQuery__
 *
 * To run a query within a React component, call `useIncomingTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingTransfersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useIncomingTransfersQuery(
    baseOptions?: Apollo.QueryHookOptions<IncomingTransfersQuery, IncomingTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<IncomingTransfersQuery, IncomingTransfersQueryVariables>(IncomingTransfersDocument, options);
}
export function useIncomingTransfersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IncomingTransfersQuery, IncomingTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IncomingTransfersQuery, IncomingTransfersQueryVariables>(
        IncomingTransfersDocument,
        options
    );
}
export type IncomingTransfersQueryHookResult = ReturnType<typeof useIncomingTransfersQuery>;
export type IncomingTransfersLazyQueryHookResult = ReturnType<typeof useIncomingTransfersLazyQuery>;
export type IncomingTransfersQueryResult = Apollo.QueryResult<IncomingTransfersQuery, IncomingTransfersQueryVariables>;
export const MeDocument = gql`
    query Me {
        me {
            id
            phone
            email
            allowFiatWithdrawals
            allowFiatPayments
            permissions {
                name
            }
            hasPassword
            ...UserStores
            ...MyBalancesData
        }
        settings {
            coreEnabled
            internalTransferFee
        }
    }
    ${UserStoresFragmentDoc}
    ${MyBalancesDataFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MerchantsDocument = gql`
    query Merchants($filters: MerchantFilterInput, $first: Int, $offset: Int, $sort: [UserSortEnum]) {
        merchants(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            pageInfo {
                total
                totalFiltered
            }
            users {
                ...User
            }
        }
    }
    ${UserFragmentDoc}
`;

/**
 * __useMerchantsQuery__
 *
 * To run a query within a React component, call `useMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMerchantsQuery(baseOptions?: Apollo.QueryHookOptions<MerchantsQuery, MerchantsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
}
export function useMerchantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MerchantsQuery, MerchantsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
}
export type MerchantsQueryHookResult = ReturnType<typeof useMerchantsQuery>;
export type MerchantsLazyQueryHookResult = ReturnType<typeof useMerchantsLazyQuery>;
export type MerchantsQueryResult = Apollo.QueryResult<MerchantsQuery, MerchantsQueryVariables>;
export const MerchantsBalancesDocument = gql`
    query MerchantsBalances {
        merchantsBalances {
            amount
            currency {
                id
                name
                blockchain
            }
        }
    }
`;

/**
 * __useMerchantsBalancesQuery__
 *
 * To run a query within a React component, call `useMerchantsBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantsBalancesQuery(
    baseOptions?: Apollo.QueryHookOptions<MerchantsBalancesQuery, MerchantsBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MerchantsBalancesQuery, MerchantsBalancesQueryVariables>(MerchantsBalancesDocument, options);
}
export function useMerchantsBalancesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MerchantsBalancesQuery, MerchantsBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MerchantsBalancesQuery, MerchantsBalancesQueryVariables>(
        MerchantsBalancesDocument,
        options
    );
}
export type MerchantsBalancesQueryHookResult = ReturnType<typeof useMerchantsBalancesQuery>;
export type MerchantsBalancesLazyQueryHookResult = ReturnType<typeof useMerchantsBalancesLazyQuery>;
export type MerchantsBalancesQueryResult = Apollo.QueryResult<MerchantsBalancesQuery, MerchantsBalancesQueryVariables>;
export const MyBalancesDocument = gql`
    query MyBalances {
        me {
            id
            allowFiatWithdrawals
            allowFiatPayments
            ...MyBalancesData
        }
    }
    ${MyBalancesDataFragmentDoc}
`;

/**
 * __useMyBalancesQuery__
 *
 * To run a query within a React component, call `useMyBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyBalancesQuery(baseOptions?: Apollo.QueryHookOptions<MyBalancesQuery, MyBalancesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyBalancesQuery, MyBalancesQueryVariables>(MyBalancesDocument, options);
}
export function useMyBalancesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyBalancesQuery, MyBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyBalancesQuery, MyBalancesQueryVariables>(MyBalancesDocument, options);
}
export type MyBalancesQueryHookResult = ReturnType<typeof useMyBalancesQuery>;
export type MyBalancesLazyQueryHookResult = ReturnType<typeof useMyBalancesLazyQuery>;
export type MyBalancesQueryResult = Apollo.QueryResult<MyBalancesQuery, MyBalancesQueryVariables>;
export const MyShopifyIntegrationsDocument = gql`
    query MyShopifyIntegrations {
        myShopifyIntegrations {
            id
            shopName
            created
            store {
                id
                name
                created
                isTestMode
                status
            }
            isActive
            currency
        }
    }
`;

/**
 * __useMyShopifyIntegrationsQuery__
 *
 * To run a query within a React component, call `useMyShopifyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyShopifyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyShopifyIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyShopifyIntegrationsQuery(
    baseOptions?: Apollo.QueryHookOptions<MyShopifyIntegrationsQuery, MyShopifyIntegrationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyShopifyIntegrationsQuery, MyShopifyIntegrationsQueryVariables>(
        MyShopifyIntegrationsDocument,
        options
    );
}
export function useMyShopifyIntegrationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyShopifyIntegrationsQuery, MyShopifyIntegrationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyShopifyIntegrationsQuery, MyShopifyIntegrationsQueryVariables>(
        MyShopifyIntegrationsDocument,
        options
    );
}
export type MyShopifyIntegrationsQueryHookResult = ReturnType<typeof useMyShopifyIntegrationsQuery>;
export type MyShopifyIntegrationsLazyQueryHookResult = ReturnType<typeof useMyShopifyIntegrationsLazyQuery>;
export type MyShopifyIntegrationsQueryResult = Apollo.QueryResult<
    MyShopifyIntegrationsQuery,
    MyShopifyIntegrationsQueryVariables
>;
export const MyTransferStatisticsDocument = gql`
    query MyTransferStatistics($storeId: String) {
        myOrderStats(filters: { storeId: $storeId }) {
            day {
                ...OrderStatParts
            }
            week {
                ...OrderStatParts
            }
            month {
                ...OrderStatParts
            }
        }
        myTransferStats(filters: { storeId: $storeId }) {
            currencies {
                ...CurrStat
            }
            total {
                day {
                    ...TotalStat
                }
                week {
                    ...TotalStat
                }
                month {
                    ...TotalStat
                }
            }
        }
    }
    ${OrderStatPartsFragmentDoc}
    ${CurrStatFragmentDoc}
    ${TotalStatFragmentDoc}
`;

/**
 * __useMyTransferStatisticsQuery__
 *
 * To run a query within a React component, call `useMyTransferStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTransferStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTransferStatisticsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMyTransferStatisticsQuery(
    baseOptions?: Apollo.QueryHookOptions<MyTransferStatisticsQuery, MyTransferStatisticsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyTransferStatisticsQuery, MyTransferStatisticsQueryVariables>(
        MyTransferStatisticsDocument,
        options
    );
}
export function useMyTransferStatisticsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyTransferStatisticsQuery, MyTransferStatisticsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyTransferStatisticsQuery, MyTransferStatisticsQueryVariables>(
        MyTransferStatisticsDocument,
        options
    );
}
export type MyTransferStatisticsQueryHookResult = ReturnType<typeof useMyTransferStatisticsQuery>;
export type MyTransferStatisticsLazyQueryHookResult = ReturnType<typeof useMyTransferStatisticsLazyQuery>;
export type MyTransferStatisticsQueryResult = Apollo.QueryResult<
    MyTransferStatisticsQuery,
    MyTransferStatisticsQueryVariables
>;
export const OrderDocument = gql`
    query Order($id: String!, $method: PaymentMethod, $extraFields: RequisiteExtraFieldsInput) {
        order(id: $id, method: $method, extraFields: $extraFields) {
            id
            sequentNumber
            storeId
            isTest
            status
            store {
                id
                name
            }
            currency {
                id
                name
                smartContract
                precission
                blockchain
                isFeeEnabled
                feePercentage
                feeFix
            }
            paymentOptions {
                method
                methodId
                currencyName
                serviceName
                requiredFields
                optionalFields
            }
            paymentParams {
                memo
            }
            paymentTransfer {
                sender
                status
                id
                memo
                action {
                    id
                    txid
                }
            }
            amount {
                fee
                send
                receive
            }
            created
            merchantOrderId
            description
            successPaymentRedirectUrl
            failedPaymentRedirectUrl
            responseUrl
            transfers {
                id
                amount {
                    receive
                }
                currency {
                    id
                    name
                    precission
                }
                status
                sender
                receiver
                created
                txnType
            }
            ...OrderDynamicData
        }
    }
    ${OrderDynamicDataFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      method: // value for 'method'
 *      extraFields: // value for 'extraFields'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderListDocument = gql`
    query OrderList($first: Int, $offset: Int, $filters: OrderFilterInput, $sort: [OrderSortEnum]) {
        me {
            id
            defaultStore {
                id
                orders(first: $first, offset: $offset, filters: $filters, sort: $sort) {
                    pageInfo {
                        total
                        totalFiltered
                    }
                    orders {
                        ...OrderListItem
                    }
                }
            }
        }
    }
    ${OrderListItemFragmentDoc}
`;

/**
 * __useOrderListQuery__
 *
 * To run a query within a React component, call `useOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOrderListQuery(baseOptions?: Apollo.QueryHookOptions<OrderListQuery, OrderListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrderListQuery, OrderListQueryVariables>(OrderListDocument, options);
}
export function useOrderListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OrderListQuery, OrderListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrderListQuery, OrderListQueryVariables>(OrderListDocument, options);
}
export type OrderListQueryHookResult = ReturnType<typeof useOrderListQuery>;
export type OrderListLazyQueryHookResult = ReturnType<typeof useOrderListLazyQuery>;
export type OrderListQueryResult = Apollo.QueryResult<OrderListQuery, OrderListQueryVariables>;
export const OrderRedirectUrlDocument = gql`
    query OrderRedirectUrl($id: String!) {
        orderRedirectUrl(id: $id)
    }
`;

/**
 * __useOrderRedirectUrlQuery__
 *
 * To run a query within a React component, call `useOrderRedirectUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRedirectUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRedirectUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderRedirectUrlQuery(
    baseOptions: Apollo.QueryHookOptions<OrderRedirectUrlQuery, OrderRedirectUrlQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrderRedirectUrlQuery, OrderRedirectUrlQueryVariables>(OrderRedirectUrlDocument, options);
}
export function useOrderRedirectUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OrderRedirectUrlQuery, OrderRedirectUrlQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrderRedirectUrlQuery, OrderRedirectUrlQueryVariables>(
        OrderRedirectUrlDocument,
        options
    );
}
export type OrderRedirectUrlQueryHookResult = ReturnType<typeof useOrderRedirectUrlQuery>;
export type OrderRedirectUrlLazyQueryHookResult = ReturnType<typeof useOrderRedirectUrlLazyQuery>;
export type OrderRedirectUrlQueryResult = Apollo.QueryResult<OrderRedirectUrlQuery, OrderRedirectUrlQueryVariables>;
export const OutcomTransfersDocument = gql`
    query OutcomTransfers($filters: TransferFilterInput, $first: Int, $offset: Int, $sort: [TransferSortEnum]) {
        outcomeLog(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            transfers {
                transferId
                orderId
                storeId
                status
                sequentNumber
                type
                createdDate
                paymentDate
                account
                isTest
                userId
                declineReason {
                    code
                    message {
                        en
                        ru
                    }
                }
                originId
                fiatFee
                fiatAmount
                fiatMethod {
                    id
                    name
                    currencyName
                }
                currency {
                    id
                    smartContract
                    name
                    precission
                    blockchain
                }
                storeName
                amount {
                    send
                    receive
                    fee
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useOutcomTransfersQuery__
 *
 * To run a query within a React component, call `useOutcomTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomTransfersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOutcomTransfersQuery(
    baseOptions?: Apollo.QueryHookOptions<OutcomTransfersQuery, OutcomTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OutcomTransfersQuery, OutcomTransfersQueryVariables>(OutcomTransfersDocument, options);
}
export function useOutcomTransfersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OutcomTransfersQuery, OutcomTransfersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OutcomTransfersQuery, OutcomTransfersQueryVariables>(OutcomTransfersDocument, options);
}
export type OutcomTransfersQueryHookResult = ReturnType<typeof useOutcomTransfersQuery>;
export type OutcomTransfersLazyQueryHookResult = ReturnType<typeof useOutcomTransfersLazyQuery>;
export type OutcomTransfersQueryResult = Apollo.QueryResult<OutcomTransfersQuery, OutcomTransfersQueryVariables>;
export const PaymentOrderDocument = gql`
    query PaymentOrder($id: String!, $method: PaymentMethod, $extraFields: RequisiteExtraFieldsInput) {
        order(id: $id, method: $method, extraFields: $extraFields) {
            id
            sequentNumber
            isTest
            expirationDate
            created
            store {
                id
                name
            }
            amount {
                fee
                send
                receive
            }
            currency {
                id
                name
                precission
                blockchain
            }
            paymentParams {
                memo
                receiver
                metaInfo
                amount
                requiredExtraFields
                paymentLink
                serviceName
                infoLink
                requisiteId
                deeplinks {
                    name
                    link
                }
            }
            paymentOptions {
                method
                methodId
                currencyName
                serviceName
                requiredFields
                optionalFields
            }
            ...PaymentOrderDynamicData
        }
    }
    ${PaymentOrderDynamicDataFragmentDoc}
`;

/**
 * __usePaymentOrderQuery__
 *
 * To run a query within a React component, call `usePaymentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      method: // value for 'method'
 *      extraFields: // value for 'extraFields'
 *   },
 * });
 */
export function usePaymentOrderQuery(
    baseOptions: Apollo.QueryHookOptions<PaymentOrderQuery, PaymentOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PaymentOrderQuery, PaymentOrderQueryVariables>(PaymentOrderDocument, options);
}
export function usePaymentOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PaymentOrderQuery, PaymentOrderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PaymentOrderQuery, PaymentOrderQueryVariables>(PaymentOrderDocument, options);
}
export type PaymentOrderQueryHookResult = ReturnType<typeof usePaymentOrderQuery>;
export type PaymentOrderLazyQueryHookResult = ReturnType<typeof usePaymentOrderLazyQuery>;
export type PaymentOrderQueryResult = Apollo.QueryResult<PaymentOrderQuery, PaymentOrderQueryVariables>;
export const SettingsDocument = gql`
    query Settings {
        settings {
            feeLimits {
                ether {
                    feeLimit
                }
                tron {
                    feeLimit
                }
            }
            coreEnabled
        }
    }
`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const ShopifyIntegrationDocument = gql`
    query ShopifyIntegration($id: String!) {
        shopifyIntegration(id: $id) {
            id
            shopName
            created
            store {
                id
                name
                created
                isTestMode
                status
            }
            isActive
            currency
        }
    }
`;

/**
 * __useShopifyIntegrationQuery__
 *
 * To run a query within a React component, call `useShopifyIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopifyIntegrationQuery(
    baseOptions: Apollo.QueryHookOptions<ShopifyIntegrationQuery, ShopifyIntegrationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ShopifyIntegrationQuery, ShopifyIntegrationQueryVariables>(
        ShopifyIntegrationDocument,
        options
    );
}
export function useShopifyIntegrationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ShopifyIntegrationQuery, ShopifyIntegrationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ShopifyIntegrationQuery, ShopifyIntegrationQueryVariables>(
        ShopifyIntegrationDocument,
        options
    );
}
export type ShopifyIntegrationQueryHookResult = ReturnType<typeof useShopifyIntegrationQuery>;
export type ShopifyIntegrationLazyQueryHookResult = ReturnType<typeof useShopifyIntegrationLazyQuery>;
export type ShopifyIntegrationQueryResult = Apollo.QueryResult<
    ShopifyIntegrationQuery,
    ShopifyIntegrationQueryVariables
>;
export const StatisticsDocument = gql`
    query Statistics {
        userStats {
            day {
                ...UserStat
            }
            week {
                ...UserStat
            }
            month {
                ...UserStat
            }
        }
        storeStats {
            day {
                ...StoreStat
            }
            week {
                ...StoreStat
            }
            month {
                ...StoreStat
            }
        }
    }
    ${UserStatFragmentDoc}
    ${StoreStatFragmentDoc}
`;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
}
export function useStatisticsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
}
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export type StatisticsQueryResult = Apollo.QueryResult<StatisticsQuery, StatisticsQueryVariables>;
export const StoresDocument = gql`
    query Stores($filters: StoreFilterInput, $first: Int, $offset: Int, $sort: [StoreSortEnum]) {
        stores(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            stores {
                id
                name
                created
                isTestMode
                paymentStats {
                    paymentsCount
                    paymentsAmount
                    currency {
                        blockchain
                        name
                        id
                    }
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useStoresQuery(baseOptions?: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
}
export function useStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
}
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<StoresQuery, StoresQueryVariables>;
export const StoresWithStatsDocument = gql`
    query StoresWithStats($filters: StoreFilterInput, $first: Int, $offset: Int, $sort: [StoreSortEnum]) {
        stores(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            stores {
                id
                name
                created
                isTestMode
                paymentStats {
                    currency {
                        id
                        name
                        isFeeEnabled
                    }
                    paymentsCount
                    paymentsAmount
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useStoresWithStatsQuery__
 *
 * To run a query within a React component, call `useStoresWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresWithStatsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useStoresWithStatsQuery(
    baseOptions?: Apollo.QueryHookOptions<StoresWithStatsQuery, StoresWithStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StoresWithStatsQuery, StoresWithStatsQueryVariables>(StoresWithStatsDocument, options);
}
export function useStoresWithStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StoresWithStatsQuery, StoresWithStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StoresWithStatsQuery, StoresWithStatsQueryVariables>(StoresWithStatsDocument, options);
}
export type StoresWithStatsQueryHookResult = ReturnType<typeof useStoresWithStatsQuery>;
export type StoresWithStatsLazyQueryHookResult = ReturnType<typeof useStoresWithStatsLazyQuery>;
export type StoresWithStatsQueryResult = Apollo.QueryResult<StoresWithStatsQuery, StoresWithStatsQueryVariables>;
export const StoreBalancesDocument = gql`
    query storeBalances($storeId: String!) {
        store(id: $storeId) {
            name
            admin {
                id
                balances {
                    currencies {
                        id
                        name
                        blockchain
                        precission
                        isIncomeEnabled
                        isOutcomeEnabled
                    }
                    amount
                    awaitedIncome
                    name
                }
            }
        }
    }
`;

/**
 * __useStoreBalancesQuery__
 *
 * To run a query within a React component, call `useStoreBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreBalancesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreBalancesQuery(
    baseOptions: Apollo.QueryHookOptions<StoreBalancesQuery, StoreBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StoreBalancesQuery, StoreBalancesQueryVariables>(StoreBalancesDocument, options);
}
export function useStoreBalancesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StoreBalancesQuery, StoreBalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StoreBalancesQuery, StoreBalancesQueryVariables>(StoreBalancesDocument, options);
}
export type StoreBalancesQueryHookResult = ReturnType<typeof useStoreBalancesQuery>;
export type StoreBalancesLazyQueryHookResult = ReturnType<typeof useStoreBalancesLazyQuery>;
export type StoreBalancesQueryResult = Apollo.QueryResult<StoreBalancesQuery, StoreBalancesQueryVariables>;
export const StoreStatisticDocument = gql`
    query StoreStatistic(
        $storeId: String!
        $dateFrom: DateTime
        $dateTo: DateTime
        $todayFrom: DateTime
        $userTimezoneShift: Int
        $todayTo: DateTime
    ) {
        storeStatistic(storeId: $storeId, dateFrom: $dateFrom, dateTo: $dateTo, userTimezoneShift: $userTimezoneShift) {
            storeId
            fundsReceipts {
                dateFrom
                dateTo
                total
                currency {
                    id
                    name
                    precission
                }
                chartPoints {
                    dateFrom
                    dateTo
                    value
                }
            }
            orders {
                dateFrom
                dateTo
                total
                cancellations
                refunds
                payments
                expirations
                chartPoints {
                    dateFrom
                    dateTo
                    value
                }
                prevPeriod {
                    total
                    cancellations
                    refunds
                    payments
                    expirations
                }
            }
            avgBuyersBills {
                dateFrom
                dateTo
                averageValue
                prevPeriod {
                    averageValue
                }
                currency {
                    id
                    name
                    precission
                }
                chartPoints {
                    dateFrom
                    dateTo
                    value
                }
            }
        }
        todayStoreStatistic: storeStatistic(
            storeId: $storeId
            dateFrom: $todayFrom
            userTimezoneShift: $userTimezoneShift
            dateTo: $todayTo
        ) {
            storeId
            orders {
                total
                prevPeriod {
                    total
                }
            }
        }
    }
`;

/**
 * __useStoreStatisticQuery__
 *
 * To run a query within a React component, call `useStoreStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreStatisticQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      todayFrom: // value for 'todayFrom'
 *      userTimezoneShift: // value for 'userTimezoneShift'
 *      todayTo: // value for 'todayTo'
 *   },
 * });
 */
export function useStoreStatisticQuery(
    baseOptions: Apollo.QueryHookOptions<StoreStatisticQuery, StoreStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StoreStatisticQuery, StoreStatisticQueryVariables>(StoreStatisticDocument, options);
}
export function useStoreStatisticLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StoreStatisticQuery, StoreStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StoreStatisticQuery, StoreStatisticQueryVariables>(StoreStatisticDocument, options);
}
export type StoreStatisticQueryHookResult = ReturnType<typeof useStoreStatisticQuery>;
export type StoreStatisticLazyQueryHookResult = ReturnType<typeof useStoreStatisticLazyQuery>;
export type StoreStatisticQueryResult = Apollo.QueryResult<StoreStatisticQuery, StoreStatisticQueryVariables>;
export const StoreWithStatsDocument = gql`
    query storeWithStats($storeId: String!) {
        store(id: $storeId) {
            id
            name
            created
            isTestMode
            paymentStats {
                currency {
                    id
                    name
                    isFeeEnabled
                }
                paymentsCount
                paymentsAmount
            }
        }
    }
`;

/**
 * __useStoreWithStatsQuery__
 *
 * To run a query within a React component, call `useStoreWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreWithStatsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreWithStatsQuery(
    baseOptions: Apollo.QueryHookOptions<StoreWithStatsQuery, StoreWithStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<StoreWithStatsQuery, StoreWithStatsQueryVariables>(StoreWithStatsDocument, options);
}
export function useStoreWithStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<StoreWithStatsQuery, StoreWithStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<StoreWithStatsQuery, StoreWithStatsQueryVariables>(StoreWithStatsDocument, options);
}
export type StoreWithStatsQueryHookResult = ReturnType<typeof useStoreWithStatsQuery>;
export type StoreWithStatsLazyQueryHookResult = ReturnType<typeof useStoreWithStatsLazyQuery>;
export type StoreWithStatsQueryResult = Apollo.QueryResult<StoreWithStatsQuery, StoreWithStatsQueryVariables>;
export const TransferDocument = gql`
    query Transfer($id: String!) {
        transfer(id: $id) {
            id
            amount {
                fee
                receive
                send
            }
            action {
                id
                txid
            }
            currency {
                id
                name
                precission
                blockchain
            }
            status
            sender
            receiver
            memo
            created
            txnType
        }
    }
`;

/**
 * __useTransferQuery__
 *
 * To run a query within a React component, call `useTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransferQuery(baseOptions: Apollo.QueryHookOptions<TransferQuery, TransferQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
}
export function useTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferQuery, TransferQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TransferQuery, TransferQueryVariables>(TransferDocument, options);
}
export type TransferQueryHookResult = ReturnType<typeof useTransferQuery>;
export type TransferLazyQueryHookResult = ReturnType<typeof useTransferLazyQuery>;
export type TransferQueryResult = Apollo.QueryResult<TransferQuery, TransferQueryVariables>;
export const TransferStatisticsDocument = gql`
    query TransferStatistics($storeId: String) {
        orderStats(filters: { storeId: $storeId }) {
            day {
                ...OrderStatParts
            }
            week {
                ...OrderStatParts
            }
            month {
                ...OrderStatParts
            }
        }
        transferStats(filters: { storeId: $storeId }) {
            currencies {
                ...CurrStat
            }
            total {
                day {
                    ...TotalStat
                }
                week {
                    ...TotalStat
                }
                month {
                    ...TotalStat
                }
            }
        }
    }
    ${OrderStatPartsFragmentDoc}
    ${CurrStatFragmentDoc}
    ${TotalStatFragmentDoc}
`;

/**
 * __useTransferStatisticsQuery__
 *
 * To run a query within a React component, call `useTransferStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferStatisticsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useTransferStatisticsQuery(
    baseOptions?: Apollo.QueryHookOptions<TransferStatisticsQuery, TransferStatisticsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TransferStatisticsQuery, TransferStatisticsQueryVariables>(
        TransferStatisticsDocument,
        options
    );
}
export function useTransferStatisticsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TransferStatisticsQuery, TransferStatisticsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TransferStatisticsQuery, TransferStatisticsQueryVariables>(
        TransferStatisticsDocument,
        options
    );
}
export type TransferStatisticsQueryHookResult = ReturnType<typeof useTransferStatisticsQuery>;
export type TransferStatisticsLazyQueryHookResult = ReturnType<typeof useTransferStatisticsLazyQuery>;
export type TransferStatisticsQueryResult = Apollo.QueryResult<
    TransferStatisticsQuery,
    TransferStatisticsQueryVariables
>;
export const UserListDocument = gql`
    query UserList($filters: UserFilterInput, $first: Int, $offset: Int, $sort: [UserSortEnum]) {
        users(first: $first, offset: $offset, filters: $filters, sort: $sort) {
            users {
                id
                email
                phone
                eosAccount
                allowFiatPayments
                allowFiatWithdrawals
                created
                group {
                    id
                    name
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserListQuery(baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const ConnectByWalletWsDocument = gql`
    subscription ConnectByWalletWS($verificationCode: String!) {
        connectByWallet(verificationCode: $verificationCode) {
            ... on WalletConnectionSuccess {
                token
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on WalletLinkOutdatedError {
                errorMessage
            }
            ... on EosAccountAlreadyTakenError {
                errorMessage
            }
        }
    }
`;

/**
 * __useConnectByWalletWsSubscription__
 *
 * To run a query within a React component, call `useConnectByWalletWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConnectByWalletWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectByWalletWsSubscription({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useConnectByWalletWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>(
        ConnectByWalletWsDocument,
        options
    );
}
export type ConnectByWalletWsSubscriptionHookResult = ReturnType<typeof useConnectByWalletWsSubscription>;
export type ConnectByWalletWsSubscriptionResult = Apollo.SubscriptionResult<ConnectByWalletWsSubscription>;
export const MeWsDocument = gql`
    subscription MeWS {
        user {
            id
            ...MyBalancesData
        }
    }
    ${MyBalancesDataFragmentDoc}
`;

/**
 * __useMeWsSubscription__
 *
 * To run a query within a React component, call `useMeWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMeWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMeWsSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<MeWsSubscription, MeWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<MeWsSubscription, MeWsSubscriptionVariables>(MeWsDocument, options);
}
export type MeWsSubscriptionHookResult = ReturnType<typeof useMeWsSubscription>;
export type MeWsSubscriptionResult = Apollo.SubscriptionResult<MeWsSubscription>;
export const OrderWsDocument = gql`
    subscription OrderWs($orderId: String!) {
        publicOrder(orderId: $orderId) {
            ...OrderDynamicData
        }
    }
    ${OrderDynamicDataFragmentDoc}
`;

/**
 * __useOrderWsSubscription__
 *
 * To run a query within a React component, call `useOrderWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderWsSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<OrderWsSubscription, OrderWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<OrderWsSubscription, OrderWsSubscriptionVariables>(OrderWsDocument, options);
}
export type OrderWsSubscriptionHookResult = ReturnType<typeof useOrderWsSubscription>;
export type OrderWsSubscriptionResult = Apollo.SubscriptionResult<OrderWsSubscription>;
export const OrderListItemWsDocument = gql`
    subscription OrderListItemWS($orderId: String!) {
        publicOrder(orderId: $orderId) {
            ...OrderListItemDynamicData
        }
    }
    ${OrderListItemDynamicDataFragmentDoc}
`;

/**
 * __useOrderListItemWsSubscription__
 *
 * To run a query within a React component, call `useOrderListItemWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderListItemWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListItemWsSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderListItemWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<OrderListItemWsSubscription, OrderListItemWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<OrderListItemWsSubscription, OrderListItemWsSubscriptionVariables>(
        OrderListItemWsDocument,
        options
    );
}
export type OrderListItemWsSubscriptionHookResult = ReturnType<typeof useOrderListItemWsSubscription>;
export type OrderListItemWsSubscriptionResult = Apollo.SubscriptionResult<OrderListItemWsSubscription>;
export const PaymentOrderWsDocument = gql`
    subscription PaymentOrderWS($orderId: String!) {
        publicOrder(orderId: $orderId) {
            ...PaymentOrderDynamicData
        }
    }
    ${PaymentOrderDynamicDataFragmentDoc}
`;

/**
 * __usePaymentOrderWsSubscription__
 *
 * To run a query within a React component, call `usePaymentOrderWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentOrderWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentOrderWsSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePaymentOrderWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<PaymentOrderWsSubscription, PaymentOrderWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<PaymentOrderWsSubscription, PaymentOrderWsSubscriptionVariables>(
        PaymentOrderWsDocument,
        options
    );
}
export type PaymentOrderWsSubscriptionHookResult = ReturnType<typeof usePaymentOrderWsSubscription>;
export type PaymentOrderWsSubscriptionResult = Apollo.SubscriptionResult<PaymentOrderWsSubscription>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AbortTransferOutput: [
            'AbortTransferSuccess',
            'ConfirmationFailedError',
            'TransferNotFoundError',
            'WrongTransferStatusError',
            'WrongTransferTypeError'
        ],
        ActivateFiatMethodOutput: ['FiatMethodNotFoundError', 'UpdateFiatMethodSuccess'],
        CancelOrderOutput: ['CancelOrderSuccess', 'OrderInactiveError', 'OrderNotFoundError', 'OrderPaidError'],
        ChangeLoginOutput: [
            'ChangeLoginSuccess',
            'InvalidCredentialsError',
            'InvalidEmailError',
            'InvalidPasswordError',
            'InvalidPhoneError',
            'UserAlreadyExistsError'
        ],
        ChangePasswordOutput: ['ChangePasswordSuccess', 'InvalidCredentialsError', 'InvalidPasswordError'],
        ChangeUserGroupOutput: [
            'ChangeUserGroupSuccess',
            'GroupNotFoundError',
            'UserHasNoPasswordError',
            'UserNotFoundError'
        ],
        CheckPasswordOutput: ['CheckPasswordSuccess', 'InvalidCredentialsError'],
        CheckTransferOutput: [
            'CheckTransferSuccess',
            'FiatMethodNotFoundError',
            'OrderInactiveError',
            'OrderNotFoundError',
            'OrderPaidError',
            'TransferNotFoundError',
            'WrongTransferStatusError',
            'WrongTransferTypeError'
        ],
        ClientWithdrawOutput: [
            'ClientWithdrawSuccess',
            'ConfirmationFailedError',
            'CurrencyNotFoundError',
            'DuplicateTransferError',
            'EosAccountNotExistsError',
            'ExchangePairNotFoundError',
            'FiatMethodNotFoundError',
            'FiatTransferNotPossibleError',
            'FiatWithdrawalsNotAllowedError',
            'ForbiddenWithdrawalReceiverError',
            'InsufficientFundsError',
            'InvalidParamsError',
            'StoreNotFoundError',
            'WithdrawDuplicationError',
            'WithdrawLimitExceededError',
            'WithdrawalAmountTooHighError',
            'WithdrawalAmountTooLowError'
        ],
        CollectWalletOutput: [
            'CollectWalletSuccess',
            'ConfirmationFailedError',
            'CurrencyNotFoundError',
            'InvalidParamsError'
        ],
        ConfirmPasswordResetOutput: [
            'ConfirmPasswordResetSuccess',
            'InvalidJWTError',
            'InvalidPasswordError',
            'InvalidVerifyCodeError',
            'UserNotFoundError'
        ],
        ConnectByWalletOutput: ['ConnectByWalletSuccess', 'DeeplinkNotGeneratedError'],
        ConnectShopifyOutput: [
            'ConnectShopifySuccess',
            'InvalidCredentialsError',
            'InvalidParamsError',
            'ShopifyIntegrationExistsError',
            'ShopifyPermissionRequiredError'
        ],
        ConversionOutput: [
            'ConfirmationFailedError',
            'ConversionsNotAllowedError',
            'CurrencyNotFoundError',
            'InsufficientFundsError',
            'InvalidParamsError',
            'WithdrawDuplicationError',
            'WithdrawSuccess'
        ],
        CoreWithdrawOutput: [
            'ConfirmationFailedError',
            'CurrencyNotFoundError',
            'EosAccountNotExistsError',
            'FiatTransferNotPossibleError',
            'ForbiddenWithdrawalReceiverError',
            'InvalidParamsError',
            'WithdrawDuplicationError',
            'WithdrawSuccess'
        ],
        CreateFeeOutput: [
            'CreateFeeSuccess',
            'CurrencyNotFoundError',
            'FiatMethodNotFoundError',
            'InvalidParamsError',
            'StoreNotFoundError',
            'UserNotFoundError'
        ],
        CreateFiatBlackListOutput: ['CreateFiatBlackListSuccess', 'FiatMethodNotFoundError', 'InvalidParamsError'],
        CreateGroupOutput: ['CreateGroupSuccess', 'NameAlreadyExistsError'],
        CreateOrderOutput: [
            'CreateOrderSuccess',
            'CurrencyNotFoundError',
            'InvalidParamsError',
            'ServerProblemError',
            'StoreNotFoundError'
        ],
        CreateStoreOutput: ['ConfirmationFailedError', 'CreateStoreSuccess', 'NameAlreadyExistsError'],
        DeactivateFiatMethodOutput: ['DeactivateFiatMethodSuccess'],
        DeleteFeeOutput: ['DeleteFeeSuccess', 'FeeEntryNotFoundError'],
        DeleteFiatBlackListOutput: ['DeleteFiatBlackListSuccess', 'FiatBlackListEntryNotFoundError'],
        DeleteGroupOutput: ['DeleteGroupSuccess', 'GroupNotFoundError'],
        DeleteShopifyIntegrationOutput: ['DeleteShopifyIntegrationSuccess', 'ShopifyIntegrationNotFoundError'],
        DepositOutput: [
            'CurrencyNotFoundError',
            'DepositLimitExceededError',
            'DepositParams',
            'InvalidParamsError',
            'ServerProblemError',
            'StoreNotFoundError'
        ],
        FailTransferOutput: [
            'ConfirmationFailedError',
            'FailTransferSuccess',
            'TransferNotFoundError',
            'WrongTransferStatusError',
            'WrongTransferTypeError'
        ],
        FinalizeTransferOutput: [
            'ConfirmationFailedError',
            'FinalizeTransferSuccess',
            'TransferNotFoundError',
            'WrongTransferStatusError'
        ],
        GetNewKeyOutput: ['ConfirmationFailedError', 'GetNewKeySuccess', 'StoreNotFoundError'],
        GiveOutput: [
            'ConfirmationFailedError',
            'CurrencyNotFoundError',
            'EosAccountNotExistsError',
            'ForbiddenWithdrawalReceiverError',
            'InsufficientFundsError',
            'InvalidParamsError',
            'WithdrawDuplicationError',
            'WithdrawSuccess'
        ],
        NotifyOrderPaidOutput: [
            'ActionLockedError',
            'CheckTransferSuccess',
            'FiatMethodNotFoundError',
            'OrderInactiveError',
            'OrderNotFoundError',
            'OrderPaidError'
        ],
        Output: ['FiatMethodNotFoundError', 'InvalidParamsError', 'UpdateFiatLimitsSuccess'],
        PaybackOrderOutput: [
            'CurrencyNotFoundError',
            'InsufficientFundsError',
            'InvalidParamsError',
            'OrderNotFoundError',
            'OrderNotPaidError',
            'OrderNotRefundableError',
            'PaybackOrderSuccess'
        ],
        RefreshTokenOutput: ['RefreshTokenSuccess'],
        ResendCodeOutput: ['InvalidJWTError', 'NoResendAttemptsError', 'ResendCodeSuccess'],
        ResetPasswordOutput: [
            'LoginIsEmptyError',
            'NoResendAttemptsError',
            'ResetPasswordSuccess',
            'UserNotFoundError'
        ],
        RetryTransferOutput: [
            'ConfirmationFailedError',
            'RetryTransferSuccess',
            'TransferNotFoundError',
            'WrongTransferStatusError',
            'WrongTransferTypeError'
        ],
        SetExchangeRateOutput: ['ExchangePairNotFoundError', 'InvalidParamsError', 'UpdateExchangeRateSuccess'],
        SetFeeLimitOutput: ['InvalidParamsError', 'SetFeeLimitSuccess'],
        SetFeeOutput: ['CurrencyNotFoundError', 'InvalidParamsError', 'SetFeeSuccess'],
        SetFiatFeeOutput: ['FiatMethodNotFoundError', 'InvalidParamsError', 'UpdateFiatMethodSuccess'],
        SetLimitsOutput: ['CurrencyNotFoundError', 'InvalidParamsError', 'SetLimitsSuccess'],
        SetWithdrawFeeOutput: ['CurrencyNotFoundError', 'InvalidParamsError', 'SetWithdrawFeeSuccess'],
        SigninOutput: ['InvalidCredentialsError', 'InvalidPhoneError', 'LoginIsEmptyError', 'SigninSuccess'],
        SignupOutput: [
            'InvalidEmailError',
            'InvalidPasswordError',
            'InvalidPhoneError',
            'LoginIsEmptyError',
            'NoResendAttemptsError',
            'SignupSuccess',
            'UserAlreadyExistsError'
        ],
        SwitchCoreOutput: ['SwitchCoreSuccess'],
        TestCancelOrderOutput: ['CancelOrderSuccess', 'OrderInactiveError', 'OrderNotFoundError', 'OrderPaidError'],
        TestExpireOutput: ['CantExpireError', 'OrderNotFoundError', 'TestExpireSuccess'],
        TestPaymentOutput: ['OrderNotFoundError', 'TestPaymentSuccess'],
        ToggleCurrencyOutput: ['CurrencyNotFoundError', 'ToggleCurrencySuccess'],
        TogglePermissionOutput: ['GroupNotFoundError', 'PermissionNotFoundError', 'TogglePermissionSuccess'],
        ToggleUserFiatOutput: ['InvalidParamsError', 'ToggleUserFiatSuccess', 'UserNotFoundError'],
        UpdateGroupOutput: ['GroupNotFoundError', 'NameAlreadyExistsError', 'UpdateGroupSuccess'],
        UpdateShopifyIntegrationOutput: [
            'InvalidCredentialsError',
            'InvalidParamsError',
            'ShopifyIntegrationExistsError',
            'ShopifyIntegrationNotFoundError',
            'ShopifyPermissionRequiredError',
            'UpdateShopifyIntegrationSuccess'
        ],
        UpdateStoreOutput: ['NameAlreadyExistsError', 'StoreNotFoundError', 'UpdateStoreSuccess'],
        VerifyCodeOutput: [
            'InvalidJWTError',
            'InvalidVerifyCodeError',
            'NoConfirmationAttemptsError',
            'UserAlreadyExistsError',
            'VerifyCodeExpiredError',
            'VerifyCodeSuccess'
        ],
        WalletConnectiontUpdate: [
            'ConfirmationFailedError',
            'EosAccountAlreadyTakenError',
            'UserNotFoundError',
            'WalletConnectionSuccess',
            'WalletLinkOutdatedError'
        ],
        WithdrawFundsOutput: [
            'ConfirmationFailedError',
            'FiatTransferNotPossibleError',
            'InvalidParamsError',
            'WithdrawDuplicationError',
            'WithdrawFundsResult',
            'WithdrawalAmountTooHighError',
            'WithdrawalAmountTooLowError'
        ],
        WithdrawOutput: [
            'ConfirmationFailedError',
            'CurrencyNotFoundError',
            'DuplicateTransferError',
            'EosAccountNotExistsError',
            'ExchangePairNotFoundError',
            'FiatMethodNotFoundError',
            'FiatTransferNotPossibleError',
            'FiatWithdrawalsNotAllowedError',
            'ForbiddenWithdrawalReceiverError',
            'InsufficientFundsError',
            'InvalidParamsError',
            'WithdrawDuplicationError',
            'WithdrawLimitExceededError',
            'WithdrawSuccess',
            'WithdrawalAmountTooHighError',
            'WithdrawalAmountTooLowError'
        ]
    }
};
export default result;
