import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckPasswordMutation, useGetNewKeyMutation, useMeQuery } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useFormField from 'hooks/useFormField/useFormField';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';
import Preloader from 'ui/Preloader/Preloader';
import Checkbox from 'ui/Checkbox/Checkbox';
import { useToast } from 'ui/Toast/Toast';
import Stepper from 'ui/Stepper/Stepper';
import Button from 'ui/Button/Button';
import Alert from 'ui/Alert/Alert';
import Param from '../Param/Param';
import { Step, StoreGenerateKeyProps } from './StoreGenerateKey.d';
import styles from './StoreGenerateKey.module.scss';

export default function StoreGenerateKey({ onBackClick, onClose }: StoreGenerateKeyProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const inputPassword = useFormField('');

    const [generateChecked, setGenerateChecked] = React.useState(false);
    const [saveChecked, setSaveChecked] = React.useState(false);
    const [step, setStep] = React.useState<Step>(Step.Initial);
    const [key, setKey] = React.useState('');

    const formPasswordId = React.useId();

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const meQuery = useMeQuery();
    const { defaultStore } = meQuery.data?.me ?? {};
    const { id } = defaultStore ?? {};

    const [checkPasswordMutation, { loading: checkPasswordLoading }] = useCheckPasswordMutation();
    const [generateNewKeyMutation, { loading: getNewKeyLoading }] = useGetNewKeyMutation();

    const handleGenerateChange = () => setGenerateChecked(!generateChecked);
    const handleSaveChange = () => setSaveChecked(!saveChecked);
    const handleSaveClick = () => setStep(Step.Attention);
    const handleBackClick = () => onBackClick?.(false);

    const handleGenerateKey = () => {
        if (id) {
            generateNewKeyMutation({ variables: { input: { storeId: id, password: inputPassword.value } } })
                .then(({ data }) => {
                    const getNewKey = data?.getNewKey;

                    if (getNewKey?.__typename === 'GetNewKeySuccess') {
                        setKey(getNewKey.privateKey);
                        setStep(Step.Save);
                    } else {
                        const { __typename } = getNewKey ?? {};

                        onGlobalError(t(`store.newKey.error.${__typename}`));
                        setStep(Step.Initial);
                    }
                })
                .catch((err) => onGlobalError(getErrorData(err).message));
        } else {
            onGlobalError(t('store.newKey.error.StoreNotFoundError'));
        }
    };

    const handleAttentionClick = () => {
        setSaveChecked(false);
        setStep(Step.Save);
    };

    const handleSuccessClose = () => {
        toast.success(t('store.newKey.success'));
        onClose?.();
    };

    const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        checkPasswordMutation({ variables: { input: { password: inputPassword.value } } })
            .then(({ data }) => {
                const checkPassword = data?.checkPassword;

                if (checkPassword?.__typename === 'CheckPasswordSuccess') {
                    handleGenerateKey();
                } else {
                    const { __typename, errorMessage = '' } = checkPassword ?? {};

                    inputPassword.errorChange(
                        true,
                        t([`store.newKey.confirm.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    React.useEffect(() => {
        if (getNewKeyLoading) setStep(Step.Load);
    }, [getNewKeyLoading]);

    React.useEffect(() => {
        if ((step === Step.Initial || step === Step.Attention) && buttonRef.current) buttonRef.current.focus();
        if (step === Step.Password && inputRef.current) inputRef.current.focus();
    }, [step]);

    if (step === Step.Load) {
        return (
            <>
                <DialogHeader hideCloseButton />
                <DialogBody className={styles.LoadBody}>
                    <Preloader size="4rem" />
                    <h4 className={styles.LoadText}>{t('store.newKey.loading.text')}</h4>
                </DialogBody>
            </>
        );
    }

    if (step === Step.Password)
        return (
            <>
                <DialogHeader
                    title={t('store.newKey.title')}
                    hideCloseButton
                    onBackButtonClick={() => setStep(Step.Initial)}
                    titleAlign="center"
                />
                <DialogBody>
                    <Stepper
                        title={t('store.newKey.confirm.step')}
                        steps={[
                            { key: '1', isActive: true },
                            { key: '2', isActive: true },
                            { key: '3', isActive: false }
                        ]}
                    />
                    <p className={styles.Text}>{t('store.newKey.confirm.description')}</p>
                    <form id={formPasswordId} onSubmit={handlePasswordSubmit}>
                        <div className={styles.NameContainer}>
                            <TextFieldPassword
                                classes={{ root: styles.FormPasswordInput }}
                                value={inputPassword.value}
                                isError={inputPassword.error}
                                helperText={inputPassword.helperText}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    inputPassword?.change(removeSpaces(e.target.value))
                                }
                                autoComplete="current-password"
                                ref={inputRef}
                                required
                            />
                        </div>
                    </form>
                </DialogBody>
                <DialogFooter>
                    <Button
                        size="large"
                        fullWidth
                        form={formPasswordId}
                        disabled={!inputPassword.value || Boolean(inputPassword.error)}
                        loading={checkPasswordLoading}
                    >
                        {t('store.buttonContinue')}
                    </Button>
                </DialogFooter>
            </>
        );

    if (step === Step.Save) {
        return (
            <>
                <DialogHeader hideCloseButton title={t('store.newKey.title')} />
                <DialogBody>
                    <Stepper
                        title={t('store.newKey.save.step')}
                        steps={[
                            { key: '1', isActive: true },
                            { key: '2', isActive: true },
                            { key: '3', isActive: true }
                        ]}
                    />
                    <p className={styles.Text}>{t('store.newKey.save.description')}</p>
                    <Param
                        classes={{ root: styles.Param }}
                        label={t('store.newKey.save.label')}
                        value={key}
                        withCopy
                        onCopyText={t('store.newKey.save.copied')}
                    />
                    <Alert type="error">{t('store.newKey.save.alert')}</Alert>
                </DialogBody>
                <DialogFooter>
                    <Checkbox
                        classes={{ root: styles.Checkbox, labelText: styles.Text }}
                        checked={saveChecked}
                        onChange={handleSaveChange}
                        label={t('store.newKey.save.check')}
                    />
                    <Button size="large" fullWidth disabled={!saveChecked} onClick={handleSaveClick}>
                        {t('store.buttonDone')}
                    </Button>
                </DialogFooter>
            </>
        );
    }

    if (step === Step.Attention) {
        return (
            <>
                <DialogHeader hideCloseButton title={t('store.newKey.title')} classes={{ title: styles.Center }} />
                <DialogBody>
                    <p className={styles.Text}>{t('store.newKey.attention.text')}</p>
                </DialogBody>
                <DialogFooter>
                    <Button
                        ref={buttonRef}
                        size="large"
                        fullWidth
                        onClick={handleAttentionClick}
                        style={{ marginBottom: '0.5rem' }}
                    >
                        {t('store.newKey.attention.buttonCancel')}
                    </Button>
                    <Button size="large" variant="outlined" color="secondary" fullWidth onClick={handleSuccessClose}>
                        {t('store.newKey.attention.buttonSubmit')}
                    </Button>
                </DialogFooter>
            </>
        );
    }

    return (
        <>
            <DialogHeader
                onBackButtonClick={handleBackClick}
                title={t('store.newKey.title')}
                hideCloseButton
                titleAlign="center"
            />
            <DialogBody>
                <Stepper
                    title={t('store.newKey.initial.step')}
                    steps={[
                        { key: '1', isActive: true },
                        { key: '2', isActive: false },
                        { key: '3', isActive: false }
                    ]}
                />
                <p className={styles.Text}>{t('store.newKey.initial.description')}</p>
                <Alert type="error" classes={{ root: styles.AlertRoot }}>
                    {t('store.newKey.initial.alert')}
                </Alert>
            </DialogBody>
            <DialogFooter>
                <Checkbox
                    classes={{ root: styles.Checkbox, labelText: styles.Text }}
                    checked={generateChecked}
                    onChange={handleGenerateChange}
                    label={t('store.newKey.initial.check')}
                />
                <Button
                    ref={buttonRef}
                    size="large"
                    fullWidth
                    style={{ marginBottom: '0.5rem' }}
                    onClick={handleBackClick}
                >
                    {t('store.newKey.initial.buttonCancel')}
                </Button>
                <Button size="large" fullWidth disabled={!generateChecked} onClick={() => setStep(Step.Password)}>
                    {t('store.newKey.initial.buttonGenerateKey')}
                </Button>
            </DialogFooter>
        </>
    );
}
