import React from 'react';
import cn from 'classnames';
import { CheckIcon, InfoCircledIcon, WarningFilledIcon, WarningShieldIcon } from '../Icons/Icons';
import { AlertProps, AlertIconProps } from './Alert.d';
import styles from './Alert.module.scss';

function AlertIcon({ type, className }: AlertIconProps) {
    switch (type) {
        case 'info':
            return <InfoCircledIcon className={className} />;
        case 'warning':
            return <WarningShieldIcon className={className} />;
        case 'success':
            return <CheckIcon className={className} />;
        case 'error':
            return <WarningFilledIcon className={className} />;
        default:
            return <InfoCircledIcon className={className} />;
    }
}

export default function Alert({
    action,
    classes,
    children,
    icon,
    role = 'alert',
    size = 'large',
    type = 'default',
    variant = 'standard'
}: AlertProps) {
    const { root, action: actionClass, content, contentWrapper, icon: iconClass, iconWrapper } = classes ?? {};

    const classesRoot = cn(
        styles.Root,
        styles[`Variant-${variant}`],
        styles[`Type-${type}`],
        styles[`Size-${size}`],
        root
    );

    return (
        <div className={classesRoot} role={role || undefined}>
            {icon !== false && (
                <div className={cn(styles.IconWrapper, iconWrapper)}>
                    {icon || <AlertIcon type={type} className={cn(styles.Icon, iconClass)} />}
                </div>
            )}

            <div className={cn(styles.ContentWrapper, contentWrapper)}>
                <div className={cn(styles.Content, content)}>{children}</div>
            </div>

            {action && <div className={cn(styles.Action, actionClass)}>{action}</div>}
        </div>
    );
}
