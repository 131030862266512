import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';
import { ClockFilledIcon } from 'ui/Icons/Icons';
import styles from './PaymentTimer.module.scss';

interface PaymentTimerClasses {
    content: string;
    icon: string;
    root: string;
    text: string;
    time: string;
}

export interface PaymentTimerProps {
    classes?: Partial<PaymentTimerClasses>;
    icon?: React.ReactNode | false;
    text?: React.ReactNode;
    timeout?: number;
    timestamp?: number;
    onStop?: () => void;
}

export default function PaymentTimer({ classes, icon, text, timestamp, timeout = 0, onStop }: PaymentTimerProps) {
    const { t } = useTranslation();
    const timer = useCountdownTimer(timeout, timestamp);

    const hours = parseInt(`${timer.timeLeft / 60 / 60}`, 10);
    const hoursText = hours < 10 ? `0${hours}:` : `${hours}:`;

    const minutes = parseInt(`${(timer.timeLeft / 60) % 60}`, 10);
    const minutesText = minutes < 10 ? `0${minutes}:` : `${minutes}:`;

    const seconds = parseInt(`${timer.timeLeft % 60}`, 10);
    const secondsText = seconds < 10 ? `0${seconds}` : `${seconds}`;

    const { root, content, icon: iconClass, text: textClass, time: timeClass } = classes ?? {};

    React.useEffect(() => {
        if (timer.timeLeft === 0) {
            onStop?.();
        }
    }, [onStop, timer.timeLeft]);

    return (
        <div className={cn(styles.Root, root)}>
            <div className={cn(styles.Content, content)}>
                {icon !== false && (icon || <ClockFilledIcon className={cn(styles.Icon, iconClass)} />)}
                <div className={cn(styles.Text, textClass)}>{text || t('payment.details.time')}</div>
            </div>
            <div className={cn(styles.Time, timeClass)}>
                {Boolean(hours) && hoursText}
                {minutesText}
                {secondsText}
            </div>
        </div>
    );
}
