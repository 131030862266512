import React from 'react';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import PaymentHowToPay from '../PaymentHowToPay/PaymentHowToPay';

export default function PaymentHowToPayModal({ open, setOpen, ...props }: ReturnType<typeof useDialog>) {
    const handleClose = () => setOpen(false);

    return (
        <Dialog open={open} fullWidth fullScreen transition="slide-left" {...props}>
            <PaymentHowToPay onClose={handleClose} />
        </Dialog>
    );
}
