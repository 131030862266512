import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import AuthHeader from '../AuthHeader/AuthHeader';
import AuthLogin from '../AuthLogin/AuthLogin';
import { View } from '../AuthLogin/AuthLogin.d';
import AuthQr from '../AuthQr/AuthQr';
import styles from './Auth.module.scss';

export default function Auth() {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    const [view, setView] = React.useState(View.App);

    if (isLandscapeOrLess) {
        if (view === View.Qr) {
            return (
                <AuthQr
                    HeaderProps={{
                        title: t('auth.qr.title'),
                        titleAlign: 'center',
                        children: isMobileOrLess && <AuthHeader className={styles.HeaderMobile} />,
                        onBackButtonClick: () => setView(View.Login)
                    }}
                />
            );
        }

        return (
            <AuthLogin
                HeaderProps={{
                    title: t('auth.login.title'),
                    children: isMobileOrLess && <AuthHeader className={styles.HeaderMobile} />
                }}
                onChangeView={setView}
            />
        );
    }

    return (
        <div className={styles.Container}>
            {!isLandscapeOrLess && <AuthHeader className={styles.Header} />}

            <div className={styles.Main}>
                <div className={styles.Login}>
                    <AuthLogin
                        HeaderProps={{
                            title: t('auth.login.title')
                        }}
                    />
                </div>
                <div className={styles.Qr}>
                    <AuthQr />
                </div>
            </div>
        </div>
    );
}
