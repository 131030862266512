import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAdmin, RequireAuth } from 'helpers/Auth/Auth';
import Preloader from 'ui/Preloader/Preloader';
import Layout from 'components/Layout/Layout';
import AdminLayout from 'components/AdminLayout/AdminLayout';
import StartTour from 'components/StartTour/StartTour';
import NotFoundPage from './NotFoundPage';
import UnderConstructionPage from './UnderConstructionPage';
import PaymentPage from './PaymentPage';
import AuthLoginPage from './AuthLoginPage';

const AuthSignupPage = React.lazy(() => import('./AuthSignupPage'));
const AuthRecoverPage = React.lazy(() => import('./AuthRecoverPage'));

const AdminDashboardPage = React.lazy(() => import('./AdminDashboardPage'));
const AdminTransactionsPage = React.lazy(() => import('./AdminTransactionsPage'));
const AdminListPage = React.lazy(() => import('./AdminListPage'));
const AdminAcquiringPage = React.lazy(() => import('./AdminAcquiringPage'));
const AdminMerchantPage = React.lazy(() => import('./AdminMerchantsPage'));
const AdminWalletsPage = React.lazy(() => import('./AdminWalletsPage'));

const DashboardPage = React.lazy(() => import('./DashboardPage'));
const TransactionsPage = React.lazy(() => import('./TransactionsPage'));

const DevelopersPage = React.lazy(() => import('./DevelopersPage'));
const DevelopersTabDocs = React.lazy(() => import('./DevelopersTabDocs'));
const DevelopersDocsArticleApi = React.lazy(() => import('./DevelopersDocsArticleApi'));
const DevelopersDocsArticleShopify = React.lazy(() => import('./DevelopersDocsArticleShopify'));
const DevelopersDocsArticleTilda = React.lazy(() => import('./DevelopersDocsArticleTilda'));
const DevelopersTabIntegrations = React.lazy(() => import('./DevelopersTabIntegrations'));

const IntegrationPage = React.lazy(() => import('./IntegrationPage'));
const DocsPage = React.lazy(() => import('./DocsPage'));

type ID = null | number | string;

export const URLS_MAP = {
    root: '/',
    login: '/login',
    signup: '/signup',
    recover: '/recover',
    admin: {
        index: '/admin',
        acquiring: '/admin/acquiring',
        list: '/admin/list',
        merchants: '/admin/merchants',
        transactions: '/admin/transactions',
        wallets: '/admin/wallets'
    },
    payment: (id: ID = ':id') => `/payment/${id}`,
    docs: {
        index: '/docs',
        api: '/docs/api',
        tilda: '/docs/tilda',
        shopify: '/docs/shopify'
    },
    dashboard: '/dashboard',
    transactions: '/transactions',
    developers: {
        index: '/for-developers',
        docs: {
            index: '/for-developers/docs',
            api: '/for-developers/docs/api',
            shopify: '/for-developers/docs/shopify',
            tilda: '/for-developers/docs/tilda'
        },
        integrations: '/for-developers/integrations'
    },
    integration: '/integration',
    notFound: '*'
};

function LoadingProgress() {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0
            }}
        >
            <Preloader variant="linear" />
        </div>
    );
}

export default function MainRoutes() {
    return (
        <Routes>
            <Route path={URLS_MAP.login} element={<AuthLoginPage />} />
            <Route
                path={URLS_MAP.signup}
                element={
                    <React.Suspense fallback={<LoadingProgress />}>
                        <AuthSignupPage />
                    </React.Suspense>
                }
            />
            <Route
                path={URLS_MAP.recover}
                element={
                    <React.Suspense fallback={<LoadingProgress />}>
                        <AuthRecoverPage />
                    </React.Suspense>
                }
            />
            <Route path={URLS_MAP.payment()} element={<PaymentPage />} />
            <Route
                path={URLS_MAP.docs.index}
                element={
                    <React.Suspense fallback={<LoadingProgress />}>
                        <DocsPage />
                    </React.Suspense>
                }
            >
                <Route
                    path={URLS_MAP.docs.api}
                    element={
                        <React.Suspense fallback={<LoadingProgress />}>
                            <DevelopersDocsArticleApi />
                        </React.Suspense>
                    }
                />
                <Route
                    path={URLS_MAP.docs.shopify}
                    element={
                        <React.Suspense fallback={<LoadingProgress />}>
                            <DevelopersDocsArticleShopify />
                        </React.Suspense>
                    }
                />
                <Route
                    path={URLS_MAP.docs.tilda}
                    element={
                        <React.Suspense fallback={<LoadingProgress />}>
                            <DevelopersDocsArticleTilda />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route element={<RequireAuth />}>
                <Route path={URLS_MAP.root} element={<StartTour />} />
                <Route element={<RequireAdmin />}>
                    <Route element={<AdminLayout />}>
                        <Route
                            path={URLS_MAP.admin.index}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminDashboardPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.acquiring}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminAcquiringPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.transactions}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminTransactionsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.merchants}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminMerchantPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.wallets}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminWalletsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.list}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <AdminListPage />
                                </React.Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route element={<Layout />}>
                    <Route
                        path={URLS_MAP.dashboard}
                        element={
                            <React.Suspense fallback={<LoadingProgress />}>
                                <DashboardPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.transactions}
                        element={
                            <React.Suspense fallback={<LoadingProgress />}>
                                <TransactionsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={URLS_MAP.developers.index}
                        element={
                            <React.Suspense fallback={<LoadingProgress />}>
                                <DevelopersPage />
                            </React.Suspense>
                        }
                    >
                        <Route
                            path={URLS_MAP.developers.docs.index}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <DevelopersTabDocs />
                                </React.Suspense>
                            }
                        >
                            <Route
                                path={URLS_MAP.developers.docs.api}
                                element={
                                    <React.Suspense fallback={<LoadingProgress />}>
                                        <DevelopersDocsArticleApi />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={URLS_MAP.developers.docs.shopify}
                                element={
                                    <React.Suspense fallback={<LoadingProgress />}>
                                        <DevelopersDocsArticleShopify />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={URLS_MAP.developers.docs.tilda}
                                element={
                                    <React.Suspense fallback={<LoadingProgress />}>
                                        <DevelopersDocsArticleTilda />
                                    </React.Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path={URLS_MAP.developers.integrations}
                            element={
                                <React.Suspense fallback={<LoadingProgress />}>
                                    <DevelopersTabIntegrations />
                                </React.Suspense>
                            }
                        />
                        <Route path="*" element={<UnderConstructionPage />} />
                    </Route>
                    <Route
                        path={URLS_MAP.integration}
                        element={
                            <React.Suspense fallback={<LoadingProgress />}>
                                <IntegrationPage />
                            </React.Suspense>
                        }
                    />
                </Route>
            </Route>
            <Route path={URLS_MAP.notFound} element={<NotFoundPage />} />
        </Routes>
    );
}
