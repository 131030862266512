import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { showModalSessionVar } from 'apollo/cache';
import { URLS_MAP } from 'routes/routes';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import { InfoCircledIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import styles from './AuthSessionModal.module.scss';

export default function AuthSessionModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const showModalSession = useReactiveVar(showModalSessionVar);
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const formId = React.useId();

    const handleClose = () => {
        showModalSessionVar(false);
        navigate({ pathname: URLS_MAP.login });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleClose();
    };

    return (
        <Dialog
            open={showModalSession}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            onOpenChange={handleClose}
        >
            <DialogHeader title={t('auth.session.title')}>
                <div className={styles.IconWrapper}>
                    <InfoCircledIcon fontSize={20} />
                </div>
            </DialogHeader>
            <DialogBody>
                <form id={formId} onSubmit={handleSubmit}>
                    <div className={styles.Description}>{t('auth.session.description')}</div>
                </form>
            </DialogBody>
            <DialogFooter>
                <Button type="submit" form={formId} size="large" fullWidth>
                    {t('auth.session.button')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
