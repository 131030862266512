import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useResendCodeMutation, useVerifyCodeMutation } from 'apollo/generated';
import { constructAuthHeader } from 'helpers/Auth/Auth';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { ClockIcon } from 'ui/Icons/Icons';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import InputCode from 'ui/InputCode/InputCode';
import { AuthCodeProps, ResendBtnProps } from './AuthCode.d';
import styles from './AuthCode.module.scss';

const TIMER_SECONDS = 60;

function ResendButton({ onError, stopTimer, token, onTokenChange }: ResendBtnProps) {
    const { t } = useTranslation();
    const resendTimer = useCountdownTimer(TIMER_SECONDS);
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const [resendCodeMutation, { loading }] = useResendCodeMutation();

    const minutes = parseInt(`${resendTimer.timeLeft / 60}`, 10);
    const seconds = parseInt(`${resendTimer.timeLeft % 60}`, 10);

    const handleClickResend = () => {
        resendCodeMutation({
            context: token ? { headers: constructAuthHeader(token) } : undefined
        })
            .then(({ data }) => {
                const resendCode = data?.resendCode;

                if (resendCode?.__typename === 'ResendCodeSuccess') {
                    onTokenChange?.(resendCode.token);
                    resendTimer.reset();
                    toast.success(t('auth.code.resendAlert'));
                } else {
                    const { __typename, errorMessage = '' } = resendCode ?? {};

                    onError?.(t([`auth.code.error.${__typename}`, errorMessage, 'global.error.tryLater']));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    React.useEffect(() => {
        if (stopTimer) resendTimer.stop();
    }, [stopTimer, resendTimer]);

    if (resendTimer.timeLeft <= 0)
        return (
            <div className={styles.ResendBtnWrapper}>
                <div>{t('auth.code.resendNotReceived')}</div>
                <Link as="button" type="button" disabled={loading} size="small" onClick={handleClickResend}>
                    {t('auth.code.resendButton')}
                </Link>
            </div>
        );

    return (
        <div className={styles.Timer}>
            {t('auth.code.resendTimer')}
            <div className={styles.TimerText}>
                <ClockIcon className={styles.TimerIcon} />
                {minutes}:{seconds.toString().padStart(2, '0')}
            </div>
        </div>
    );
}

export default function AuthCode({
    description,
    submitText,
    className,
    onSuccess,
    onResendError,
    verificationToken
}: AuthCodeProps) {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const onGlobalError = useGlobalError();

    const formId = React.useId();

    const inputCode = useFormField('');

    const [verifyCodeMutation, { loading }] = useVerifyCodeMutation();

    const [token, setToken] = React.useState(verificationToken);

    const handleTokenChange = (nextToken: string) => setToken(nextToken);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const inputCodeValue = inputCode.value.trim();

        if (inputCodeValue.length < 6) {
            return inputCode.errorChange(true, t('auth.code.error.requiredFields'));
        }

        verifyCodeMutation({
            context: token ? { headers: constructAuthHeader(token) } : undefined,
            variables: { input: { code: inputCodeValue } }
        })
            .then(({ data }) => {
                const verifyCode = data?.verifyCode;

                if (verifyCode?.__typename === 'VerifyCodeSuccess') {
                    onSuccess?.(verifyCode.token);
                } else if (verifyCode?.__typename === 'NoConfirmationAttemptsError') {
                    inputCode.errorChange(true, t(`auth.code.error.${verifyCode?.__typename}`));
                } else {
                    const { __typename, errorMessage = '' } = verifyCode ?? {};

                    inputCode.errorChange(
                        true,
                        t([`auth.code.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    return (
        <>
            <form id={formId} onSubmit={handleSubmit} className={cn(styles.Form, className)}>
                {description && <div className={styles.Description}>{description}</div>}

                <div className={styles.Field}>
                    <InputCode
                        classes={{ container: styles.InputCodeContainer }}
                        label={t('auth.code.label')}
                        size={isMobileOrLess ? 40 : 46}
                        fieldsCount={6}
                        isError={inputCode.error}
                        value={inputCode.value}
                        helperText={inputCode.helperText}
                        type="numeric"
                        onChange={inputCode.change}
                    />
                </div>
            </form>
            <div className={styles.ButtonsWrapper}>
                <Button
                    type="submit"
                    form={formId}
                    classes={{ root: styles.BtnSubmit }}
                    loading={loading}
                    disabled={inputCode.value.length < 6 || inputCode.error}
                    size="large"
                    fullWidth
                >
                    {submitText || t('auth.buttonContinue')}
                </Button>

                <ResendButton token={token} onTokenChange={handleTokenChange} onError={onResendError} />
            </div>
        </>
    );
}
