import React from 'react';

/**
 * Ensures that the input element's previous valid value is retained if the new value is not valid.
 *
 * @description
 * This function validates the input field's value based on HTML5 validation rules.
 * If the entered value passes the validation rules (e.g., it's not an empty string, satisfies
 * the `minLength`, `maxLength`, `pattern`, `required` attributes, etc.), the new value will be set
 * as the current input field value. If the entered value doesn't pass validation, the current value
 * will remain unchanged.
 *
 * This function is useful for preventing invalid values from being set in the input field, ensuring
 * that only valid data is retained.
 *
 * If you don't use this function, the input field value will be updated regardless of validation.
 * This may result in invalid values that do not adhere to validation rules being set as new input field values.
 *
 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event - The change event of the input field.
 * @param {string} currentValue - The current value of the input field.
 *
 * @returns {string} - The new value of the input field or the current value if the new value doesn't pass validation.
 */
export default function validateInputValue(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentValue: string
): string {
    const { value, validity } = event.target;

    return validity.valid ? value : currentValue;
}
