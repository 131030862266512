/**
 * Insecure Random number.
 *
 * @param {number} min
 * @param {number} max
 *
 * @returns {number}
 */
const insecureRandom = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export default insecureRandom;
