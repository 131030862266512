export default {
    title_paid: 'Order paid',
    title_cancelled: 'Order cancelled',
    title_expired: 'Order not paid',
    title_processing: 'Payment processing',

    description_cancelled: 'Your order has been canceled by the store',
    description_expired: 'The payment link has expired',
    description_processing: 'If processing takes more than an hour, contact technical support',
    time: 'After 10 seconds, you will automatically return to the store',
    details: {
        title: 'OrderPage information',
        orderNumber: 'Order №',
        store: 'Store',
        token: 'Payment In Progress method',
        status: 'Status',
        statusType: {
            CREATED: 'Waiting for payment',
            MONEY_RECEIVED: 'Waiting for confirm',
            PAID: 'Paid',
            CANCELLED: 'Cancelled',
            EXPIRED: 'Expired'
        },
        infoAlert: 'Usually a transaction comes within 3 minutes',
        toPay: 'Total to be paid',
        fee: 'Commission',
        total: 'Total',
        time: 'Account validity time',
        select: 'Select a network',
        network: 'Network',
        minutes: 'MIN',
        created: {
            text: 'Created by',
            subtext: 'PayCash for Store'
        },
        name: 'Name',
        surname: 'Last name',
        email: 'Email',
        formName: 'Enter your name',
        formSurname: 'Enter your last name',
        formEmail: 'Enter your email',
        emailValid: 'Invalid email format'
    },

    paymentInfoAttention: {
        text: 'Use one of the applications',
        payCrypto: 'Pay with crypto',
        payCard: 'Pay with card',
        btnPay: 'Pay',
        btnShowDetails: 'Other payment methods'
    },

    paymentMethod: {
        error: {
            title: 'Error',
            notAvailable: 'Payment method not available',
            chooseAnother: 'Choose another payment method'
        }
    },

    paymentMobileApp: {
        title: 'Choose a payment method',
        description: 'With app',
        divider: 'or',
        btnQr: 'By QR code and details'
    },

    paymentRusPay: {
        input: {
            label: 'First and last name',
            placeholder: 'Enter your first and last name',
            error: {
                formatInvalid: 'The name should be filled in with 2 words'
            }
        }
    },

    paymentWorldPay: {
        input: {
            label: 'First and last name',
            placeholder: 'Enter your first and last name',
            helperText: 'Enter your first and last name in English',
            error: {
                formatInvalid: 'The name should be filled in with 2 words'
            }
        },
        description:
            'By clicking the "Confirm and Pay" button,\n' +
            'I confirm that I have read the offer posted at the link <0>elm47.com/offer</0>, as well as all <1>order details</1>, and accept them in full.',
        description_otp:
            'Do not change the OTP code on the page that opens.\n' +
            'On the page that opens, click "Next", then\n' +
            'click "Accept & Continue", then select a convenient one\n' +
            'for you method and make payment',
        error: {
            paymentLinkNotFound: 'Payment link not found'
        }
    },

    selectPaymentMethod: 'Select a payment method',
    cryptocurrency: 'Cryptocurrency',
    cards: 'Bank cards',
    bank: {
        international: 'International bank',
        international_aed: 'International e-voucher',
        russian: 'Russian bank'
    },

    pay: 'Go to the payment',
    buttonConfirmAndPay: 'Confirm and pay',
    btnHowTo: 'How to pay for an order?',
    btnShare: 'Share',
    btnShareDescription: 'Send an invoice',
    btnShareDescriptionMobile: 'Click to send an invoice',
    btnReturnToStore: 'Back to shop',
    btnCheckTransfer: 'Check at',
    btnContinue: 'Continue',

    shareModal: {
        title: 'Share link',
        linkCopy: 'Link copied',
        send: 'Send',
        sendError: 'The sharing feature is not supported by your browser. Link copied.',
        shareError: 'Error share:',
        paymentLink: 'Payment link:',
        downloadQrError: 'Something went wrong: Failed to download QR code'
    },

    howToPay: {
        title: 'How do I pay for my order?',
        goBack: 'Go back',

        downloadStep: {
            description: 'Download one of the recommended apps',
            title: 'Download the app'
        },
        createAccountStep: {
            title: 'Create a Free Account',
            description:
                'Now you need to create a wallet or import an existing EOS wallet into the application. To create a wallet:',
            step_1: 'Click on the “Create Wallet” button',
            step_2: 'Come up with a name for your wallet',
            step_3: 'Save the generated private key'
        },
        topUpStep: {
            title: 'Top up your account balance',
            description:
                'Next, you need to top up your wallet balance. There are several ways to top up your wallet balance, we recommend to top up directly from a bank card. You can also top up your balance through the Binance exchange and other exchanges or through the “Buy” section in the app.',
            button_1: 'Top up with a card',
            button_2: 'How to deposit via the exchange'
        },
        openQrScanStep: {
            title: 'Open the QR scanner',
            description:
                'After you have topped up your balance, click on the QR button in the upper right corner of the application.'
        },

        scanQrStep: {
            title: 'Scan the QR code',
            description:
                'When paying with cryptocurrency, scan the QR code on the payment page through the app. When paying with a bank card, click continue. On the payment page, enter the card details'
        },
        confirmStep: {
            title: 'Confirm the transfer',
            description: 'Confirm the transfer in the app.'
        },
        doneStep: {
            title: 'Done',
            description: '10 seconds after paying the bill, you will be automatically redirected back to the store.'
        },

        step_1: 'Step 1',
        step_2: 'Step 2',
        step_3: 'Step 3',
        step_4: 'Step 4',
        step_5: 'Step 5',
        step_6: 'Step 6',
        step_7: 'Step 7'
    },

    paymentInfo: {
        title: 'Scan the QR\nwith the camera in the app',
        subTitle: 'via app',

        payWithQr: 'Payment by QR code and details',

        btnDownloadQr: 'Download QR',

        orDivider: 'or pay manually',

        account: 'Wallet',
        memo: 'MEMO',
        amount: 'Amount to be paid'
    },

    order: {
        error: {
            loadingFailed: 'An error occurred while loading the page.\nTry reloading the page.'
        },
        button: {
            refresh: 'Refresh'
        }
    },
    test: {
        title: 'Status',
        title_aside: 'Select order status',
        button: {
            pending: 'Waiting for payment',
            success: 'Successful payment',
            expired: 'Payment time has expired',
            cancel: 'Cancel',
            change: 'Change order status'
        }
    },
    info: {
        title: 'Scan the QR\nwith the camera in the app',
        account: 'Wallet',
        memo: 'MEMO',
        alert_EOS: 'Please enter the address, amount and MEMO correctly.\nIn case of error, funds will be lost.',
        alert_ETHER: 'Please enter the address correctly.\nIn case of error, funds will be lost.',
        alert_TRON: 'Please enter the address correctly.\nIn case of error, funds will be lost.'
    },
    orderPaid: {
        success: 'The balance has been successfully top up',
        error: {
            FiatMethodNotFoundError: 'Top-up method not found',
            OrderNotFoundError: 'Order not found',
            OrderInactiveError: 'Order is no longer active',
            OrderPaidError: 'The order has already been paid',
            ActionLockedError: 'in {{cooldown}} seconds',
            paidNotFound: "Payment not found. Make sure you've paid\nthe bill and try checking in a minute"
        },
        submit: {
            button: 'I paid',
            timerText: 'Can be checked in'
        }
    },
    status: {
        amount: 'Has been paid',
        amount_processing: 'In processing',
        button: {
            support: 'Write in tech. support'
        },
        error: {
            OrderNotFoundError: 'Order not found'
        }
    }
};
