import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import Button from 'ui/Button/Button';
import { SandClockIcon } from 'ui/Icons/Icons';
import { CombinedStatusEnum, Direction, TransferSortEnum, useAllTransfersQuery } from 'apollo/generated';

const LOCAL_STORAGE_TRANSACTIONS_RETRY_TIMESTAMP = 'transactions_retry_timestamp';

const timestamp = localStorage.getItem(LOCAL_STORAGE_TRANSACTIONS_RETRY_TIMESTAMP);

const variables = {
    offset: 0,
    first: 1,
    filters: {
        createdDate: {
            from: timestamp ? new Date(+timestamp) : null,
            to: new Date()
        },
        status: [CombinedStatusEnum.Retry]
    },
    sort: [TransferSortEnum.CreatedDesc]
};

export default function TransactionsProcessing({ classRoot }: { classRoot: string }) {
    const [searchParams] = useSearchParams();

    const paramsDirection = searchParams.get('direction') ?? Direction.In;

    const { data, loading } = useAllTransfersQuery({
        variables,
        fetchPolicy: 'network-only'
    });

    const retryTransactionsNumber = data?.allTransfers?.pageInfo.totalFiltered;

    const link = window.location.pathname.includes('/admin/transactions')
        ? `admin/transactions?direction=${paramsDirection}&inProcessing=1`
        : 'admin/transactions?direction=OUT&inProcessing=1';

    return (
        <Button
            classes={{ root: classRoot }}
            as={NavLink}
            color="secondary"
            iconStart={<SandClockIcon />}
            variant="outlined"
            isDark
            to={link}
            loading={loading}
        >
            {Boolean(retryTransactionsNumber) && retryTransactionsNumber}
        </Button>
    );
}
