import React from 'react';
import cn from 'classnames';
import { PreloaderProps } from './Preloader.d';
import styles from './Preloader.module.scss';

const SIZE = 44;

const Preloader = React.forwardRef<HTMLSpanElement, PreloaderProps>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function PreloaderComponent(
        {
            color = 'primary',
            classes,
            disableShrink,
            isDefinite,
            size = 28,
            thickness = 4,
            value = 0,
            variant = 'circle',
            ...props
        }: PreloaderProps,
        ref
    ) {
        const { root, circle, barFirst, barSecond } = classes ?? {};

        const classesRoot = cn(
            styles.Root,
            styles[`Color-${color}`],
            styles[`Variant-${variant}`],
            disableShrink && styles.ShrinkDisable,
            isDefinite && styles.Definite,
            root
        );

        const circumference = 2 * Math.PI * ((SIZE - thickness) / 2);

        return (
            <span
                className={classesRoot}
                role="progressbar"
                style={{ fontSize: size, height: variant === 'linear' ? Math.trunc(thickness) : undefined }}
                {...props}
                aria-valuenow={isDefinite ? Math.round(value) : undefined}
                ref={ref}
            >
                {variant === 'circle' && (
                    <svg className={cn(styles.Svg, circle)} viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
                        <circle
                            className={styles.Circle}
                            cx={SIZE}
                            cy={SIZE}
                            r={(SIZE - thickness) / 2}
                            fill="none"
                            strokeWidth={thickness}
                            strokeDasharray={isDefinite ? circumference.toFixed(3) : '80px, 200px'}
                            strokeDashoffset={
                                isDefinite ? `${(((100 - value) / 100) * circumference).toFixed(3)}px` : 0
                            }
                        />
                    </svg>
                )}
                {variant === 'linear' && (
                    <>
                        <span
                            className={cn(styles.BarFirst, barFirst)}
                            style={{
                                transform: isDefinite ? `translateX(${value - 100}%)` : 'none'
                            }}
                        />
                        <span className={cn(styles.BarSecond, barSecond)} />
                    </>
                )}
            </span>
        );
    }
);

export default Preloader;
