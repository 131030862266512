import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentAmountDetailsProps } from './PaymentAmountDetails.d';
import styles from './PaymentAmountDetails.module.scss';

export default function PaymentAmountDetails({ currency = '', receive, fee, send }: PaymentAmountDetailsProps) {
    const { t } = useTranslation();

    return (
        <div className={styles.Root}>
            {receive && (
                <div className={styles.Item}>
                    <span className={styles.Label}>{t('payment.details.toPay')}</span>
                    <span className={styles.Value}>
                        {receive} {currency}
                    </span>
                </div>
            )}

            {fee && (
                <div className={styles.Item}>
                    <span className={styles.Label}>{t('payment.details.fee')}</span>
                    <span className={styles.Value}>
                        {fee} {currency}
                    </span>
                </div>
            )}

            {send && (
                <div className={styles.Item}>
                    <span className={styles.Label}>{t('payment.details.total')}</span>
                    <span className={styles.Value}>
                        {send} {currency}
                    </span>
                </div>
            )}
        </div>
    );
}
