export default {
    login: {
        title: 'Enter',
        signUp: '<0>No account?</0> <1>Sign up</1>',
        recover: '<0>Forgot your password?</0><1>Reset password</1>',
        success: 'You have successfully\nlogged in'
    },
    logout: {
        title: 'Logout of account',
        description: 'Are you sure you want to logout of your account',
        buttonSubmit: 'Yes',
        buttonCancel: 'No'
    },
    qr: {
        title: 'Login with QR',
        description: 'To login or register, <0>select\napp and scan the QR code</0>',
        subtext: 'Select an application',
        error: {
            loadingFailed: 'QR code\nloading failed'
        }
    },
    app: {
        title: 'Login with app',
        description: 'For quick and convenient login, we recommend using one of the applications',
        divider: 'or',
        download: "Don't have an app?\n<0>Download</0>"
    },
    session: {
        title: 'Auto exit',
        description: 'You have been logged out because your account has been inactive for 30 minutes',
        button: 'Enter'
    },
    inputLogin: {
        label: 'Login',
        placeholder: 'Enter email or phone'
    },
    inputPassword: {
        label: 'Password',
        placeholder: 'Enter password',
        tooltipShow: 'Show',
        tooltipHide: 'Hide',
        rule: {
            length: '8 characters',
            uppercase: '1 capital character',
            number: '1 digit',
            special: '1 special sign'
        },
        error: {
            shortLength: 'Password length must be greater than 8 characters',
            noUppercase: 'Password must contain at least one uppercase letter',
            noNumber: 'Password must contain at least one number',
            noSpecial: 'The password must contain at least one special character (!@#$%^&*—_+=;:,./?|`~{}[])'
        }
    },
    inputPasswordRepeat: {
        label: 'Repeat password',
        placeholder: 'Repeat password',
        error: {
            notMatch: 'The entered passwords do not match'
        }
    },
    signUp: {
        title: 'Sign up',
        login: 'Already have an account? <0>Login</0>',
        button: 'Sign up',
        error: {
            InvalidPasswordError: 'Password does not meet requirements',
            LoginIsEmptyError: 'Please enter an email address or phone number',
            InvalidEmailError: 'The entered email format is not valid',
            InvalidPhoneError: 'The entered phone number format is not valid',
            UserAlreadyExistsError_email: 'The entered email is already in use',
            UserAlreadyExistsError_phone: 'The entered phone number is already in use'
        }
    },
    recover: {
        title: 'Reset the password',
        description:
            'In order to recover your password, enter the email or phone number you provided during registration',
        button: 'Update password',
        error: {
            requiredFields: 'To continue, enter your email or phone number',
            LoginIsEmptyError: 'Please enter an email address or phone number',
            NoResendAttemptsError: 'You have exhausted all attempts for today. Try later.',
            UserNotFoundError: 'User not found',
            InvalidVerifyCodeError: 'Invalid verification code',
            InvalidJWTError: 'JWT token error',
            InvalidPasswordError: 'Password does not meet requirements'
        }
    },
    code: {
        label: 'Confirmation code',
        descriptionSignUp: 'To confirm registration, a confirmation code was sent to <0>{{account}}</0>',
        descriptionRecover: 'Enter the confirmation code that was sent to <0>{{account}}</0>',
        resendTimer: 'The code can be sent again in',
        resendButton: 'Resend verification code',
        resendNotReceived: "Didn't receive the code?",
        resendAlert: 'The verification code has been resent. Also check your spam folder.',
        error: {
            requiredFields: 'Enter a code',
            NoResendAttemptsError: 'You have exhausted all attempts for today. Try later.',
            InvalidJWTError: 'JWT token error',
            NoConfirmationAttemptsError: 'The number of attempts has been reached. Try later.',
            InvalidVerifyCodeError: 'Invalid verification code',
            UserAlreadyExistsError: 'The user already exists',
            VerifyCodeExpiredError: 'The verification code has expired.\nPlease use a new code.'
        }
    },
    buttonEnter: 'Login to account',
    buttonContinue: 'Continue',
    buttonForgotPassword: 'Forgot your password?',
    buttonLoginWithQr: 'Login with Qr',
    buttonLoginWithApp: 'Login with app',
    buttonLogin: 'Enter by login',
    buttonReload: 'Reload',
    error: {
        emailInvalid: 'The entered email format is not valid',
        phoneInvalid: 'The entered phone number format is not valid',
        loginInvalid: 'You have entered an incorrect login or password',
        requiredFields: 'Please fill all required fields to continue',
        loginFailed: 'Login failed'
    }
};
