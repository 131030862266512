import notify from './notify';
import acquiring from './acquiring';
import auth from './auth';
import profile from './profile';
import store from './store';
import dashboard from './dashboard';
import deposit from './deposit';
import transfer from './transfer';
import order from './order';
import developers from './developers';
import transactions from './transactions';
import payment from './payment';

export default {
    global: {
        copied: 'Data copied',
        units: {
            hours: 'hour',
            hours_short: 'h',
            minutes: 'min',
            minutes_short: 'm.'
        },
        button: {
            refresh: 'Refresh',
            reset: 'Reset'
        },
        error: {
            default: 'Error',
            tryLater: 'An error occurred. Try later.',
            tryMore: 'An unexpected error occurred.\nPlease try again.',
            loadingError: 'An error occurred while loading items.\nTry refreshing the page.'
        }
    },
    navigation: {
        dashboard: 'Dashboard',
        transactions: 'Transactions',
        forDevelopers: 'For developers',
        profile: 'Profile',
        settings: 'Settings',
        support: 'Support',
        more: 'More',
        storeSettings: 'Store settings'
    },
    notify,
    acquiring,
    auth,
    profile,
    store,
    deposit,
    transfer,
    order,
    dashboard,
    transactions,
    developers,
    payment,
    underConstruction: {
        title: 'В разработке',
        description: 'Данный раздел находится\nв разработке'
    },
    pageError: {
        code: 'Ошибка {{ code}}',
        title_500: 'Внутренняя\nошибка сервера',
        title_404: 'Страница\nне найдена',
        description_500:
            'На сервере произошла непредвиденная\nошибка. Пожалуйста, подождите: она\nвскоре будет исправлена.',
        description_404: 'Вероятно, страница не существует\nили вы ошиблись, когда вводили\nадрес в строке браузера',
        btn: 'Вернуться на главную'
    }
};
