export enum Step {
    Name,
    Key,
    Password
}

export interface CreateStoreProps {
    isModalView?: boolean;
    onClose?: () => void;
    existingStoreNames?: (string | undefined)[];
    onClose?: () => void;
    step: Step;
    setStep: (s: Step) => void;
    onStoreCreate?: (name?: string) => void;
}
