import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
    isAdminVar,
    isAuthVar,
    showModalSessionVar,
    LOCAL_STORAGE_KEY_TOKEN_AUTH,
    LOCAL_STORAGE_KEY_IS_ADMIN
} from 'apollo/cache';
import { URLS_MAP } from 'routes/routes';

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);

export const AUTH_HEADER = 'Authorization';

export const constructAuthHeader = (token: string) => ({
    Authorization: `JWT ${token}`
});

export const admin = (bool: boolean) => {
    isAdminVar(bool);

    if (bool) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_IS_ADMIN, '1');
    }
};

export const login = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_AUTH, token);
    isAuthVar(true);
};

export const logout = (withShowSessionModal?: boolean) => {
    if (withShowSessionModal) showModalSessionVar(true);

    window.localStorage.removeItem(LOCAL_STORAGE_KEY_IS_ADMIN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
    admin(false);
    isAuthVar(false);
};

export function RequireAuth() {
    const location = useLocation();
    const isAuth = useReactiveVar(isAuthVar);

    return isAuth ? <Outlet /> : <Navigate to={{ pathname: URLS_MAP.login }} state={{ from: location }} replace />;
}

export function RequireAdmin() {
    const location = useLocation();
    const isAdmin = useReactiveVar(isAdminVar);

    return isAdmin ? <Outlet /> : <Navigate to={URLS_MAP.root} state={{ from: location }} replace />;
}
