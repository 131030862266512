import React from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useConnectByWalletMutation, useConnectByWalletWsSubscription, WalletName } from 'apollo/generated';
import { ReactComponent as KalynaIcon } from 'assets/images/app-kalyna.svg';
import { ReactComponent as MalinkaIcon } from 'assets/images/app-malinka.svg';
import { ReactComponent as PaycashIcon } from 'assets/images/app-paycash.svg';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useTimeout from 'hooks/useTimeout/useTimeout';
import useAuth from 'hooks/useAuth/useAuth';
import { CheckFilledCircledIcon, RefreshIcon, WarningFilledIcon } from 'ui/Icons/Icons';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import modalStyles from 'ui/Dialog/Dialog.module.scss';
import Preloader from 'ui/Preloader/Preloader';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import Tabs from 'ui/Tabs/Tabs';
import Qr from '../Qr/Qr';
import AuthHeader from '../AuthHeader/AuthHeader';
import { AuthQrProps, AuthQrErrorProps } from './AuthQr.d';
import styles from './AuthQr.module.scss';

function Error({ onClick }: AuthQrErrorProps) {
    const { t } = useTranslation();

    return (
        <div className={styles.Error}>
            <div className={styles.ErrorContainer}>
                <WarningFilledIcon className={styles.ErrorIcon} />
                <div className={styles.ErrorText}>{t('auth.qr.error.loadingFailed')}</div>
                <Button onClick={onClick} iconStart={<RefreshIcon />} variant="outlined" color="secondary">
                    {t('auth.buttonReload')}
                </Button>
            </div>
        </div>
    );
}

function Success() {
    const { t } = useTranslation();
    const isTouchableDevice = useIsTouchableDevice();

    return (
        <div
            className={cn(
                modalStyles.Wrapper,
                modalStyles.WrapperFullScreen,
                styles.DialogWrapper,
                !isTouchableDevice && styles.DialogWrapperDesktop
            )}
        >
            <DialogBody>
                <div className={styles.Success}>
                    <div className={styles.SuccessContainer}>
                        <CheckFilledCircledIcon className={styles.SuccessIcon} />
                        <div className={styles.SuccessText}>{t('auth.login.success')}</div>
                    </div>
                </div>
            </DialogBody>
        </div>
    );
}

export default function AuthQr({ wallet = WalletName.Paycash, onWalletChange, HeaderProps }: AuthQrProps) {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isLandscapeOrMore = useMediaQuery((breakpoints) => breakpoints.up.md);
    const isTouchableDevice = useIsTouchableDevice();
    const { login } = useAuth();

    const [walletName, setWalletName] = React.useState(wallet);
    const [token, setToken] = React.useState('');
    const [error, setError] = React.useState('');
    const [qrData, setQrData] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [showSuccess, setShowSuccess] = React.useState(false);

    const appLink = React.useMemo(() => {
        switch (walletName) {
            case WalletName.Malinka:
                return 'https://malinka.life/';
            case WalletName.Kalyna:
                return 'https://kalyna.online/';

            default:
                return 'https://paycash.app/';
        }
    }, [walletName]);

    const handleAuthError = (err: string) => setError(err);

    const handleAuthSuccess = (dataToken?: string | null) => {
        if (dataToken) {
            setToken(dataToken);
            setShowSuccess(true);
        } else {
            setError('Empty token');
        }
    };
    // prettier-ignore
    useTimeout(() => {
        if (showSuccess) {
            login(token);
        }
    }, 1500, [showSuccess]);

    const [connectByWalletMutation, { loading }] = useConnectByWalletMutation();
    /** After generating QR code and verificationCode awaiting response from server */
    useConnectByWalletWsSubscription({
        skip: !verificationCode,
        variables: { verificationCode },
        onData: ({ data: { data } }) => {
            const connectByWallet = data?.connectByWallet;

            if (connectByWallet?.__typename === 'WalletConnectionSuccess') {
                const { token: dataToken = '' } = connectByWallet;

                handleAuthSuccess(dataToken);
            } else {
                const errorMessage = connectByWallet?.errorMessage ?? '';

                handleAuthError(errorMessage);
            }
        }
    });
    /** Generate QR code and verificationCode for selected `wallet` type */
    const connectByWalletCb = React.useCallback(async () => {
        try {
            handleAuthError('');

            const { data: d } = await connectByWalletMutation({
                variables: { input: { walletName } },
                context: { headers: { Authorization: '' } }
            });

            const connectByWallet = d?.connectByWallet;

            if (connectByWallet?.__typename === 'ConnectByWalletSuccess') {
                const { qrData: qr, verificationCode: vc } = connectByWallet ?? {};

                setQrData(qr ?? '');
                handleAuthError('');
                setVerificationCode(vc);
            } else if (connectByWallet?.__typename === 'DeeplinkNotGeneratedError') {
                handleAuthError(connectByWallet?.errorMessage);
            }
        } catch (err) {
            handleAuthError(String(err));
        }
    }, [connectByWalletMutation, walletName]);

    const handleReload = () => connectByWalletCb();

    const handleWalletChange = (nextWalletName: WalletName) => {
        setWalletName(nextWalletName);
        onWalletChange?.(nextWalletName);
    };

    React.useEffect(() => {
        (async () => {
            try {
                await connectByWalletCb();
            } catch (err) {
                handleAuthError(String(err));
            }
        })();
    }, [connectByWalletCb, wallet]);

    if (showSuccess) return <Success />;

    return (
        <>
            {!isMobileOrLess && !isLandscapeOrMore && !isTouchableDevice && (
                <div className={styles.HeaderWrapper}>
                    <AuthHeader className={styles.Header} />
                </div>
            )}
            <div
                className={cn(
                    modalStyles.Wrapper,
                    isMobileOrLess && modalStyles.WrapperFullScreen,
                    !isMobileOrLess && modalStyles['WrapperScroll-body'],
                    !isTouchableDevice && styles.DialogWrapperDesktop,
                    styles.DialogWrapper
                )}
            >
                <div
                    className={cn(
                        modalStyles.Container,
                        !isMobileOrLess && modalStyles['MaxWidth-sm'],
                        !isMobileOrLess && modalStyles.ContainerFullWidth,
                        isMobileOrLess && modalStyles.ContainerFullScreen,
                        !isTouchableDevice && styles.DialogContainerDesktop
                    )}
                >
                    <DialogHeader
                        classes={{ root: cn(modalStyles.Header, !isTouchableDevice && styles.DialogHeaderDesktop) }}
                        hideCloseButton
                        {...HeaderProps}
                    />
                    <DialogBody className={cn(modalStyles.Body, styles.DialogBody)}>
                        {isTouchableDevice && (
                            <div className={styles.Description}>
                                <Trans i18nKey="auth.qr.description">
                                    <span className={styles.DescriptionTextMain} />
                                </Trans>
                            </div>
                        )}
                        <div className={styles.Container}>
                            <div className={styles.Content}>
                                {error && <Error onClick={handleReload} />}

                                {loading && (
                                    <div className={styles.Preloader}>
                                        <Preloader size={40} />
                                    </div>
                                )}

                                {!loading && !error && qrData && <Qr value={qrData} size={220} />}
                            </div>
                            <div className={styles.TabsContainer}>
                                <Tabs
                                    onChange={handleWalletChange}
                                    tabList={[
                                        {
                                            label: <PaycashIcon className={styles.TabsIcon} />,
                                            value: WalletName.Paycash
                                        },
                                        {
                                            label: <KalynaIcon className={styles.TabsIcon} />,
                                            value: WalletName.Kalyna
                                        },
                                        {
                                            label: <MalinkaIcon className={styles.TabsIcon} />,
                                            value: WalletName.Malinka
                                        }
                                    ]}
                                    fullWidth
                                    value={walletName}
                                />
                                <div className={styles.TabsDescription}>{t('auth.qr.subtext')}</div>
                            </div>
                        </div>
                    </DialogBody>
                    <DialogFooter>
                        {isTouchableDevice ? (
                            <div className={styles.Description}>
                                <Trans i18nKey="auth.app.download">
                                    <Link as="a" size="small" href={appLink} target="_blank" rel="noreferrer">
                                        .
                                    </Link>
                                </Trans>
                            </div>
                        ) : (
                            <div className={styles.Description}>
                                <Trans i18nKey="auth.qr.description">
                                    <span className={styles.DescriptionTextMain} />
                                </Trans>
                            </div>
                        )}
                    </DialogFooter>
                </div>
            </div>
        </>
    );
}
