export default {
    acquiring: 'Acquiring',
    balance: 'Balance',
    created: 'Created',
    disabled: 'Disabled',
    eos: 'eos',
    email: 'Email',
    name: 'name',
    phone: 'phone',
    RUBLES: 'RUBLES',
    RUBCASH: 'RUBCASH',
    RUS_PAY: 'RF',
    USDCASH: 'USDCASH',
    USDT: 'USDT',
    WORLD_PAY: 'World',
    currency: {
        name: 'токены'
    }
};
