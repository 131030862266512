import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';

export default function getPaymentParamsAmount(amountWithCurrencyName?: string, precision = 2) {
    const amount = addThousandsSeparator(numToPrecisionStr(Number(amountWithCurrencyName?.split(' ')[0]), precision));
    const currencyName = amountWithCurrencyName?.split(' ')[1];

    return {
        amount,
        currencyName
    };
}
