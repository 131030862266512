import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PaymentMethod } from 'apollo/generated';
import { BankCardFilledIcon, CentFilledCircledIcon, SendFilledIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import PaymentButton from '../PaymentButton/PaymentButton';
import { contains } from '../PaymentDetails/PaymentDetails';
import { Methods } from '../PaymentDetails/PaymentDetails.d';
import { PaymentMobileActionsProps } from './PaymentMobileActions.d';
import styles from './PaymentMobileActions.module.scss';

export default function PaymentMobileActions({
    activePaymentMethod,
    children,
    dropdownSelected,
    methods,
    onMobileAppClick,
    onPaymentTypeChange,
    onShareClick,
    rusPayServiceName,
    worldPayServiceName
}: React.PropsWithChildren<PaymentMobileActionsProps>) {
    const { t } = useTranslation();

    const isEos = contains(methods, ['EOS'] as Methods);
    const isTron = contains(methods, ['TRON'] as Methods);
    const isEther = contains(methods, ['ETHER'] as Methods);
    const isWordPay = contains(methods, ['WORLD_PAY'] as Methods);
    const isRusPay = contains(methods, ['RUS_PAY'] as Methods);

    const getExistingCardMethod = () => {
        if (isRusPay) return PaymentMethod.RusPay;
        if (isWordPay) return PaymentMethod.WorldPay;

        return null;
    };

    return (
        <>
            {(isEos || isTron || isEther) && (isWordPay || isRusPay) && Number(methods?.length) >= 2 && (
                <>
                    <Button
                        type="button"
                        classes={{ root: styles.SparseButton }}
                        size="large"
                        color="gray"
                        iconEnd={<BankCardFilledIcon />}
                        onClick={(e) => onMobileAppClick?.(e, true, 'card', getExistingCardMethod())}
                    >
                        {t('payment.paymentInfoAttention.payCard')}
                    </Button>
                    <Button
                        type="button"
                        classes={{ root: styles.SparseButton }}
                        size="large"
                        color="primary"
                        iconEnd={<CentFilledCircledIcon />}
                        onClick={(e) => onMobileAppClick?.(e, true, 'crypto', dropdownSelected as PaymentMethod)}
                    >
                        {t('payment.paymentInfoAttention.payCrypto')}
                    </Button>
                </>
            )}

            {((isEos && isTron) || (isEos && isEther) || (isTron && isEther) || (isEos && isTron && isEther)) &&
                !(isRusPay || isWordPay) &&
                Number(methods?.length) <= 3 &&
                children}

            {(isEos || isEther || isTron) && methods?.length === 1 && children}

            {isRusPay && isWordPay && Number(methods?.length) === 2 && (
                <>
                    <div>
                        <PaymentButton
                            onClick={() => onPaymentTypeChange?.(PaymentMethod.WorldPay)}
                            activeMethod={activePaymentMethod}
                            location={PaymentMethod.WorldPay}
                            serviceName={worldPayServiceName}
                        />
                        <PaymentButton
                            onClick={() => onPaymentTypeChange?.(PaymentMethod.RusPay)}
                            activeMethod={activePaymentMethod}
                            location={PaymentMethod.RusPay}
                            serviceName={rusPayServiceName}
                        />
                    </div>

                    {activePaymentMethod === PaymentMethod.RusPay && (
                        <Button
                            type="button"
                            classes={{ root: styles.SparseButton }}
                            iconEnd={<BankCardFilledIcon />}
                            size="large"
                            onClick={(e) => onMobileAppClick?.(e, true, 'card', PaymentMethod.RusPay)}
                        >
                            {t('payment.pay')}
                        </Button>
                    )}

                    {activePaymentMethod === PaymentMethod.WorldPay && (
                        <Button
                            type="button"
                            size="large"
                            classes={{ root: styles.SparseButton }}
                            iconEnd={<BankCardFilledIcon />}
                            onClick={(e) => onMobileAppClick?.(e, true, 'card', PaymentMethod.WorldPay)}
                        >
                            {t('payment.pay')}
                        </Button>
                    )}
                </>
            )}

            {isRusPay && Number(methods?.length) === 1 && (
                <Button
                    classes={{ root: styles.SparseButton }}
                    size="large"
                    iconEnd={<BankCardFilledIcon />}
                    color="primary"
                    onClick={(e) => onMobileAppClick?.(e, true, 'card', PaymentMethod.RusPay)}
                >
                    {t('payment.pay')}
                </Button>
            )}

            {isWordPay && Number(methods?.length) === 1 && (
                <Button
                    classes={{ root: styles.SparseButton }}
                    size="large"
                    color="primary"
                    iconEnd={<BankCardFilledIcon />}
                    onClick={(e) => onMobileAppClick?.(e, true, 'card', PaymentMethod.WorldPay)}
                >
                    {t('payment.pay')}
                </Button>
            )}

            <div>
                <Button
                    classes={{ root: cn(styles.SparseButton, styles.ShareButton) }}
                    onClick={() => onShareClick?.()}
                    iconEnd={<SendFilledIcon />}
                    size="large"
                    fullWidth
                >
                    {t('payment.btnShareDescription')}
                </Button>
            </div>
        </>
    );
}
