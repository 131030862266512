import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { errorVar } from 'apollo/cache';
import { useNavigate } from 'react-router-dom';
import BigImage500 from 'assets/images/error-500.svg';
import BigImage404 from 'assets/images/error-404.svg';
import SmallImage500 from 'assets/images/status-error.svg';
import SmallImage404 from 'assets/images/status-question.svg';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Button from 'ui/Button/Button';
import styles from './Error.module.scss';

export type ErrorStatus = 500 | 404 | null;

interface ErrorProps {
    code?: ErrorStatus;
    onClickBtn?: () => void;
}

function Error({ code, onClickBtn }: ErrorProps) {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const storeErrorCode = errorVar();
    const errorCode = code ?? storeErrorCode ?? 404;

    const Image = React.useMemo(() => {
        switch (errorCode) {
            case 404:
                return isMobileOrLess ? SmallImage404 : BigImage404;
            case 500:
                return isMobileOrLess ? SmallImage500 : BigImage500;
            default:
                return '';
        }
    }, [errorCode, isMobileOrLess]);

    const onClick = () => {
        errorVar(null);

        if (onClickBtn) onClickBtn();

        navigate('/');
    };

    return (
        <main className={cn(styles.Component)}>
            <div className={cn(styles.ImageWrapper)}>
                <img src={Image} alt="Currency" />
            </div>
            <div className={cn(styles.ContentWrapper)}>
                <code className={cn(styles.Code)}>{t('pageError.code', { code: errorCode })}</code>
                <h1 className={cn(styles.Title)}>{t('pageError.title', { context: errorCode.toString() })}</h1>
                <p>{t(`pageError.description`, { context: errorCode.toString() })}</p>
                <Button size={isMobileOrLess ? 'large' : 'medium'} data-testid="btn" onClick={onClick}>
                    {t(`pageError.btn`)}
                </Button>
            </div>
        </main>
    );
}

export default React.memo(Error);
