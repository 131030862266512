import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { ChevronRightIcon, ProfileFilledIcon } from 'ui/Icons/Icons';
import styles from './AdminNavigationModalMore.module.scss';

export type AdminNavigationModalMoreProps = ReturnType<typeof useDialog> & {
    onClick?: (id: string) => void;
    openProfileModal: () => void;
    buttonsList: {
        isView?: boolean;
        link: string;
        icon: React.ReactNode;
        title: string;
    }[];
};

export default function AdminNavigationModalMore({
    buttonsList,
    openProfileModal,
    open,
    setOpen,
    onClick,
    ...props
}: AdminNavigationModalMoreProps) {
    const navigate = useNavigate();

    const handleClick = (link: string) => () => {
        navigate(link);
        setOpen(false);
    };
    return (
        <Dialog
            transition="slide-down"
            open={open}
            onOpenChange={setOpen}
            classes={{ container: cn(styles.Container) }}
            {...props}
        >
            {buttonsList.slice(3, buttonsList.length).map(({ link, title, icon }) => (
                <Button
                    key={title}
                    fullWidth
                    color="secondary"
                    onClick={handleClick(link)}
                    classes={{ root: cn(styles.Button) }}
                >
                    <div className={cn(styles.Wrapper)}>
                        <div className={cn(styles.IconWrapper)}>
                            <span className={cn(styles.Icon, styles.IconWithBackground)}>{icon}</span>
                        </div>
                        <div className={cn(styles.TextWrapper)}>
                            <h5 className={cn(styles.Text)}>{title}</h5>
                            <ChevronRightIcon className={cn(styles.Arrow)} />
                        </div>
                    </div>
                </Button>
            ))}
            <Button classes={{ root: cn(styles.Button) }} color="secondary" onClick={openProfileModal} fullWidth>
                <div className={cn(styles.Wrapper)}>
                    <div className={cn(styles.IconWrapper)}>
                        <ProfileFilledIcon className={cn(styles.Icon, styles.IconWithBackground)} />
                    </div>
                    <div className={cn(styles.TextWrapper)}>
                        <h5 className={cn(styles.Text)}>Профиль</h5>
                        <ChevronRightIcon className={cn(styles.Arrow)} />
                    </div>
                </div>
            </Button>
        </Dialog>
    );
}
