import * as clipboard from 'clipboard-polyfill';
import { useToast } from 'ui/Toast/Toast';

export default function useCopy() {
    const toast = useToast();

    return (text: string, success: string, error: string, onCopied?: () => void) => {
        // TODO: Replace clipboard-polyfill to navigator.clipboard
        clipboard
            .writeText(text)
            .then(() => {
                toast.success(success);

                if (onCopied) {
                    onCopied();
                }
            })
            .catch(() => {
                toast.error(error);
            });
    };
}
