import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useChangeLoginMutation } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useLoginValidation from 'hooks/useLoginValidation/useLoginValidation';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import { HexagonFilledIcon } from 'ui/Icons/Icons';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button/Button';
import AuthCode from '../AuthCode/AuthCode';
import ProfilePasswordConfirm from '../ProfilePasswordConfirm/ProfilePasswordConfirm';
import { ProfileEmailProps } from './ProfileEmail.d';
import styles from './ProfileEmail.module.scss';

export default function ProfileEmail({ onChangeView, mode }: ProfileEmailProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();

    const [isPasswordVerified, setIsPasswordVerified] = React.useState(false);
    const [showCodeForm, setShowCodeForm] = React.useState(false);
    const [token, setToken] = React.useState('');

    const formId = React.useId();

    const inputEmail = useFormField('');
    const inputPassword = useFormField('');

    const { isValid: emailIsValid } = useLoginValidation(inputEmail.value);

    const [changeLoginMutation, { loading }] = useChangeLoginMutation();

    const handleCodeError = (err: string) => {
        setShowCodeForm(false);
        inputEmail.errorChange(true, err);
    };

    const handleCodeSuccess = () => onChangeView();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        inputEmail.change(removeSpaces(e.target.value));

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const emailValue = inputEmail.value.trim();
        const passwordValue = inputPassword.value.trim();

        if (!emailValue) return inputEmail.errorChange(true, t('profile.email.error.requiredFields'));
        if (!emailIsValid) return inputEmail.errorChange(true, t('profile.email.error.emailInvalid'));

        changeLoginMutation({
            variables: {
                input: {
                    password: passwordValue,
                    newEmail: emailValue
                }
            }
        })
            .then(({ data }) => {
                const changeLogin = data?.changeLogin;

                if (changeLogin?.__typename === 'ChangeLoginSuccess') {
                    setToken(changeLogin.token);
                    setShowCodeForm(true);
                } else {
                    const { __typename, errorMessage = '' } = changeLogin ?? {};

                    switch (__typename) {
                        case 'InvalidCredentialsError':
                        case 'InvalidPasswordError': {
                            setIsPasswordVerified(false);
                            inputPassword.errorChange(true, t(`profile.email.error.${__typename}`));
                            break;
                        }
                        default: {
                            inputEmail.errorChange(
                                true,
                                t([`profile.email.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                            );
                        }
                    }
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    if (!isPasswordVerified)
        return (
            <ProfilePasswordConfirm
                password={inputPassword}
                onBackButtonClick={onChangeView}
                onSuccess={() => setIsPasswordVerified(true)}
            />
        );

    if (showCodeForm)
        return (
            <>
                <DialogHeader
                    title={t('profile.email.title', { context: mode })}
                    onBackButtonClick={() => setShowCodeForm(false)}
                    classes={{
                        title: styles.ModalHeaderText
                    }}
                />
                <DialogBody>
                    <AuthCode
                        verificationToken={token}
                        onSuccess={handleCodeSuccess}
                        onResendError={handleCodeError}
                        description={
                            <Trans
                                values={{ account: inputEmail.value }}
                                i18nKey="profile.email.description"
                                context="code"
                            >
                                <b />
                            </Trans>
                        }
                        submitText={t('profile.buttonContinue')}
                    />
                </DialogBody>
            </>
        );

    return (
        <>
            <DialogHeader
                title={t('profile.email.title', { context: mode })}
                description={t('profile.email.description', { context: mode })}
                onBackButtonClick={onChangeView}
                classes={{
                    title: styles.ModalHeaderText
                }}
            />
            <DialogBody>
                <form id={formId} onSubmit={handleSubmit}>
                    <TextField
                        label={t('profile.email.inputLabel')}
                        value={inputEmail.value}
                        onChange={handleEmailChange}
                        isError={inputEmail.error}
                        helperText={inputEmail.helperText}
                        required
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    form={formId}
                    size="large"
                    fullWidth
                    loading={loading}
                    disabled={!inputEmail.value || inputEmail.error}
                    iconStart={<HexagonFilledIcon />}
                >
                    {t('profile.buttonContinue')}
                </Button>
            </DialogFooter>
        </>
    );
}
