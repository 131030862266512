import React from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import QrLogoSrc from 'assets/images/logo-for-qr.svg';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import Button from 'ui/Button/Button';
import { DownloadIcon } from 'ui/Icons/Icons';
import { QrDownloadProps } from './QrDownload.d';
import styles from './QrDownload.module.scss';

export default function QrDownload({ data, classes, color, hideIcon, title }: QrDownloadProps) {
    const { t } = useTranslation();
    const globalError = useGlobalError();

    const qrCodeRef = React.useRef<HTMLDivElement>(null);

    const downloadQRCode = () => {
        if (!qrCodeRef?.current) {
            globalError(t('payment.shareModal.downloadQrError'));

            return;
        }

        const canvas = qrCodeRef.current.querySelector('canvas');

        if (!canvas) {
            globalError(t('payment.shareModal.downloadQrError'));

            return;
        }

        const url = canvas.toDataURL('image/png', 1.0);
        /** Create a temporary link to download the QR code image. */
        const link = document.createElement('a');

        link.download = 'qrcode.png';
        link.href = url;
        link.click();
    };

    const size = 300;

    const imageSettings = {
        src: QrLogoSrc,
        height: size / 5 /** IMG width. */,
        width: size / 5 /** IMG height. */,
        excavate: true /** Delete the QR code section below the image. */
    };

    return (
        <>
            <div className={styles.Qr} ref={qrCodeRef}>
                <QRCodeCanvas
                    value={`${data}` || window.location.href}
                    imageSettings={imageSettings}
                    size={size}
                    bgColor="white"
                    fgColor="black"
                    includeMargin
                />
            </div>
            <Button
                variant="text"
                color={color ?? 'gray'}
                size="small"
                isRound
                classes={classes}
                iconStart={!hideIcon && <DownloadIcon />}
                onClick={downloadQRCode}
            >
                {title}
            </Button>
        </>
    );
}
