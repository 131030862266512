import React from 'react';
import cn from 'classnames';
import Logo from '../Logo/Logo';
import LanguageSwitcher from '../LangaugeSwitcher/LangaugeSwitcher';
import styles from './AuthHeader.module.scss';

export interface AuthHeaderProps {
    className?: string;
}

export default function AuthHeader({ className }: AuthHeaderProps) {
    return (
        <div className={cn(styles.Root, className)} style={{ display: 'flex' }}>
            <Logo />
            <LanguageSwitcher />
        </div>
    );
}
