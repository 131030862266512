import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import AuthApp from '../AuthApp/AuthApp';
import AuthHeader from '../AuthHeader/AuthHeader';
import AuthLogin from '../AuthLogin/AuthLogin';
import { View } from '../AuthLogin/AuthLogin.d';
import AuthQr from '../AuthQr/AuthQr';

export default function AuthMobile() {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const [view, setView] = React.useState(View.App);

    switch (view) {
        case View.Login:
            return (
                <AuthLogin
                    HeaderProps={{
                        title: t('auth.buttonLogin'),
                        titleAlign: 'center',
                        onBackButtonClick: () => setView(View.App)
                    }}
                    onChangeView={setView}
                />
            );
        case View.Qr:
            return (
                <AuthQr
                    HeaderProps={{
                        title: t('auth.qr.title'),
                        titleAlign: 'center',
                        onBackButtonClick: () => setView(View.App)
                    }}
                />
            );
        default:
            return (
                <AuthApp
                    HeaderProps={{
                        children: isMobileOrLess && <AuthHeader />
                    }}
                    onChangeView={setView}
                />
            );
    }
}
