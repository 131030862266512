import React from 'react';
import cn from 'classnames';
import styles from './List.module.scss';

export const ListItem = React.forwardRef<
    HTMLLIElement,
    React.HTMLProps<HTMLLIElement> & {
        className?: string;
    }
>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function ListItemComponent({ children, className, ...props }, ref) {
        return (
            <li ref={ref} className={cn(styles.ListItem, className)} {...props}>
                {children}
            </li>
        );
    }
);

const List = React.forwardRef<
    HTMLUListElement,
    React.HTMLProps<HTMLUListElement> & {
        className?: string;
    }
>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function ListComponent({ children, className, ...props }, ref) {
        return (
            <ul ref={ref} className={cn(styles.List, className)} {...props}>
                {children}
            </ul>
        );
    }
);

export default List;
