import React from 'react';
import cn from 'classnames';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMeQuery } from 'apollo/generated';
import { URLS_MAP } from 'routes/routes';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useAuth from 'hooks/useAuth/useAuth';
import Preloader from 'ui/Preloader/Preloader';
import modalStyles from 'ui/Dialog/Dialog.module.scss';
import Header from '../Header/Header';
import StoreCreate from '../StoreCreate/StoreCreate';
import { Step } from '../StoreCreate/StoreCreate.d';
import styles from './StartTour.module.scss';

export default function StartTour() {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const navigate = useNavigate();
    const { setAdmin } = useAuth();

    const [redirect, setRedirect] = React.useState(false);
    const [step, setStep] = React.useState(Step.Name);

    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });
    const me = data?.me;
    const { defaultStore, permissions } = me ?? {};

    const handleCompleteClick = () => navigate(URLS_MAP.dashboard, { replace: true });

    React.useEffect(() => {
        if (defaultStore === null) {
            setRedirect(true);
        }
    }, [defaultStore]);

    if (!data || loading)
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );

    if (permissions && permissions.length) {
        setAdmin(true);

        return <Navigate to={URLS_MAP.admin.index} replace />;
    }

    if (!redirect && defaultStore) return <Navigate to={URLS_MAP.dashboard} />;

    return (
        <>
            <Header isLimitedView />
            <main className={styles.Main}>
                <div
                    className={cn(
                        modalStyles.Wrapper,
                        isMobileOrLess && modalStyles.WrapperFullScreen,
                        !isMobileOrLess && modalStyles['WrapperScroll-body'],
                        styles.DialogWrapper
                    )}
                >
                    <div
                        className={cn(
                            modalStyles.Container,
                            !isMobileOrLess && modalStyles['MaxWidth-sm'],
                            !isMobileOrLess && modalStyles.ContainerFullWidth,
                            isMobileOrLess && modalStyles.ContainerFullScreen
                        )}
                    >
                        <StoreCreate step={step} setStep={setStep} onClose={handleCompleteClick} />
                    </div>
                </div>
            </main>
        </>
    );
}
