import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import IconLogoDark from 'assets/images/logo-main-dark.svg';
import IconLogoLight from 'assets/images/logo-main-light.svg';
import IconLogoShort from 'assets/images/logo-main-short.svg';
import { LogoProps } from './Logo.d';
import styles from './Logo.module.scss';

function LogoIcon(theme: LogoProps['theme'], isShort: LogoProps['isShort']) {
    if (isShort) return IconLogoShort;

    switch (theme) {
        case 'light':
            return IconLogoLight;
        case 'dark':
            return IconLogoDark;
        default:
            return IconLogoDark;
    }
}

export default function Logo({ classes, disabled, isShort, href = URLS_MAP.dashboard, theme = 'light' }: LogoProps) {
    const { root, img } = classes ?? {};

    return (
        <Link className={cn(styles.Root, disabled && styles.RootDisabled, root)} to={href}>
            <img className={cn(styles.Img, img)} src={LogoIcon(theme, isShort)} alt="Logo" />
        </Link>
    );
}
