import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useChangeLoginMutation } from 'apollo/generated';
import formatPhone from 'helpers/formatPhone/formatPhone';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import { HexagonFilledIcon } from 'ui/Icons/Icons';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import FormControl from 'ui/FormControl/FormControl';
import PhoneField from 'ui/PhoneField/PhoneField';
import Button from 'ui/Button/Button';
import AuthCode from '../AuthCode/AuthCode';
import ProfilePasswordConfirm from '../ProfilePasswordConfirm/ProfilePasswordConfirm';
import { ProfilePhoneProps } from './ProfilePhone.d';
import styles from './ProfilePhone.module.scss';

export default function ProfilePhone({ onChangeView, mode }: ProfilePhoneProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();

    const [isPasswordVerified, setIsPasswordVerified] = React.useState(false);
    const [showCodeForm, setShowCodeForm] = React.useState(false);
    const [token, setToken] = React.useState('');

    const formId = React.useId();

    const inputPhone = useFormField('');
    const inputPassword = useFormField('');

    const [changeLoginMutation, { loading }] = useChangeLoginMutation();

    const handleCodeError = (err: string) => {
        setShowCodeForm(false);
        inputPhone.errorChange(true, err);
    };

    const handleCodeSuccess = () => onChangeView();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const phoneValue = inputPhone.value.trim();
        const passwordValue = inputPassword.value.trim();

        if (!phoneValue) return inputPhone.errorChange(true, t('profile.phone.error.requiredFields'));

        changeLoginMutation({
            variables: {
                input: {
                    password: passwordValue,
                    newPhone: formatPhone(phoneValue)
                }
            }
        })
            .then(({ data }) => {
                const changeLogin = data?.changeLogin;

                if (changeLogin?.__typename === 'ChangeLoginSuccess') {
                    setToken(changeLogin?.token);
                    setShowCodeForm(true);
                } else {
                    const { __typename, errorMessage = '' } = changeLogin ?? {};

                    switch (__typename) {
                        case 'InvalidCredentialsError':
                        case 'InvalidPasswordError': {
                            setIsPasswordVerified(false);
                            inputPassword.errorChange(true, t(`profile.phone.error.${__typename}`));
                            break;
                        }
                        default: {
                            inputPhone.errorChange(
                                true,
                                t([`profile.phone.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                            );
                        }
                    }
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    if (!isPasswordVerified)
        return (
            <ProfilePasswordConfirm
                password={inputPassword}
                onBackButtonClick={onChangeView}
                onSuccess={() => setIsPasswordVerified(true)}
            />
        );

    if (showCodeForm)
        return (
            <>
                <DialogHeader
                    title={t('profile.phone.title', { context: mode })}
                    onBackButtonClick={() => setShowCodeForm(false)}
                />
                <DialogBody>
                    <AuthCode
                        verificationToken={token}
                        onSuccess={handleCodeSuccess}
                        onResendError={handleCodeError}
                        description={
                            <Trans
                                values={{ account: inputPhone.value }}
                                i18nKey="profile.phone.description"
                                context="code"
                            >
                                <b />
                            </Trans>
                        }
                        submitText={t('profile.buttonContinue')}
                    />
                </DialogBody>
            </>
        );

    return (
        <>
            <DialogHeader
                title={t('profile.phone.title', { context: mode })}
                description={t('profile.phone.description', { context: mode })}
                onBackButtonClick={onChangeView}
                classes={{
                    title: styles.ModalHeaderText
                }}
            />
            <DialogBody>
                <form id={formId} onSubmit={handleSubmit}>
                    <FormControl
                        label={t('profile.phone.inputLabel')}
                        isError={inputPhone.error}
                        helperText={inputPhone.helperText}
                    >
                        <PhoneField
                            value={inputPhone.value}
                            onChange={(_, __, ___, formattedValue) => {
                                inputPhone.change(formattedValue);
                            }}
                        />
                    </FormControl>
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    size="large"
                    type="submit"
                    loading={loading}
                    form={formId}
                    fullWidth
                    disabled={!inputPhone.value || inputPhone.error}
                    iconStart={<HexagonFilledIcon />}
                >
                    {t('profile.buttonContinue')}
                </Button>
            </DialogFooter>
        </>
    );
}
