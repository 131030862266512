import React from 'react';

export type UseTimeoutParams = Parameters<typeof useTimeout>;
export type UseTimeoutReturn = ReturnType<typeof useTimeout>;

/**
 * Timeout hook.
 *
 * @param {React.EffectCallback} callback
 * @param {number} delay
 * @param {React.DependencyList} deps Default []
 *
 * @returns {React.MutableRefObject<number | null>}
 */
const useTimeout = (
    callback: React.EffectCallback,
    delay: number,
    deps: React.DependencyList = []
): React.MutableRefObject<number | null> => {
    const timeoutRef = React.useRef<number | null>(null);
    const callbackRef = React.useRef(callback);
    /**
     * Remember the latest callback.
     * Without this, if you change the callback, when setTimeout kicks in, it
     * will still call your old callback.
     */
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    /** Set up the timeout */
    React.useEffect(() => {
        if (!Number.isNaN(delay)) {
            timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);

            /** Clear timeout if the components is unmounted or the delay changes */
            return () => {
                window.clearTimeout(timeoutRef.current || 0);

                timeoutRef.current = null;
            };
        }

        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...deps]);

    /** In case you want to manually clear the timeout from the consuming component... */
    return timeoutRef;
};

export default useTimeout;
