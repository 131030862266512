import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentParams } from 'apollo/generated';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { UseFormFieldReturn } from 'hooks/useFormField/useFormField';
import Preloader from 'ui/Preloader/Preloader';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button/Button';
import styles from './PaymentCardRus.module.scss';

export interface PaymentRusPayProps {
    loading?: boolean;
    paymentParams?: Partial<PaymentParams> | null;
    paymentLink?: string;
    name?: UseFormFieldReturn;
    onNameSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

export default function PaymentCardRus({
    loading,
    name,
    onNameSubmit,
    paymentParams,
    paymentLink
}: PaymentRusPayProps) {
    const { t } = useTranslation();

    const formNameId = React.useId();

    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const { amount, serviceName } = paymentParams ?? {};

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value.replace(/[^\p{L}\s]/gu, '');

        name?.change(formattedValue);
    };

    const handleNameValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const words = value.split(/\s+/);

        if (value && words.length < 2) {
            name?.errorChange(true, t('payment.paymentRusPay.input.error.formatInvalid'));
        }
    };

    React.useEffect(() => {
        if (!loading && inputRef.current) inputRef.current.focus();
    }, [loading]);

    if (loading) {
        return (
            <div className={styles.PreloaderContainer}>
                <Preloader size={40} />
            </div>
        );
    }

    if (serviceName === 'listrentals') {
        return (
            <div className={styles.Root}>
                <div className={styles.Header}>
                    <div className={styles.Info}>
                        <div className={styles.InfoText}>{t('payment.details.toPay')}</div>
                        <div className={styles.InfoAmount}>{addThousandsSeparator(amount)}</div>
                    </div>
                </div>
                <div className={styles.Body}>
                    <form id={formNameId} className={styles.Form} onSubmit={onNameSubmit}>
                        <TextField
                            classes={{ root: styles.FormItemMarginTop, input: styles.InputCapitalize }}
                            label={t('payment.paymentRusPay.input.label')}
                            placeholder={t('payment.paymentRusPay.input.placeholder')}
                            value={name?.value}
                            onChange={handleNameChange}
                            onBlur={handleNameValidate}
                            isError={name?.error}
                            helperText={name?.helperText}
                            ref={inputRef}
                        />
                    </form>
                </div>
                <div className={styles.Footer}>
                    <Button
                        form={formNameId}
                        classes={{ root: styles.PayButton }}
                        size="large"
                        disabled={name?.error || !name?.value}
                        fullWidth
                    >
                        {t('payment.pay')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.Root}>
            <div className={styles.Container}>
                <div className={styles.Info}>
                    <div className={styles.InfoText}>{t('payment.details.toPay')}</div>
                    <div className={styles.InfoAmount}>{addThousandsSeparator(amount)}</div>
                </div>
                <div className={styles.Footer}>
                    <Button
                        as="a"
                        href={paymentLink}
                        size="large"
                        fullWidth
                        disabled={!paymentLink}
                        target={serviceName === 'qrpos' ? '_blank' : '_self'}
                        rel={serviceName === 'qrpos' ? 'noreferrer' : undefined}
                    >
                        {t('payment.pay')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
