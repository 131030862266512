export default {
    title: 'Dashboard',
    actions: {
        order: {
            label: 'Create invoice',
            tooltip: 'Create a payment page\nand send it to users'
        },
        transfer: {
            label: 'New transfer',
            tooltip: 'Transfer your cryptocurrency\nto the bank cards or inside the blockchain'
        },
        transferInternal: {
            label: 'Internal transfer',
            tooltip: 'Transfer your funds\nto PayCash for Store users'
        }
    },
    balances: {
        title: 'Balances',
        waiting: 'Waiting',
        alertDisabled: 'Deposits and withdrawals are temporarily disabled',
        buttonDeposit: 'Deposit',
        buttonWithdraw: 'Withdraw',
        error: {
            loadingFailed: 'An unexpected\nerror occurred while loading the balance. Try refreshing the page',
            depositNotAvailable: 'There are no currencies available for depositing',
            withdrawNotAvailable: 'There are no currencies available for withdrawal'
        }
    },
    feesCrypto: {
        titleDeposit: '<0>Deposit fees</0> Cryptocurrency',
        titleWithdraw: '<0>Withdrawal fees</0> Cryptocurrency',
        feePercent: 'Fee',
        feeFix: 'Fix.'
    },
    feesFiat: {
        title: '<0>Rates and fees</0> Payment by card',
        input: 'Input',
        output: 'Output',
        buy: 'Buy',
        sell: 'Sell',
        fee: 'Fee',
        rus: 'RF',
        world: 'World',
        other: 'Other',
        min: 'Min. amount',
        max: 'Max. amount',
        rate: 'Rate'
    },
    operations: {
        crypto: {
            titleInput: '<0>Input</0> Cryptocurrency',
            titleOutput: '<0>Output</0> Cryptocurrency'
        },
        fiat: {
            titleInput: '<0>Input</0> Bank card',
            titleOutput: '<0>Output</0> Bank card',
            rus: 'RF',
            world: 'World',
            support:
                '<0>Payment for orders by bank card is disabled</0>' +
                '<1>The ability to pay for orders with a bank card is disabled.</1>' +
                '<2>To enable it, please contact technical support.</2>',
            supportButton: 'Support'
        }
    },
    sales: {
        title: 'Transactions',
        today: 'Today',
        income: 'Income',
        outcome: 'Outcome',
        week: 'Week',
        month: 'Month',
        deposits: 'Deposits',
        withdraws: 'Withdraws',
        clientWithdraws: 'Transfers',
        totalOrders: 'Orders',
        paidOrders: 'Paid',
        incompleteOrders: 'Unpaid',
        cancelledOrders: 'Canceled',
        paybacks: 'Refunds'
    }
};
