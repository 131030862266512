import React from 'react';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';

interface DepositContextType {
    resendTimerTron: ReturnType<typeof useCountdownTimer>;
    resendTimerEther: ReturnType<typeof useCountdownTimer>;
    onTimerEtherReset: (timeout?: number) => void;
    onTimerTronReset: (timeout?: number) => void;
}

const DepositContext = React.createContext<DepositContextType | null>(null);

export const useDepositContext = () => {
    const context = React.useContext(DepositContext);

    if (!context) {
        throw new Error('useDepositContext must be wrapped in DepositProvider');
    }

    return context;
};

interface DepositProviderProps {
    /**
     * Timeout in sec.
     *
     * @default 60
     */
    timeout?: number;
}

export function DepositProvider({ children, timeout = 60 }: React.PropsWithChildren<DepositProviderProps>) {
    const [timestampTron, setTimestampTron] = React.useState(0);
    const [timestampEther, setTimestampEther] = React.useState(0);

    const resendTimerTron = useCountdownTimer(0, timestampTron);
    const resendTimerEther = useCountdownTimer(0, timestampEther);

    const handleTimerEtherReset = React.useCallback(
        (time = timeout) => {
            const timeoutDelta = 2;
            const currentTimeout = (time + timeoutDelta) * 1_000;

            setTimestampEther(Date.now() + currentTimeout);
            resendTimerEther.reset();
        },
        [resendTimerEther, timeout]
    );

    const handleTimerTronReset = React.useCallback(
        (time = timeout) => {
            const timeoutDelta = 2;
            const currentTimeout = (time + timeoutDelta) * 1_000;

            setTimestampTron(Date.now() + currentTimeout);
            resendTimerTron.reset();
        },
        [resendTimerTron, timeout]
    );

    const contextValue = React.useMemo(
        () => ({
            resendTimerTron,
            resendTimerEther,
            onTimerEtherReset: handleTimerEtherReset,
            onTimerTronReset: handleTimerTronReset
        }),
        [handleTimerEtherReset, handleTimerTronReset, resendTimerEther, resendTimerTron]
    );

    return <DepositContext.Provider value={contextValue}>{children}</DepositContext.Provider>;
}
