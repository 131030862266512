import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckPasswordMutation, useCreateStoreMutation } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useFormField from 'hooks/useFormField/useFormField';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import TextField from 'ui/TextField/TextField';
import Stepper from 'ui/Stepper/Stepper';
import Button from 'ui/Button/Button';
import Alert from 'ui/Alert/Alert';
import Param from '../Param/Param';
import { Step, CreateStoreProps } from './StoreCreate.d';
import styles from './StoreCreate.module.scss';

export default function StoreCreate({
    onStoreCreate,
    onClose,
    step,
    setStep,
    isModalView,
    existingStoreNames
}: CreateStoreProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();

    const inputStoreName = useFormField('');
    const inputPassword = useFormField('');

    const [key, setKey] = React.useState('');

    const formKeyId = React.useId();
    const formNameId = React.useId();
    const formPasswordId = React.useId();

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const [checkPasswordMutation, { loading: checkPasswordLoading }] = useCheckPasswordMutation();
    const [createStoreMutation, { loading: createStoreLoading }] = useCreateStoreMutation();

    const createStore = () => {
        createStoreMutation({
            variables: {
                input: {
                    name: inputStoreName.value.trim(),
                    password: inputPassword.value
                }
            }
        })
            .then(({ data }) => {
                const cs = data?.createStore;

                if (cs?.__typename === 'CreateStoreSuccess') {
                    onStoreCreate?.(inputStoreName.value);
                    setKey(cs.privateKey);
                    setStep(Step.Key);
                } else if (cs?.__typename === 'NameAlreadyExistsError') {
                    inputStoreName.errorChange(true, t('store.create.error.NameAlreadyExistsError'));
                } else if (cs?.__typename === 'ConfirmationFailedError') {
                    setStep(Step.Password);
                    inputPassword.errorChange(true, t('store.create.error.ConfirmationFailedError'));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => inputStoreName.change(e.target.value);

    const handleStoreNameSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const nameValue = inputStoreName.value.trim();

        if (!nameValue.length) return inputStoreName.errorChange(true, t('store.create.error.requiredFields'));

        if (existingStoreNames?.includes(nameValue)) {
            return inputStoreName.errorChange(true, t('store.create.error.NameAlreadyExistsError'));
        }

        if (isModalView) {
            setStep(Step.Password);
        } else {
            createStore();
            setStep(Step.Key);
        }

        return undefined;
    };

    const handleKeySubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onClose?.();
    };

    const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const nameValue = inputStoreName.value.trim();

        checkPasswordMutation({ variables: { input: { password: inputPassword.value } } })
            .then(({ data }) => {
                const checkPassword = data?.checkPassword;

                if (checkPassword?.__typename === 'CheckPasswordSuccess') {
                    if (nameValue) {
                        createStore();
                    } else {
                        onGlobalError(t('store.create.confirm.error.requiredFields'));
                        setStep(Step.Name);
                    }
                } else {
                    const { __typename, errorMessage = '' } = checkPassword ?? {};

                    inputPassword.errorChange(
                        true,
                        t([`store.create.confirm.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    React.useEffect(() => {
        if ((step === Step.Name || step === Step.Password) && inputRef.current) inputRef.current.focus();
        if (step === Step.Key && buttonRef.current) buttonRef.current.focus();
    }, [step]);

    if (step === Step.Key) {
        return (
            <>
                <DialogHeader title={t('store.create.title')} hideCloseButton={!isModalView} />
                <DialogBody>
                    <Stepper
                        title={t('store.create.key.step')}
                        steps={
                            isModalView
                                ? [
                                      { key: '1', isActive: true },
                                      { key: '2', isActive: true },
                                      { key: '3', isActive: true }
                                  ]
                                : [
                                      { key: '1', isActive: true },
                                      { key: '2', isActive: true }
                                  ]
                        }
                    />
                    <div className={styles.Description}>{t('store.create.key.description')}</div>
                    <form id={formKeyId} onSubmit={handleKeySubmit}>
                        <Param
                            classes={{ root: styles.FormItem }}
                            label={t('store.create.key.label')}
                            value={key}
                            withCopy
                            onCopyText={t('store.create.key.copied')}
                            ButtonProps={{ ref: buttonRef }}
                        />
                        <Alert type="error" classes={{ root: styles.FormItem }}>
                            {t('store.create.key.alert')}
                        </Alert>
                    </form>
                </DialogBody>
                <DialogFooter>
                    <Button form={formKeyId} type="submit" size="large" fullWidth>
                        {t('store.buttonDone')}
                    </Button>
                </DialogFooter>
            </>
        );
    }

    if (step === Step.Password)
        return (
            <>
                <DialogHeader
                    title={t('store.create.title')}
                    onBackButtonClick={() => setStep(Step.Name)}
                    hideCloseButton={!isModalView}
                />
                <DialogBody>
                    <Stepper
                        title={t('store.create.confirm.step')}
                        steps={[
                            { key: '1', isActive: true },
                            { key: '2', isActive: true },
                            { key: '3', isActive: false }
                        ]}
                    />
                    <div className={styles.Description}>{t('store.create.confirm.description')}</div>
                    <form id={formPasswordId} onSubmit={handlePasswordSubmit}>
                        <TextFieldPassword
                            classes={{ root: styles.FormItem }}
                            value={inputPassword.value}
                            isError={inputPassword.error}
                            helperText={inputPassword.helperText}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                inputPassword?.change(removeSpaces(e.target.value))
                            }
                            autoComplete="current-password"
                            required
                            ref={inputRef}
                        />
                    </form>
                </DialogBody>
                <DialogFooter>
                    <Button
                        type="submit"
                        form={formPasswordId}
                        size="large"
                        fullWidth
                        disabled={!inputPassword.value || Boolean(inputPassword.error)}
                        loading={checkPasswordLoading || createStoreLoading}
                    >
                        {t('store.buttonContinue')}
                    </Button>
                </DialogFooter>
            </>
        );

    return (
        <>
            <DialogHeader title={t('store.create.title')} hideCloseButton={!isModalView} />
            <DialogBody>
                <Stepper
                    title={t('store.create.name.step')}
                    steps={
                        isModalView
                            ? [
                                  { key: '1', isActive: true },
                                  { key: '2', isActive: false },
                                  { key: '3', isActive: false }
                              ]
                            : [
                                  { key: '1', isActive: true },
                                  { key: '2', isActive: false }
                              ]
                    }
                />
                <div className={styles.Description}>{t('store.create.name.description')}</div>
                <form id={formNameId} onSubmit={handleStoreNameSubmit}>
                    <TextField
                        classes={{ root: styles.FormItem }}
                        isError={inputStoreName.error}
                        helperText={inputStoreName.helperText}
                        label={t('store.create.name.inputLabel')}
                        placeholder={t('store.create.name.inputPlaceholder')}
                        value={inputStoreName.value}
                        onChange={handleNameChange}
                        maxLength={255}
                        ref={inputRef}
                        required
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    form={formNameId}
                    fullWidth
                    size="large"
                    disabled={!inputStoreName.value || inputStoreName.error || createStoreLoading}
                    loading={createStoreLoading}
                >
                    {t('store.buttonContinue')}
                </Button>
                <div className={styles.FooterText}>{t('store.create.name.subtext')}</div>
            </DialogFooter>
        </>
    );
}
