export default {
    title_paid: 'Оплачено',
    title_cancelled: 'Заказ отменен',
    title_expired: 'Заказ не оплачен',
    title_processing: 'Обработка платежа',

    description_cancelled: 'Ваш заказ был отменен магазином',
    description_expired: 'Время существования ссылки на оплату истекло',
    description_processing: 'В случае, если обработка проходит более часа, обратитесь в тех. поддержку',
    time: 'Через 10 секунд вы автоматически вернетесь в магазин',
    details: {
        title: 'Информация о заказе',
        orderNumber: 'Заказ №',
        store: 'Магазин',
        token: 'Способ оплаты',
        status: 'Статус',
        statusType: {
            CREATED: 'Ожидание оплаты',
            MONEY_RECEIVED: 'Ожидание подтверждения',
            PAID: 'Оплачено',
            CANCELLED: 'Отменено',
            EXPIRED: 'Время на оплату истекло'
        },
        infoAlert: 'Обычно транзакция приходит в течении 3 минут',
        toPay: 'Итого к оплате',
        fee: 'Комиссия',
        total: 'Итого',
        time: 'Срок действия счета',
        select: 'Выберите сеть',
        network: 'Сеть',
        minutes: 'МИН',
        created: {
            text: 'Создано через',
            subtext: 'PayCash for Store'
        },
        name: 'Имя',
        surname: 'Фамилия',
        email: 'Почта',
        formName: 'Введите имя',
        formSurname: 'Введите фамилию',
        formEmail: 'Введите почту',
        emailValid: 'Неверный формат почты'
    },

    paymentInfoAttention: {
        text: 'Для оплаты воспользуйтесь\nодним из приложений',
        payCrypto: 'Оплатить криптовалютой',
        payCard: 'Оплатить картой',
        btnPay: 'Оплатить',
        btnShowDetails: 'Другие способы оплаты'
    },

    paymentMethod: {
        error: {
            title: 'Ошибка',
            notAvailable: 'Метод оплаты недоступен',
            chooseAnother: 'Выберите другой метод оплаты'
        }
    },

    paymentMobileApp: {
        title: 'Выберите cпособ оплаты',
        description: 'Оплатите через приложение',
        divider: 'или',
        btnQr: 'По QR коду и реквизитам'
    },

    paymentRusPay: {
        input: {
            label: 'Имя и фамилия',
            placeholder: 'Введите имя и фамилию',
            error: {
                formatInvalid: 'Имя должно быть заполнено в 2 слова'
            }
        }
    },

    paymentWorldPay: {
        input: {
            label: 'Имя и фамилия',
            placeholder: 'Введите имя и фамилию',
            helperText: 'Введите имя и фамилию на английском языке',
            error: {
                formatInvalid: 'Имя должно быть заполнено в 2 слова'
            }
        },
        description:
            'Нажатием кнопки "Подтвердить и оплатить"\n' +
            'я подтверждаю, что ознакомлен с офертой, размещенной по ссылке <0>elm47.com/offer</0>, а также со всеми <1>деталями заказа</1> и принимаю их в полном объеме.',
        description_otp:
            'Не меняйте OTP код на открывшейся странице.\n' +
            'На открывшейся странице нажмите "Next", затем\n' +
            'нажмите "Accept & Continue", далее выберите удобный\n' +
            'для вас метод и совершите оплату',
        error: {
            paymentLinkNotFound: 'Ссылка на оплату не найдена'
        }
    },

    selectPaymentMethod: 'Выберите способ оплаты',
    cryptocurrency: 'Криптовалюта',
    cards: 'Банковские карты',
    bank: {
        international: 'Международный банк',
        international_aed: 'Международный е-ваучер',
        russian: 'Российский банк'
    },

    pay: 'Перейти к оплате',
    buttonConfirmAndPay: 'Подтвердить и оплатить',
    btnHowTo: 'Как оплатить?',
    btnShare: 'Поделиться',
    btnShareDescription: 'Выставить счёт',
    btnShareDescriptionMobile: 'Нажмите чтобы выставить счёт',
    btnReturnToStore: 'Вернуться в магазин',
    btnCheckTransfer: 'Посмотреть на',
    btnContinue: 'Продолжить',

    shareModal: {
        title: 'Поделиться ссылкой',
        linkCopy: 'Ссылка скопирована',
        send: 'Отправить',
        sendError: 'Функция поделиться не поддерживается в вашем браузере. Ссылка была скопирована.',
        shareError: 'Ошибка поделиться:',
        paymentLink: 'Ссылка на оплату:',
        downloadQrError: 'Что-то пошло не так: Не удалось скачать QR код'
    },

    howToPay: {
        title: 'Как оплатить через приложение',
        goBack: 'Назад',

        downloadStep: {
            description: 'Скачайте одно из рекомендованных приложений',
            title: 'Скачайте приложение'
        },
        createAccountStep: {
            title: 'Создайте бесплатный аккаунт',
            description:
                'Теперь необходимо создать кошелек или импортировать существующий EOS кошелек в приложение. Чтобы создать кошелек:',
            step_1: 'Нажмите на кнопку “Создать кошелек”',
            step_2: 'Придумайте имя для вашего кошелька',
            step_3: 'Сохраните сгенерированный приватный ключ'
        },
        topUpStep: {
            title: 'Пополните баланс аккаунта',
            description:
                'Далее необходимо пополнить баланс вашего кошелька. Есть несколько способов пополнения баланса вашего кошелька, рекомендуем пополнять напрямую с банковской карты. Также вы можете пополнить баланс через биржу Binance и другие биржи или через раздел “Купить” в приложении.',
            button_1: 'Пополнить картой',
            button_2: 'Как пополнить через биржу'
        },
        openQrScanStep: {
            title: 'Откройте сканер QR',
            description: 'После того как вы пополнили баланс, нажмите на кнопку QR в верхнем правом углу приложения.'
        },

        scanQrStep: {
            title: 'Отсканируйте QR',
            description:
                'При оплате криптовалютой, отсканируйте QR на странице оплаты через приложение. При оплате банковской картой, нажмите продолжить. На странице оплаты введите данные карты'
        },
        confirmStep: {
            title: 'Подтвердите перевод',
            description: 'Подтвердите перевод в приложении.'
        },
        doneStep: {
            title: 'Готово',
            description: 'Через 10 секунд после оплаты счета вас автоматически перенаправит обратно в магазин.'
        },

        step_1: 'Шаг 1',
        step_2: 'Шаг 2',
        step_3: 'Шаг 3',
        step_4: 'Шаг 4',
        step_5: 'Шаг 5',
        step_6: 'Шаг 6',
        step_7: 'Шаг 7'
    },

    paymentInfo: {
        title: 'Отсканируйте QR\nкамерой в приложении',
        subTitle: 'через приложение',

        payWithQr: 'Оплата по QR коду и реквизитам',

        btnDownloadQr: 'Скачать QR',

        orDivider: 'или оплатите вручную',

        account: 'Кошелек',
        memo: 'MEMO',
        amount: 'Сумма к оплате'
    },

    order: {
        error: {
            loadingFailed: 'При загрузке страницы произошла ошибка.\nПопробуйте перезагрузить страницу.'
        },
        button: {
            refresh: 'Обновить'
        }
    },

    test: {
        title: 'Статус',
        title_aside: 'Выберите статус заказа ',
        button: {
            pending: 'Ожидание оплаты',
            success: 'Успешная оплата',
            expired: 'Время оплаты истекло',
            cancel: 'Отмена',
            change: 'Изменить статус заказа'
        }
    },
    info: {
        title: 'Отсканируйте QR\nкамерой в приложении',
        account: 'Кошелек',
        memo: 'MEMO',
        alert_EOS: 'Правильно введите кошелек, сумму и MEMO.\nВ случае ошибки средства будут утрачены.',
        alert_ETHER: 'Правильно введите кошелек и сумму.\nВ случае ошибки средства будут утрачены.',
        alert_TRON: 'Правильно введите кошелек и сумму.\nВ случае ошибки средства будут утрачены.'
    },
    orderPaid: {
        success: 'Баланс успешно пополнен',
        error: {
            FiatMethodNotFoundError: 'Метод пополнения не найден',
            OrderNotFoundError: 'Заказ не найден',
            OrderInactiveError: 'Заказ уже не активен',
            OrderPaidError: 'Заказ уже оплачен',
            ActionLockedError: 'через {{cooldown}} сек.',
            paidNotFound: 'Оплата не найдена. Убедитесь что вы оплатили\nсчет и попробуйте проверить через минуту'
        },
        submit: {
            button: 'Я оплатил',
            timerText: 'Проверить можно будет через'
        }
    },
    status: {
        amount: 'Было оплачено',
        amount_processing: 'В обработке',
        button: {
            support: 'Написать в тех. поддержку'
        },
        error: {
            OrderNotFoundError: 'Заказ не найден'
        }
    }
};
