import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMeQuery, useUpdateStoreMutation } from 'apollo/generated';
import { useFormatDate } from 'helpers/formatDateAndTime/formatDateAndTime';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import { KeyIcon } from 'ui/Icons/Icons';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import TextField from 'ui/TextField/TextField';
import StoreModeSwitchButton from '../StoreModeSwitchButton/StoreModeSwitchButton';
import { StoreSettingsProps } from './StoreSettings.d';
import styles from './StoreSettings.module.scss';

export default function StoreSettings({ onClick, onClose }: StoreSettingsProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const onGlobalError = useGlobalError();
    const formatDate = useFormatDate();

    const store = useFormField('');

    const [initialStoreName, setInitialStoreName] = React.useState('');

    const formId = React.useId();

    const meQuery = useMeQuery();
    const defaultStore = meQuery.data?.me?.defaultStore;
    const { id, created, name, isTestMode } = defaultStore ?? {};

    const [updateStoreMutation, { loading }] = useUpdateStoreMutation();

    const handleKeyClick = () => onClick?.(true);
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => store.change(e.target.value);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const nameValue = store.value.trim();

        if (nameValue && id) {
            updateStoreMutation({
                variables: {
                    input: {
                        storeId: id,
                        name: nameValue
                    }
                }
            })
                .then(({ data }) => {
                    const updateStore = data?.updateStore;

                    if (updateStore?.__typename === 'UpdateStoreSuccess') {
                        toast.success(t('store.settings.alertSuccess', { name: nameValue }));
                        onClose?.();
                    } else {
                        const { __typename, errorMessage = '' } = updateStore ?? {};

                        store.errorChange(
                            true,
                            t([`store.settings.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                        );
                    }
                })
                .catch((err) => onGlobalError(getErrorData(err).message));
        } else {
            onGlobalError(t('store.settings.error.StoreNotFoundError'));
        }
    };

    React.useEffect(() => {
        if (name && !initialStoreName) {
            store.change(name);
            setInitialStoreName(name);
        }
    }, [initialStoreName, name, store]);

    return (
        <>
            <DialogHeader title={t('store.settings.title')} classes={{ title: styles.ModalHeaderText }} />
            <DialogBody className={styles.ModalBody}>
                <form id={formId} onSubmit={handleSubmit}>
                    <div className={styles.Info}>
                        <StoreModeSwitchButton
                            id={id ?? ''}
                            isChecked={!isTestMode}
                            title={t('store.mode.title')}
                            status={t('store.mode.status', { context: isTestMode && 'test' })}
                        />
                        <div className={styles.InfoDescription}>
                            <div className={styles.InfoItem}>
                                <div className={styles.InfoName}>{t('store.settings.id')}</div>
                                <div className={styles.InfoValue}>{id}</div>
                            </div>
                            <div className={styles.InfoItem}>
                                <div className={styles.InfoName}>{t('store.settings.created')}</div>
                                <div className={styles.InfoValue}>{formatDate(created).toFormat('DD')}</div>
                            </div>
                        </div>
                    </div>
                    <TextField
                        isError={store.error}
                        helperText={store.helperText}
                        value={store.value}
                        label={t('store.settings.inputLabel')}
                        placeholder={t('store.settings.inputPlaceholder')}
                        onChange={handleNameChange}
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="button"
                    size="large"
                    color="secondary"
                    variant="outlined"
                    style={{ marginBottom: '0.5rem' }}
                    fullWidth
                    onClick={handleKeyClick}
                    iconStart={<KeyIcon />}
                >
                    {t('store.settings.buttonGenerateKey')}
                </Button>
                <Button
                    size="large"
                    type="submit"
                    form={formId}
                    fullWidth
                    disabled={Boolean(!store.value || store.value === name)}
                    loading={loading}
                >
                    {t('store.buttonSave')}
                </Button>
            </DialogFooter>
        </>
    );
}
