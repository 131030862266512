export default {
    title: 'Пополнить баланс',
    app: {
        description: 'Для быстрого и удобного пополнения рекомендуем использовать одно из приложений'
    },
    blockchain: {
        title: 'Способ пополнения',
        description: 'Выберите blockchain сеть через которую хотите пополнить USDT'
    },
    info: {
        alert_EOS: 'Правильно введите кошелек и MEMO.\nВ случае ошибки средства будут утрачены.',
        alert_ETHER: 'Правильно введите кошелек.\nВ случае ошибки средства будут утрачены.',
        alert_TRON: 'Правильно введите кошелек.\nВ случае ошибки средства будут утрачены.'
    },
    expired: {
        title: 'Срок действия истек',
        text: 'Время существования QR кода\nна пополнение аккаунта истекло. Закройте данное окно и попробуйте\nеще раз.'
    },
    error: {
        loadingFailed: 'При загрузке данных произошла ошибка',
        InvalidParamsError: 'Параметры не валидны ({{paramName}})',
        StoreNotFoundError: 'Магазин не найден',
        CurrencyNotFoundError: 'Валюта не найдена',
        ServerProblemError: 'Проблема с сервером',
        DepositLimitExceededError: 'Превышен лимит пополнения'
    },
    buttonQr: 'Оплатить по QR',
    buttonQrDownload: 'Скачать QR',
    buttonContinue: 'Продолжить',
    buttonReload: 'Обновить',
    buttonClose: 'Закрыть'
};
