import React from 'react';
import { useTranslation } from 'react-i18next';
import DevelopmentImage from 'assets/images/development.svg';
import styles from './StubUnderConstruction.module.scss';

export default function StubUnderConstruction() {
    const { t } = useTranslation();

    return (
        <div className={styles.Component}>
            <img src={DevelopmentImage} alt="Under construction" />
            <h1>{t('underConstruction.title')}</h1>
            <p>{t('underConstruction.description')}</p>
        </div>
    );
}
