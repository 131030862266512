/**
 * Removes spaces from a string.
 *
 * @param {string} str - An input string.
 *
 * @returns {string}
 */
export default function removeSpaces(str: string): string {
    return str.replace(/\s/g, '');
}
