import { useTranslation } from 'react-i18next';
import { useToast } from 'ui/Toast/Toast';

export default function useGlobalError() {
    const { t } = useTranslation();
    const toast = useToast();

    return (msg?: string) => {
        toast.error(`${t('global.error.tryLater')}${msg ? `: ${msg}` : '.'}`);
    };
}
