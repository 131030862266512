import React from 'react';
import cn from 'classnames';
import { SwitchProps } from './Switch.d';
import styles from './Switch.module.scss';

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function SwitchComponent(
        {
            checked,
            classes,
            color = 'primary',
            defaultChecked,
            disabled,
            id,
            label,
            labelAlign = 'middle',
            labelPlacement = 'end',
            labelSubtext,
            onChange,
            required,
            ...props
        }: SwitchProps,
        ref
    ) {
        const inputId = React.useId();
        const [isChecked, setIsChecked] = React.useState(Boolean(checked || defaultChecked));

        const {
            root,
            control,
            input,
            labelContainer,
            labelText,
            labelSubtext: labelSubtextClass,
            labelRequired
        } = classes ?? {};

        const classesRoot = cn(
            styles.Root,
            isChecked && styles.Checked,
            color && styles[`Color-${color}`],
            labelAlign && styles[`Align-${labelAlign}`],
            labelPlacement && styles[`Placement-${labelPlacement}`],
            disabled && styles.Disabled,
            root
        );

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (disabled) return;

            if (!onChange) {
                setIsChecked(e.target.checked);
            } else {
                onChange(!isChecked, e);
            }
        };

        React.useEffect(() => {
            if (!defaultChecked) {
                setIsChecked(Boolean(checked));
            }
        }, [checked, defaultChecked]);

        return (
            <label className={classesRoot} htmlFor={id ?? inputId}>
                <span className={cn(styles.Control, isChecked && styles.Checked, control)}>
                    <input
                        id={id ?? inputId}
                        className={cn(styles.Input, input)}
                        disabled={disabled}
                        type="checkbox"
                        checked={!defaultChecked ? isChecked : undefined}
                        defaultChecked={defaultChecked}
                        onChange={handleChange}
                        required={required}
                        role="switch"
                        {...props}
                        ref={ref}
                    />
                </span>
                {label && (
                    <div className={cn(styles.LabelContainer, labelContainer)}>
                        <span className={labelText}>
                            {label}
                            {required && (
                                <span className={labelRequired} aria-hidden="true">
                                    {' '}
                                    *
                                </span>
                            )}
                        </span>
                        {labelSubtext && (
                            <span className={cn(styles.LabelSubtext, labelSubtextClass)}>{labelSubtext}</span>
                        )}
                    </div>
                )}
            </label>
        );
    }
);

export default Switch;
