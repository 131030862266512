import React from 'react';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import StoreModeSwitch from '../StoreModeSwitch/StoreModeSwitch';

export default function StoreModeSwitchModal({ open, setOpen, context, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const handleClose = () => setOpen(false);

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            {...props}
        >
            <StoreModeSwitch
                storeId={context.dataRef.current?.storeId}
                onClose={handleClose}
                isNextTestMode={context.dataRef.current?.isNextTestMode}
            />
        </Dialog>
    );
}
