import React from 'react';
import { useTranslation } from 'react-i18next';
import useCopy from 'hooks/useCopy/useCopy';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogBody, DialogFooter, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import { CopyIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import Qr from '../Qr/Qr';
import QrDownload from '../QrDownload/QrDownload';
import PaymentShareButton from '../PaymentShareButton/PaymentShareButton';
import styles from './PaymentShareModal.module.scss';

type PaymentShareModalProps = ReturnType<typeof useDialog> & {};

export default function PaymentShareModal({ open, setOpen, ...props }: PaymentShareModalProps) {
    const [t] = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const copy = useCopy();

    const handleCopyClick = (copyText: string) =>
        copy(copyText.toString(), t('payment.shareModal.linkCopy'), t('global.error.tryLater'));

    const handleClose = () => setOpen(false);

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            fullScreen={isLandscapeOrLess}
            transition={isLandscapeOrLess ? 'slide-down' : undefined}
            classes={{ root: styles.ModalRoot, container: styles.ModalContainer }}
            {...props}
        >
            <DialogHeader title={t('payment.shareModal.title')} />
            <DialogBody>
                <div className={styles.QrContainer}>
                    <Qr value={window.location.href} className={styles.Qr} />
                    {isLandscapeOrLess && <QrDownload classes={{ root: styles.QrDownloadButton }} />}
                </div>
            </DialogBody>
            <DialogFooter>
                <div className={styles.LinkContainer}>
                    <div className={styles.Link}>{window.location.href}</div>
                    <Button
                        variant="text"
                        color="gray"
                        size="small"
                        isRound
                        iconStart={<CopyIcon />}
                        onClick={() => handleCopyClick(window.location.href)}
                        classes={{ root: styles.LinkCopyButton }}
                    />
                </div>
                {isLandscapeOrLess && <PaymentShareButton classes={{ root: styles.SendButton }} />}
            </DialogFooter>
        </Dialog>
    );
}
