export const Permissions = {
    CAN_VIEW_DASHBOARD: 'CAN_VIEW_DASHBOARD',
    CAN_VIEW_BALANCES_AND_RESOURCES: 'CAN_VIEW_BALANCES_AND_RESOURCES',
    CAN_DEPOSIT: 'CAN_DEPOSIT',
    CAN_WITHDRAW: 'CAN_WITHDRAW',
    CAN_TOGGLE_CURRENCIES: 'CAN_TOGGLE_CURRENCIES',
    CAN_EDIT_COMMISSIONS: 'CAN_EDIT_COMMISSIONS',
    CAN_EDIT_LIMITS: 'CAN_EDIT_LIMITS',
    CAN_SHUT_DOWN: 'CAN_SHUT_DOWN',
    CAN_VIEW_TRANSACTIONS: 'CAN_VIEW_TRANSACTIONS',
    CAN_VIEW_USER_INFO: 'CAN_VIEW_USER_INFO',
    CAN_VIEW_SHOP_INFO: 'CAN_VIEW_SHOP_INFO',
    CAN_EDIT_ADMINS: 'CAN_EDIT_ADMINS',
    CAN_VIEW_ADMINS: 'CAN_VIEW_ADMINS',
    CAN_ADD_ADMINS: 'CAN_ADD_ADMINS',
    CAN_DELETE_ADMINS: 'CAN_DELETE_ADMINS',
    CAN_CREATE_REPORT: 'CAN_CREATE_REPORT',
    CAN_EDIT_RATES: 'CAN_EDIT_RATES',
    CAN_TOGGLE_FIAT: 'CAN_TOGGLE_FIAT',
    CAN_RESTART_OR_ABORT_TRANSFERS: 'CAN_RESTART_OR_ABORT_TRANSFERS',
    CAN_FAIL_TRANSFERS: 'CAN_FAIL_TRANSFERS'
} as const;

export interface Can {
    addAdmins: boolean;
    createReport: boolean;
    deposit: boolean;
    deleteAdmins: boolean;
    editAdmins: boolean;
    editCommissions: boolean;
    editLimits: boolean;
    seeAdmins: boolean;
    seeBalancesAndResources: boolean;
    seeDashboard: boolean;
    seeShopInfo: boolean;
    seeTransactions: boolean;
    seeUserInfo: boolean;
    shutDown: boolean;
    toggleCurrencies: boolean;
    withdraw: boolean;
    editRates: boolean;
    toggleFiat: boolean;
    allowRestartOrAbortTransfers: boolean;
    canFailTransfers: boolean;
}
