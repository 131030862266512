export default {
    title: 'Top up balance',
    app: {
        description: 'For quick and convenient top up, we recommend using one of the applications'
    },
    blockchain: {
        title: 'Deposit method',
        description: 'Select the blockchain network through which you want to top up USDT'
    },
    info: {
        alert_EOS: 'Please enter your address and MEMO correctly.\nIn case of error, funds will be lost.',
        alert_ETHER: 'Please enter the address correctly.\nIn case of error, funds will be lost.',
        alert_TRON: 'Please enter the address correctly.\nIn case of error, funds will be lost.'
    },
    expired: {
        title: 'Expired',
        text: 'The QR code\nfor top up your account has expired. Close this window and try again.'
    },
    error: {
        loadingFailed: 'An error occurred while loading data',
        InvalidParamsError: 'Parameters are not valid ({{paramName}})',
        StoreNotFoundError: 'Store not found',
        CurrencyNotFoundError: 'Currency not found',
        ServerProblemError: 'Problem with the server',
        DepositLimitExceededError: 'Deposit limit exceeded'
    },
    buttonQr: 'Pay with QR',
    buttonQrDownload: 'Download QR',
    buttonContinue: 'Continue',
    buttonReload: 'Reload',
    buttonClose: 'Close'
};
