import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { isAuthVar } from 'apollo/cache';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogBody, DialogFooter, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import Checkbox from 'ui/Checkbox/Checkbox';
import Button from 'ui/Button/Button';
import TokenIcon from '../TokenIcon/TokenIcon';
import styles from './TokenNotifyModal.module.scss';

const LOCAL_STORAGE_KEY = 'sawDisabledTokens';
const VERSION = '3';

export default function TokenNotifyModal() {
    const { t } = useTranslation();
    const isAuth = useReactiveVar(isAuthVar);
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const alertModal = useDialog();

    const [agree, setAgree] = React.useState(false);

    const handleAgreeClick = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, VERSION);
        alertModal.setOpen(false);
    };

    React.useEffect(() => {
        const didntSee =
            !localStorage.getItem(LOCAL_STORAGE_KEY) || localStorage.getItem(LOCAL_STORAGE_KEY) !== VERSION;

        if (didntSee && isAuth) {
            alertModal.setOpen(true);
        } else {
            alertModal.setOpen(false);
        }
    }, [alertModal, isAuth]);

    return (
        <Dialog {...alertModal} fullScreen={isMobileOrLess}>
            <DialogHeader title={t('notify.title')} hideCloseButton />
            <DialogBody>
                <small>{t('notify.text1')}</small>
                <ul className={styles.TokenList}>
                    <li className={styles.TokenItem}>
                        <TokenIcon classes={{ root: styles.TokenIcon }} name="rubles" size={18} />
                        <span className={styles.TokenText}>RUBLES</span>
                    </li>
                    <li className={styles.TokenItem}>
                        <TokenIcon classes={{ root: styles.TokenIcon }} name="uahcash" size={18} />
                        <span className={styles.TokenText}>UAHCASH</span>
                    </li>
                </ul>
                <small>{t('notify.text2')}</small>
                <div className={styles.Divider} />
                <small>{t('notify.text3')}</small>
                <ul className={styles.TokenList}>
                    <li className={styles.TokenItem}>
                        <TokenIcon classes={{ root: styles.TokenIcon }} name="usdcash" size={18} />
                        <span className={styles.TokenText}>USDCASH</span>
                    </li>
                    <li className={styles.TokenItem}>
                        <TokenIcon classes={{ root: styles.TokenIcon }} name="usdt" size={18} />
                        <span className={styles.TokenText}>USDT</span>
                    </li>
                    <li className={styles.TokenItem}>
                        <TokenIcon classes={{ root: styles.TokenIcon }} name="rubcash" size={18} />
                        <span className={styles.TokenText}>RUBCASH</span>
                    </li>
                </ul>
            </DialogBody>
            <DialogFooter>
                <Checkbox
                    classes={{ root: styles.Checkbox }}
                    label={t('notify.check')}
                    checked={agree}
                    onChange={setAgree}
                />
                <Button disabled={!agree} size="large" color="primary" onClick={handleAgreeClick} fullWidth>
                    {t('notify.buttonSubmit')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
