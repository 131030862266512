import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { ShopFilledIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import PaymentTimer from '../PaymentTimer/PaymentTimer';
import { PaymentOrderParamsProps } from './PaymentOrderParams.d';
import styles from './PaymentOrderParams.module.scss';

export default function PaymentOrderParams({ number, name, showContent, time, status }: PaymentOrderParamsProps) {
    const { t } = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    return (
        <>
            <div className={cn(styles.Root, !showContent && styles.Final)}>
                <div className={styles.IconWrapper}>
                    <ShopFilledIcon className={styles.ShopIcon} />
                </div>
                <div className={styles.TextWrapper}>
                    {name && <span className={styles.Title}>{name}</span>}

                    <span className={styles.Text}>
                        {t('payment.details.orderNumber')}
                        {number}
                    </span>
                </div>
            </div>

            {showContent && (
                <div className={styles.Info}>
                    <div className={styles.InfoRow}>
                        <PaymentTimer
                            classes={{ root: styles.TimerRoot }}
                            timestamp={DateTime.fromISO(`${time}Z`).toMillis()}
                            icon={isLandscapeOrLess ? false : undefined}
                        />
                    </div>
                    <div className={styles.InfoRow}>
                        <span className={styles.InfoRowIconWrapper}>
                            {!isLandscapeOrLess && (
                                <Preloader
                                    classes={{ root: styles.PreloaderRoot }}
                                    color="secondary"
                                    size={17.5}
                                    thickness={6}
                                />
                            )}

                            <span className={styles.InfoRowText}>{t('payment.details.status')}</span>
                        </span>
                        <span className={styles.InfoRowBold}>{t(`payment.details.statusType.${status}`)}</span>
                    </div>
                </div>
            )}
        </>
    );
}
