import React, { PureComponent } from 'react';
import { captureException } from '@sentry/react';
import PageError from 'components/Error/Error';

class ErrorBoundary extends PureComponent {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(err: any, info: any) {
        // eslint-disable-next-line no-console
        console.log('Catch Error ', err, info.componentStack);
        captureException(err);

        this.setState({
            hasError: true
        });
    }

    resetError = () => {
        this.setState({
            hasError: false
        });
    };

    render() {
        // You can render any custom fallback UI
        // if (this.state.hasError) return this.props.fallback;

        // @ts-ignore
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) return <PageError code={500} onClickBtn={this.resetError} />;
        // @ts-ignore
        // eslint-disable-next-line react/destructuring-assignment,react/prop-types
        return this.props.children;
    }
}

export default ErrorBoundary;
