import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'apollo/cache';
import { useSigninMutation } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import { URLS_MAP } from 'routes/routes';
import formatPhone from 'helpers/formatPhone/formatPhone';
import getErrorData from 'helpers/getErrorData/getErrorData';
import validateInputValue from 'helpers/validateInputValue/validateInputValue';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import useLoginValidation from 'hooks/useLoginValidation/useLoginValidation';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useFormField from 'hooks/useFormField/useFormField';
import useAuth from 'hooks/useAuth/useAuth';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import TextField from 'ui/TextField/TextField';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import modalStyles from 'ui/Dialog/Dialog.module.scss';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';
import AuthHeader from '../AuthHeader/AuthHeader';
import { AuthLoginProps, View } from './AuthLogin.d';
import styles from './AuthLogin.module.scss';

const DRAG_ANIMATION_DURATION = 500;

export default function AuthLogin({ HeaderProps, onChangeView }: AuthLoginProps) {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isLandscapeOrMore = useMediaQuery((breakpoints) => breakpoints.up.md);
    const isTouchableDevice = useIsTouchableDevice();
    const toast = useToast();
    const onGlobalError = useGlobalError();
    const { login } = useAuth();

    const formId = React.useId();

    const inputLogin = useFormField('');
    const inputPassword = useFormField('');

    const { isValid: loginIsValid, errorText: loginErrorText } = useLoginValidation(inputLogin.value);

    const formRef = React.useRef<HTMLFormElement>(null);

    const [signInMutation, { loading }] = useSigninMutation({ context: { headers: { Authorization: '' } } });

    const formErrorAnimation = () => {
        const className = 'DragAnimation';

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);
        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputLogin?.change(validateInputValue(e, e.target.value));
        inputPassword.errorChange(false);
    };

    const handleLoginBlur = () => {
        if (!loginIsValid) {
            inputLogin.errorChange(true, loginErrorText);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        inputLogin.errorChange(false);
        inputPassword.errorChange(false);

        if (typeof window !== 'undefined' && window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH)) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
        }

        if (!inputLogin.value || !inputPassword.value) {
            formErrorAnimation();

            return toast.error(t('auth.error.requiredFields'));
        }

        if (!loginIsValid) {
            return inputLogin.errorChange(true, loginErrorText);
        }

        signInMutation({
            variables: {
                input: {
                    email: inputLogin.value.includes('@') ? removeSpaces(inputLogin.value) : undefined,
                    phone: !inputLogin.value.includes('@') ? formatPhone(inputLogin.value) : undefined,
                    password: inputPassword.value
                }
            }
        })
            .then(({ data }) => {
                const signin = data?.signin;

                if (signin) {
                    switch (signin.__typename) {
                        case 'SigninSuccess': {
                            const { token, me } = signin;
                            const { permissions } = me ?? {};
                            const isAdmin = Boolean(permissions && permissions.length);

                            login(token, isAdmin);
                            break;
                        }
                        case 'InvalidCredentialsError': {
                            formErrorAnimation();
                            inputLogin.errorChange(true);
                            inputPassword.errorChange(true, t('auth.error.loginInvalid'));
                            break;
                        }
                        case 'InvalidPhoneError': {
                            formErrorAnimation();
                            inputLogin.errorChange(true, t('auth.error.phoneInvalid'));
                            break;
                        }
                        case 'LoginIsEmptyError': {
                            formErrorAnimation();
                            inputLogin.errorChange(true);
                            inputPassword.errorChange(true, t('auth.error.requiredFields'));
                            break;
                        }
                        default: {
                            onGlobalError(t('auth.error.loginFailed'));
                        }
                    }
                } else {
                    onGlobalError(t('auth.error.loginFailed'));
                }
            })
            .catch((err) => {
                formErrorAnimation();
                onGlobalError(getErrorData(err).message);
            });

        return undefined;
    };

    return (
        <>
            {!isMobileOrLess && !isLandscapeOrMore && !isTouchableDevice && (
                <div className={styles.HeaderWrapper}>
                    <AuthHeader className={styles.Header} />
                </div>
            )}
            <div
                className={cn(
                    modalStyles.Wrapper,
                    isMobileOrLess && modalStyles.WrapperFullScreen,
                    !isMobileOrLess && modalStyles['WrapperScroll-body'],
                    !isTouchableDevice && styles.DialogWrapperDesktop,
                    styles.DialogWrapper
                )}
            >
                <div
                    className={cn(
                        modalStyles.Container,
                        !isMobileOrLess && modalStyles['MaxWidth-sm'],
                        !isMobileOrLess && modalStyles.ContainerFullWidth,
                        isMobileOrLess && modalStyles.ContainerFullScreen,
                        !isTouchableDevice && styles.DialogContainerDesktop
                    )}
                >
                    <DialogHeader classes={{ root: styles.DialogHeader }} hideCloseButton {...HeaderProps} />
                    <DialogBody>
                        <form
                            id={formId}
                            style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }}
                            ref={formRef}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                classes={{ root: styles.FormItem }}
                                label={t('auth.inputLogin.label')}
                                placeholder={t('auth.inputLogin.placeholder')}
                                value={inputLogin.value}
                                isError={inputLogin.error}
                                helperText={inputLogin.helperText}
                                onChange={handleLoginChange}
                                onBlur={handleLoginBlur}
                                autoComplete="username"
                                required
                            />
                            <TextFieldPassword
                                classes={{ root: styles.FormItem }}
                                value={inputPassword.value}
                                isError={inputPassword.error}
                                helperText={inputPassword.helperText}
                                onChange={(e) => {
                                    inputLogin.errorChange(false);
                                    inputPassword?.change(validateInputValue(e, e.target.value));
                                }}
                                autoComplete="current-password"
                                required
                                labelEndContent={
                                    (isTouchableDevice || !isLandscapeOrMore) && (
                                        <Link
                                            as={NavLink}
                                            to={URLS_MAP.recover}
                                            replace
                                            className={styles.InputLabelEndContent}
                                            size="small"
                                        >
                                            {t('auth.buttonForgotPassword')}
                                        </Link>
                                    )
                                }
                            />
                        </form>
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            type="submit"
                            form={formId}
                            classes={{ root: styles.FormSubmitButton }}
                            loading={loading}
                            disabled={!inputLogin.value || !inputPassword.value}
                            size="large"
                            fullWidth
                        >
                            {t('auth.buttonEnter')}
                        </Button>

                        {isTouchableDevice || !isLandscapeOrMore ? (
                            <div className={cn(styles.FooterBottom, styles.FooterBottomColumn)}>
                                <div className={cn(styles.FooterBottomItem, styles.FooterBottomItemCenter)}>
                                    <Trans i18nKey="auth.login.signUp">
                                        <span />
                                        <Link as={NavLink} size="small" to={URLS_MAP.signup}>
                                            .
                                        </Link>
                                    </Trans>
                                </div>
                                <div className={cn(styles.FooterBottomItem, styles.FooterBottomItemCenter)}>
                                    <Link
                                        as="button"
                                        type="button"
                                        onClick={() => onChangeView?.(View.Qr)}
                                        size="small"
                                    >
                                        {t('auth.buttonLoginWithQr')}
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.FooterBottom}>
                                <div className={styles.FooterBottomItem}>
                                    <Trans i18nKey="auth.login.signUp">
                                        <div className={styles.FooterBottomTextGray} />
                                        <Link as={NavLink} size="small" to={URLS_MAP.signup} replace>
                                            .
                                        </Link>
                                    </Trans>
                                </div>
                                <div className={cn(styles.FooterBottomItem, styles.FooterBottomItemRight)}>
                                    <Trans i18nKey="auth.login.recover">
                                        <div className={styles.FooterBottomTextGray} />
                                        <Link as={NavLink} size="small" to={URLS_MAP.recover} replace>
                                            .
                                        </Link>
                                    </Trans>
                                </div>
                            </div>
                        )}
                    </DialogFooter>
                </div>
            </div>
        </>
    );
}
