import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTestCancelOrderMutation, useTestExpireMutation, useTestPaymentMutation } from 'apollo/generated';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { CheckIcon, ChevronDownIcon, CrossIcon } from 'ui/Icons/Icons';
import Dialog from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { PaymentTestBarProps } from './PaymentTestBar.d';
import styles from './PaymentTestBar.module.scss';

export default function PaymentTestBar({ isOpen, close, open, id = '' }: PaymentTestBarProps) {
    const { t } = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const onGlobalError = useGlobalError();

    const [testPaymentMutation, { loading: loadingTestPayment }] = useTestPaymentMutation();
    const [testExpireMutation, { loading: loadingTestExpire }] = useTestExpireMutation();
    const [testCancelOrderMutation, { loading: loadingTestCancelOrder }] = useTestCancelOrderMutation();

    const onClickTestCancelOrder = () => {
        testCancelOrderMutation({ variables: { input: { id } } })
            .then(({ data }) => {
                const testCancelOrder = data?.testCancelOrder;

                if (testCancelOrder?.__typename === 'CancelOrderSuccess') close?.();
                else onGlobalError(testCancelOrder?.errorMessage);
            })
            .catch((e) => onGlobalError(getErrorData(e).message));
    };

    const onClickTestPayment = () => {
        testPaymentMutation({ variables: { input: { id } } })
            .then(({ data }) => {
                const testPayment = data?.testPayment;

                if (testPayment?.__typename === 'TestPaymentSuccess') close?.();
                else onGlobalError(testPayment?.errorMessage);
            })
            .catch((e) => onGlobalError(getErrorData(e).message));
    };

    const onClickTestExpire = () => {
        testExpireMutation({ variables: { input: { id } } })
            .then(({ data }) => {
                const testExpire = data?.testExpire;

                if (testExpire?.__typename === 'TestExpireSuccess') close?.();
                else onGlobalError(testExpire?.errorMessage);
            })
            .catch((e) => onGlobalError(getErrorData(e).message));
    };

    if (!isLandscapeOrLess)
        return (
            <div className={styles.DevPanelWrapper}>
                <div className={cn(styles.DevPanel, isOpen && styles.open)}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="button"
                        onClick={isOpen ? close : open}
                        className={cn(styles.DevPanelController, isOpen && styles.open)}
                    >
                        <ChevronDownIcon />
                    </button>

                    <div className={styles.DevPanelContent}>
                        <span>{t('payment.test.title')}</span>
                        <Button>{t('payment.test.button.pending')}</Button>
                        <Button
                            disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                            onClick={onClickTestPayment}
                            color="secondary"
                            variant="outlined"
                        >
                            {t('payment.test.button.success')}
                        </Button>
                        <Button
                            disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                            onClick={onClickTestCancelOrder}
                            color="secondary"
                            variant="outlined"
                        >
                            {t('payment.test.button.cancel')}
                        </Button>
                        <Button
                            disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                            onClick={onClickTestExpire}
                            color="secondary"
                            variant="outlined"
                        >
                            {t('payment.test.button.expired')}
                        </Button>
                    </div>
                </div>
            </div>
        );

    return (
        <Dialog
            fullScreen
            transition="slide-right"
            classes={{ container: styles.DevSidebar, wrapper: styles.Wrapper }}
            open={isOpen}
            onOpenChange={close}
        >
            <header className={styles.DevSidebarHeader}>
                <Button
                    classes={{ root: styles.DevSidebarBtnClose }}
                    variant="text"
                    color="gray"
                    iconStart={<CrossIcon fontSize="1rem" />}
                    onClick={close}
                />
                <h6>{t('payment.test.title', { context: 'aside' })}</h6>
            </header>
            <ul className={styles.DevSidebarContent}>
                <li>
                    <button type="button" className={styles.active} color="primary">
                        {t('payment.test.button.pending')} <CheckIcon />
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                        onClick={onClickTestPayment}
                        color="secondary"
                    >
                        {t('payment.test.button.success')}
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                        onClick={onClickTestCancelOrder}
                        color="secondary"
                    >
                        {t('payment.test.button.cancel')}
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        disabled={loadingTestExpire || loadingTestPayment || loadingTestCancelOrder}
                        onClick={onClickTestExpire}
                        color="secondary"
                    >
                        {t('payment.test.button.expired')}
                    </button>
                </li>
            </ul>
        </Dialog>
    );
}
