import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useUpdateStoreMutation } from 'apollo/generated';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import { InfoCircledIcon } from 'ui/Icons/Icons';
import { DialogHeader, DialogFooter } from 'ui/Dialog/Dialog';
import { StoreModeSwitchProps } from './StoreModeSwitch.d';
import styles from './StoreModeSwitch.module.scss';

export default function StoreModeSwitch({ isNextTestMode, storeId, onClose }: StoreModeSwitchProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const [updateStoreMutation, { loading }] = useUpdateStoreMutation();

    const handleSubmit = () => {
        updateStoreMutation({
            variables: {
                input: {
                    storeId: storeId ?? '',
                    isTestMode: isNextTestMode
                }
            }
        })
            .then(({ data }) => {
                const updateStore = data?.updateStore;

                if (updateStore?.__typename === 'UpdateStoreSuccess') {
                    onClose?.();
                    toast.success(t('store.modeSwitch.alertSuccess'));
                } else {
                    const { __typename, errorMessage = '' } = updateStore ?? {};

                    onGlobalError(t([`store.modeSwitch.error.${__typename}`, errorMessage]));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    return (
        <>
            <DialogHeader
                title={t('store.modeSwitch.title')}
                description={t('store.modeSwitch.description', {
                    context: isNextTestMode && 'test'
                })}
            >
                <InfoCircledIcon className={cn(styles.ModalHeaderIcon, styles.ModalHeaderIconInfo)} />
            </DialogHeader>
            <DialogFooter>
                <Button
                    loading={loading}
                    onClick={handleSubmit}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('store.modeSwitch.buttonSubmit')}
                </Button>
                <Button onClick={() => onClose?.()} size="large" fullWidth>
                    {t('store.modeSwitch.buttonCancel')}
                </Button>
            </DialogFooter>
        </>
    );
}
