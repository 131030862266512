import React from 'react';
import cn from 'classnames';
import { FormControlProps } from './FormControl.d';
import styles from './FormControl.module.scss';

export default function FormControl({
    children,
    classes,
    disabled,
    id,
    isError,
    helperText,
    label,
    labelEndContent,
    ...props
}: React.PropsWithChildren<FormControlProps>) {
    const {
        root,
        labelContainer: labelContainerClasses,
        label: labelClasses,
        helperText: helperTextClasses
    } = classes ?? {};

    return (
        <div {...props} className={cn(styles.Root, isError && styles.Error, disabled && styles.Disabled, root)}>
            {label && (
                <div className={cn(styles.LabelContainer, labelContainerClasses)}>
                    <label className={cn(styles.Label, labelClasses)} htmlFor={id}>
                        {label}
                    </label>

                    {labelEndContent && labelEndContent}
                </div>
            )}

            {children}

            {helperText && <span className={cn(styles.HelperText, helperTextClasses)}>{helperText}</span>}
        </div>
    );
}
