export const createTransactionLink = (trxId = '', blockchain = 'EOS') => {
    if (blockchain === 'TRON')
        return {
            domainName: 'tronscan.org',
            trxLink: `https://tronscan.org/#/transaction/${trxId}`
        };

    if (blockchain === 'ETHER')
        return {
            domainName: 'etherscan.io',
            trxLink: `https://etherscan.io/tx/${trxId}`
        };

    return {
        domainName: 'bloks.io',
        trxLink: `https://bloks.io/transaction/${trxId}`
    };
};
export const createEosAccountLink = (account = '') => `https://bloks.io/account/${account}`;

export const createAccountLink = (account = '', blockchain = 'EOS') => {
    if (blockchain === 'TRON') return `https://tronscan.org/#/address/${account}`;
    if (blockchain === 'ETHER') return `https://etherscan.io/address/${account}`;
    if (blockchain === 'EOS') return `https://bloks.io/account/${account}`;
    return '';
};
