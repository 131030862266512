export default {
    login: {
        title: 'Вход',
        signUp: '<0>Нет аккаунта?</0> <1>Регистрация</1>',
        recover: '<0>Забыли пароль?</0><1>Сбросить пароль</1>',
        success: 'Вы успешно\nавторизовались'
    },
    logout: {
        title: 'Выйти из аккаунта',
        description: 'Вы уверены что хотите выйти из аккаунта',
        buttonSubmit: 'Да',
        buttonCancel: 'Нет'
    },
    qr: {
        title: 'Вход по QR',
        description: 'Для входа или регистрации, <0>выберите\nприложение и отсканируйте QR-код</0>',
        subtext: 'Выберите приложение',
        error: {
            loadingFailed: 'Не удалось\nзагрузить QR-код'
        }
    },
    app: {
        title: 'Вход через приложение',
        description: 'Для быстрой и удобной авторизации рекомендуем использовать одно из приложений',
        divider: 'или',
        download: 'Нет приложения?\n<0>Скачать</0>'
    },
    session: {
        title: 'Авто выход',
        description: 'Вы были разлогинены так как Ваш аккаунт бездействовал в течении 30 минут',
        button: 'Войти'
    },
    inputLogin: {
        label: 'Логин',
        placeholder: 'Введите почту или телефон'
    },
    inputPassword: {
        label: 'Пароль',
        placeholder: 'Введите пароль',
        tooltipShow: 'Показать',
        tooltipHide: 'Скрыть',
        rule: {
            length: '8 символов',
            uppercase: '1 заглавный знак',
            number: '1 цифра',
            special: '1 спец. знак'
        },
        error: {
            shortLength: 'Длина пароля должна быть больше 8 символов',
            noUppercase: 'Пароль должен содержать минимум одну заглавную букву',
            noNumber: 'Пароль должен содержать минимум одну цифру',
            noSpecial: 'Пароль должен содержать минимум один спецсимвол (!@#$%^&*—_+=;:,./?|`~{}[])'
        }
    },
    inputPasswordRepeat: {
        label: 'Повторите пароль',
        placeholder: 'Повторите пароль',
        error: {
            notMatch: 'Введенные пароли не совпадают'
        }
    },
    signUp: {
        title: 'Регистрация',
        login: 'Уже есть аккаунт? <0>Войти</0>',
        button: 'Зарегистрироваться',
        error: {
            InvalidPasswordError: 'Пароль не соответствует требованиям',
            LoginIsEmptyError: 'Введите адрес электронной почты или номер телефона',
            InvalidEmailError: 'Введенный формат почты не действителен',
            InvalidPhoneError: 'Введенный формат номера телефона не действителен',
            UserAlreadyExistsError_email: 'Введенная почта уже используется',
            UserAlreadyExistsError_phone: 'Введенный номер телефона уже используется'
        }
    },
    recover: {
        title: 'Сбросить пароль',
        description:
            'Для того чтобы восстановить пароль, введите почту или номер телефона который вы указывали при регистрации',
        button: 'Обновить пароль',
        error: {
            requiredFields: 'Для того чтобы продолжить, введите почту или номер телефона',
            LoginIsEmptyError: 'Введите адрес электронной почты или номер телефона',
            NoResendAttemptsError: 'Вы исчерпали все попытки на сегодня. Попробуйте позже.',
            UserNotFoundError: 'Пользователь не найден',
            InvalidVerifyCodeError: 'Неверный код подтверждения',
            InvalidJWTError: 'Ошибка JWT токена',
            InvalidPasswordError: 'Пароль не соответствует требованиям'
        }
    },
    code: {
        label: 'Код подтверждения',
        descriptionSignUp: 'Для подтверждения регистрации на <0>{{account}}</0> был выслан код подтверждения',
        descriptionRecover: 'Введите код подтверждения который был отправлен на <0>{{account}}</0>',
        resendTimer: 'Код можно будет отправить снова через',
        resendButton: 'Переотправить код подтверждения',
        resendNotReceived: 'Не получили код?',
        resendAlert: 'Код подтверждения был отправлен заново. Также проверьте папку спам.',
        error: {
            requiredFields: 'Введите код',
            NoResendAttemptsError: 'Вы исчерпали все попытки на сегодня. Попробуйте позже.',
            InvalidJWTError: 'Ошибка JWT токена',
            NoConfirmationAttemptsError: 'Количество попыток исчерпано. Попробуйте позже.',
            InvalidVerifyCodeError: 'Неверный код подтверждения',
            UserAlreadyExistsError: 'Пользователь уже существует',
            VerifyCodeExpiredError: 'Истек срок действия кода подтверждения.\nИспользуйте новый код.'
        }
    },
    buttonEnter: 'Войти в аккаунт',
    buttonContinue: 'Продолжить',
    buttonForgotPassword: 'Забыли пароль?',
    buttonLoginWithQr: 'Войти при помощи QR',
    buttonLoginWithApp: 'Войти через приложение',
    buttonLogin: 'Вход по логину',
    buttonReload: 'Обновить',
    error: {
        emailInvalid: 'Введенный формат почты не действителен',
        phoneInvalid: 'Введенный формат номера не действителен',
        loginInvalid: 'Вы ввели неверный логин или пароль',
        requiredFields: 'Для продолжения заполните все обязательные поля',
        loginFailed: 'Неудачная авторизация'
    }
};
