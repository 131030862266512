import { PaymentMethod } from 'apollo/generated';

export const DEFAULT_BLOCKCHAIN_NAME = PaymentMethod.Eos;

/**
 * Represents the blockchain data structure.
 *
 * @interface {{
 *   standard: string;
 *   symbol: string;
 *   name: string;
 * }}
 */
export interface BlockchainDataInterface {
    standard: string;
    symbol: string;
    name: string;
}

/**
 * Retrieves blockchain data based on the provided blockchain ID.
 *
 * @param {string} [blockchainId] - The ID of the blockchain (optional).
 *
 * @returns {BlockchainDataInterface} The blockchain data object with standard, symbol, and name properties.
 */
export default function getBlockchainData(blockchainId?: string): BlockchainDataInterface {
    switch (blockchainId) {
        case PaymentMethod.Eos:
            return { name: 'EOS', symbol: 'EOS', standard: 'EOS' };
        case PaymentMethod.Ether:
            return { name: 'Ether', symbol: 'ETH', standard: 'ERC-20' };
        case PaymentMethod.Tron:
            return { name: 'Tron', symbol: 'TRX', standard: 'TRC20' };
        default:
            return { name: '', symbol: '', standard: '' };
    }
}
