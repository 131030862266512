export default {
    title: 'Transfer',
    title_internal: 'Internal transfer',
    title_withdraw: 'Withdraw',
    token: 'Select a token and transfer method',
    token_internal: 'Token',
    token_withdraw: 'Select a token and withdraw method',
    currency: 'Select currency',
    method: 'Output method',
    fiatWorld: 'International Bank',
    fiatRus: 'Russian Bank',
    amount: 'Transfer amount',
    amount_withdraw: 'Withdraw amount',
    amountMin: 'Minimum amount',
    amountMax: 'Maximum amount',
    inputAmount: {
        label: 'Will be debited',
        labelEndContent: 'Maximum',
        placeholder: 'Amount debited'
    },
    inputAmountTotal: {
        label: 'Will be credited',
        placeholder: 'You will receive'
    },
    inputAccount: {
        label: 'Account',
        placeholder: 'Enter {{blockchain}} account',
        placeholder_internal: 'Enter the receiver account',
        helperText_internal: 'Receiver account - email, phone number, EOS wallet or store ID'
    },
    inputMemo: {
        label: 'MEMO',
        labelEndContent: 'Optional',
        placeholder: 'Enter MEMO'
    },
    inputCard: {
        label: 'Card number',
        placeholder: 'Enter card number'
    },
    fee: 'Fee',
    feePaySystem: 'Payment system fee',
    confirm: {
        description: 'To confirm the transfer of funds\nyou must enter your account password',
        address: 'Wallet address',
        address_internal: 'Receiver account',
        send: 'Will be send',
        receive: 'Will be receive',
        receive_internal: 'Will be credited',
        memo: 'MEMO'
    },
    cancel: {
        title: 'Cancel',
        description: 'Are you sure you want to stop the funds transfer process?',
        description_withdraw: 'Are you sure you want to stop the withdrawal process'
    },
    success: {
        title: 'Done',
        description_withdraw:
            'You can check the withdrawal status in the transactions section, as well as on the {{domainName}} website'
    },
    alert: {
        success: 'The funds transfer was successful.',
        success_withdraw: 'The withdraw was successful.'
    },
    error: {
        balanceNotFound: 'Balance not found',
        cardInvalid: 'Invalid card number',
        amountRequired: 'You have not specified the withdrawal amount',
        accountRequired: 'You have not specified an account',
        cardRequired: 'You have not specified a card number',
        ConfirmationFailedError: 'Invalid password',
        EosAccountNotExistsError: 'Account does not exist',
        ForbiddenWithdrawalReceiverError: 'Forbidden withdrawal receiver',
        InvalidParamsError: 'Parameters are not valid ({{paramName}})',
        InsufficientFundsError: 'Insufficient funds on balance',
        CurrencyNotFoundError: 'Currency not found',
        WithdrawalAmountTooLowError: 'The amount is less than the minimum. (Minimum {{minimalAmount}})',
        WithdrawalAmountTooHighError: 'The maximum amount has been exceeded. (Maximum {{maximalAmount}})',
        WithdrawLimitExceededError: 'Withdrawal limit exceeded',
        ExchangePairNotFoundError: 'Exchange pair not found',
        FiatMethodNotFoundError: 'Top-up method not found',
        FiatWithdrawalsNotAllowedError: 'Fiat withdrawals are not allowed',
        FiatTransferNotPossibleError: 'This card is not supported by the selected transfer method',
        FiatTransferNotPossibleError_withdraw: 'This card is not supported by the selected withdrawal method',
        WithdrawDuplicationError:
            'A transaction with these parameters is already in progress, please wait for it to complete or retry the transaction later'
    },
    buttonContinue: 'Continue',
    buttonTransfer: 'Transfer',
    buttonWithdraw: 'Withdraw',
    buttonBack: 'Back',
    buttonDone: 'Done',
    buttonYes: 'Yes',
    buttonNo: 'No',
    buttonBlockchain: 'Check on {{domainName}}'
};
