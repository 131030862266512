import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoreNode } from 'apollo/generated';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import { useToast } from 'ui/Toast/Toast';
import StoreCreate from '../StoreCreate/StoreCreate';
import { Step } from '../StoreCreate/StoreCreate.d';

export type StoreCreateModalProps = ReturnType<typeof useDialog> & {
    stores?: Partial<StoreNode | null>[] | null;
};

export default function StoreCreateModal({ open, setOpen, stores, ...props }: StoreCreateModalProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const [step, setStep] = React.useState(Step.Name);
    const [storeName, setStoreName] = React.useState('');

    const existingStoreNames = stores?.map((store) => store?.name);

    const handleStoreCreate = (name?: string) => setStoreName(name ?? '');

    const handleClose = () => {
        if (storeName) toast.success(t('store.create.alert.success', { name: storeName }));

        setOpen(false);
        setStoreName('');
    };

    const handleUnmount = () => setStep(Step.Name);

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            onUnmount={handleUnmount}
            {...props}
        >
            <StoreCreate
                isModalView
                onClose={handleClose}
                existingStoreNames={existingStoreNames}
                step={step}
                setStep={setStep}
                onStoreCreate={handleStoreCreate}
            />
        </Dialog>
    );
}
