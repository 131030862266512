export default {
    title: 'Дашборд',
    actions: {
        order: {
            label: 'Выставить счёт',
            tooltip: 'Создавайте страницу на оплату\nи отправляйте ее пользователям'
        },
        transfer: {
            label: 'Новый перевод',
            tooltip: 'Переводите свою криптовалюту\nна банк. карты или внутри блокчейна'
        },
        transferInternal: {
            label: 'Внутренний перевод',
            tooltip: 'Переводите свои средства\nпользователям PayCash for Store'
        }
    },
    balances: {
        title: 'Балансы',
        waiting: 'В ожидании',
        alertDisabled: 'Пополнение и вывод временно отключены',
        buttonDeposit: 'Пополнить',
        buttonWithdraw: 'Вывести',
        error: {
            loadingFailed: 'При загрузке баланса произошла непредвиденная\nошибка. Попробуйте обновить страницу',
            depositNotAvailable: 'Нет валют доступных для пополнения',
            withdrawNotAvailable: 'Нет валют доступных для вывода'
        }
    },
    feesCrypto: {
        titleDeposit: '<0>Комиссии на ввод</0> Криптовалюта',
        titleWithdraw: '<0>Комиссии на вывод</0> Криптовалюта',
        feePercent: 'Комиссия',
        feeFix: 'Фикс.'
    },
    feesFiat: {
        title: '<0>Курсы и комиссии</0> Оплата картой',
        input: 'Ввод',
        output: 'Вывод',
        buy: 'Покупка',
        sell: 'Продажа',
        fee: 'Комиссия',
        rus: 'РФ',
        world: 'Мир',
        other: 'Другой',
        min: 'Минимальная сумма',
        max: 'Максимальная сумма',
        rate: 'Курс'
    },
    operations: {
        crypto: {
            titleInput: '<0>Ввод</0> Криптовалюта',
            titleOutput: '<0>Вывод</0> Криптовалюта'
        },
        fiat: {
            titleInput: '<0>Ввод</0> Банк. карта',
            titleOutput: '<0>Вывод</0> Банк. карта',
            rus: 'РФ',
            world: 'Мир',
            support:
                '<0>Оплата заказов банковской картой отключена</0>' +
                '<1>Возможность оплаты заказов банковской картой отключена.</1>' +
                '<2>Чтобы включить ее, обратитесь в службу технической поддержки.</2>',
            supportButton: 'Поддержка'
        }
    },
    sales: {
        title: 'Транзакции',
        income: 'Пополнения',
        outcome: 'Выводы',
        today: 'Сегодня',
        week: 'Неделя',
        month: 'Месяц',
        deposits: 'Пополнения',
        withdraws: 'Выводы',
        clientWithdraws: 'Переводы',
        totalOrders: 'Заказы',
        paidOrders: 'Оплаченные',
        incompleteOrders: 'Не оплаченные',
        cancelledOrders: 'Отмененные',
        paybacks: 'Возвраты'
    }
};
