import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMeQuery } from 'apollo/generated';
import { KeyIcon, MailIcon, SmartphoneIcon } from 'ui/Icons/Icons';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import FormControl from 'ui/FormControl/FormControl';
import PhoneField from 'ui/PhoneField/PhoneField';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import ProfilePasswordEdit from '../ProfilePasswordEdit/ProfilePasswordEdit';
import ProfileEmail from '../ProfileEmail/ProfileEmail';
import ProfilePhone from '../ProfilePhone/ProfilePhone';
import { View, ProfileProps } from './Profile.d';

export default function Profile({ onClose, view, setView }: ProfileProps) {
    const { t } = useTranslation();

    const meQuery = useMeQuery();
    const { email, phone } = meQuery.data?.me ?? {};

    if (view === View.EmailAdd) return <ProfileEmail onChangeView={() => setView(View.Main)} mode="add" />;
    if (view === View.EmailEdit) return <ProfileEmail onChangeView={() => setView(View.Main)} mode="edit" />;

    if (view === View.PhoneAdd) return <ProfilePhone onChangeView={() => setView(View.Main)} mode="add" />;
    if (view === View.PhoneEdit) return <ProfilePhone onChangeView={() => setView(View.Main)} mode="edit" />;

    if (view === View.PasswordAdd) {
        // return (
        //     <ProfileFormChangePassword
        //         openIntro={() => setView(View.Main)}
        //         onSuccess={() => console.log(111)}
        //     />
        // );
    }

    if (view === View.PasswordEdit) {
        return <ProfilePasswordEdit onViewChange={() => setView(View.Main)} onSuccess={() => onClose?.()} />;
    }

    return (
        <>
            <DialogHeader title={t('profile.init.title')} />
            <DialogBody>
                {email && (
                    <TextField
                        label={t('profile.init.email')}
                        value={email}
                        labelEndContent={
                            <Link
                                as="button"
                                type="button"
                                size="small"
                                onClick={() => setView?.(View.EmailEdit)}
                                style={{ alignSelf: 'flex-start' }}
                            >
                                {t('profile.init.buttonEdit')}
                            </Link>
                        }
                        disabled
                        RootProps={{
                            style: {
                                marginBottom: '0.5rem'
                            }
                        }}
                    />
                )}

                {phone && (
                    <FormControl
                        label={t('profile.init.phone')}
                        labelEndContent={
                            <Link
                                as="button"
                                size="small"
                                type="button"
                                onClick={() => setView?.(View.PhoneEdit)}
                                style={{ alignSelf: 'flex-start' }}
                            >
                                {t('profile.init.buttonEdit')}
                            </Link>
                        }
                        disabled
                        style={{ marginBottom: '0.5rem' }}
                    >
                        <PhoneField disabled value={phone ?? ''} />
                    </FormControl>
                )}
            </DialogBody>
            <DialogFooter>
                {!email && (
                    <Button
                        type="button"
                        size="large"
                        onClick={() => setView?.(View.EmailAdd)}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        iconStart={<MailIcon />}
                        style={{ marginBottom: '0.5rem' }}
                    >
                        {t('profile.init.buttonAddEmail')}
                    </Button>
                )}

                {!phone && (
                    <Button
                        type="button"
                        size="large"
                        onClick={() => setView?.(View.PhoneAdd)}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        iconStart={<SmartphoneIcon />}
                        style={{ marginBottom: '0.5rem' }}
                    >
                        {t('profile.init.buttonAddPhone')}
                    </Button>
                )}

                <Button
                    type="button"
                    size="large"
                    onClick={() => setView?.(View.PasswordEdit)}
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    iconStart={<KeyIcon />}
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('profile.init.buttonChangePassword')}
                </Button>
            </DialogFooter>
        </>
    );
}
