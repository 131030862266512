import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { DeeplinkNode, PaymentMethod, PaymentOptionNode, usePaymentOrderQuery } from 'apollo/generated';
import PaycashLogo from 'assets/images/app-paycash.svg';
import MalinkaLogo from 'assets/images/app-malinka.svg';
import KalynaLogo from 'assets/images/app-kalyna.svg';
import LogoImg from 'assets/images/logo-main-short.svg';
import getErrorData from 'helpers/getErrorData/getErrorData';
import getPaymentAmount from 'helpers/getPaymentAmount/getPaymentAmount';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useFormField from 'hooks/useFormField/useFormField';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';
import { CrossIcon, InfoCircledIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import Button from 'ui/Button/Button';
import { DialogBody, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import modalStyles from 'ui/Dialog/Dialog.module.scss';
import Skeleton from 'ui/Skeleton/Skeleton';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import PaymentCardRus from '../PaymentCardRus/PaymentCardRus';
import PaymentCardWord, { WorldPaySteps } from '../PaymentCardWord/PaymentCardWord';
import PaymentMobileApp from '../PaymentMobileApp/PaymentMobileApp';
import PaymentShareModal from '../PaymentShareModal/PaymentShareModal';
import PaymentOnlyCrypto from '../PaymentOnlyCrypto/PaymentOnlyCrypto';
import PaymentHowToPayModal from '../PaymentHowToPayModal/PaymentHowToPayModal';
import LanguageSwitcher from '../LangaugeSwitcher/LangaugeSwitcher';
import PaymentOrderPaid from '../PaymentOrderPaid/PaymentOrderPaid';
import PaymentMobileActions from '../PaymentMobileActions/PaymentMobileActions';
import { PaymentMobileAppProps } from '../PaymentMobileApp/PaymentMobileApp.d';
import { PaymentInProgressProps } from './PaymentInProgress.d';
import styles from './PaymentInProgress.module.scss';

const LOCAL_STORAGE_KEY_PAYMENT_RUS_NAME = 'paymentRusName';
const LOCAL_STORAGE_KEY_PAYMENT_WORLD_NAME = 'paymentWorldName';

const TEST_LINKS = [
    { name: 'paycash', link: '#' },
    { name: 'malinka', link: '#' },
    { name: 'kalyna', link: '#' }
];

const TIMEOUT = 60;

const getLinks = (links: DeeplinkNode[]): PaymentMobileAppProps['buttons'] =>
    links.map(({ name, link }) => {
        switch (name) {
            case 'paycash':
                return { name: 'PayCash', link, image: { src: PaycashLogo, alt: 'PayCash' } };
            case 'malinka':
                return { name: 'Malinka', link, image: { src: MalinkaLogo, alt: 'Malinka' } };
            case 'kalyna':
                return { name: 'Kalyna', link, image: { src: KalynaLogo, alt: 'Kalyna' } };
            default:
                return null;
        }
    });

const getBlockchains = (methods?: (PaymentMethod | null)[] | null) =>
    methods?.filter(
        (method) => method === PaymentMethod.Eos || method === PaymentMethod.Ether || method === PaymentMethod.Tron
    );

const getMethods = (paymentOptions?: (PaymentOptionNode | null)[] | null) => {
    if (!paymentOptions) return [];

    return paymentOptions?.map((option) => option?.method ?? null);
};

export default function PaymentInProgress({
    amount,
    currency,
    deepLinks,
    id,
    isFinal,
    isTest,
    memo,
    metaInfo,
    paymentOptions,
    onDevPanelOpen,
    OrderParams,
    receiver
}: PaymentInProgressProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);

    const paymentShareModal = useDialog();
    const modalHowTo = useDialog();

    const formRusPayNameMobileId = React.useId();
    const formWorldPayNameMobileId = React.useId();

    const inputRusPayName = useFormField(localStorage.getItem(LOCAL_STORAGE_KEY_PAYMENT_RUS_NAME) ?? '');
    const inputWorldPayName = useFormField(localStorage.getItem(LOCAL_STORAGE_KEY_PAYMENT_WORLD_NAME) ?? '');

    const methods = getMethods(paymentOptions);
    const rusPayServiceName =
        paymentOptions?.find((option) => option?.method === PaymentMethod.RusPay)?.serviceName ?? '';
    const worldPayServiceName =
        paymentOptions?.find((option) => option?.method === PaymentMethod.WorldPay)?.serviceName ?? '';

    const [showPaymentInfo, setShowPaymentInfo] = React.useState(false);
    const [worldPayStep, setWorldPayStep] = React.useState(WorldPaySteps.Name);
    const [isMobileApp, setIsMobileApp] = React.useState(false);
    const [activePaymentMethod, setActivePaymentMethod] = React.useState(methods?.length ? methods[0] : '');
    const [methodLoading, setMethodLoading] = React.useState(false);

    const [activeTab, setActiveTab] = React.useState<'crypto' | 'card'>('crypto');
    const [paymentLink, setPaymentLink] = React.useState('');
    const [blockchain, setBlockchain] = React.useState(() => getBlockchains(methods)?.[0] || PaymentMethod.Eos);

    const [timestampTron, setTimestampTron] = React.useState(0);
    const [timestampEther, setTimestampEther] = React.useState(0);

    const resendTimerTron = useCountdownTimer(0, timestampTron);
    const resendTimerEther = useCountdownTimer(0, timestampEther);

    const blockchains = getBlockchains(methods);

    const {
        data: paymentOrderData,
        loading: paymentOrderLoading,
        error: paymentOrderError,
        refetch
    } = usePaymentOrderQuery({
        variables: {
            id,
            method: methods ? methods[0] : undefined
        },
        context: { headers: { Authorization: '' } }
    });

    const showLoader = paymentOrderLoading || (!paymentOrderError && !paymentOrderData?.order);
    const showContent = !showLoader && Boolean(paymentOrderData?.order) && !paymentOrderError;
    const showError = !showLoader && paymentOrderError;

    let links: { paycash?: string | null; malinka?: string | null; kalyna?: string | null } = {
        paycash: isTest ? '#' : '',
        malinka: isTest ? '#' : '',
        kalyna: isTest ? '#' : ''
    };

    if (paymentOrderData?.order?.paymentParams?.deeplinks?.length) {
        const paycash = paymentOrderData?.order?.paymentParams?.deeplinks?.find((el) => el?.name === 'paycash');
        const malinka = paymentOrderData?.order?.paymentParams?.deeplinks?.find((el) => el?.name === 'malinka');
        const kalyna = paymentOrderData?.order?.paymentParams?.deeplinks?.find((el) => el?.name === 'kalyna');

        links = {
            paycash: paycash?.link,
            malinka: malinka?.link,
            kalyna: kalyna?.link
        };
    }

    const deepLinkButtons = React.useMemo(() => {
        if (deepLinks && !isTest) return getLinks(deepLinks as DeeplinkNode[]);
        if (isTest) return getLinks(TEST_LINKS as DeeplinkNode[]);

        return undefined;
    }, [isTest, deepLinks]);

    const handleTogglePaymentInfo = (show: boolean) => () => setShowPaymentInfo(show);
    const handleShareClick = () => paymentShareModal.setOpen(true);
    const handleHowToClick = () => modalHowTo.setOpen(true);

    const handleTimerStart = (timeout = TIMEOUT) => {
        const timeoutDelta = 2;
        const currentTimeout = (timeout + timeoutDelta) * 1_000;

        if (activePaymentMethod === PaymentMethod.Tron) {
            setTimestampTron(Date.now() + currentTimeout);
            resendTimerTron.reset();
        }

        if (activePaymentMethod === PaymentMethod.Ether) {
            setTimestampEther(Date.now() + currentTimeout);
            resendTimerEther.reset();
        }
    };

    const handleRusPayNameSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const value = inputRusPayName.value.trim();

        if (!value) {
            inputRusPayName?.errorChange(true, t('payment.paymentRusPay.input.error.formatInvalid'));

            return;
        }

        if (value && !inputRusPayName.error) {
            setMethodLoading(true);

            refetch({
                extraFields: {
                    name: value
                }
            })
                .then(({ data }) => {
                    const { paymentParams } = data.order ?? {};
                    const { paymentLink: pL } = paymentParams ?? {};

                    localStorage.setItem(LOCAL_STORAGE_KEY_PAYMENT_RUS_NAME, value);

                    if (pL) {
                        window.location.href = pL;
                    }
                })
                .catch((err) => {
                    onGlobalError(getErrorData(err).message);
                    setMethodLoading(true);
                });
        }
    };

    const handleWorldPayNameSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const value = inputWorldPayName.value.trim();

        if (!value) {
            inputWorldPayName?.errorChange(true, t('payment.paymentWorldPay.input.error.formatInvalid'));

            return;
        }

        if (value && !inputWorldPayName.error) {
            setMethodLoading(true);

            refetch({
                extraFields: {
                    name: value
                }
            })
                .then(({ data }) => {
                    const { paymentParams } = data.order ?? {};
                    const { paymentLink: pL } = paymentParams ?? {};

                    localStorage.setItem(LOCAL_STORAGE_KEY_PAYMENT_WORLD_NAME, value);

                    if (pL) {
                        setPaymentLink(pL);
                        setWorldPayStep(WorldPaySteps.Pay);
                    } else {
                        onGlobalError(t('payment.paymentWorldPay.error.paymentLinkNotFound'));
                    }
                })
                .catch((err) => {
                    onGlobalError(getErrorData(err).message);
                })
                .finally(() => {
                    setMethodLoading(false);
                });
        }
    };

    const handleWorldPayClick = () => {
        if (paymentLink) {
            window.location.href = paymentLink;
        }
    };

    const handlePaymentTypeChange = (type: PaymentMethod | null) => {
        if (type) setActivePaymentMethod(type);

        setMethodLoading(true);

        refetch({
            id,
            method: type
        })
            .then(({ data }) => {
                const { paymentParams } = data.order ?? {};
                const { paymentLink: pL } = paymentParams ?? {};

                if (pL) {
                    setPaymentLink(pL);
                }
            })
            .finally(() => {
                setMethodLoading(false);
            });
    };

    const handleMobileApp = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        show: boolean,
        activeTabName: 'crypto' | 'card',
        method: PaymentMethod | null
    ) => {
        e.preventDefault();

        if (method) {
            handlePaymentTypeChange(method);
            setActiveTab(activeTabName);
            setIsMobileApp(show);
        }
    };

    const handleDropdownChange = (value: string) => {
        setActivePaymentMethod(value);
        setBlockchain(value as PaymentMethod);
        handlePaymentTypeChange(value as PaymentMethod);
    };

    React.useEffect(() => {
        setPaymentLink(paymentOrderData?.order?.paymentParams?.paymentLink || '');
    }, [paymentOrderData]);

    if (
        isLandscapeOrLess &&
        activePaymentMethod === PaymentMethod.WorldPay &&
        worldPayServiceName === 'element' &&
        worldPayStep === WorldPaySteps.Pay
    )
        return (
            <div
                className={cn(
                    modalStyles.Wrapper,
                    isLandscapeOrLess && modalStyles.WrapperFullScreen,
                    !isLandscapeOrLess && modalStyles['WrapperScroll-body'],
                    styles.DialogWrapper
                )}
            >
                <div
                    className={cn(
                        modalStyles.Container,
                        !isLandscapeOrLess && modalStyles['MaxWidth-sm'],
                        !isLandscapeOrLess && modalStyles.ContainerFullWidth,
                        isLandscapeOrLess && modalStyles.ContainerFullScreen
                    )}
                >
                    <DialogHeader
                        title={t('payment.details.token')}
                        onClose={() => setWorldPayStep(WorldPaySteps.Name)}
                        classes={{ root: styles.RusPayMobileHeader }}
                    />
                    <DialogBody>
                        <PaymentCardWord
                            loading={methodLoading}
                            name={inputWorldPayName}
                            onNameSubmit={handleWorldPayNameSubmit}
                            onPayClick={handleWorldPayClick}
                            paymentParams={paymentOrderData?.order?.paymentParams}
                            paymentLink={paymentLink}
                            step={worldPayStep}
                        />
                    </DialogBody>
                </div>
            </div>
        );

    return (
        <>
            {!showPaymentInfo && (
                <div className={cn(styles.Main, isTest && styles.MainTest)}>
                    <div className={styles.Details}>
                        <PaymentDetails
                            OrderParams={OrderParams}
                            activePaymentMethod={activePaymentMethod}
                            activeTab={activeTab}
                            blockchain={blockchain}
                            currency={currency}
                            formRusPayNameId={formRusPayNameMobileId}
                            formWorldPayNameId={formWorldPayNameMobileId}
                            handleMobileApp={handleMobileApp}
                            handlePaymentTypeChange={handlePaymentTypeChange}
                            handleTabChange={(name: 'crypto' | 'card') => setActiveTab(name)}
                            isMobileApp={isMobileApp}
                            methodLoading={methodLoading}
                            methods={methods}
                            onBlockchainChange={handleDropdownChange}
                            onHowToButtonClick={handleHowToClick}
                            onRusPayNameSubmit={handleRusPayNameSubmit}
                            onShareButtonClick={handleShareClick}
                            onWorldPayNameSubmit={handleWorldPayNameSubmit}
                            paymentOrderData={paymentOrderData}
                            rusPayName={inputRusPayName}
                            rusPayServiceName={rusPayServiceName}
                            worldPayName={inputWorldPayName}
                            worldPayServiceName={worldPayServiceName}
                        />

                        {isLandscapeOrLess && (
                            <div className={styles.HowToPayButtonWrapper}>
                                <Button
                                    variant="text"
                                    classes={{ root: styles.HowToPayButton }}
                                    iconStart={<InfoCircledIcon />}
                                    color="gray"
                                    size="large"
                                    onClick={() => modalHowTo.setOpen(true)}
                                >
                                    {t('payment.btnHowTo')}
                                </Button>
                            </div>
                        )}

                        {isTest && !isFinal && isLandscapeOrLess && (
                            <button type="button" onClick={onDevPanelOpen} className={styles.PhoneDevPanelController}>
                                {t('payment.test.button.change')}
                            </button>
                        )}
                    </div>

                    {isLandscapeOrLess && (
                        <aside className={styles.PhoneBottomShield}>
                            {isMobileApp ? (
                                <PaymentMobileApp
                                    method={activePaymentMethod}
                                    link={paymentLink}
                                    buttons={activeTab === 'crypto' ? deepLinkButtons : []}
                                    onOpen={(e) => handleMobileApp(e, false, 'crypto', blockchain)}
                                    onShowQr={handleTogglePaymentInfo(true)}
                                    formRusPayNameId={formRusPayNameMobileId}
                                    formWorldPayNameId={formWorldPayNameMobileId}
                                    inputRusPayName={inputRusPayName}
                                    inputWorldPayName={inputWorldPayName}
                                    rusPayServiceName={rusPayServiceName}
                                    worldPayServiceName={worldPayServiceName}
                                    methodLoading={methodLoading}
                                />
                            ) : (
                                <>
                                    <header className={styles.PhoneBottomShieldController}>
                                        <span className={styles.PaymentInfoText}>{t('payment.details.toPay')}</span>

                                        {paymentOrderData?.order?.paymentParams?.amount ? (
                                            <span className={styles.PaymentInfoAmount}>
                                                {
                                                    getPaymentAmount(
                                                        paymentOrderData?.order?.paymentParams?.amount,
                                                        activePaymentMethod === PaymentMethod.Eos ||
                                                            activePaymentMethod === PaymentMethod.Ether ||
                                                            activePaymentMethod === PaymentMethod.Tron
                                                            ? paymentOrderData?.order?.currency?.precission
                                                            : undefined
                                                    ).amount
                                                }{' '}
                                                {
                                                    getPaymentAmount(paymentOrderData?.order?.paymentParams?.amount)
                                                        .currencyName
                                                }
                                            </span>
                                        ) : (
                                            <span className={styles.PaymentInfoAmount}>
                                                <Skeleton variant="text" width={120} style={{ fontSize: '1.5rem' }} />
                                            </span>
                                        )}
                                    </header>
                                    <div className={styles.PhoneBottomShieldContent}>
                                        <PaymentMobileActions
                                            dropdownSelected={blockchain}
                                            paymentLink={paymentLink}
                                            activePaymentMethod={activePaymentMethod}
                                            methods={methods}
                                            onMobileAppClick={handleMobileApp}
                                            onPaymentTypeChange={handlePaymentTypeChange}
                                            onShareClick={handleShareClick}
                                            rusPayServiceName={rusPayServiceName}
                                            worldPayServiceName={worldPayServiceName}
                                        >
                                            <PaymentOnlyCrypto
                                                blockchain={blockchain}
                                                blockchains={blockchains}
                                                onBlockchainChange={handleDropdownChange}
                                                activePaymentMethod={activePaymentMethod}
                                                link={paymentLink}
                                                buttons={activeTab === 'crypto' ? deepLinkButtons : []}
                                                onShowQr={handleTogglePaymentInfo(true)}
                                            />
                                        </PaymentMobileActions>
                                    </div>
                                </>
                            )}
                        </aside>
                    )}

                    {showLoader && <Preloader />}

                    {showError && (
                        <div className={styles.Error}>
                            <div>
                                <p className={styles.ErrorText}>{t('payment.paymentMethod.error.title')}</p>
                                <p className={styles.ErrorTitle}>{t('payment.paymentMethod.error.notAvailable')}</p>
                                <p className={styles.ErrorDescription}>
                                    {t('payment.paymentMethod.error.chooseAnother')}
                                </p>
                            </div>
                        </div>
                    )}

                    {!isLandscapeOrLess && !showError && (
                        <div className={styles.PaymentInfo}>
                            {showContent &&
                                (activePaymentMethod === PaymentMethod.Eos ||
                                    activePaymentMethod === PaymentMethod.Tron ||
                                    activePaymentMethod === PaymentMethod.Ether) && (
                                    <>
                                        <PaymentInfo
                                            classes={{
                                                root: styles.PaymentInfoRoot,
                                                qrContainer: styles.PaymentInfoQrContainer
                                            }}
                                            qrValue={metaInfo}
                                            QrProps={{
                                                size: 240
                                            }}
                                            receiver={receiver}
                                            amount={amount}
                                            memo={activePaymentMethod === PaymentMethod.Eos ? memo : ''}
                                            network={activePaymentMethod}
                                            links={activePaymentMethod === PaymentMethod.Eos ? links : undefined}
                                        />

                                        {(activePaymentMethod === PaymentMethod.Tron ||
                                            activePaymentMethod === PaymentMethod.Ether) && (
                                            <div style={{ marginTop: '1rem' }}>
                                                <PaymentOrderPaid
                                                    requisiteId={paymentOrderData?.order?.paymentParams?.requisiteId}
                                                    timer={
                                                        activePaymentMethod === PaymentMethod.Tron
                                                            ? resendTimerTron
                                                            : resendTimerEther
                                                    }
                                                    onTimerStart={handleTimerStart}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                            {showContent && activePaymentMethod === PaymentMethod.RusPay && (
                                <PaymentCardRus
                                    loading={methodLoading}
                                    name={inputRusPayName}
                                    onNameSubmit={handleRusPayNameSubmit}
                                    paymentParams={paymentOrderData?.order?.paymentParams}
                                    paymentLink={paymentLink}
                                />
                            )}

                            {showContent && activePaymentMethod === PaymentMethod.WorldPay && (
                                <PaymentCardWord
                                    loading={methodLoading}
                                    name={inputWorldPayName}
                                    onNameSubmit={handleWorldPayNameSubmit}
                                    onPayClick={handleWorldPayClick}
                                    paymentParams={paymentOrderData?.order?.paymentParams}
                                    paymentLink={paymentLink}
                                    step={worldPayStep}
                                />
                            )}

                            {isTabletOrLess && (
                                <div className={styles.BottomButtons}>
                                    <div className={styles.ShareButtonWrapper}>
                                        <div className={styles.Created}>
                                            <img src={LogoImg} alt="Logo" className={styles.CreatedImage} />
                                            <div>
                                                <div className={styles.CreatedText}>
                                                    {t('payment.details.created.text')}
                                                </div>
                                                <div className={styles.CreatedSubtext}>
                                                    {t('payment.details.created.subtext')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {!isLandscapeOrLess && !isTabletOrLess && (
                        <div className={styles.SideButtonsWrapper}>
                            <div className={styles.LangAndHowButtons}>
                                {!isTabletOrLess && (
                                    <Button
                                        variant="text"
                                        classes={{ root: styles.BtnHowTo }}
                                        iconStart={<InfoCircledIcon />}
                                        color="gray"
                                        onClick={() => modalHowTo.setOpen(true)}
                                    >
                                        {t('payment.btnHowTo')}
                                    </Button>
                                )}
                                <div className={styles.LangSwitchWrapper}>
                                    <LanguageSwitcher />
                                </div>
                            </div>
                            <div className={styles.ShareButtonWrapper}>
                                <div className={styles.Created}>
                                    <img src={LogoImg} alt="Logo" className={styles.CreatedImage} />
                                    <div>
                                        <div className={styles.CreatedText}>{t('payment.details.created.text')}</div>
                                        <div className={styles.CreatedSubtext}>
                                            {t('payment.details.created.subtext')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {isLandscapeOrLess && showPaymentInfo && (
                <div style={{ width: '100%' }}>
                    <div className={styles.PaymentInfoTitleWrapper}>
                        <span className={styles.PaymentInfoTitle}>{t('payment.paymentInfo.payWithQr')}</span>
                        <Button
                            variant="text"
                            isRound
                            color="secondary"
                            iconStart={<CrossIcon fontSize="0.75rem" />}
                            classes={{ root: styles.BtnClose }}
                            onClick={() => setShowPaymentInfo(false)}
                        />
                    </div>
                    <PaymentInfo
                        classes={{ root: styles.PaymentInfoRoot, qrContainer: styles.PaymentInfoQrContainer }}
                        qrValue={metaInfo}
                        QrProps={{
                            size: 240
                        }}
                        receiver={receiver}
                        amount={amount}
                        memo={activePaymentMethod !== PaymentMethod.Tron ? memo : ''}
                        network={activePaymentMethod ?? ''}
                    />

                    {(activePaymentMethod === PaymentMethod.Tron || activePaymentMethod === PaymentMethod.Ether) && (
                        <div style={{ margin: '1rem auto 1rem', maxWidth: 310 }}>
                            <PaymentOrderPaid
                                requisiteId={paymentOrderData?.order?.paymentParams?.requisiteId}
                                timer={activePaymentMethod === PaymentMethod.Tron ? resendTimerTron : resendTimerEther}
                                onTimerStart={handleTimerStart}
                            />
                        </div>
                    )}
                </div>
            )}

            <PaymentHowToPayModal {...modalHowTo} />
            <PaymentShareModal {...paymentShareModal} />
        </>
    );
}
