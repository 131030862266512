import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'apollo/generated';
import { QrIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { PaymentMobileAppProps } from './PaymentMobileApp.d';
import styles from './PaymentMobileApp.module.scss';

export default function PaymentMobileApp({
    method,
    link,
    onShowQr,
    buttons = [],
    formRusPayNameId,
    formWorldPayNameId,
    inputRusPayName,
    inputWorldPayName,
    rusPayServiceName,
    worldPayServiceName,
    methodLoading
}: PaymentMobileAppProps) {
    const { t } = useTranslation();

    const handleShowQr = () => onShowQr(true);

    return (
        <div className={styles.Component}>
            {(method === PaymentMethod.Eos || method === PaymentMethod.Ether || method === PaymentMethod.Tron) && (
                <>
                    {method === PaymentMethod.Eos && (
                        <>
                            <div className={styles.Description}>{t('payment.paymentMobileApp.description')}</div>

                            {buttons.map(
                                (button) =>
                                    button && (
                                        <Button
                                            type="button"
                                            as="a"
                                            classes={{ root: styles.AppButton }}
                                            key={button.name}
                                            href={button.link ?? ''}
                                            color="gray"
                                            size="large"
                                            iconStart={
                                                <img
                                                    className={styles.AppIcon}
                                                    src={button.image?.src}
                                                    alt={button.image?.alt}
                                                />
                                            }
                                            fullWidth
                                        >
                                            {button.name}
                                        </Button>
                                    )
                            )}

                            <div className={styles.Divider}>
                                <div className={styles.DividerText}>{t('payment.paymentMobileApp.divider')}</div>
                            </div>
                        </>
                    )}

                    <Button
                        fullWidth
                        size="large"
                        color="secondary"
                        variant="outlined"
                        onClick={handleShowQr}
                        iconStart={<QrIcon />}
                    >
                        {t('payment.paymentMobileApp.btnQr')}
                    </Button>
                </>
            )}

            {(method === PaymentMethod.RusPay || method === PaymentMethod.WorldPay) && (
                <>
                    {method === PaymentMethod.RusPay && rusPayServiceName === 'listrentals' && (
                        <Button
                            form={formRusPayNameId}
                            fullWidth
                            size="large"
                            disabled={!inputRusPayName?.value || inputRusPayName?.error}
                            loading={methodLoading}
                        >
                            {t('payment.btnContinue')}
                        </Button>
                    )}

                    {method === PaymentMethod.WorldPay && worldPayServiceName === 'element' && (
                        <Button
                            form={formWorldPayNameId}
                            fullWidth
                            size="large"
                            disabled={!inputWorldPayName?.value || inputWorldPayName?.error}
                            loading={methodLoading}
                        >
                            {t('payment.btnContinue')}
                        </Button>
                    )}

                    {((method === PaymentMethod.RusPay && rusPayServiceName !== 'listrentals') ||
                        (method === PaymentMethod.WorldPay && worldPayServiceName !== 'element')) && (
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            as="a"
                            href={link}
                            loading={methodLoading}
                            target={rusPayServiceName === 'qrpos' ? '_blank' : '_self'}
                            rel={rusPayServiceName === 'qrpos' ? 'noreferrer' : undefined}
                        >
                            {t('payment.pay')}
                        </Button>
                    )}

                    {method === PaymentMethod.WorldPay && worldPayServiceName === 'zenzero' && (
                        <div className={styles.FooterDescription}>
                            {t('payment.paymentWorldPay.description', { context: 'otp' })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
