import React from 'react';
import cn from 'classnames';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    FloatingPortal,
    useDelayGroup,
    useDelayGroupContext,
    useMergeRefs,
    useId,
    FloatingArrow,
    arrow,
    useTransitionStyles,
    FloatingDelayGroup,
    size
} from '@floating-ui/react';
import { TooltipProps, TooltipGroupProps, TooltipOptions } from './Tooltip.d';
import styles from './Tooltip.module.scss';

function useTooltip({
    anchorRef = null,
    disableOnTouch,
    disableFocusListener,
    initialOpen = false,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    placement = 'top'
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

    const arrowRef = React.useRef<SVGSVGElement>(null);
    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const { delay } = useDelayGroupContext();

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        elements: {
            /** Sets anchor element */
            reference: anchorRef?.current || null
        },
        middleware: [
            offset(5),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
            arrow({ element: arrowRef }),
            size({
                apply({ elements, availableWidth }) {
                    Object.assign(elements.floating.style, {
                        maxWidth: `${availableWidth}px`
                    });
                },
                padding: 10
            })
        ]
    });

    const { context } = data;

    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen === undefined,
        mouseOnly: disableOnTouch,
        delay
    });

    const focus = useFocus(context, {
        enabled: !disableFocusListener && controlledOpen === undefined
    });

    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            arrowRef,
            ...interactions,
            ...data
        }),
        [open, setOpen, interactions, data]
    );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipState = () => {
    const context = React.useContext(TooltipContext);

    if (!context) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
};

export function TooltipProvider({ children, ...options }: React.PropsWithChildren<TooltipOptions>) {
    /** This can accept any props as options, e.g. `placement`, or other positioning options. */
    const tooltip = useTooltip(options);

    return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function TooltipTriggerComponent({ children, ...props }, propRef) {
        const state = useTooltipState();

        const childrenRef = (children as any).ref;

        const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

        return (
            <span
                ref={ref}
                /** Used for style the trigger based on the state. */
                data-state={state.open ? 'open' : 'closed'}
                {...state.getReferenceProps(props)}
            >
                {children}
            </span>
        );
    }
);

export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement> & {
        isDark?: boolean;
    }
>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function TooltipContentComponent({ children, isDark, ...props }, propRef) {
        const state = useTooltipState();
        const id = useId();
        const { isInstantPhase, currentId } = useDelayGroupContext();
        const ref = useMergeRefs([state.refs.setFloating, propRef]);

        useDelayGroup(state.context, { id });

        const instantDuration = 0;
        const duration = 250;

        const { isMounted, styles: css } = useTransitionStyles(state.context, {
            duration: isInstantPhase
                ? {
                      open: instantDuration,
                      /** `id` is this component's `id`. `currentId` is the current group's `id`. */
                      close: currentId === id ? duration : instantDuration
                  }
                : duration,
            initial: {
                opacity: 0
            }
        });

        if (!isMounted) return null;

        return (
            <FloatingPortal>
                <div ref={ref} style={{ ...state.floatingStyles, ...css }} {...state.getFloatingProps(props)}>
                    {children}
                    <FloatingArrow
                        ref={state.arrowRef}
                        context={state.context}
                        className={cn(styles.Arrow, isDark && styles.ArrowDark)}
                        height={3}
                        width={6}
                    />
                </div>
            </FloatingPortal>
        );
    }
);

/** When using a group, the `isItem` property needs to be set for all tooltips within the group. */
export function TooltipGroup({ children, delay = { open: 500, close: 200 }, ...props }: TooltipGroupProps) {
    return (
        <FloatingDelayGroup delay={delay} {...props}>
            {children}
        </FloatingDelayGroup>
    );
}

/** Tooltips should not contain interactive content. Use a Popover instead! */
export default function Tooltip({
    children,
    content,
    classes,
    delay = 250,
    dense = true,
    isDark,
    isItem,
    TriggerProps,
    ...props
}: TooltipProps) {
    const { trigger, content: contentClasses } = classes ?? {};

    return isItem ? (
        <TooltipProvider {...props}>
            <TooltipTrigger {...TriggerProps} className={trigger}>
                {children}
            </TooltipTrigger>
            <TooltipContent
                className={cn(styles.Root, dense && styles.Dense, isDark && styles.Dark, contentClasses)}
                isDark={isDark}
            >
                {content}
            </TooltipContent>
        </TooltipProvider>
    ) : (
        <TooltipGroup delay={delay}>
            <TooltipProvider {...props}>
                <TooltipTrigger {...TriggerProps} className={trigger}>
                    {children}
                </TooltipTrigger>
                <TooltipContent
                    className={cn(styles.Root, dense && styles.Dense, isDark && styles.Dark, contentClasses)}
                    isDark={isDark}
                >
                    {content}
                </TooltipContent>
            </TooltipProvider>
        </TooltipGroup>
    );
}
