import React from 'react';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from 'apollo/generated';
import getErrorData from 'helpers/getErrorData/getErrorData';
import getPasswordValidate from 'helpers/getPasswordValidate/getPasswordValidate';
import useFormField from 'hooks/useFormField/useFormField';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';
import { ProfilePasswordEditProps } from './ProfilePasswordEdit.d';
import styles from './ProfilePasswordEdit.module.scss';

const DRAG_ANIMATION_DURATION = 500;

export default function ProfilePasswordEdit({ onViewChange, onSuccess }: ProfilePasswordEditProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const formId = React.useId();

    const formRef = React.useRef<HTMLFormElement>(null);

    const inputPassword = useFormField('');
    const inputPasswordNew = useFormField('');
    const inputPasswordNewRepeat = useFormField('');

    const [changePasswordMutation, { loading }] = useChangePasswordMutation();

    const formErrorAnimation = () => {
        const className = 'DragAnimation';

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);

        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        inputPasswordNew.errorChange(false);
        inputPasswordNewRepeat.errorChange(false);

        const passwordValue = inputPassword.value.trim();
        const passwordNewValue = inputPasswordNew.value.trim();
        const passwordNewRepeatValue = inputPasswordNewRepeat.value.trim();

        const { length, hasNumber, hasSpecial, hasUppercase } = getPasswordValidate(inputPasswordNew.value);

        if (!passwordValue.length)
            return inputPassword.errorChange(true, t('profile.password.inputPasswordOld.error.requiredFields'));

        if (!passwordNewValue || !passwordNewRepeatValue) {
            formErrorAnimation();
            inputPasswordNew.errorChange(true);

            return inputPasswordNewRepeat.errorChange(true, t('profile.password.error.requiredFields'));
        }

        if (!length) return inputPasswordNew.errorChange(true, t('profile.password.inputPassword.error.shortLength'));
        if (!hasNumber) return inputPasswordNew.errorChange(true, t('profile.password.inputPassword.error.noNumber'));
        if (!hasUppercase)
            return inputPasswordNew.errorChange(true, t('profile.password.inputPassword.error.noUppercase'));
        if (!hasSpecial) return inputPasswordNew.errorChange(true, t('profile.password.inputPassword.error.noSpecial'));

        if (passwordNewValue !== passwordNewRepeatValue) {
            formErrorAnimation();
            inputPasswordNew.errorChange(true);

            return inputPasswordNewRepeat.errorChange(true, t('profile.password.inputPasswordRepeat.error.notMatch'));
        }

        changePasswordMutation({
            variables: {
                input: {
                    oldPassword: passwordValue,
                    newPassword: passwordNewValue
                }
            }
        })
            .then(({ data }) => {
                const changePassword = data?.changePassword;

                if (changePassword?.__typename === 'ChangePasswordSuccess') {
                    onSuccess();
                    toast.success(t('profile.password.alertSuccess'));
                } else {
                    const { __typename, errorMessage = '' } = changePassword ?? {};

                    inputPassword.errorChange(
                        true,
                        t([`profile.password.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    return (
        <>
            <DialogHeader title={t('profile.password.title', { context: 'edit' })} onBackButtonClick={onViewChange} />
            <DialogBody>
                <form id={formId} ref={formRef} onSubmit={handleSubmit}>
                    <TextFieldPassword
                        classes={{ root: styles.FormItem }}
                        label={t('profile.password.inputPasswordOld.label')}
                        placeholder={t('profile.password.inputPasswordOld.placeholder')}
                        value={inputPassword.value}
                        isError={inputPassword.error}
                        helperText={inputPassword.helperText}
                        onChange={(e) => {
                            inputPassword.change(e.target.value);
                        }}
                        autoComplete="current-password"
                        required
                    />
                    <TextFieldPassword
                        classes={{ root: styles.FormItem }}
                        label={t('profile.password.inputPassword.label')}
                        placeholder={t('profile.password.inputPassword.placeholder')}
                        value={inputPasswordNew.value}
                        isError={inputPasswordNew.error}
                        helperText={inputPasswordNew.helperText}
                        validatePassword={getPasswordValidate(inputPasswordNew.value)}
                        onChange={(e) => {
                            inputPasswordNewRepeat.errorChange(false);
                            inputPasswordNew.change(e.target.value);
                        }}
                        autoComplete="new-password"
                    />
                    <TextFieldPassword
                        classes={{ root: styles.FormItem }}
                        label={t('profile.password.inputPasswordRepeat.label')}
                        placeholder={t('profile.password.inputPasswordRepeat.placeholder')}
                        value={inputPasswordNewRepeat.value}
                        isError={inputPasswordNewRepeat.error}
                        helperText={inputPasswordNewRepeat.helperText}
                        onChange={(e) => {
                            inputPasswordNew.errorChange(false);
                            inputPasswordNewRepeat.change(e.target.value);
                        }}
                        autoComplete="new-password"
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    loading={loading}
                    size="large"
                    type="submit"
                    fullWidth
                    form={formId}
                    disabled={
                        !inputPassword.value ||
                        !inputPasswordNew.value ||
                        inputPasswordNew.value.length !== inputPasswordNewRepeat.value.length ||
                        inputPassword.error ||
                        inputPasswordNew.error
                    }
                >
                    {t('profile.buttonContinue')}
                </Button>
            </DialogFooter>
        </>
    );
}
