import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'apollo/generated';
import UsdtIcon from 'assets/images/token-usdt.svg';
import RubcashIcon from 'assets/images/token-rubcash.svg';
import UsdcashIcon from 'assets/images/token-usdcash.svg';
import getBlockchainData from 'helpers/getBlockchainData/getBlockchainData';
import getPaymentAmount from 'helpers/getPaymentAmount/getPaymentAmount';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useDropdown from 'hooks/useDropdown/useDropdown';
import {
    BankCardFilledIcon,
    CentFilledCircledIcon,
    CheckFilledCircledIcon,
    ChevronRightIcon,
    CrossIcon,
    InfoCircledIcon,
    SendFilledIcon
} from 'ui/Icons/Icons';
import Tabs from 'ui/Tabs/Tabs';
import Button from 'ui/Button/Button';
import Dropdown from 'ui/Dropdown/Dropdown';
import TextField from 'ui/TextField/TextField';
import { DropdownOption } from 'ui/Dropdown/Dropdown.d';
import Logo from '../Logo/Logo';
import TokenIcon from '../TokenIcon/TokenIcon';
import PaymentButton from '../PaymentButton/PaymentButton';
import LanguageSwitcher from '../LangaugeSwitcher/LangaugeSwitcher';
import { Methods, PaymentDetailsProps } from './PaymentDetails.d';
import styles from './PaymentDetails.module.scss';

const getTabs = (language: string) => [
    {
        label: (
            <div style={{ fontSize: '1rem' }}>
                <CentFilledCircledIcon className={styles.TabsIcon} />
                {language === 'ru' ? 'Криптовалюта' : 'Cryptocurrency'}
            </div>
        ),
        value: 'crypto'
    },
    {
        label: (
            <div style={{ fontSize: '1rem' }}>
                <BankCardFilledIcon className={styles.TabsIcon} />
                {language === 'ru' ? 'Банк. карта' : 'Bank card'}
            </div>
        ),
        value: 'card'
    }
];

export const contains = (sourceMethods: Methods, searchMethods: Methods): boolean => {
    if (sourceMethods?.length && searchMethods?.length) {
        for (let i = 0; i < Number(searchMethods?.length); i += 1) {
            if (sourceMethods.indexOf(searchMethods[i]) === -1) return false;
        }

        return true;
    }

    return false;
};

const getSrcForImg = (currency?: string | null) => {
    if (currency === 'USDT') return UsdtIcon;
    if (currency === 'RUBCASH') return RubcashIcon;
    if (currency === 'USDCASH') return UsdcashIcon;

    return UsdtIcon;
};

export interface DropdownLabelProps {
    blockchain?: PaymentMethod;
    isActive?: boolean;
}

const getBlockchains = (methods?: (PaymentMethod | null)[] | null) =>
    methods?.filter(
        (method) => method === PaymentMethod.Eos || method === PaymentMethod.Ether || method === PaymentMethod.Tron
    );

function BlockchainLabel({ blockchain, isActive }: DropdownLabelProps) {
    const { t } = useTranslation();

    return (
        <div className={cn(styles.BlockchainLabel, isActive && styles.BlockchainLabelActive)}>
            <TokenIcon
                name={getBlockchainData(blockchain).name}
                size={24}
                classes={{ root: styles.BlockchainLabelIcon }}
            />
            <div className={styles.BlockchainLabelContent}>
                <div className={styles.BlockchainLabelText}>{t('payment.details.network')}</div>
                <div className={styles.BlockchainLabelSubtext}>{getBlockchainData(blockchain).standard}</div>
            </div>
        </div>
    );
}

const getBlockchainOptions = (methods?: Methods, activeMethod?: PaymentMethod): DropdownOption[] => {
    if (!methods) return [];

    return methods.map((method) => ({
        label: <BlockchainLabel blockchain={method ?? undefined} isActive={method === activeMethod} />,
        value: method ?? '',
        isSelected: method === activeMethod
    }));
};

export default function PaymentDetails({
    OrderParams,
    activePaymentMethod,
    activeTab,
    blockchain,
    currency,
    formRusPayNameId,
    formWorldPayNameId,
    handleMobileApp,
    handlePaymentTypeChange,
    handleTabChange,
    isMobileApp,
    methodLoading,
    methods,
    onBlockchainChange,
    onHowToButtonClick,
    onRusPayNameSubmit,
    onShareButtonClick,
    onWorldPayNameSubmit,
    paymentOrderData,
    rusPayName,
    rusPayServiceName,
    worldPayServiceName,
    worldPayName
}: PaymentDetailsProps) {
    const { t, i18n } = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
    const dropdown = useDropdown();

    const blockchains = getBlockchains(methods);
    const dropdownOptions = getBlockchainOptions(blockchains, blockchain);

    const inputRusRef = React.useRef<HTMLInputElement | null>(null);
    const inputWorldRef = React.useRef<HTMLInputElement | null>(null);

    const { language } = i18n;

    const isEos = contains(methods, ['EOS'] as Methods);
    const isTron = contains(methods, ['TRON'] as Methods);
    const isEther = contains(methods, ['ETHER'] as Methods);
    const isWordPay = contains(methods, ['WORLD_PAY'] as Methods);
    const isRusPay = contains(methods, ['RUS_PAY'] as Methods);

    const { amount } = paymentOrderData?.order?.paymentParams ?? {};

    const getExistingCryptoMethod = () => {
        if (isEos) return PaymentMethod.Eos;
        if (isEther) return PaymentMethod.Ether;
        if (isTron) return PaymentMethod.Tron;

        return null;
    };

    const getExistingCardMethod = () => {
        if (isRusPay) return PaymentMethod.RusPay;
        if (isWordPay) return PaymentMethod.WorldPay;

        return null;
    };

    const handleDropdown = (value?: string) => {
        onBlockchainChange?.(value as PaymentMethod);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (activePaymentMethod === PaymentMethod.RusPay) {
            const formattedValue = e.target.value.replace(/[^\p{L}\s]/gu, '');

            rusPayName?.change(formattedValue);
        }

        if (activePaymentMethod === PaymentMethod.WorldPay) {
            const formattedValue = e.target.value.replace(/[^A-Za-z\s]/g, '');

            worldPayName?.change(formattedValue);
        }
    };

    const handleNameValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const words = value.split(/\s+/);

        if (value && words.length < 2) {
            if (activePaymentMethod === PaymentMethod.RusPay) {
                rusPayName?.errorChange(true, t('payment.paymentRusPay.input.error.formatInvalid'));
            }

            if (activePaymentMethod === PaymentMethod.WorldPay) {
                worldPayName?.errorChange(true, t('payment.paymentWorldPay.input.error.formatInvalid'));
            }
        }
    };

    React.useEffect(() => {
        if (!methodLoading && activePaymentMethod === PaymentMethod.RusPay && inputRusRef.current) {
            inputRusRef.current?.focus();
        }

        if (!methodLoading && activePaymentMethod === PaymentMethod.WorldPay && inputWorldRef.current) {
            inputWorldRef.current?.focus();
        }
    }, [activePaymentMethod, methodLoading]);

    return (
        <div className={cn(styles.Details, isMobileApp && styles.DetailsWhiteBg)}>
            <div>
                {!isMobileApp && (
                    <div>
                        {isLandscapeOrLess && (
                            <div className={styles.Header}>
                                <Logo classes={{ root: styles.Logo }} />
                                <div className={styles.HeaderButtons}>
                                    <LanguageSwitcher />
                                </div>
                            </div>
                        )}
                        {OrderParams}
                    </div>
                )}

                {!isLandscapeOrLess && (
                    <div>
                        {(isEos || isTron || isEther) && (isWordPay || isRusPay) && (
                            <div style={{ marginBottom: '0.75rem' }}>
                                <h4 className={styles.Title}>{t('payment.selectPaymentMethod')}</h4>
                                <p className={styles.DetailsPaymentType}>{t('payment.cryptocurrency')}</p>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        onClick={() => handlePaymentTypeChange(blockchain as PaymentMethod)}
                                        color="secondary"
                                        variant="outlined"
                                        fullWidth
                                        classes={{
                                            root: cn(
                                                styles.UsdtTypeButton,
                                                activePaymentMethod === PaymentMethod.Eos ||
                                                    activePaymentMethod === PaymentMethod.Ether ||
                                                    activePaymentMethod === PaymentMethod.Tron
                                                    ? styles.ActiveButton
                                                    : ''
                                            )
                                        }}
                                        iconEnd={
                                            (activePaymentMethod === PaymentMethod.Eos ||
                                                activePaymentMethod === PaymentMethod.Ether ||
                                                activePaymentMethod === PaymentMethod.Tron) && (
                                                <CheckFilledCircledIcon className={styles.CheckIcon} />
                                            )
                                        }
                                    >
                                        <div>
                                            <img src={getSrcForImg(currency)} alt="USDT" className={styles.UsdtIcon} />
                                            <span style={{ fontWeight: 700 }}>{currency}</span>
                                        </div>
                                    </Button>

                                    {currency === 'USDT' && (
                                        <Dropdown
                                            classes={{
                                                controller: styles.BlockchainController,
                                                arrow: styles.BlockchainControllerArrow
                                            }}
                                            options={dropdownOptions}
                                            controllerLabel={<BlockchainLabel blockchain={blockchain} />}
                                            onChange={handleDropdown}
                                            open={dropdown.open}
                                            onOpenChange={dropdown.setOpen}
                                            disabled={
                                                activePaymentMethod === PaymentMethod.WorldPay ||
                                                activePaymentMethod === PaymentMethod.RusPay ||
                                                blockchains?.length === 1
                                            }
                                        />
                                    )}
                                </div>

                                {(isWordPay || isRusPay) && (
                                    <p className={styles.DetailsPaymentType}>{t('payment.cards')}</p>
                                )}

                                {isWordPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.WorldPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.WorldPay}
                                        serviceName={worldPayServiceName}
                                    />
                                )}

                                {isRusPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.RusPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.RusPay}
                                        serviceName={rusPayServiceName}
                                    />
                                )}
                            </div>
                        )}
                        {/* if only [EOS, TRON, ETHER] in methods */}
                        {((isEos && isTron) ||
                            (isEos && isEther) ||
                            (isTron && isEther) ||
                            (isEos && isTron && isEther)) &&
                            !(isRusPay || isWordPay) &&
                            Number(methods?.length) <= 3 && (
                                <div className={styles.PaymentInfoDetails}>
                                    <span className={styles.PaymentInfoSelectMethod}>{t('payment.details.toPay')}</span>
                                    <span className={styles.PaymentInfoDetailsAmount}>{amount}</span>
                                    <span className={styles.PaymentInfoDetailsText}>{t('payment.details.select')}</span>
                                    <Dropdown
                                        classes={{ controller: styles.BlockchainController }}
                                        options={dropdownOptions}
                                        controllerLabel={<BlockchainLabel blockchain={blockchain} />}
                                        onChange={handleDropdown}
                                        open={dropdown.open}
                                        onOpenChange={dropdown.setOpen}
                                        disabled={
                                            activePaymentMethod === PaymentMethod.WorldPay ||
                                            activePaymentMethod === PaymentMethod.RusPay ||
                                            blockchains?.length === 1
                                        }
                                    />
                                </div>
                            )}
                        {/* if only rubcash or usdtcash in methods */}
                        {(isEos || isEther || isTron) && methods?.length === 1 && (
                            <div className={styles.PaymentInfoDetails}>
                                <span className={styles.PaymentInfoSelectMethod}>{t('payment.details.toPay')}</span>
                                <span className={styles.PaymentInfoDetailsAmount}>{amount}</span>
                            </div>
                        )}
                        {/* if only bankcards in methods */}
                        {getExistingCardMethod() && !getExistingCryptoMethod() && (
                            <div className={styles.PaymentInfoDetails}>
                                <span className={styles.PaymentInfoSelectMethod}>
                                    {t('payment.selectPaymentMethod')}
                                </span>

                                {isWordPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.WorldPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.WorldPay}
                                        serviceName={worldPayServiceName}
                                    />
                                )}

                                {isRusPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.RusPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.RusPay}
                                        serviceName={rusPayServiceName}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}

                {isMobileApp && isLandscapeOrLess && (
                    <div className={styles.MobilePayment}>
                        <div className={styles.MobilePaymentHeader}>
                            <div className={styles.MobilePaymentHeaderTitle}>{t('payment.details.token')}</div>
                            <Button
                                variant="text"
                                isRound
                                color="secondary"
                                iconStart={<CrossIcon fontSize="0.75rem" />}
                                classes={{ root: styles.BtnClose }}
                                onClick={(e) =>
                                    handleMobileApp(e, false, 'crypto', activePaymentMethod || blockchain || '')
                                }
                            />
                        </div>

                        {(((isRusPay || isWordPay) && isEos) || isEther || isTron) && (
                            <Tabs
                                tabList={getTabs(language)}
                                variant="line"
                                fullWidth
                                value={activeTab}
                                classes={{ root: styles.MobilePaymentTabs }}
                                onChange={(val: string) => {
                                    if (val === 'crypto') {
                                        handlePaymentTypeChange(blockchain);
                                    } else {
                                        handlePaymentTypeChange(getExistingCardMethod());
                                    }

                                    handleTabChange(val);
                                }}
                            />
                        )}

                        {activeTab === 'crypto' ? (
                            <Dropdown
                                classes={{ controller: styles.BlockchainController }}
                                options={dropdownOptions}
                                controllerLabel={<BlockchainLabel blockchain={blockchain} />}
                                onChange={handleDropdown}
                                customChevronIcon={isMobileApp ? <ChevronRightIcon className={styles.Chevron} /> : ''}
                                open={dropdown.open}
                                onOpenChange={dropdown.setOpen}
                                fullWidth
                                disabled={
                                    activePaymentMethod === PaymentMethod.WorldPay ||
                                    activePaymentMethod === PaymentMethod.RusPay ||
                                    blockchains?.length === 1
                                }
                            />
                        ) : (
                            <div>
                                {isWordPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.WorldPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.WorldPay}
                                        serviceName={worldPayServiceName}
                                    />
                                )}

                                {isRusPay && (
                                    <PaymentButton
                                        onClick={() => handlePaymentTypeChange(PaymentMethod.RusPay)}
                                        activeMethod={activePaymentMethod}
                                        location={PaymentMethod.RusPay}
                                        serviceName={rusPayServiceName}
                                    />
                                )}
                            </div>
                        )}

                        <div className={styles.MobilePaymentInfo}>
                            <span className={styles.PaymentInfoText}>{t('payment.details.toPay')}</span>
                            <span className={styles.PaymentInfoAmount}>
                                {
                                    getPaymentAmount(
                                        amount,
                                        activeTab === 'crypto'
                                            ? paymentOrderData?.order?.currency?.precission
                                            : undefined
                                    ).amount
                                }{' '}
                                {getPaymentAmount(amount).currencyName}
                            </span>
                        </div>

                        {activePaymentMethod === PaymentMethod.RusPay && rusPayServiceName === 'listrentals' && (
                            <form id={formRusPayNameId} onSubmit={onRusPayNameSubmit}>
                                <TextField
                                    classes={{ root: styles.FormItemMarginTop, input: styles.InputCapitalize }}
                                    label={t('payment.paymentRusPay.input.label')}
                                    placeholder={t('payment.paymentRusPay.input.placeholder')}
                                    value={rusPayName?.value}
                                    onChange={handleNameChange}
                                    onBlur={handleNameValidate}
                                    isError={rusPayName?.error}
                                    helperText={rusPayName?.helperText}
                                    ref={inputRusRef}
                                />
                            </form>
                        )}

                        {activePaymentMethod === PaymentMethod.WorldPay && worldPayServiceName === 'element' && (
                            <form id={formWorldPayNameId} onSubmit={onWorldPayNameSubmit}>
                                <TextField
                                    classes={{ root: styles.FormItemMarginTop, input: styles.InputCapitalize }}
                                    label={t('payment.paymentRusPay.input.label')}
                                    placeholder={t('payment.paymentRusPay.input.placeholder')}
                                    value={worldPayName?.value}
                                    onChange={handleNameChange}
                                    onBlur={handleNameValidate}
                                    isError={worldPayName?.error}
                                    helperText={worldPayName?.helperText}
                                    ref={inputWorldRef}
                                />
                            </form>
                        )}
                    </div>
                )}
            </div>

            {!isLandscapeOrLess && (
                <div className={styles.Bottom}>
                    <Button
                        classes={{ root: styles.ShareButton }}
                        onClick={onShareButtonClick}
                        iconEnd={<SendFilledIcon />}
                        size="large"
                        fullWidth
                    >
                        {t('payment.btnShareDescription')}
                    </Button>
                    {isTabletOrLess && (
                        <div className={styles.Row}>
                            <Button
                                variant="text"
                                classes={{ root: styles.BtnHowTo }}
                                iconStart={<InfoCircledIcon />}
                                color="gray"
                                onClick={onHowToButtonClick}
                            >
                                {t('payment.btnHowTo')}
                            </Button>
                            <div className={styles.LangSwitchWrapper}>
                                <LanguageSwitcher />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
