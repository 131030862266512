export default {
    title: 'Транзакции',
    search: 'Поиск',
    downloadReport: 'Скачать отчёт',
    downloadReportError: 'Не удалось сформировать отчёт',
    sort: 'Сортировка',
    sortType: {
        CREATED: 'Созданные от старых к новым',
        CREATED_DESC: 'Созданные от новых к старым',
        PAYMENT_DATE_DESC: 'Оплаченные от новых к старым',
        PAYMENT_DATE: 'Оплаченные от старых к новым',
        FULL_AMOUNT_DESC: 'По убыванию суммы',
        FULL_AMOUNT: 'По возрастанию суммы',
        FEE_DESC: 'По убыванию комиссии',
        FEE: 'По возрастанию комиссии',
        SEQUENT_NUMBER_DESC: 'Номер заказа от новых к старым',
        SEQUENT_NUMBER: 'Номер заказа от старых к новым',
        BALANCE_RUBCASH: 'По возрастанию баланса RUBCASH',
        BALANCE_RUBCASH_DESC: 'По убыванию баланса RUBCASH',
        BALANCE_USDCASH: 'По возрастанию баланса USDCASH',
        BALANCE_USDCASH_DESC: 'По убыванию баланса USDCASH',
        BALANCE_USDT: 'По возрастанию баланса USDT',
        BALANCE_USDT_DESC: 'По убыванию баланса USDT'
    },
    tabs: {
        deposit: 'Пополнение',
        withdraw: 'Вывод'
    },
    tableHeader: {
        accountCurrency: 'Валюта счета',
        accountAmount: 'Счет',
        amountPayment: 'Сумма оплаты',
        amount: 'Сумма',
        count: 'Количество',
        createdDate: 'Создано',
        commission: 'Комиссия',
        commissionPayments: 'Комиссия платежки',
        debitAmount: 'Сумма списания',
        debitCurrency: 'Валюта списания',
        debitedPayments: 'Списано c платежки',
        entered: 'Зачислено',
        number: 'Номер',
        paycheck: 'Платёжка',
        paymentDate: 'Время оплаты',
        paymentCurrency: 'Валюта оплаты',
        receive: 'Получено',
        receivingCurrency: 'Валюта получения',
        status: 'Статус',
        storeName: 'Название магазина',
        type: 'Тип',
        token: 'Токен',
        network: 'Сеть',
        paymentMethod: 'Способ оплаты',
        user: 'Пользователь',
        store: 'Магазин',
        and: 'и'
    },
    type: {
        title: 'Тип заявки',
        name: 'типы',
        CLIENT_WITHDRAW: 'Перевод',
        DEPOSIT: 'Пополнение',
        ORDER_PAYMENT: 'Заказ',
        PAYBACK: 'Возврат',
        TEST_ORDER_PAYMENT: 'Заказ тест',
        UNKNOWN: 'Неизвестно',
        WITHDRAW: 'Вывод',
        ADMIN_DEPOSIT: 'Пополнение',
        ADMIN_WITHDRAW: 'Вывод',
        GIVE: 'Вн. перевод',
        TAKE: 'Вн. перевод'
    },
    payback: 'Возврат',
    status: {
        name: 'статусы',
        ACCEPTED: 'Оплачено',
        CANCELLED: 'Отменено',
        CREATED: 'Ожидание оплаты',
        DECLINED: 'Отклонено',
        EXPIRED: 'Время ожидания истекло',
        FAILED: 'Ошибка',
        FROZEN: 'Заморожено',
        INITIATED: 'Ожидание',
        PENDING: 'Отправка',
        RECEIVED: 'Получено',
        RETRY: 'В обработке',
        SENDED: 'Отправлено',
        MONEY_RECEIVED: 'Получено',
        PAID: 'Оплачено'
    },
    filters: {
        title: 'Фильтры',
        selectDate: 'Выберите дату',
        allTypes: 'Все типы',
        status: 'Статус',
        allStatuses: 'Все статусы',
        allCurrencies: 'Все валюты',
        allPayments: 'Все платежки',
        apply: 'Применить',
        reset: 'Сбросить',
        commission: 'Комиссия',
        amountPayment: 'Сумма оплаты',
        chargedToPayment: 'Списано с платёжки',
        paymentCurrency: 'Валюта оплаты',
        receivingCurrency: 'Валюта получения',
        debitCurrency: 'Валюта списания',
        paycheck: 'Платёжка',
        from: 'От',
        to: 'До'
    },
    dataNotAvailable: 'Данные отсутствуют',
    notAvailable: 'Транзакции отсутствуют',
    notAvailableDescription: 'У вас пока что нет заказов в данном магазине',
    error: 'Ошибка',
    nothingFound: 'Ничего не найдено',
    nothingFoundDescription:
        'Мы не смогли ничего найти по вашему\nзапросу. Проверьте правильность\nнаписания и попробуйте еще раз',
    paymentDate: 'Время оплаты',
    created: 'Время создания',
    bill: 'Счёт',
    enrolled: 'Зачислено',
    writeOffAmount: 'Сумма списания',
    feeForPayment: 'Комиссия платёжки',
    accountCurrency: 'Валюта счёта',
    orderInfo: {
        error: {
            RetryTransferSuccess: 'Транзакция успешно повторена',
            WrongTransferStatusError: 'Неверный статуса транзакции',
            WrongTransferTypeError: 'Неверный типа транзакции',
            TransferNotFoundError: 'Транзакция не найдена',
            ConfirmationFailedError: 'Введён неверный пароль',
            AbortTransferSuccess: 'Транзакция успешно отменена',
            FailTransferSuccess: 'Транзакция успешно переведена в статус FAILED',
            FinalizeTransferSuccess: 'Транзакция успешно подтверждена'
        },
        orderType: {
            name: 'типы',
            CLIENT_WITHDRAW: 'Перевод',
            DEPOSIT: 'Пополнение',
            ORDER_PAYMENT: 'Заказ',
            PAYBACK: 'Возврат',
            TEST_ORDER_PAYMENT: 'Заказ тест',
            UNKNOWN: 'Неизвестно',
            WITHDRAW: 'Вывод',
            ADMIN_DEPOSIT: 'Пополнение',
            ADMIN_WITHDRAW: 'Вывод',
            GIVE: 'Внутренний перевод',
            TAKE: 'Внутренний перевод'
        },
        changeStatus: {
            title: 'Вы уверены что хотите перевести статус транзакции в состояние ошибки(FAILED)?',
            description:
                ' Статус FAILED допускает, что транзакция тем не менее могла выполниться. Проверьте наличие этой транзакции в платежной системе.',
            confirmation: 'Подтверждение',
            type: 'Вы уверены что хотите отменить транзакцию?',
            type_ABORT: 'Вы уверены что хотите отменить транзакцию?',
            type_RETRY: 'Вы уверены что хотите повторить транзакцию?',
            type_CONFIRM: 'Вы уверены что хотите подтвердить транзакцию?',
            confirm: 'Подтверждаю'
        },
        info: 'Информация',
        repeat: 'Повторить',
        cancel: 'Отменить',
        cancellation: 'Отмена',
        confirm: 'Подтвердить',
        commission: 'Комиссия',
        entered: 'Зачислено',
        network: 'Сеть',
        store: 'Магазин',
        minutes: 'Мин.',
        paymentCurrency: 'Валюта оплаты',
        amountPayment: 'Сумма оплаты',
        paycheck: 'Платёжка',
        update: 'Обновить',
        print: 'Печать',
        timeOfOrderCreation: 'Время создания заказа',
        paymentTime: 'Время оплаты заказа',
        transactionId: 'ID Транзакции',
        paymentSystemId: 'ID Платежки',
        sender: 'Отправитель',
        receiver: 'Получатель',
        receive: 'Получено',
        errorReason: 'Причина ошибки',
        description: 'Описание',
        redirectSuccess: 'Редирект при успешной оплате',
        redirectFailure: 'Редирект при неуспешной оплате',
        orderNumber: 'Номер заказа',
        expirationsTime: 'Время экспирации заказа',
        existenceTime: 'Время существования ссылки',
        storeId: 'ID на сайте магазина',
        internalId: 'Внутренний ID',
        callbackLink: 'Callback ссылка',
        fundsReceived: 'Полученные средства',
        orderAmount: 'Сумма заказа',
        paymentToken: 'Токен оплаты',
        paymentLink: 'Ссылка на оплату',
        senderAccount: 'Аккаунт оплатившего',
        receiverAccount: 'Аккаунт получателя',
        blockchainLink: 'Ссылка на блокчейн'
    },
    cancelOrder: {
        title: 'Отмена',
        description: 'Вы уверены что хотите отменить данный заказ?',
        btnSubmit: 'Отменить заказ',
        btnCancel: 'Не отменять',
        error: {
            CancelOrderSuccess: 'Заказ отменён.',
            OrderNotFoundError: 'Заказ не найден.',
            OrderPaidError: 'Заказ уже оплачен.',
            OrderInactiveError: 'Заказ уже не активен.'
        }
    },
    refund: {
        error: {
            PaybackOrderSuccess: 'Возврат средств успешно произведен',
            InsufficientFundsError: '',
            OrderNotFoundError: 'Заказ не найден',
            InvalidParamsError: 'Недействительные параметров',
            OrderNotPaidError: 'Заказ не оплачен',
            CurrencyNotFoundError: 'Валюта не найдена',
            OrderNotRefundableError: 'Заказ не подлежит возврату',
            amountTooLow: 'Сумма списания слишком мала',
            amountTooLarge: 'Нельзя сделать возврат больше стоимости заказа',
            amountPaymentTooLarge: 'Сумма не может быть больше оплаченной клиентом'
        },
        account: 'Аккаунт',
        title_intro: 'Возврат средств',
        title_refundIntroAttention: 'Возврат средств',
        title_agreement: 'Возврат средств',
        title_hasNoBalance: 'Недостаточно средств',
        description_intro: 'Вы хотите совершить полный возврат средств по заказу <0>№{{orderNumber}}</0>',
        description_refundIntroAttention:
            'По заказу <0>№{{orderNumber}}</0> вы уже вернули средства в размере <0>{{amount}}</0>',
        description_refundIntroInfo: 'По заказу <0>№{{orderNumber}}</0> вы уже вернули часть средств',
        description_refundAllInfo: 'По заказу <0>№{{orderNumber}}</0> вы произвели полный возврат средств',
        description_agreement:
            'Вы уверены что хотите вернуть средства за данный заказ, отмена операции будет невозможна',
        description_hasNoBalance: 'Для возврата средств на вашем балансе недостаточно средств.',
        missingBalance: 'Недостающая сумма',
        successMsg: 'Возврат средств успешно произведен',
        receiver: 'Аккаунт получателя',
        fee: 'Комиссия',
        received: 'Вы получили',
        paid: 'Было оплачено',
        youReturned: 'Вы вернули',
        toBeReturned: 'Остаток к возврату',
        youWillPay: 'С вас будет списано',
        clientReceive: 'Клиент получит',
        clientReceived: 'Клиент получил',
        btnSubmit_intro: 'Продолжить',
        btnSubmit_agreement: 'Сделать возврат средств',
        btnSubmit_hasNoBalance: 'Пополнить баланс',
        btnCancel: 'Отмена',
        btnChangeAmount: 'Изменить сумму возврата',
        btnReturnAllSum: 'Вернуть полную сумму',
        debitAmount: 'Сумма списания',
        willBeReceived: 'Будет зачислено'
    },
    newOrder: 'Новый заказ',
    update: 'Обновить',
    cancelOrderError: 'Не найден ID ордера',
    loadingFailed: 'При загрузке произошла ошибка.\nПопробуйте обновить страницу',
    transactionsNotAvailableDescription: 'У вас пока что нет заказов в данном магазине',
    transactionsNotAvailable: 'Транзакции отсутствуют'
};
