import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useCopy from 'hooks/useCopy/useCopy';
import { CopyIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { ButtonProps as DefaultButtonProps, ButtonPropsWithRef } from 'ui/Button/Button.d';
import styles from './Param.module.scss';

interface Classes {
    root?: string;
    title?: string;
    content?: string;
}

export interface ParamProps {
    classes?: Classes;
    label?: React.ReactNode;
    value?: React.ReactNode;
    copyText?: number | string;
    onCopyText?: string;
    withCopy?: boolean;
    ButtonProps?: ButtonPropsWithRef<'button', DefaultButtonProps>;
}

export default function Param({ label, value, classes, copyText, onCopyText, withCopy, ButtonProps }: ParamProps) {
    const { t } = useTranslation();
    const copy = useCopy();

    const { content, title, root } = classes ?? {};

    const onClickCopy = () =>
        copy(String(copyText ?? value), t(onCopyText ?? 'global.copied'), t('global.error.tryLater'));

    return (
        <p className={cn(styles.Root, root)}>
            <span className={cn(styles.Label, title)}>{label}</span>
            <span className={cn(styles.Value, content)}>
                <b>{value}</b>
                {withCopy && (
                    <Button
                        type="button"
                        variant="text"
                        color="gray"
                        size="small"
                        isRound
                        iconStart={<CopyIcon />}
                        onClick={onClickCopy}
                        classes={{ root: cn(styles.ButtonCopy) }}
                        {...ButtonProps}
                    />
                )}
            </span>
        </p>
    );
}
