import React from 'react';
import cn from 'classnames';
import {
    useFloating,
    useClick,
    useDismiss,
    useRole,
    useListNavigation,
    useInteractions,
    FloatingFocusManager,
    useTypeahead,
    offset,
    flip,
    size,
    autoUpdate,
    FloatingPortal,
    useTransitionStatus,
    FloatingOverlay
} from '@floating-ui/react';
import { OSDetection } from 'utils/featuresDetection/featuresDetection';
import { ChevronDownIcon, ChevronUpIcon } from '../Icons/Icons';
import List, { ListItem } from '../List/List';
import Button from '../Button/Button';
import { DropdownProps, DropdownOption } from './Dropdown.d';
import styles from './Dropdown.module.scss';

function findIndexByValue(arr: DropdownOption[]): number | null {
    const filtered = arr.filter((item) => item.isSelected);

    return filtered.length > 0 ? arr.indexOf(filtered[0]) : null;
}

/** Toggle contextual overlays for displaying lists of links and more. */
export default function Dropdown({
    classes,
    controllerLabel,
    controllerLabelDefault = 'Select...',
    ControllerProps,
    customChevronIcon,
    disabled,
    fullWidth,
    footer,
    header,
    isModal,
    isSelectedIndex = true,
    maxHeight = '40vh',
    maxWidth = false,
    noOptionText = 'No options',
    open,
    onOpenChange,
    onChange,
    options,
    placement = 'bottom-start',
    typeaheadDisable
}: DropdownProps) {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number | null>(() => findIndexByValue(options));

    const { arrow, controller, container, dropdown, list, listItem, overlay } = classes ?? {};

    const { refs, floatingStyles, context } = useFloating({
        placement: placement !== 'auto' ? placement : undefined,
        open,
        onOpenChange,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(12),
            flip({ padding: 16 }),
            size({
                apply({ rects, elements, availableHeight }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: maxHeight !== false ? maxHeight : `${availableHeight}px`,
                        minWidth: `${rects.reference.width}px`,
                        maxWidth: maxWidth === 'reference' ? `${rects.reference.width}px` : maxWidth || undefined
                    });
                },
                padding: 10
            })
        ]
    });

    const listRef = React.useRef<Array<HTMLElement | null>>([]);
    const listContentRef = React.useRef(options.map((item) => item.value));
    const isTypingRef = React.useRef(false);

    const { isMounted, status } = useTransitionStatus(context, { duration: 500 });
    const click = useClick(context, { event: 'mousedown' });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'listbox' });
    const selectedItemLabel =
        selectedIndex !== null ? options[selectedIndex].label ?? String(options[selectedIndex].value) : undefined;

    const listNav = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
        /** This is a large list, allow looping. */
        loop: true
    });

    const typeahead = useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        selectedIndex,
        onMatch: open ? setActiveIndex : setSelectedIndex,
        onTypingChange(isTyping) {
            isTypingRef.current = isTyping;
        },
        enabled: !typeaheadDisable
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        dismiss,
        role,
        listNav,
        typeahead,
        click
    ]);

    const handleSelect = (i: number) => () => {
        if (isSelectedIndex) setSelectedIndex(i);

        onChange?.(options[i].value);
        onOpenChange?.(false);
    };

    const handleKeyDown = (i: number) => (event: React.KeyboardEvent<HTMLLIElement>) => {
        if (event.key === 'Enter' || (event.key === ' ' && !isTypingRef.current)) {
            event.preventDefault();
            handleSelect(i)();
        }
    };
    /** Fixes the auto-scroll bug on iOS when closing the modal. */
    React.useEffect(() => {
        if (!isModal) return;

        const os = OSDetection();

        if (!isMounted || os !== 'iOS') return;

        if (os === 'iOS' && status !== 'unmounted') {
            document.documentElement.style.scrollBehavior = 'auto';
        } else {
            document.documentElement.style.scrollBehavior = 'smooth';
        }
    }, [isModal, isMounted, status]);

    React.useEffect(
        () => () => {
            if (status !== 'unmounted') {
                setSelectedIndex(null);
            }
        },
        [status]
    );

    return (
        <>
            <Button
                classes={{
                    root: cn(styles.Controller, open && styles.Active, disabled && styles.Disabled, controller),
                    iconEnd: cn(styles.ControllerIconEnd, arrow)
                }}
                type="button"
                disabled={disabled}
                fullWidth={fullWidth}
                ref={refs.setReference}
                aria-labelledby="select-label"
                iconEnd={customChevronIcon || (open ? <ChevronUpIcon /> : <ChevronDownIcon />)}
                {...getReferenceProps({
                    onKeyDown: (e) => {
                        if (disabled) {
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    }
                })}
                {...ControllerProps}
            >
                {controllerLabel || selectedItemLabel || <span>{controllerLabelDefault}</span>}
            </Button>
            {isMounted && (
                <FloatingPortal>
                    {isModal && (
                        <FloatingOverlay className={cn(styles.Overlay, overlay)} lockScroll data-status={status} />
                    )}

                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            ref={refs.setFloating}
                            data-status={status}
                            style={{ ...floatingStyles }}
                            className={cn(styles.Dropdown, isModal && styles.DropdownModal, dropdown)}
                            {...getFloatingProps()}
                        >
                            <div className={cn(styles.Container, container)}>
                                {header && <div className={styles.Header}>{header}</div>}

                                <List className={cn(styles.List, list)}>
                                    {options.length ? (
                                        options.map(
                                            (
                                                {
                                                    label: optionLabel,
                                                    value: optionValue,
                                                    disabled: optionDisabled,
                                                    isSelected
                                                },
                                                i
                                            ) => (
                                                <ListItem
                                                    key={optionValue}
                                                    ref={(node) => {
                                                        listRef.current[i] = node;
                                                    }}
                                                    role="option"
                                                    tabIndex={i === activeIndex ? 0 : -1}
                                                    aria-selected={i === activeIndex}
                                                    className={cn(
                                                        styles.ListItem,
                                                        (isSelected || i === selectedIndex) && styles.ListItemSelected,
                                                        i === activeIndex && !optionDisabled && styles.ListItemActive,
                                                        optionDisabled && styles.ListItemDisabled,
                                                        listItem
                                                    )}
                                                    {...getItemProps({
                                                        onClick:
                                                            !optionDisabled && (!isSelected || i !== selectedIndex)
                                                                ? handleSelect(i)
                                                                : undefined,
                                                        onKeyDown:
                                                            !optionDisabled && (!isSelected || i !== selectedIndex)
                                                                ? handleKeyDown(i)
                                                                : undefined
                                                    })}
                                                >
                                                    {optionLabel || String(optionValue)}
                                                </ListItem>
                                            )
                                        )
                                    ) : (
                                        <ListItem
                                            className={cn(styles.ListItem, styles.ListItemDisabled)}
                                            tabIndex={-1}
                                        >
                                            {noOptionText}
                                        </ListItem>
                                    )}
                                </List>

                                {footer && <div className={styles.Footer}>{footer}</div>}
                            </div>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
}
