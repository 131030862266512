export default {
    acquiring: 'Эквайринг',
    balance: 'Баланс',
    created: 'Создан',
    disabled: 'Отключено',
    eos: 'eos',
    email: 'Почта',
    name: 'Название',
    phone: 'Телефон',
    RUBCASH: 'RUBCASH',
    RUBLES: 'RUBLES',
    RUS_PAY: 'Рф',
    USDCASH: 'USDCASH',
    USDT: 'USDT',
    WORLD_PAY: 'Мир',
    currency: {
        name: 'токены'
    }
};
