import React from 'react';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import AuthMobile from 'components/AuthMobile/AuthMobile';
import Auth from 'components/Auth/Auth';

export default function AuthLoginPage() {
    const isTouchableDevice = useIsTouchableDevice();

    return isTouchableDevice ? <AuthMobile /> : <Auth />;
}
