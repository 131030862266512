import React from 'react';
import { UseFormField } from './useFormField.d';

export type UseFormFieldReturn<T = string> = ReturnType<typeof useFormField<T>>;

/**
 * Form field hook.
 *
 * @param {T} initialValue - Initial value of the field.
 * @param {string} initialHelperText - Initial helper text for the field.
 *
 * @returns {UseFormField<T>} Form field utilities and state.
 */
export default function useFormField<T>(initialValue: T, initialHelperText: string = ''): UseFormField<T> {
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(initialHelperText);

    const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null>(null);

    const updateHelperText = React.useCallback(
        (newHelperText?: string) => {
            setHelperText(newHelperText || initialHelperText);
        },
        [initialHelperText]
    );

    const handleChange = React.useCallback(
        (newValue: T, newHelperText?: string) => {
            setError(false);
            setValue(newValue);
            updateHelperText(newHelperText);
        },
        [updateHelperText]
    );

    const handleChangeFormat = React.useCallback(
        (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue: string,
            formatter: (value: string) => string,
            newHelperText?: string
        ) => {
            const formattedValue = formatter(newValue);
            /** Define the cursor offset */
            const cursorPosition = event.target.selectionStart ?? 0;
            /** Count the number of spaces to the current cursor position */
            const numSpacesBeforeCursor = newValue.slice(0, cursorPosition).split(' ').length - 1;
            /** Count the number of spaces after formatting */
            const numSpacesAfterCursor = formattedValue.slice(0, cursorPosition).split(' ').length - 1;
            /** Space difference */
            const spaceDifference = numSpacesAfterCursor - numSpacesBeforeCursor;
            const newCursorPosition = cursorPosition + spaceDifference;

            setError(false);
            updateHelperText(newHelperText);
            setValue(formattedValue as T);
            /** Restore the cursor position */
            window.requestAnimationFrame(() => {
                if (event.target.setSelectionRange) {
                    event.target.setSelectionRange(newCursorPosition, newCursorPosition);
                }
            });
        },
        [updateHelperText]
    );

    const handleErrorChange = React.useCallback(
        (isError: boolean, newHelperText?: string) => {
            setError(isError);
            updateHelperText(newHelperText);
        },
        [updateHelperText]
    );

    return {
        ref,
        value,
        change: handleChange,
        formatChange: handleChangeFormat,
        error,
        errorChange: handleErrorChange,
        helperText,
        helperTextChange: setHelperText
    };
}
