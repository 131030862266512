export interface StoreGenerateKeyProps {
    onBackClick?: (show: boolean) => void;
    onClose?: () => void;
}

export enum Step {
    Initial,
    Password,
    Load,
    Save,
    Attention
}
