import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'apollo/generated';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import getBlockchainData from 'helpers/getBlockchainData/getBlockchainData';
import { CentFilledCircledIcon, CheckFilledCircledIcon, ChevronRightIcon } from 'ui/Icons/Icons';
import Dialog, { DialogBody, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import TokenIcon from '../TokenIcon/TokenIcon';
import PaymentMobileApp from '../PaymentMobileApp/PaymentMobileApp';
import { PaymentMobileAppButton } from '../PaymentMobileApp/PaymentMobileApp.d';
import styles from './PaymentOnlyCrypto.module.scss';

interface PaymentOnlyCryptoProps {
    activePaymentMethod: string | null;
    blockchain?: PaymentMethod;
    blockchains?: (PaymentMethod | null)[] | null;
    onBlockchainChange: (value: string) => void;
    link: string;
    buttons?: Array<PaymentMobileAppButton | null>;
    onShowQr: (isShow: boolean) => void;
}

export default function PaymentOnlyCrypto({
    activePaymentMethod,
    blockchain,
    blockchains,
    onBlockchainChange,
    link,
    buttons,
    onShowQr
}: PaymentOnlyCryptoProps) {
    const { t } = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    const paymentMethodsModal = useDialog();
    const paymentOptionsModal = useDialog();

    const handleClose = () => paymentMethodsModal.setOpen(false);
    const handleOpenClick = () => paymentMethodsModal.setOpen(true);

    const handleChooseClick = (value: string) => () => {
        onBlockchainChange(value);
        paymentMethodsModal.setOpen(false);
    };

    const handleMobileApp = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        show: boolean,
        method: PaymentMethod | null
    ) => {
        e.preventDefault();

        if (method === PaymentMethod.Eos) {
            paymentOptionsModal.setOpen(show);
        } else {
            onShowQr(true);
        }
    };

    return (
        <>
            {blockchains && blockchains?.length > 1 && (
                <Button
                    classes={{ root: styles.BtnRoot }}
                    type="button"
                    size="large"
                    color="secondary"
                    variant="outlined"
                    iconEnd={
                        <div className={styles.BtnIcon}>
                            <span className={styles.BtnSubTitle}>{getBlockchainData(blockchain).standard}</span>
                            <ChevronRightIcon className={styles.BtnSubTitle} />
                        </div>
                    }
                    onClick={handleOpenClick}
                >
                    <div className={styles.BtnIcon}>
                        <TokenIcon name={blockchain} size={24} />
                        <span className={styles.BtnTitle}>{t('payment.details.network')}</span>
                    </div>
                </Button>
            )}

            <Button
                size="large"
                color="primary"
                classes={{ root: styles.SparseButton }}
                iconEnd={<CentFilledCircledIcon />}
                onClick={(e) => handleMobileApp(e, true, blockchain as PaymentMethod)}
            >
                {t('payment.pay')}
            </Button>

            <Dialog
                open={paymentMethodsModal.open}
                onOpenChange={handleClose}
                fullScreen={isLandscapeOrLess}
                transition={isLandscapeOrLess ? 'slide-down' : undefined}
                classes={{ root: styles.ModalRoot, container: styles.ModalContainer }}
            >
                <DialogHeader title={t('payment.details.select')} />
                <DialogBody>
                    {blockchains?.map((item) => (
                        <Button
                            key={item}
                            classes={{ root: cn(styles.BtnRoot, item === blockchain && styles.BtnRootActive) }}
                            type="button"
                            size="large"
                            color="secondary"
                            variant="outlined"
                            iconEnd={
                                item === blockchain ? <CheckFilledCircledIcon className={styles.BtnSelectedIcon} /> : ''
                            }
                            onClick={handleChooseClick(item ?? '')}
                            disabled={item === blockchain}
                        >
                            <div className={styles.BtnIcon}>
                                <TokenIcon name={item ?? ''} size={24} />
                                <span className={styles.BtnTitle}>{getBlockchainData(item ?? '').standard}</span>
                            </div>
                        </Button>
                    ))}
                </DialogBody>
            </Dialog>

            <Dialog
                open={paymentOptionsModal.open}
                onOpenChange={() => paymentOptionsModal.setOpen(false)}
                fullScreen={isLandscapeOrLess}
                transition={isLandscapeOrLess ? 'slide-down' : undefined}
                classes={{ root: styles.ModalRoot, container: styles.ModalContainer }}
            >
                <DialogHeader title={t('payment.paymentMobileApp.title')} />
                <DialogBody>
                    <PaymentMobileApp
                        method={activePaymentMethod}
                        link={link}
                        buttons={buttons || []}
                        onOpen={(e) => handleMobileApp(e, false, blockchain as PaymentMethod)}
                        onShowQr={onShowQr}
                    />
                </DialogBody>
            </Dialog>
        </>
    );
}
