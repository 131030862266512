export default {
    create: {
        title: 'Creating a store',
        name: {
            step: 'Store name',
            description: 'To start accepting payments, you need to create your first store.',
            subtext: 'You can always change these\nparameters in the store settings',
            inputLabel: 'Name',
            inputPlaceholder: 'Come up with a name'
        },
        confirm: {
            step: 'Confirmation',
            description: 'You must enter a password to continue',
            error: {
                requiredFields: 'Enter password',
                InvalidCredentialsError: 'Password invalid'
            }
        },
        key: {
            step: 'API Key',
            description: 'Keep this key for yourself, because you will not be able to view it again',
            label: 'API Key',
            alert: 'Do not give your store key to strangers',
            copied: 'API key copied'
        },
        alert: {
            success: 'Store "{{name}}" created'
        },
        error: {
            requiredFields: 'Enter the name of the store',
            NameAlreadyExistsError: 'The name is already taken',
            ConfirmationFailedError: 'Invalid password'
        }
    },
    settings: {
        title: 'Settings',
        id: 'ID',
        created: 'Created',
        inputLabel: 'Name',
        inputPlaceholder: 'Enter the name of the store',
        alertSuccess: 'The store "{{name}}" was successfully edited',
        alertChangeSuccess: 'Store "{{name}}" selected',
        error: {
            NameAlreadyExistsError: 'The given name is already taken',
            StoreNotFoundError: 'Store not found'
        },
        buttonGenerateKey: 'Generate new API key'
    },
    newKey: {
        title: 'New API key',
        initial: {
            step: 'Generating a new key',
            description: 'Are you sure you want to generate a new key? The old key will be deleted and invalid.',
            alert: 'When a new key is generated, the old key will be deleted and invalid',
            check: "I'm sure I want to generate a new API key",
            buttonGenerateKey: 'Generate new key',
            buttonCancel: 'Keep the old key'
        },
        confirm: {
            step: 'Confirmation',
            description: 'You must enter a password to continue',
            error: {
                requiredFields: 'Enter password',
                InvalidCredentialsError: 'Password invalid'
            }
        },
        loading: {
            text: 'Generating a new\nAPI key'
        },
        save: {
            step: 'Saving',
            description: "Keep this key for yourself because you won't be able to view it again",
            alert: 'Do not give your store key to strangers',
            check: 'I saved the API key',
            label: 'API Key',
            copied: 'API key copied'
        },
        attention: {
            text: 'Are you sure that you have saved the key in a safe place, since after closing this window you will not be able to view the new key?',
            buttonSubmit: 'Yes, saved',
            buttonCancel: 'No, not saved'
        },
        success: 'The key was successfully updated',
        error: {
            StoreNotFoundError: 'Store not found'
        }
    },
    mode: {
        title: 'Operating mode',
        status: 'On',
        status_test: 'Developer mode'
    },
    modeSwitch: {
        title: 'Switch mode',
        description: 'Are you sure you want to switch mode from test to production?',
        description_test:
            'Are you sure you want to switch the mode from working to test? Payment for orders will not work in test mode.',
        alertSuccess: 'Store mode has changed',
        error: {
            NameAlreadyExistsError: 'The given name is already taken',
            StoreNotFoundError: 'Store not found'
        },
        buttonSubmit: 'Yes, switch',
        buttonCancel: 'No, do not toggle'
    },
    noStores: 'No stores',
    buttonCreate: 'Create store',
    buttonContinue: 'Continue',
    buttonDone: 'Done',
    buttonSave: 'Save'
};
