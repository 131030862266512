import React from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { PaymentStatus, useNotifyOrderPaidMutation } from 'apollo/generated';
import roundNumber from 'utils/roundNumber/roundNumber';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';
import { ClockFilledIcon } from 'ui/Icons/Icons';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import styles from './PaymentOrderPaid.module.scss';

export interface PaymentOrderPaidProps {
    requisiteId?: string | null;
    timer?: ReturnType<typeof useCountdownTimer>;
    onTimerStart?: (timeout?: number) => void;
}

export default function PaymentOrderPaid({ timer, requisiteId, onTimerStart }: PaymentOrderPaidProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const [notifyOrderPaidMutation, { loading }] = useNotifyOrderPaidMutation();

    const minutes = parseInt(`${(timer?.timeLeft || 0) / 60}`, 10);
    const seconds = parseInt(`${(timer?.timeLeft || 0) % 60}`, 10);

    const [disabled, setDisabled] = React.useState(false);

    const handleResendClick = () => {
        if (!requisiteId) {
            onGlobalError(t('payment.orderPaid.error.OrderNotFoundError'));

            return;
        }

        notifyOrderPaidMutation({
            variables: { requisiteId },
            context: { headers: { Authorization: '' } }
        })
            .then(({ data }) => {
                const notifyOrderPaid = data?.notifyOrderPaid;

                if (notifyOrderPaid?.__typename === 'CheckTransferSuccess') {
                    const { status } = notifyOrderPaid ?? {};

                    if (status !== PaymentStatus.Paid && status !== PaymentStatus.MoneyReceived) {
                        toast.info(t('payment.orderPaid.error.paidNotFound'), { type: 'info' });
                    } else {
                        toast.success(t('payment.orderPaid.success'));
                    }

                    onTimerStart?.();
                } else if (notifyOrderPaid?.__typename === 'ActionLockedError') {
                    const { cooldown } = notifyOrderPaid ?? {};

                    const cooldownInSeconds =
                        roundNumber((DateTime.fromISO(`${cooldown}Z`).toMillis() - Date.now()) / 1_000) || 1;

                    onGlobalError(t('payment.orderPaid.error.ActionLockedError', { cooldown: cooldownInSeconds }));
                    onTimerStart?.(cooldownInSeconds);
                } else {
                    const { __typename, errorMessage = '' } = notifyOrderPaid ?? {};

                    onGlobalError(t([`payment.orderPaid.error.${__typename}`, errorMessage, 'global.error.tryLater']));
                    onTimerStart?.();
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message))
            .finally(() => {
                /** Disable button for first timeout value. */
                setDisabled(true);
            });
    };

    React.useEffect(() => {
        if ((timer?.timeLeft || 0) === 0) {
            setDisabled(false);
        }
    }, [timer?.timeLeft]);

    return (
        <div className={styles.Root}>
            <Button
                type="button"
                size="large"
                fullWidth
                disabled={disabled || (timer?.timeLeft || 0) > 0}
                onClick={handleResendClick}
                loading={loading}
            >
                {t('payment.orderPaid.submit.button')}
            </Button>

            {(timer?.timeLeft || 0) > 0 && (
                <div className={styles.Timer}>
                    {t('payment.orderPaid.submit.timerText')}
                    <div className={styles.TimerContent}>
                        <ClockFilledIcon className={styles.TimerIcon} />
                        <div className={styles.TimerValue}>
                            {minutes}:{seconds.toString().padStart(2, '0')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
