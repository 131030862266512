import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export const useFormatDate = () => {
    const { i18n } = useTranslation();

    return (date: string) => DateTime.fromISO(`${date}Z`, { zone: 'local' }).setLocale(i18n.language);
};

export const useFormatDataNew = () => (date: string) => {
    const dateTime = new Date(date);
    const isDateNaN = Number.isNaN(dateTime.getDate());

    return !isDateNaN ? DateTime.fromISO(`${date}Z`).toFormat('dd.MM.yy, HH:mm') : '-';
};

export const useMinuteDifference = () => (firstDate: string, secondDate: string) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const isDate1NaN = Number.isNaN(date1.getDate());
    const isDate2NaN = Number.isNaN(date2.getDate());
    const diffInMilliseconds = !isDate1NaN && !isDate2NaN ? Math.abs(date2.getTime() - date1.getTime()) : 0;

    return Math.ceil(diffInMilliseconds / (1000 * 60));
};
