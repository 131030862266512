import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PaymentParams } from 'apollo/generated';
import getPaymentAmount from 'helpers/getPaymentAmount/getPaymentAmount';
import { UseFormFieldReturn } from 'hooks/useFormField/useFormField';
import Preloader from 'ui/Preloader/Preloader';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import styles from './PaymentCardWord.module.scss';

export enum WorldPaySteps {
    Name,
    Pay
}

export interface PaymentRusPayProps {
    loading?: boolean;
    name?: UseFormFieldReturn;
    onNameSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    onPayClick?: (e: React.MouseEvent<HTMLElement>) => void;
    paymentLink?: string;
    paymentParams?: Partial<PaymentParams> | null;
    step?: WorldPaySteps;
}

export default function PaymentCardWord({
    loading,
    name,
    onNameSubmit,
    onPayClick,
    paymentLink,
    paymentParams,
    step = WorldPaySteps.Name
}: PaymentRusPayProps) {
    const { t } = useTranslation();

    const formNameId = React.useId();

    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const { amount, serviceName, infoLink } = paymentParams ?? {};

    const infoAmount = `${getPaymentAmount(amount).amount} ${getPaymentAmount(amount).currencyName}`;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value.replace(/[^A-Za-z\s]/g, '');

        name?.change(formattedValue);
    };

    const handleNameValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const words = value.split(/\s+/);

        if (value && words.length < 2) {
            name?.errorChange(true, t('payment.paymentRusPay.input.error.formatInvalid'));
        }
    };

    React.useEffect(() => {
        if (!loading && inputRef.current) inputRef.current.focus();
    }, [loading]);

    if (loading) {
        return (
            <div className={styles.PreloaderContainer}>
                <Preloader size={40} />
            </div>
        );
    }

    if (serviceName === 'element') {
        return (
            <div className={styles.Root}>
                <div className={styles.Header}>
                    <div className={styles.Info}>
                        <div className={styles.InfoText}>{t('payment.details.toPay')}</div>
                        <div className={styles.InfoAmount}>{infoAmount}</div>
                    </div>
                </div>

                {step === WorldPaySteps.Name && (
                    <div className={styles.Body}>
                        <form id={formNameId} className={styles.Form} onSubmit={onNameSubmit}>
                            <TextField
                                classes={{ root: styles.FormItemMarginTop, input: styles.InputCapitalize }}
                                label={t('payment.paymentRusPay.input.label')}
                                placeholder={t('payment.paymentRusPay.input.placeholder')}
                                value={name?.value}
                                onChange={handleNameChange}
                                onBlur={handleNameValidate}
                                isError={name?.error}
                                helperText={t('payment.paymentWorldPay.input.helperText')}
                                ref={inputRef}
                            />
                        </form>
                    </div>
                )}

                <div className={styles.Footer}>
                    {step === WorldPaySteps.Name && (
                        <Button
                            form={formNameId}
                            classes={{ root: styles.PayButton }}
                            size="large"
                            disabled={name?.error || !name?.value}
                            fullWidth
                        >
                            {t('payment.btnContinue')}
                        </Button>
                    )}

                    {step === WorldPaySteps.Pay && (
                        <>
                            <Button
                                classes={{ root: styles.PayButton }}
                                size="large"
                                disabled={name?.error || !name?.value}
                                fullWidth
                                onClick={onPayClick}
                            >
                                {t('payment.buttonConfirmAndPay')}
                            </Button>

                            <div className={styles.FooterDescription}>
                                <Trans i18nKey="payment.paymentWorldPay.description">
                                    <Link
                                        as="a"
                                        href="https://elm47.com/offer"
                                        target="_blank"
                                        rel="noreferrer"
                                        size="small"
                                    >
                                        .
                                    </Link>
                                    <Link
                                        as="a"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={infoLink ?? '/'}
                                        size="small"
                                        disabled={!infoLink}
                                    >
                                        .
                                    </Link>
                                </Trans>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    if (serviceName === 'zenzero') {
        return (
            <div className={styles.Root}>
                <div className={styles.Header}>
                    <div className={styles.Info}>
                        <div className={styles.InfoText}>{t('payment.details.toPay')}</div>
                        <div className={styles.InfoAmount}>{infoAmount}</div>
                    </div>
                </div>

                <div className={styles.Footer}>
                    <Button
                        as="a"
                        classes={{ root: styles.PayButton }}
                        href={paymentLink}
                        size="large"
                        fullWidth
                        disabled={!paymentLink}
                    >
                        {t('payment.pay')}
                    </Button>

                    <div className={styles.FooterDescription}>
                        {t('payment.paymentWorldPay.description', { context: 'otp' })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.Root}>
            <div className={styles.Container}>
                <div className={styles.Info}>
                    <div className={styles.InfoText}>{t('payment.details.toPay')}</div>
                    <div className={styles.InfoAmount}>{infoAmount}</div>
                </div>
                <div className={styles.Footer}>
                    <Button as="a" href={paymentLink} size="large" fullWidth disabled={!paymentLink}>
                        {t('payment.pay')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
