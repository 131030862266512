import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';
import generated from './generated';
import { getExpirationDelta, OFFSET } from '../helpers/IdleController/IdleController';

export const LOCAL_STORAGE_KEY_TOKEN_AUTH = 'tokenAuth';
export const LOCAL_STORAGE_KEY_IS_ADMIN = 'isAdmin';

const isAuth = Boolean(getExpirationDelta() - OFFSET > 0);
const isAdmin = typeof window !== 'undefined' ? window.localStorage.getItem(LOCAL_STORAGE_KEY_IS_ADMIN) : null;

export const isAuthVar: ReactiveVar<boolean> = makeVar(isAuth);
export const isAdminVar: ReactiveVar<boolean> = makeVar(Boolean(isAdmin));
export const showModalSessionVar: ReactiveVar<boolean> = makeVar(false);
export const errorVar: ReactiveVar<any> = makeVar(null);

export const cache: InMemoryCache = new InMemoryCache({
    ...generated,
    typePolicies: {
        Query: {
            fields: {
                isAuth: {
                    read() {
                        return isAuthVar();
                    }
                },
                isAdmin: {
                    read() {
                        return isAdminVar();
                    }
                },
                showModalSession: {
                    read() {
                        return showModalSessionVar();
                    }
                },
                error: {
                    read() {
                        return errorVar();
                    }
                }
            }
        }
    }
});
