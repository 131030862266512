import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { ExitIcon, ProfileFilledIcon, SocialTelegramFilledIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { Navigation, NavigationMobile, TELEGRAM_LINK } from '../Navigation/Navigation';
import LanguageSwitcher from '../LangaugeSwitcher/LangaugeSwitcher';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import ProfileModal from '../ProfileModal/ProfileModal';
import HeaderStore from '../HeaderStore/HeaderStore';
import Logo from '../Logo/Logo';
import { HeaderProps } from './Header.d';
import styles from './Header.module.scss';

export default function Header({ isLimitedView, LogoProps }: HeaderProps) {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
    const isDesktopOrLess = useMediaQuery((breakpoints) => breakpoints.down.xl);

    const profileModal = useDialog();
    const logoutModal = useDialog();

    return (
        <>
            <header className={styles.Root}>
                <div className={styles.Left}>
                    <Logo isShort {...LogoProps} classes={{ root: styles.Logo }} />
                    {!isLimitedView && !isTabletOrLess && <HeaderStore classes={{ root: styles.StoreRoot }} />}
                </div>

                {!isLimitedView && <Navigation />}
                {!isLimitedView && isTabletOrLess && (
                    <HeaderStore classes={{ root: styles.StoreRoot }} showShopIcon={!isMobileOrLess} />
                )}

                <div className={styles.Right}>
                    <LanguageSwitcher
                        ControllerProps={{
                            isDark: true,
                            variant: 'outlined',
                            color: 'secondary',
                            classes: { root: styles.LanguageRoot }
                        }}
                    />

                    {!isLimitedView && !isTabletOrLess && (
                        <>
                            <Button
                                as="a"
                                href={TELEGRAM_LINK}
                                target="_blank"
                                rel="noreferrer"
                                isDark
                                variant="outlined"
                                color="secondary"
                                iconStart={<SocialTelegramFilledIcon className={styles.TelegramIcon} />}
                            >
                                {!isDesktopOrLess && t('navigation.support')}
                            </Button>

                            {!isLimitedView && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    isDark
                                    onClick={() => profileModal.setOpen(true)}
                                    classes={{ root: styles.ProfileButton }}
                                    iconStart={<ProfileFilledIcon className={styles.ProfileIcon} />}
                                />
                            )}
                        </>
                    )}

                    {isLimitedView && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => profileModal.setOpen(true)}
                            classes={{ root: styles.ProfileButton }}
                            iconStart={<ProfileFilledIcon className={styles.ProfileIcon} />}
                        >
                            {t('navigation.profile')}
                        </Button>
                    )}

                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        onClick={() => logoutModal.setOpen(true)}
                        classes={{ root: styles.LogoutButton }}
                        iconStart={<ExitIcon className={styles.LogoutIcon} />}
                    />
                </div>
            </header>

            {!isLimitedView && <NavigationMobile />}

            <LogoutModal {...logoutModal} />
            <ProfileModal {...profileModal} />
        </>
    );
}
