import React from 'react';
import cn from 'classnames';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useMeQuery } from 'apollo/generated';
import { URLS_MAP } from 'routes/routes';
import { DepositProvider } from 'helpers/DepositProvider/DepositProvider';
import Preloader from 'ui/Preloader/Preloader';
import { CrossIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import Alert from 'ui/Alert/Alert';
import Header from '../Header/Header';
import styles from './Layout.module.scss';

export default function Layout() {
    const { pathname } = useLocation();

    const [showAlert, setShowAlert] = React.useState(false);

    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });
    const { me, settings } = data ?? {};
    const { coreEnabled } = settings ?? {};

    const handleAlertClose = () => setShowAlert(false);

    React.useEffect(() => {
        if (typeof coreEnabled === 'boolean') setShowAlert(!coreEnabled);
    }, [coreEnabled, pathname]);

    if (loading || (data && !me)) {
        return (
            <div className={cn(styles.Preloader)}>
                <Preloader />
            </div>
        );
    }

    return (
        <>
            {showAlert && (
                <Alert
                    classes={{ root: cn(styles.Alert), iconWrapper: cn(styles.AlertIconWrapper) }}
                    variant="filled"
                    type="error"
                    action={
                        <Button
                            iconStart={<CrossIcon />}
                            color="danger"
                            size="small"
                            isRound
                            isDark
                            onClick={handleAlertClose}
                        />
                    }
                >
                    Технические работы. Пополнение и вывод средств временно отключены.
                </Alert>
            )}

            <Header LogoProps={{ disabled: pathname === URLS_MAP.dashboard }} />
            <main className={styles.Main}>
                <DepositProvider>
                    <Outlet context={coreEnabled} />
                </DepositProvider>
            </main>
        </>
    );
}

export function useCoreEnabled() {
    return useOutletContext<boolean>();
}
