export default {
    title: 'Создать заказ',
    title_success: 'Заказ создан',
    description_success: 'Заказ #{{orderNumber}} успешно создан',
    currency: 'Выберите валюту',
    fee: 'Комиссия',
    receive: 'Магазин получит',
    receivePlaceholder: 'Вы получите',
    send: 'К оплате',
    sendPlaceholder: 'Клиент оплатит',
    from: 'От',
    to: 'До',
    fiatWorld: 'Международная банк. карта',
    fiatRus: 'Российский банк',
    method: 'Способы оплаты',
    inputExpirationTime: {
        label: 'Время существования ссылки',
        placeholder: 'Введите время'
    },
    inputDetails: {
        label: 'Описание',
        placeholder: 'Введите описание заказа'
    },
    inputRedirectSuccess: {
        label: 'Редирект при успешной оплате',
        placeholder: 'Введите ссылку'
    },
    inputRedirectFailure: {
        label: 'Редирект при неуспешной оплате',
        placeholder: 'Введите ссылку'
    },
    inputCallback: {
        label: 'Callback ссылка',
        placeholder: 'Введите ссылку'
    },
    inputStoreId: {
        label: 'ID на сайте магазина',
        placeholder: 'Введите значение'
    },
    paymentLink: 'Ссылка на оплату',
    copied: 'Ссылка на заказ скопирована',
    error: {
        amountMin: 'Минимум {{amount}} {{currency}}',
        amountMax: 'Максимум {{amount}} {{currency}}',
        CurrencyNotFoundError: 'Валюта не найдена',
        InvalidParamsError: 'Параметры не валидны ({{paramName}})',
        ServerProblemError: 'Проблема с сервером',
        StoreNotFoundError: 'Магазин не найден',
        amountMinimum: 'Минимальная сумма заказа {{minAmount}}',
        amountTooLow: 'Сумма заказа слишком мала',
        priceRequired: 'Введите сумму заказа',
        storeIdAlreadyExist: 'Такой ID на сайте магазина уже существует. Попробуйте другой.',
        timeMaximum: `Максимальное время существования ссылки на оплату - {{time}} мин`,
        tokenRequired: 'Выберите токен'
    },
    buttonCreate: 'Создать заказ',
    buttonContinue: 'Продолжить',
    buttonDone: 'Готово',
    buttonBack: 'Назад',
    buttonDetails: 'Подробности заказа'
};
