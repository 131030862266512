export default {
    create: {
        title: 'Создание магазина',
        name: {
            step: 'Название магазина',
            description: 'Чтобы начать принимать платежи, необходимо создать первый магазин.',
            subtext: 'Вы всегда сможете изменить данные\nпараметры в настройках магазина',
            inputLabel: 'Название',
            inputPlaceholder: 'Придумайте название'
        },
        confirm: {
            step: 'Подтверждение',
            description: 'Для того, чтобы продолжить, необходимо ввести пароль',
            error: {
                requiredFields: 'Введите пароль',
                InvalidCredentialsError: 'Неверный пароль'
            }
        },
        key: {
            step: 'Ключ API',
            description: 'Сохраните себе данный ключ, потому что вы не сможете его больше посмотреть',
            label: 'Ключ API',
            alert: 'Не передавайте ключ от вашего магазина посторонним лицам',
            copied: 'API ключ скопирован'
        },
        alert: {
            success: 'Магазин "{{name}}" создан'
        },
        error: {
            requiredFields: 'Введите название магазина',
            NameAlreadyExistsError: 'Название уже занято',
            ConfirmationFailedError: 'Неверный пароль'
        }
    },
    settings: {
        title: 'Настройки',
        id: 'ID',
        created: 'Создан',
        inputLabel: 'Название',
        inputPlaceholder: 'Введите название магазина',
        alertSuccess: 'Магазин "{{name}}" успешно отредактирован',
        alertChangeSuccess: 'Выбран магазин "{{name}}"',
        error: {
            NameAlreadyExistsError: 'Данное имя уже занято',
            StoreNotFoundError: 'Магазин не найден'
        },
        buttonGenerateKey: 'Сгенерировать новый API ключ'
    },
    newKey: {
        title: 'Новый API ключ',
        initial: {
            step: 'Генерация нового ключа',
            description: 'Вы уверены, что хотите сгенерировать новый ключ? Старый ключ будет удален и недействителен.',
            alert: 'При генерации нового ключа, старый ключ будет удален и недействителен',
            check: 'Я уверен, что хочу сгенерировать новый API ключ',
            buttonGenerateKey: 'Сгенерировать новый ключ',
            buttonCancel: 'Оставить старый ключ'
        },
        confirm: {
            step: 'Подтверждение',
            description: 'Для того, чтобы продолжить, необходимо ввести пароль',
            error: {
                requiredFields: 'Введите пароль',
                InvalidCredentialsError: 'Неверный пароль'
            }
        },
        loading: {
            text: 'Генерируем новый\nAPI ключ'
        },
        save: {
            step: 'Сохранение',
            description: 'Сохраните себе данный ключ, потому что вы не сможете его больше посмотреть',
            alert: 'Не передавайте ключ от вашего магазина посторонним лицам',
            check: 'Я сохранил API ключ',
            label: 'Ключ API',
            copied: 'API ключ скопирован'
        },
        attention: {
            text: 'Вы уверены, что сохранили ключ в надежном месте, так как после закрытия данного окна вы не сможете посмотреть новый ключ?',
            buttonSubmit: 'Да, сохранил',
            buttonCancel: 'Нет, не сохранил'
        },
        success: 'Ключ был успешно обновлен',
        error: {
            StoreNotFoundError: 'Магазин не найден'
        }
    },
    mode: {
        title: 'Рабочий режим',
        status: 'Включен',
        status_test: 'Режим разработчика'
    },
    modeSwitch: {
        title: 'Переключить режим',
        description: 'Вы уверены что хотите переключить режим с тестового на рабочий?',
        description_test:
            'Вы уверены что хотите переключить режим с рабочего на тестовый? В тестовом режиме не будет работать оплата заказов.',
        alertSuccess: 'Режим работы магазина изменен',
        error: {
            NameAlreadyExistsError: 'Данное имя уже занято',
            StoreNotFoundError: 'Магазин не найден'
        },
        buttonSubmit: 'Да, переключить',
        buttonCancel: 'Нет, не переключать'
    },
    noStores: 'Магазинов нет',
    buttonCreate: 'Создать магазин',
    buttonContinue: 'Продолжить',
    buttonDone: 'Готово',
    buttonSave: 'Сохранить'
};
