import React from 'react';
import { CheckFilledCircledIcon } from 'ui/Icons/Icons';
import { DropdownOption } from 'ui/Dropdown/Dropdown.d';

/**
 * Sets the 'isSelected' property in an array of DropdownOption based on the 'selected' value.
 *
 * @param {DropdownOption[]} options - An array of DropdownOption.
 * @param {string | undefined} selected - The value to match for selecting options.
 *
 * @returns {DropdownOption[]} - An array of DropdownOption with the 'isSelected' property updated.
 */
export const setChecked = (options: DropdownOption[], selected?: string): DropdownOption[] =>
    options.map((option) => ({
        ...option,
        isSelected: option.value === selected
    }));

/**
 * Set a checked mark icon for the selected option in a dropdown menu.
 *
 * @param {DropdownOption[]} options - An array of dropdown options.
 * @param {string|undefined} selected - The value of the selected option.
 *
 * @returns {DropdownOption[]} An updated array of options with checked mark icons.
 */
export const setCheckedMark = (options: DropdownOption[], selected?: string): DropdownOption[] =>
    options.map((option) => ({
        ...option,
        label: (
            <span style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', paddingRight: '2rem' }}>
                {option.label}
                {option.value === selected && (
                    <CheckFilledCircledIcon
                        fontSize={16}
                        style={{
                            color: 'var(--css-primary)',
                            position: 'absolute',
                            right: '1rem',
                            top: 0,
                            bottom: 0,
                            margin: 'auto'
                        }}
                    />
                )}
            </span>
        ),
        isSelected: option.isSelected || option.value === selected
    }));
