import React from 'react';
import cn from 'classnames';
import { useDialog } from 'ui/Dialog/Dialog';
import { CheckIcon, TagCloseIcon } from 'ui/Icons/Icons';
import Switch from 'ui/Switch/Switch';
import StoreModeSwitchModal from '../StoreModeSwitchModal/StoreModeSwitchModal';
import { StoreModeSwitchButtonProps } from './StoreModeSwitchButton.d';
import styles from './StoreModeSwitchButton.module.scss';

function ModeSwitchIcon({ isChecked }: Required<Pick<StoreModeSwitchButtonProps, 'isChecked'>>) {
    return isChecked ? (
        <CheckIcon className={cn(styles.IconStatus, styles.IconStatusOn)} />
    ) : (
        <TagCloseIcon className={cn(styles.IconStatus, styles.IconStatusOff)} />
    );
}

export default function StoreModeSwitchButton({
    id,
    title,
    isChecked,
    onChange,
    status,
    icon,
    disabled,
    classes
}: StoreModeSwitchButtonProps) {
    const modeSwitcherModal = useDialog();

    const { root } = classes ?? {};

    const handleChange = () => {
        if (onChange) onChange();

        modeSwitcherModal.context.dataRef.current = { storeId: id, isNextTestMode: Boolean(isChecked) };
        modeSwitcherModal.setOpen(true);
    };

    return (
        <>
            <div className={cn(styles.Root, disabled && styles.Disabled, root)}>
                {icon ? (
                    <div className={styles.IconContainer}>{icon}</div>
                ) : (
                    <div className={styles.IconContainer}>
                        <ModeSwitchIcon isChecked={Boolean(isChecked)} />
                    </div>
                )}
                <div className={styles.Content}>
                    <div className={styles.Title}>{title}</div>
                    <div className={styles.Status}>{status}</div>
                </div>
                <div className={styles.Action}>
                    <Switch
                        checked={Boolean(isChecked)}
                        onChange={handleChange}
                        disabled={disabled}
                        style={{ opacity: disabled ? 0.3 : 1 }}
                    />
                </div>
            </div>

            <StoreModeSwitchModal {...modeSwitcherModal} />
        </>
    );
}
