import notify from './notify';
import acquiring from './acquiring';
import auth from './auth';
import profile from './profile';
import store from './store';
import dashboard from './dashboard';
import deposit from './deposit';
import transfer from './transfer';
import order from './order';
import developers from './developers';
import transactions from './transactions';
import payment from './payment';

export default {
    global: {
        copied: 'Данные скопированы',
        units: {
            hours: 'час',
            hours_short: 'ч.',
            minutes: 'мин',
            minutes_short: 'м.'
        },
        button: {
            refresh: 'Обновить',
            reset: 'Сбросить'
        },
        error: {
            default: 'Ошибка',
            tryLater: 'Произошла ошибка. Попробуйте позже',
            tryMore: 'Произошла непредвиденная ошибка.\nПопробуйте еще раз.',
            loadingError: 'При загрузке данных произошла ошибка.\nПопробуйте обновить страницу.'
        }
    },
    navigation: {
        dashboard: 'Дашборд',
        transactions: 'Транзакции',
        forDevelopers: 'Для разработчиков',
        profile: 'Профиль',
        settings: 'Настройки',
        support: 'Поддержка',
        more: 'Еще',
        storeSettings: 'Настройки магазина'
    },
    notify,
    acquiring,
    auth,
    profile,
    store,
    deposit,
    transfer,
    order,
    dashboard,
    transactions,
    developers,
    payment,
    underConstruction: {
        title: 'В разработке',
        description: 'Данный раздел находится\nв разработке'
    },
    pageError: {
        code: 'Ошибка {{ code}}',
        title_500: 'Внутренняя\nошибка сервера',
        title_404: 'Страница\nне найдена',
        description_500:
            'На сервере произошла непредвиденная\nошибка. Пожалуйста, подождите: она\nвскоре будет исправлена.',
        description_404: 'Вероятно, страница не существует\nили вы ошиблись, когда вводили\nадрес в строке браузера',
        btn: 'Вернуться на главную'
    }
};
