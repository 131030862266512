import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useConnectByWalletMutation, useConnectByWalletWsSubscription, WalletName } from 'apollo/generated';
import KalynaIcon from 'assets/images/app-kalyna.svg';
import MalinkaIcon from 'assets/images/app-malinka.svg';
import PaycashIcon from 'assets/images/app-paycash.svg';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useAuth from 'hooks/useAuth/useAuth';
import { QrIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import Button from 'ui/Button/Button';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import modalStyles from 'ui/Dialog/Dialog.module.scss';
import AuthHeader from '../AuthHeader/AuthHeader';
import { View } from '../AuthLogin/AuthLogin.d';
import { AuthAppProps } from './AuthApp.d';
import styles from './AuthApp.module.scss';

export default function AuthApp({ onChangeView, HeaderProps }: AuthAppProps) {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const { login } = useAuth();

    const [verificationCode, setVerificationCode] = React.useState('');
    const [error, setError] = React.useState('');

    const handleAuthError = (err: string) => setError(err);

    const handleAuthSuccess = (token?: string | null) => {
        if (token) {
            login(token);
        } else {
            setError('Empty token');
        }
    };

    const [connectByWalletMutation, { loading }] = useConnectByWalletMutation();

    /** After generating QR code and verificationCode awaiting response from server */
    useConnectByWalletWsSubscription({
        skip: !verificationCode,
        variables: { verificationCode },
        onData: ({ data: { data } }) => {
            const connectByWallet = data?.connectByWallet;

            if (connectByWallet?.__typename === 'WalletConnectionSuccess') {
                const { token = '' } = connectByWallet;

                handleAuthSuccess(token);
            } else {
                const errorMessage = connectByWallet?.errorMessage ?? '';

                handleAuthError(errorMessage);
            }
        }
    });

    /** Generate QR code and verificationCode for selected `wallet` type */
    const connectByWallet = React.useCallback(
        async (wallet: WalletName) => {
            try {
                handleAuthError('');

                const { data } = await connectByWalletMutation({
                    variables: { input: { walletName: wallet } }
                });

                const connectByWalletData = data?.connectByWallet;

                if (connectByWalletData?.__typename === 'DeeplinkNotGeneratedError') {
                    handleAuthError(connectByWalletData.errorMessage);
                }

                if (connectByWalletData?.__typename !== 'ConnectByWalletSuccess') return undefined;

                return connectByWalletData;
            } catch (err) {
                handleAuthError(`${err}`);

                return undefined;
            }
        },
        [connectByWalletMutation]
    );

    const handleClick = (wallet: WalletName) => async () => {
        const data = await connectByWallet(wallet);

        const { verificationCode: code = '', deepLink = '' } =
            data?.__typename === 'ConnectByWalletSuccess' ? data : {};

        if (deepLink) {
            setVerificationCode(code);
            window.location.href = deepLink;
        }
    };

    return (
        <>
            {!isMobileOrLess && (
                <div className={styles.HeaderWrapper}>
                    <AuthHeader className={styles.Header} />
                </div>
            )}
            <div
                className={cn(
                    modalStyles.Wrapper,
                    isMobileOrLess && modalStyles.WrapperFullScreen,
                    !isMobileOrLess && modalStyles['WrapperScroll-body'],
                    styles.DialogWrapper
                )}
            >
                <div
                    className={cn(
                        modalStyles.Container,
                        !isMobileOrLess && modalStyles['MaxWidth-sm'],
                        !isMobileOrLess && modalStyles.ContainerFullWidth,
                        isMobileOrLess && modalStyles.ContainerFullScreen
                    )}
                >
                    <DialogHeader classes={{ root: modalStyles.Header }} hideCloseButton {...HeaderProps} />
                    <DialogBody className={cn(modalStyles.Body, styles.DialogBody)}>
                        <div className={styles.DialogBodyContent}>
                            <h4>{t('auth.app.title')}</h4>
                            <div className={styles.Description}>{t('auth.app.description')}</div>
                        </div>
                    </DialogBody>
                    <DialogFooter className={modalStyles.Footer}>
                        {error && <div className={styles.Error}>{`${t('global.error.default')}. ${error}`}</div>}
                        {loading && <Preloader classes={{ root: styles.Preloader }} />}
                        {!error && !loading && (
                            <>
                                <Button
                                    as="a"
                                    classes={{ root: styles.AppButton }}
                                    color="gray"
                                    href="#"
                                    size="large"
                                    iconStart={<img src={PaycashIcon} alt="PayCash" />}
                                    onClick={handleClick(WalletName.Paycash)}
                                    fullWidth
                                >
                                    Paycash
                                </Button>
                                <Button
                                    as="a"
                                    classes={{ root: styles.AppButton }}
                                    color="gray"
                                    href="#"
                                    size="large"
                                    iconStart={<img src={KalynaIcon} alt="Kalyna" />}
                                    onClick={handleClick(WalletName.Kalyna)}
                                    fullWidth
                                >
                                    Kalyna
                                </Button>
                                <Button
                                    as="a"
                                    classes={{ root: styles.AppButton }}
                                    color="gray"
                                    href="#"
                                    size="large"
                                    iconStart={<img src={MalinkaIcon} alt="Malinka" />}
                                    onClick={handleClick(WalletName.Malinka)}
                                    fullWidth
                                >
                                    Malinka
                                </Button>
                            </>
                        )}
                        <div className={styles.Divider}>
                            <div className={styles.DividerText}>{t('auth.app.divider')}</div>
                        </div>
                        <div className={styles.GroupButton}>
                            <Button
                                classes={{ root: styles.GroupButtonItem }}
                                color="secondary"
                                size="large"
                                onClick={() => onChangeView?.(View.Login)}
                                variant="outlined"
                                fullWidth
                            >
                                {t('auth.buttonLogin')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                classes={{ root: styles.GroupButtonItem }}
                                size="large"
                                iconStart={<QrIcon />}
                                onClick={() => onChangeView?.(View.Qr)}
                                aria-label="QR code"
                            />
                        </div>
                    </DialogFooter>
                </div>
            </div>
        </>
    );
}
