export default {
    title: 'Transactions',
    search: 'Search',
    downloadReport: 'Download report',
    downloadReportError: 'Failed to generate report',
    sort: 'Sort',
    sortType: {
        CREATED: 'Created from old to new',
        CREATED_DESC: 'Created from new to old',
        PAYMENT_DATE_DESC: 'Paid from new to old',
        PAYMENT_DATE: 'Paid from old to new',
        FULL_AMOUNT_DESC: 'By decreasing amount',
        FULL_AMOUNT: 'By ascending amount',
        FEE_DESC: 'By decreasing fee',
        FEE: 'By ascending fee',
        SEQUENT_NUMBER_DESC: 'Order number from new to old',
        SEQUENT_NUMBER: 'Order number from old to new',
        BALANCE_RUBCASH: 'By ascending balance RUBCASH',
        BALANCE_RUBCASH_DESC: 'By decreasing balance RUBCASH',
        BALANCE_USDCASH: 'By ascending balance USDCASH',
        BALANCE_USDCASH_DESC: 'By decreasing balance USDCASH',
        BALANCE_USDT: 'By ascending balance USDT',
        BALANCE_USDT_DESC: 'By decreasing balance USDT'
    },
    tabs: {
        deposit: 'Deposit',
        withdraw: 'Withdraw'
    },
    tableHeader: {
        accountCurrency: 'Account currency',
        accountAmount: 'Account',
        amountPayment: 'Payment amount',
        amount: 'Amount',
        count: 'Count',
        createdDate: 'Created',
        commission: 'Commission',
        commissionPayments: 'Payment fee',
        debitAmount: 'Write-off amount',
        debitCurrency: 'Write-off currency',
        debitedPayments: 'Charged from the payment',
        entered: 'Enrolled',
        number: 'Number',
        paycheck: 'Payment system',
        paymentDate: 'Payment time',
        paymentCurrency: 'Payment currency',
        receive: 'Received',
        receivingCurrency: 'Receiving currency',
        status: 'Status',
        storeName: 'Store name',
        type: 'Type',
        token: 'Token',
        network: 'Network',
        paymentMethod: 'Payment method',
        user: 'User',
        store: 'Store',
        and: 'and'
    },
    type: {
        title: 'Order type',
        name: 'types',
        CLIENT_WITHDRAW: 'Transfer',
        DEPOSIT: 'Deposit',
        ORDER_PAYMENT: 'Order',
        PAYBACK: 'Refund',
        TEST_ORDER_PAYMENT: 'Test order',
        UNKNOWN: 'Unknown',
        WITHDRAW: 'Withdrawal',
        ADMIN_DEPOSIT: 'Deposit',
        ADMIN_WITHDRAW: 'Withdrawal',
        GIVE: 'Internal transfer',
        TAKE: 'Internal transfer'
    },
    payback: 'Refund',
    status: {
        name: 'statuses',
        ACCEPTED: 'Paid',
        CANCELLED: 'Cancelled',
        CREATED: 'Waiting for payment',
        DECLINED: 'Declined',
        EXPIRED: 'Waiting time expired',
        FAILED: 'Error',
        FROZEN: 'Frozen',
        INITIATED: 'Pending',
        PENDING: 'Sending',
        RECEIVED: 'Received',
        RETRY: 'In progress',
        SENDED: 'Sent',
        MONEY_RECEIVED: 'Received',
        PAID: 'Paid'
    },
    filters: {
        title: 'Filters',
        selectDate: 'Select date',
        allTypes: 'All types',
        status: 'Status',
        allStatuses: 'All statuses',
        allCurrencies: 'All currencies',
        allPayments: 'All payments',
        apply: 'Apply',
        reset: 'Reset',
        commission: 'Commission',
        amountPayment: 'Payment amount',
        chargedToPayment: 'Debited from payment system',
        paymentCurrency: 'Payment currency',
        receivingCurrency: 'Receipt currency',
        debitCurrency: 'Write-off currency',
        paycheck: 'Payment system',
        from: 'From',
        to: 'To'
    },
    dataNotAvailable: 'Data not available',
    notAvailable: 'Transactions are not available',
    notAvailableDescription: 'You do not have any orders in this store yet',
    error: 'Error',
    nothingFound: 'Nothing found',
    nothingFoundDescription: 'We could not find anything for your request. Check the spelling and try again',
    paymentDate: 'Payment time',
    created: 'Creation time',
    bill: 'Account',
    enrolled: 'Enrolled',
    writeOffAmount: 'Write-off amount',
    feeForPayment: 'Payment commission',
    accountCurrency: 'Account currency',
    orderInfo: {
        error: {
            RetryTransferSuccess: 'Transaction successfully retried',
            WrongTransferStatusError: 'Wrong transaction status',
            WrongTransferTypeError: 'Wrong transaction type',
            TransferNotFoundError: 'Transaction not found',
            ConfirmationFailedError: 'Wrong password entered',
            AbortTransferSuccess: 'Transaction successfully canceled',
            FailTransferSuccess: 'Transaction successfully changed to FAILED status',
            FinalizeTransferSuccess: 'Transaction successfully confirmed'
        },
        orderType: {
            name: 'types',
            CLIENT_WITHDRAW: 'Transfer',
            DEPOSIT: 'Deposit',
            ORDER_PAYMENT: 'Order',
            PAYBACK: 'Refund',
            TEST_ORDER_PAYMENT: 'Test order',
            UNKNOWN: 'Unknown',
            WITHDRAW: 'Withdrawal',
            ADMIN_DEPOSIT: 'Deposit',
            ADMIN_WITHDRAW: 'Withdrawal',
            GIVE: 'Internal transfer',
            TAKE: 'Internal transfer'
        },
        changeStatus: {
            title: 'Are you sure you want to change the transaction status to FAILED?',
            description:
                'The FAILED status allows the transaction to have been completed. Check the availability of this transaction in the payment system.',
            confirmation: 'Confirmation',
            type: 'Are you sure you want to cancel the transaction?',
            type_ABORT: 'Are you sure you want to cancel the transaction?',
            type_RETRY: 'Are you sure you want to retry the transaction?',
            type_CONFIRM: 'Are you sure you want to confirm the transaction?',
            confirm: 'Confirm'
        },
        info: 'Information',
        repeat: 'Repeat',
        cancel: 'Cancel',
        cancellation: 'Cancellation',
        confirm: 'Confirm',
        commission: 'Commission',
        entered: 'Enrolled',
        network: 'Network',
        store: 'Store',
        minutes: 'Min.',
        paymentCurrency: 'Payment currency',
        amountPayment: 'Payment amount',
        paycheck: 'Payment system',
        update: 'Update',
        print: 'Print',
        timeOfOrderCreation: 'Order creation time',
        paymentTime: 'Order payment time',
        transactionId: 'Transaction ID',
        paymentSystemId: 'Payment system ID',
        sender: 'Sender',
        receiver: 'Receiver',
        receive: 'Received',
        errorReason: 'Error reason',
        description: 'Description',
        redirectSuccess: 'Redirect on successful payment',
        redirectFailure: 'Redirect on failed payment',
        orderNumber: 'Order number',
        expirationsTime: 'Order expiration time',
        existenceTime: 'Link existence time',
        storeId: 'Store ID on the website',
        internalId: 'Internal ID',
        callbackLink: 'Callback link',
        fundsReceived: 'Funds received',
        orderAmount: 'Order amount',
        paymentToken: 'Payment token',
        paymentLink: 'Payment link',
        senderAccount: "Payer's account",
        receiverAccount: "Recipient's account",
        blockchainLink: 'Blockchain link'
    },
    cancelOrder: {
        title: 'Cancellation',
        description: 'Are you sure you want to cancel this order?',
        btnSubmit: 'Cancel order',
        btnCancel: 'Do not cancel',
        error: {
            CancelOrderSuccess: 'Order canceled.',
            OrderNotFoundError: 'Order not found.',
            OrderPaidError: 'Order is already paid.',
            OrderInactiveError: 'Order is no longer active.'
        }
    },
    refund: {
        error: {
            PaybackOrderSuccess: 'Refund successful',
            InsufficientFundsError: 'Insufficient funds',
            OrderNotFoundError: 'Order not found',
            InvalidParamsError: 'Invalid parameters',
            OrderNotPaidError: 'Order not paid',
            CurrencyNotFoundError: 'Currency not found',
            OrderNotRefundableError: 'Order not refundable',
            amountTooLow: 'Amount too low for refund',
            amountTooLarge: 'Refund amount cannot exceed order value',
            amountPaymentTooLarge: 'Amount cannot exceed amount paid by customer'
        },
        account: 'Account',
        title_intro: 'Refund',
        title_refundIntroAttention: 'Refund',
        title_agreement: 'Refund',
        title_hasNoBalance: 'Insufficient funds',
        description_intro: 'You want to make a full refund for order <0>№{{orderNumber}}</0>',
        description_refundIntroAttention:
            'You have already refunded <0>{{amount}}</0> for order <0>№{{orderNumber}}</0>',
        description_refundIntroInfo: 'You have already refunded part of the amount for order <0>№{{orderNumber}}</0>',
        description_refundAllInfo: 'You have made a full refund for order <0>№{{orderNumber}}</0>',
        description_agreement:
            'Are you sure you want to refund the amount for this order? The operation cannot be canceled.',
        description_hasNoBalance: 'Insufficient balance for refund.',
        missingBalance: 'Missing amount',
        successMsg: 'Refund successful',
        receiver: 'Recipient account',
        fee: 'Commission',
        received: 'Received',
        paid: 'Paid',
        youReturned: 'You refunded',
        toBeReturned: 'To be refunded',
        youWillPay: 'Amount to be deducted from your account',
        clientReceive: 'Client will receive',
        clientReceived: 'Client received',
        btnSubmit_intro: 'Continue',
        btnSubmit_agreement: 'Make a refund',
        btnSubmit_hasNoBalance: 'Top up your balance',
        btnCancel: 'Cancel',
        btnChangeAmount: 'Change refund amount',
        btnReturnAllSum: 'Return full amount',
        debitAmount: 'Debit amount',
        willBeReceived: 'Will be received'
    },
    newOrder: 'New order',
    update: 'Update',
    cancelOrderError: 'Order ID not found',
    loadingFailed: 'An error occurred while loading.\nPlease try refreshing the page.',
    transactionsNotAvailableDescription: "You don't have any orders in this store yet",
    transactionsNotAvailable: 'No transactions'
};
