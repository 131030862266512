/**
 * Validates a password based on various criteria.
 *
 * @param {string} password - The password to be validated (default is an empty string).
 *
 * @returns {{hasSpecial: boolean, isValid: boolean, length: boolean, hasNumber: boolean, hasUppercase: boolean}}
 *
 * @description
 * An object containing validation results:
 * - {boolean} length - Indicates if the password has a length of at least 8 characters.
 * - {boolean} hasUppercase - Indicates if the password contains at least one uppercase letter.
 * - {boolean} hasNumber - Indicates if the password contains at least one numeric digit.
 * - {boolean} hasSpecial - Indicates if the password contains at least one special character.
 * - {boolean} isValid - Indicates if the password meets all validation criteria (length, uppercase, number, special).
 */
export default function getPasswordValidate(password: string = ''): {
    hasSpecial: boolean;
    isValid: boolean;
    length: boolean;
    hasNumber: boolean;
    hasUppercase: boolean;
} {
    const length = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*()—_+=;:,./?\\|`~[\]{}]/.test(password);

    return {
        length,
        hasUppercase,
        hasNumber,
        hasSpecial,
        isValid: length && hasUppercase && hasNumber && hasSpecial
    };
}
