import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import {
    CombinedStatusEnum,
    CombinedTypeEnum,
    TransferSortEnum,
    useAllTransfersQuery,
    useMeQuery
} from 'apollo/generated';
import Preloader from 'ui/Preloader/Preloader';
import { useToast } from 'ui/Toast/Toast';
import AdminHeader from '../AdminHeader/AdminHeader';
import { Permissions } from './AdminLayout.d';
import styles from './AdminLayout.module.scss';

export const LOCAL_STORAGE_TRANSACTIONS_FAILED_TIMESTAMP = 'transactions_failed_timestamp';
export const LOCAL_STORAGE_TRANSACTIONS_DECLINED_TIMESTAMP = 'transactions_declined_timestamp';

export default function AdminLayout() {
    const { i18n } = useTranslation();
    const toast = useToast();

    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });

    const permissions = data?.me?.permissions;
    const permissionList = permissions?.map((item) => item?.name);

    const can = React.useMemo(
        () => ({
            seeDashboard: permissionList?.includes(Permissions.CAN_VIEW_DASHBOARD),
            seeBalancesAndResources: permissionList?.includes(Permissions.CAN_VIEW_BALANCES_AND_RESOURCES),
            deposit: permissionList?.includes(Permissions.CAN_DEPOSIT),
            withdraw: permissionList?.includes(Permissions.CAN_WITHDRAW),
            toggleCurrencies: permissionList?.includes(Permissions.CAN_TOGGLE_CURRENCIES),
            editCommissions: permissionList?.includes(Permissions.CAN_EDIT_COMMISSIONS),
            editLimits: permissionList?.includes(Permissions.CAN_EDIT_LIMITS),
            shutDown: permissionList?.includes(Permissions.CAN_SHUT_DOWN),
            seeTransactions: permissionList?.includes(Permissions.CAN_VIEW_TRANSACTIONS),
            seeUserInfo: permissionList?.includes(Permissions.CAN_VIEW_USER_INFO),
            seeShopInfo: permissionList?.includes(Permissions.CAN_VIEW_SHOP_INFO),
            editAdmins: permissionList?.includes(Permissions.CAN_EDIT_ADMINS),
            seeAdmins: permissionList?.includes(Permissions.CAN_VIEW_ADMINS),
            addAdmins: permissionList?.includes(Permissions.CAN_ADD_ADMINS),
            deleteAdmins: permissionList?.includes(Permissions.CAN_DELETE_ADMINS),
            createReport: permissionList?.includes(Permissions.CAN_CREATE_REPORT),
            toggleFiat: permissionList?.includes(Permissions.CAN_TOGGLE_FIAT),
            editRates: permissionList?.includes(Permissions.CAN_EDIT_RATES),
            allowRestartOrAbortTransfers: permissionList?.includes(Permissions.CAN_RESTART_OR_ABORT_TRANSFERS),
            canFailTransfers: permissionList?.includes(Permissions.CAN_FAIL_TRANSFERS)
        }),
        [permissionList]
    );

    const [failedTransactions, setFailedTransactions] = React.useState(0);

    const { fetchMore } = useAllTransfersQuery({
        variables: {
            offset: 0,
            first: 0,
            filters: {},
            sort: [TransferSortEnum.CreatedDesc]
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleChangeLanguageError = React.useCallback(
        (err: string) => {
            toast.error(err);
        },
        [toast]
    );

    const getFailedTransactionsNumber = React.useCallback(async () => {
        const currentTimestamp = localStorage.getItem(LOCAL_STORAGE_TRANSACTIONS_FAILED_TIMESTAMP);

        const failedTransactionsVariables = {
            offset: 0,
            first: 0,
            filters: {
                createdDate: {
                    from: currentTimestamp ? new Date(+currentTimestamp) : null,
                    to: new Date()
                },
                status: [CombinedStatusEnum.Declined, CombinedStatusEnum.Failed],
                type: [
                    CombinedTypeEnum.OrderPayment,
                    CombinedTypeEnum.TestOrderPayment,
                    CombinedTypeEnum.Deposit,
                    CombinedTypeEnum.AdminDeposit,
                    CombinedTypeEnum.Payback,
                    CombinedTypeEnum.Withdraw,
                    CombinedTypeEnum.ClientWithdraw,
                    CombinedTypeEnum.AdminWithdraw,
                    CombinedTypeEnum.Give,
                    CombinedTypeEnum.Take
                ]
            },
            sort: [TransferSortEnum.CreatedDesc]
        };

        const { data: d } = await fetchMore({ variables: failedTransactionsVariables });

        const totalFailed = d?.allTransfers?.pageInfo?.totalFiltered || 0;
        setFailedTransactions(totalFailed);
    }, [fetchMore]);

    React.useEffect(() => {
        const newTransfersTimer = setInterval(() => {
            getFailedTransactionsNumber()
                .then(() => {})
                .catch(() => {});
        }, 10_000);
        return () => clearInterval(newTransfersTimer);
    }, [getFailedTransactionsNumber]);

    React.useEffect(() => {
        (async () => {
            try {
                if (i18n.language !== 'ru') {
                    await i18n.changeLanguage('ru');
                }
            } catch (err) {
                handleChangeLanguageError(`Произошла ошибка при переключении языка. ${String(err)}`);
            }
        })();
    }, [handleChangeLanguageError, i18n]);

    if (loading || (data && !data.me)) {
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );
    }

    return (
        <>
            <AdminHeader
                showTransactions={can.seeTransactions}
                showDashboard={can.seeDashboard}
                showAdmins={can.seeAdmins}
                seeShopInfo={can.seeShopInfo}
                failedTransactions={failedTransactions}
            />
            <main>
                <Outlet context={can} />
            </main>
        </>
    );
}
