export default {
    title: 'For developers',

    tabs: {
        docs: 'Documentation',
        integrations: 'List of integrations'
    },

    sections: {
        workingWithApi: 'Working with API',
        integrations: 'Integrations'
    },

    documentation: {
        title: 'Documentation',

        btnBack: 'Go to the previous section',
        btnNext: 'Go to the next section',

        begin: {
            title: 'Beginning',
            title2: 'Getting started',

            content: [
                "To start working with the PayCash for store API, you will need to use the store's secret key, which was obtained when creating the store.",
                'If you have not saved the key, you can regenerate it in the section <1> Stores > API key > Add.menu > Generate a new API key</1>.',
                'The received secret key must be stored in a safe place - it will be used to confirm requests.',
                'All requests are sent to the url <0>https://api.pc4.store/</0>'
            ]
        },

        auth: {
            title: 'Authorization',

            content: [
                'All requests must include an authorization header of the form <0>BasicAuth (store_id, store_secret_key)</0>',
                'Example <1>curl https://api.pc4.store/v1/create  -u store_id:store_secret_key</1>',
                'If there is no authorization header, you will receive a <0>JSON response with the status 401</0>',
                'If the store with this id is not found or the key is incorrect <0>JSON response with the status 403</0>'
            ]
        },

        createOrder: {
            title: 'Creating an order',
            description: 'To create an order, the client must make a post request to the url <0>/v1/create</0>',

            table: {
                headers: ['Parameter', 'description', 'example'],

                description: {
                    amount_to_pay: 'The order amount in cash tokens (as a string)',
                    currency_name: 'Token',
                    currency_smart_contract:
                        'The smart contract in which the token works (you can find all supported currencies at the bottom of the page))',
                    response_url:
                        'The URL to which you will receive a response about a successful or unsuccessful payment',
                    merchant_order_id: 'Unique identifier of the order in the store',
                    description: 'Description of the order. It will be displayed on the payment page',
                    expiration_timeout:
                        'Time after which the order will become invalid in minutes. By default, 60 minutes',
                    success_payment_redirect_url:
                        'URL to which the user will be redirected from the payment page in case of successful payment of the order',
                    failed_payment_redirect_url:
                        'URL to which the user will be redirected from the payment page in case of cancellation of the order or expiration of the payment period',
                    allowed_methods:
                        'A list of possible payment methods. If payment by bank card is disabled for the seller, these methods will be ignored. If the order currency is USDT, the user will be able to pay USDT via any blockchain from the list'
                }
            },
            response: {
                title: 'Client receives an immediate response depending on the result',
                description_success: 'In case of successful order creation:',
                description_failure: 'In case of unsuccessful order creation:',
                example: 'see the section "Order Information".'
            },

            statuses: {
                title: 'Possible response statuses ',
                OK: 'Order has been successfully created',
                ERROR: 'Invalid request',
                description:
                    'In case of sending an incorrect authorization token to the request, you will receive <0>https://api.pc4.store/</0>',
                orders: 'If order creation is not available, you will receive a <0>JSON response with the status 503</0>',
                validation:
                    'In case of a request validation error, you will receive a <0>JSON response with the status 400</0>',
                server: 'In case of an internal server error, you will receive a <0>JSON response with the status 500</0>'
            },

            currencies: {
                title: 'List of supported currencies',
                smartContract: 'Smart contract'
            }
        },

        orderDetails: {
            title: 'Order Information',
            content: [
                'To get information about the order, you need to make a GET request to the address <0>/v1/order_info/\u2039order_id\u203a</0>',
                '<0>order_id </0> Unique identifier of the order in the payment system',
                'Response format:',
                'If the order is not found, you will receive a response like:',
                'If an invalid authorization token is sent to the request, a <0>JSON response with http status 401 or 403 will be received</0>'
            ],

            statuses: {
                title: 'Possible order statuses',
                content: [
                    '<0>CREATED</0> Order has been created',
                    '<0>MONEY_RECEIVED</0> Payment received (but the transaction is not irreversible)',
                    '<0>PAID</0> The payment has been received, the transaction is irreversible (the transaction becomes irreversible 3 minutes after the payment is made)',
                    '<0>CANCELLED</0> Order has been cancelled',
                    '<0>EXPIRED </0> Payment was not received at the set time'
                ]
            },

            example: {
                id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # unique identifier of the order in the payment system',
                sequentNumber: '1 # serial number of the order in the store',
                amount: {
                    fullAmount: '"101.05", # amount that the client must send',
                    amountAfterTax: '"100.24875", # amount that the store will receive',
                    fee: '"0.25125" # transfer fee'
                },
                currency: {
                    name: '"USDCASH", # name of the token',
                    smartContract: '"token.pcash", # token smart contract',
                    precission: '5 # number of significant decimal places'
                },
                status: '"CREATED" # order status',
                expirationDate: '"2021-02-05T13:54:36.691514" # time in UTC-0 when the order becomes invalid',
                paymentUrl: '"<https://pcash.store/payment/1235>" # link to the page with the payment form',
                responseUrl:
                    '"https://api.my_market.com/order_payment_result" # the url to which the callback will be sent',
                successPaymentRedirectUrl:
                    '"https://my_market.com/order/1234/successs" # transfer in case of successful payment',
                failedPaymentRedirectUrl:
                    '"https://my_market.com/order/1234/failed" # transfer in case of unsuccessful payment',
                isTest: 'false # is the order a test order',
                details: {
                    merchantOrderId: '"1234" # order ID on the store\'s website',
                    description: '"order description" # order description'
                },
                paymentTransfer: {
                    description: '# order payment transaction (if the order has not been paid, this field is missing)',
                    id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # unique identifier of the transfer',
                    fullAmount: '"101.05", # amount that the client sent',
                    amountAfterTax: '"100.24875", # amount that the store received',
                    fee: '"0.25125" # transfer fee',
                    name: '"USDCASH", # name of the token',
                    smartContract: '"token.pcash", # token smart contract',
                    precission: '5 # number of significant decimal places',
                    sender: '"12345.pcash" # eos account from which the funds were sent',
                    receiver: '"141111.pcash" # eos account to which the funds were sent',
                    txnType: '"ORDER_PAYMENT" # purpose of the transfer',
                    status: '"ACCEPTED" # transfer status',
                    memo: 'order_id: ca2c5cc2-f258-4ead-97e5-99ba64641b4d',
                    txid: '"92003a5982d2491dcf4d285794d906f6c5405cd7b188e85467261952ac5c417e" # transaction ID in the EOS blockchain',
                    blockNumber: '432542 # number of the block that the transaction entered',
                    globalSequence: '54452 # id of the transfer action',
                    isIrreversible: 'true # is the transaction completely irreversible',
                    isReversed: 'false # has the transaction been canceled',
                    allowedMethods: `[ # list of possible payment methods
                "EOS",
                "ETHER",
                "TRON",
                "RUS_PAY",
                "WORLD_PAY"
            ]`
                }
            }
        },

        createTransfer: {
            title: 'Transfer of funds',
            description: 'To create a transfer, the client must make a post request to the url <0>/v1/transfer</0>',

            table: {
                headers: ['Parameter', 'description', 'example'],

                description: {
                    amount: 'The order amount in cash tokens(as a string)',
                    currency_name: 'Cash token',
                    currency_smart_contract:
                        'The smart contract in which the token works (all supported currencies are listed in the corresponding section)',
                    eos_account: 'EOS account to which the funds will be sent',
                    response_url: 'URL to which you will receive a response about a successful or unsuccessful payment',
                    fiat_method_id:
                        'If specified, the payment is considered to be fiat. You can only specify the ids of methods with direction = OUT (see the fiat_methods request)',
                    uniqId: 'Unique identifier of the payment in the store'
                }
            },

            response: {
                title: 'The client receives an immediate response depending on the result',
                description_success: 'In case of successful translation creation:',
                description_failure: 'In case of an error:',
                fiat_method_id:
                    'If <0>fiat_method_id</0> is specified, then the card number must be specified in <0>eos_account</0>',
                example: 'ID of the created transfer'
            },

            statuses: {
                title: 'Possible response statuses ',
                content: [
                    'See the section “Creating an order“',
                    'After the transaction becomes irreversible or canceled, the client receives a post request for response_url with transfer data (format in the “Order Information" section). See the details on processing the request in the “Callback“ section',
                    'Important: the ACCEPTED transfer status means that the service has processed the request and made the transfer, but the transaction can still be rejected. To make sure that the transaction is irreversible, wait until the transfer.action.is_irreversilble field becomes True (it usually takes 3 minutes). At the end of this period, you will receive a callback to the specified response_url. You can also track the status of the transfer yourself (see “Transfer Information")'
                ]
            }
        },

        transferDetails: {
            title: 'Transfer Information',
            content: [
                'To get information about the order, you need to make a GET request to the address <0>/v1/transfer_info/\u2039transfer_id\u203a</0>',
                '<0>transfer_id</0> Unique identifier of the order in the payment system',
                'Response format:',
                'If the transfer is not found, you will receive the following response:',
                'If an invalid authorization token is sent to the request, a <0>JSON response with http status 401 or 403 will be received</0>',
                'If the payment is not fiat, the value <0>fiat_amount</0> and <0>fiat_method</0> will be <0>null</0>'
            ],
            example: {
                id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # unique identifier of the transfer',
                fullAmount: '"101.05", # amount sent',
                amountAfterTax: '"100.24875", # amount received',
                amountFee: '"0.25125" # transfer fee',
                currencyName: '"USDCASH", # name of the token',
                smartContract: '"token.pcash", # token smart contract',
                precission: '5 # number of significant decimal places',
                sender: '"12345.pcash" # eos account from which the funds were sent',
                receiver: '"141111.pcash" # eos account from which the funds were sent',
                txnType: '"WITHDRAW" # purpose of the transfer',
                status: '"ACCEPTED" # transfer status',
                memo: 'user_id: bc2c5cc2-f258-4ead-97e5-99ba74641b4f',
                txid: '"92003a5982d2491dcf4d285794d906f6c5405cd7b188e85467261952ac5c417e" # transaction ID in the EOS blockchain',
                blockNumber: '432542 # number of the block that the transaction entered',
                globalSequence: '54452 # id of the transfer action',
                isIrreversible: 'true # is the transaction completely irreversible',
                isReversed: 'false # has the transaction been canceled',
                amount: '"100.5" # amount sent',
                fiatAmountFee: '"0.5" # transfer fee',
                fiatMethodId: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # unique identifier of the fiat method',
                fiatMethodName: '"best2pay" # name of the payment system',
                fiatMethodCurrencyName: '"RUB" # currency of the payment system',
                location: '"RUS_PAY" # Russian or foreign payment system'
            }
        },

        currencies: {
            title: 'List of currencies',
            content: [
                'To get information about currencies, you need to make a GET request to the address <0>/v1/currencies</0>. Returns a list of active cryptocurrencies.',
                'Response format:'
            ]
        },

        fiatMethods: {
            title: 'List of payment systems',
            content: [
                'To get information about payment systems, you need to make a GET request to the address <0>/v1/fiat_methods</0>. Returns a list of active fiat payment systems.',
                'Response format:'
            ]
        },

        callback: {
            title: 'Callback',
            content: [
                'After accepting payment from the user <0>MONEY_RECEIVED</0> when the transaction becomes irreversible <0>PAID</0>',
                'upon expiration of expiration_date <0>EXPIRED</0> or upon cancellation of an order from the cabinet <0>CANCELLED</0>, the client receives a post request',
                'on <0>response_url</0> with order data format in the “Order Information“ section',
                'Important: The MONEY_RECEIVED order status means that the store has received the money, but the transaction can still be rejected. The PAID status means that the transaction is already irreversible.',
                'The request also contains a header with a signature of the form',
                'To verify the authenticity of the request, you need to verify its signature.',
                'Used algorithm<0>Ed25519</0>',
                'Public key <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0>',
                'To verify the signature, you need to:',
                'Considering that',
                '<0>Message body</0> the string received in paragraph 2',
                '<0>Signature </0> value from the SIGNATURE header ',
                'HEX value of the public key <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0> '
            ],

            steps: [
                [
                    'Convert <0>request body</0> to JSON',
                    'IMPORTANT: there should be no spaces between the key and the value'
                ],
                [
                    'Take the   <0>sha256 hash</0> from the JSON string from paragraph 1. The output should be a hex string'
                ],
                ['Check <0>signature</0> by algorithm <0>Ed25519</0>']
            ]
        },

        lifecycles: {
            title: 'Order life cycles',
            content: [
                'Successful order',
                'The order was canceled',
                'The payment waiting period expired',
                'The transaction was rejected'
            ]
        },

        sdk: {
            title: 'SDK',
            content: [
                'To facilitate integration with our payment system, libraries for the following programming languages are implemented:'
            ]
        },

        testing: {
            title: 'Testing',
            content: [
                'To test synchronization with the payment system, log in to your personal account on the site, select the store you need and switch it to test mode (enabled by default).',
                'All orders created while the store is in test mode automatically become test orders. Buttons will appear on the payment page to control the life cycle of the order.'
            ]
        }
    },
    apikey: {
        title: 'API key'
    },
    logs: {
        title: 'Logs'
    },
    webhooks: {
        title: 'Webhooks'
    },
    integrations: {
        title: 'Integration',

        tilda: {
            title: 'Tilda Integration',

            auth: {
                title: 'Authorization',
                description:
                    'Go to the Tilda website and log in to your account. On the opened page, select the website you need.',
                buttonTitle: 'Go to Tilda website'
            },

            websiteSettings: {
                title: 'Website Settings',
                description:
                    "On the opened page with information about the site, click on the 'Website Settings' button."
            },
            paymentSystems: {
                title: 'Payment Systems',
                description:
                    "On the left in the navigation menu, go to the 'Payment Systems' section. Then scroll down to the bottom of the page and select 'Universal Payment System'."
            },

            newPaymentSystem: {
                title: 'New Payment System',
                description:
                    "Click on the 'Settings Template' field, scroll down the opened drop-down list to the end and select 'New Payment System (for developers)'."
            },

            login: {
                title: 'Login',
                description:
                    "After you have selected 'New Payment System' in the drop-down list, a list of fields to fill in will appear on the page. In the 'Login' field, enter the store ID from the pc4.store website.",
                explanation:
                    "To find the ID of your store, click on the settings button next to the active store on the pc4.store website. A modal window will open - 'Settings'. In the opened modal window, under the selection of the working/testing mode, you will find the necessary ID."
            },

            secretSigning: {
                title: 'Secret for Order Signing',
                description: "The next field to fill in is 'Secret for Order Signing'."
            },

            localization: {
                title: 'Localization',
                description: 'Now you need to specify the currency, country, and language of the payment widget.',
                step_1: 'Select currency - RUB or USD.',
                step_2: 'Specify the two-letter code of your country. You can find the code of your country by clicking the button below.',
                step_3: 'Specify the language - RU or EN.',
                buttonTitle: 'Two-letter country codes'
            },

            advancedSettings: {
                title: 'Advanced Settings',
                description: "To proceed to fill in the fields, click on the 'Advanced Settings' button."
            },

            apiUrl: {
                title: 'API URL',
                description: 'Insert the text below into the API URL field.'
            },

            matchingFields1: {
                title: 'List of Matching Fields, Part 1',
                description:
                    'Now you need to fill in the fields as shown in the screenshot above. You can copy the field values below.',
                field_1: 'Login',
                field_2: 'Currency',
                field_3: 'Language',
                field_4: 'Country',
                field_5: 'URL for Notifications',
                field_6: 'Order Number',
                field_7: 'Order Description'
            },

            matchingFields2: {
                title: 'List of Matching Fields, Part 2',
                description: 'Continue filling in the fields.',
                field_1: 'Order Amount',
                field_2: "Buyer's Email",
                field_3: "Buyer's Phone Number",
                field_4: "Buyer's Name",
                field_5: 'Signature',
                field_6: 'Products'
            },

            addAdditionalFields: {
                title: 'Add Additional Fields',
                description:
                    "Now you need to add additional fields. To do this, scroll the page down to the 'List of Additional Fields' section and click the 'Add Field 4 times' button. You should see 4 blank fields appear."
            },

            fillingAdditionalFields: {
                title: 'Filling in Additional Fields',
                description:
                    'After you have added the fields, you need to fill them in. The left side specifies the field name, the right side specifies the value.',
                field_1: 'Field 1',
                field_2: 'Field 2',
                field_3: 'Field 3',
                field_4: 'Field 4',
                description_1:
                    'In the value field, insert a link to the page to which the user will be automatically redirected upon successful payment.',
                description_2:
                    'In the value field, insert a link to the page to which the user will be automatically redirected upon unsuccessful payment.',
                description_3:
                    'In the value field, specify USDCASH or USDT, depending on which cryptocurrency you want to receive after payment to your balance.',
                description_4:
                    'In the value field, specify the validity period of the account (time the payment link exists) for the payment page in minutes. For example - 10.'
            },

            signingOrder: {
                title: 'Rules and Order Signing Algorithm',
                description: 'Next, you need to edit the order signing fields.',
                example: 'Special Rules',
                step_1: "Click on the 'Signing Rules' field and select - 'Special Rules'.",
                step_2: "In the 'Special Order Signing Rules' field, insert the value from the field below.",
                step_3: "Click on the 'Signature Algorithm' field and select 'SHA-256' from the drop-down list.",
                step_4: "Check the box next to 'Use secret as algorithm key (HMAC)'."
            },

            signingNotifications: {
                title: 'Rules and Notification Signing Algorithm',
                description: 'Next, you need to edit the notification signing fields.',
                example: 'Special Rules',
                step_1: "Uncheck the box next to 'Use the same rules for notification signing'.",
                step_2: "Click on the 'Signing Rules' field and select - 'Special Rules'.",
                step_3: "In the 'Special Notification Signing Rules' field, insert the value from the field below.",
                step_4: "Click on the 'Signature Algorithm' field and select 'SHA-256' from the drop-down list.",
                step_5: "Check the box next to 'Use secret as algorithm key (HMAC)'."
            },

            successfulPayment: {
                title: 'Successful Payment Indicator and Transaction ID',
                description: 'Next, you need to configure notifications for successful payment and transaction ID.',
                step_1: 'Field 1',
                step_2: 'Value 1',
                step_3: 'Field 2',
                step_4: "Click on 'Value 2' and select 'UUID' from the drop-down list."
            },

            successAndError: {
                title: 'Success and Error',
                description:
                    'These fields should be filled in by default, but just in case, if they are empty, enter the following data.',
                step_1: 'Success Notification Response',
                step_2: 'Error Notification Response'
            },

            paymentMethodName: {
                title: 'Payment Method Name',
                description_1: "Next, scroll the page down to the 'Header' section.",
                description_2: 'Now you need to name the payment method and accept the disclaimer.',
                example: 'Header',
                step_1: 'Enter the value in the header field as shown below.',
                step_2: 'Check the box.',
                step_3: "Click the 'Add' button."
            },

            ready: {
                title: 'Done!',
                description: 'The configured payment system will appear on the Payment Methods page.'
            }
        },

        shopify: {
            title: 'Integration with Shopify',

            auth: {
                title: 'Authorization',
                description:
                    'Go to the Shopify website and log in to your account. On the page that opens, select the site you need.',
                buttonTitle: 'Go to the Shopify website'
            },

            websiteSettings: {
                title: 'Website settings',
                description: 'On the page of the selected website that opens, click on the “Settings” button'
            },

            payment: {
                title: 'Payment',
                description: 'On the left in the navigation menu, go to the “Checkout” section'
            },

            additionalScripts: {
                title: 'Additional scripts',
                description:
                    'After going to the “Checkout” page, scroll down and find the “Order status page” block, insert the following script into the “Additional scripts” text field. Next, click on the “Save” button'
            },

            payments: {
                title: 'Payments',
                step_1: 'Go to the “Payments” section',
                step_2: 'Find the “Manual payment methods” section and click on the “Add manual method” button',
                step_3: 'In the drop-down list, select “Create custom payment method”'
            },

            addingPaymentMethod: {
                title: 'Adding a payment method',
                step_1: 'In the window that opens, enter the name of the payment method in the “Custom payment method name” field',
                step_2: 'Click on the “Activate” button'
            },

            applications: {
                title: 'Applications',
                step_1: 'Now go to the “Apps and sales channels” section',
                step_2: 'Click on the “Develop apps” button'
            },

            newApplications: {
                title: 'New application',
                description: 'Click on the “Create an app” button'
            },

            appTitle: {
                title: 'Title',
                step_1: 'In the window that opens, enter the name in the “App name” field',
                step_2: 'Click on the “Create app” button'
            },

            apiSettings: {
                title: 'API Settings',
                description: 'On the page that opens, click on the “Configure Admin API scopes” button'
            },

            orders: {
                title: 'Orders',
                step_1: 'In the “Admin Api access copies” block, find the “Orders” section and check the boxes next to the “write_orders” and “read_orders” items',
                step_2: 'Click on the “Save” button'
            },

            Installation: {
                title: 'Installation',
                description: 'Click on the “Install app” button to install the app'
            },

            acceptInstallation: {
                title: 'Confirm the installation',
                description: 'In the window that opens, click on the “Install” button'
            },

            apiKeys: {
                title: 'API keys',
                description: 'Go to the “API credentials” page'
            },

            done: {
                title: 'Done',
                description: 'Scroll down and save the value from the following fields:',
                step_1: 'Admin API access token',
                step_2: 'API secret key'
            }
        }
    },

    newIntegration: {
        title: 'New Integration',
        wait: 'Stand by',
        chooseCms: 'Choose CMS',
        soon: 'Coming Soon',
        delete: 'Delete',
        edit: 'Edit',
        linkToDocs: 'Detailed Integration Instructions',
        newApiKey: 'Generate New API and Secret Keys',
        continue: 'Continue',
        idNotFound: 'Store ID not found',
        requiredField: 'Fill in the field',

        shopifySteps: {
            addScriptStep: {
                title: "Adding to 'Scripts'",
                description: 'To accept payments in Shopify via PayCash for Store, add a new script.',
                step: 'To do this, go to'
            },

            addMethodsStep: {
                title: "Adding to 'Payment Methods'",
                description: 'Add a new payment method.',
                step_1: 'To do this, go to',
                step_2: 'Click on the dropdown',
                step_3: 'Select',
                step_4: 'In the field',
                step_5: 'enter'
            },

            storeStep: {
                title: 'Store Data',
                selectLabel: 'Select Store',
                description_1: 'Token used for payments in USD',
                description_2:
                    'You can find the store name by going to the store settings. The name will be in the upper left corner of the window that opens',
                placeholder: 'Select active store',
                noOptions: 'No active stores',
                inputLabel: 'Store Name',
                past: 'Paste',
                create: 'Create'
            }
        },

        tildaSteps: {
            storeStep: {
                title: 'Select Store',
                description: 'Select the store you want to link to Tilda',
                label: 'Store',
                placeholder: 'Select active store',
                noOptions: 'No active stores'
            },

            planStep: {
                title: 'Tariff on Tilde',
                description: 'To use the pc4.store payment system, you need to use one of the paid plans on Tilda',
                alert: 'It is impossible to integrate pc4.store using the free plan on Tilda',
                label: 'I confirm that I am using one of the paid plans on Tilda'
            },

            keyStep: {
                title: 'New API Key and Secret',
                description:
                    "To ensure the integration works correctly, you need to generate a new secret key, but here's the catch, to generate a secret key, you need to update the API key",
                alert: 'When generating a new API key, the old key will be deleted and invalid',
                label: 'I am sure I want to generate new API and secret keys'
            },

            confirmStep: {
                title: 'Confirmation',
                description: 'To continue, you need to enter a password'
            },

            saveStep: {
                title: 'New API Key and Secret',
                stepListTitle: 'Saving',
                description: "Save both keys because you won't be able to view them anymore",
                param_id: 'Store ID',
                param_apiKey: 'API Key',
                param_secretKey: 'Secret Key',
                alert: 'When generating a new API key, the old key will be deleted and invalid',
                checkbox_apiKey: 'I have saved the API key',
                checkbox_secretKey: 'I have saved the secret key'
            },

            successStep: {
                title: 'Done',
                description: 'You have added Tilda integration for the store {{storeName}}',
                alert: 'Tilda integrations do not appear in the table of all store integrations',
                close: 'Close'
            }
        },

        noData: {
            title: 'No Integrations',
            description: "You haven't added\nany integrations yet"
        },

        tableHeader: {
            integration: 'Integration',
            created: 'Created',
            storeName: 'Store Name'
        }
    },

    deleteIntegration: {
        notFound: 'Integration not found',
        deleteSuccessful: 'Integration successfully deleted',
        confirmation: 'Confirmation',
        description_shop: 'Are you sure you want to delete the Shopify integration for the store <0>{{shopName}}</0>?',
        description_1: 'Are you sure you want to delete the Shopify integration?',
        cancel: 'No, do not delete',
        accept: 'Yes, delete',
        errors: {
            DeleteShopifyIntegrationSuccess: 'Integration successfully deleted',
            ShopifyIntegrationNotFoundError: 'Integration not found'
        }
    },

    editIntegration: {
        errors: {
            UpdateShopifyIntegrationSuccess: 'Integration for store {{shopName}} successfully edited',
            ConnectShopifySuccess: 'Integration for store {{shopName}} successfully created',
            InvalidCredentialsError: 'Invalid token or store name',
            ShopifyPermissionRequiredError: 'Access denied',
            InvalidParamsError: 'Parameters are incorrect - ',
            ShopifyIntegrationNotFoundError: 'Integration not found',
            ShopifyIntegrationExistsError: 'Integration already exists'
        },
        edit: 'Edit integration',
        save: 'Save'
    }
};
