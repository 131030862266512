import React from 'react';
import cn from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import QrLogoSrc from 'assets/images/logo-for-qr.svg';
import { QRProps } from './Qr.d';
import styles from './Qr.module.scss';

export default function Qr({
    // as = 'canvas',
    value,
    className,
    imageSrc = QrLogoSrc,
    size = 200,
    ...props
}: QRProps) {
    // const QRCodeComponent = as === 'canvas' ? QRCodeCanvas : QRCodeSVG;

    const imageSettings = {
        src: imageSrc || '',
        height: size / 5 /** IMG width. */,
        width: size / 5 /** IMG height. */,
        excavate: true /** Delete the QR code section below the image. */
    };

    return (
        <div className={cn(styles.Root, className)}>
            <QRCodeSVG
                value={value}
                fgColor="var(--css-gray-900)"
                bgColor="transparent"
                imageSettings={imageSrc ? imageSettings : undefined}
                size={size}
                {...props}
            />
        </div>
    );
}
