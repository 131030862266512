import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { URLS_MAP } from 'routes/routes';
import Dialog from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { ChevronRightIcon, HexagonFilledIcon, ProfileFilledIcon, TagCloseFilledIcon } from 'ui/Icons/Icons';
import { NavigationMoreModalProps } from './NavigationMoreModal.d';
import styles from './NavigationMoreModal.module.scss';

export default function NavigationMoreModal({ open, setOpen, onClick, ...props }: NavigationMoreModalProps) {
    const { t } = useTranslation();

    const handleClick = (id: string) => () => {
        onClick?.(id);
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
            classes={{ container: styles.Container }}
            transition="slide-down"
            {...props}
        >
            <Button classes={{ root: styles.Button }} color="secondary" onClick={handleClick('profile')} fullWidth>
                <div className={styles.Wrapper}>
                    <div className={styles.IconWrapper}>
                        <ProfileFilledIcon className={cn(styles.Icon, styles.IconWithBackground)} />
                    </div>
                    <div className={styles.TextWrapper}>
                        <h5 className={styles.Text}>{t('navigation.profile')}</h5>
                        <ChevronRightIcon className={styles.Arrow} />
                    </div>
                </div>
            </Button>
            <Button classes={{ root: styles.Button }} color="secondary" onClick={handleClick('settings')} fullWidth>
                <div className={styles.Wrapper}>
                    <div className={styles.IconWrapper}>
                        <HexagonFilledIcon className={styles.Icon} />
                    </div>
                    <div className={styles.TextWrapper}>
                        <h5 className={styles.Text}>{t('navigation.storeSettings')}</h5>
                        <ChevronRightIcon className={styles.Arrow} />
                    </div>
                </div>
            </Button>
            <Button
                as={NavLink}
                to={URLS_MAP.developers.index}
                end
                classes={{ root: styles.Button }}
                color="secondary"
                onClick={handleClick('for-developers')}
                fullWidth
            >
                <div className={styles.Wrapper}>
                    <div className={styles.IconWrapper}>
                        <TagCloseFilledIcon className={styles.Icon} />
                    </div>
                    <div className={styles.TextWrapper}>
                        <h5 className={styles.Text}>{t('navigation.forDevelopers')}</h5>
                        <ChevronRightIcon className={styles.Arrow} />
                    </div>
                </div>
            </Button>
        </Dialog>
    );
}
