import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiatMethodLocation } from 'apollo/generated';
import { CheckFilledCircledIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { PaymentButtonProps } from './PaymentButton.d';
import TokenIcon from '../TokenIcon/TokenIcon';
import styles from './PaymentButton.module.scss';

const getService = (name?: string) => {
    switch (name) {
        case 'zenzero':
            return 'aed';
        case 'alium':
            return 'eur';
        default:
            return 'usd';
    }
};

export default function PaymentButton({ activeMethod, location, serviceName, onClick }: PaymentButtonProps) {
    const { t } = useTranslation();

    const service = getService(serviceName);

    return (
        <Button
            onClick={onClick}
            color="secondary"
            variant="outlined"
            fullWidth
            classes={{
                root: cn(styles.Root, activeMethod === location && styles.Active)
            }}
            iconEnd={activeMethod === location && <CheckFilledCircledIcon className={styles.CheckIcon} />}
        >
            <div className={styles.Container}>
                <TokenIcon
                    name={location === FiatMethodLocation.WorldPay ? service : 'rub'}
                    classes={{ root: styles.Icon }}
                    size={32}
                />
                <div>
                    <div className={styles.Text}>
                        {location === FiatMethodLocation.WorldPay
                            ? t('payment.bank.international', {
                                  context: serviceName === 'zenzero' ? 'aed' : undefined
                              })
                            : t('payment.bank.russian')}
                    </div>
                    <div className={styles.Subtext}>
                        {location === FiatMethodLocation.WorldPay ? service.toUpperCase() : 'RUB'}
                    </div>
                </div>
            </div>
        </Button>
    );
}
