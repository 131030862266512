import React from 'react';
import cn from 'classnames';
import styles from './Stepper.module.scss';

interface StepperProps {
    title?: React.ReactNode;
    steps: { isActive: boolean; key: number | string }[];
}

export default function Stepper({ title, steps }: StepperProps) {
    return (
        <div className={styles.Progress}>
            <div className={styles.ProgressStepList}>
                {steps.map(({ isActive, key }) => (
                    <span key={key} className={cn(styles.ProgressStep, isActive && styles.isActive)} />
                ))}
            </div>
            <h2 className={styles.ProgressTitle}>{title}</h2>
        </div>
    );
}
