import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import {
    BankCardFilledIcon,
    CrownFilledIcon,
    DashboardFilledIcon,
    DocFilledIcon,
    DotsIcon,
    ExitIcon,
    ProfileFilledIcon,
    ShopIcon
} from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { useDialog } from 'ui/Dialog/Dialog';
import Logo from '../Logo/Logo';
import TransactionsProcessing from '../TransactionsProcessing/TransactionsProcessing';
import AdminNavigationModalMore from '../AdminNavigationModalMore/AdminNavigationModalMore';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import ProfileModal from '../ProfileModal/ProfileModal';
import { AdminHeaderProps } from './AdminHeader.d';
import styles from './AdminHeader.module.scss';

export default function AdminHeader({
    showAdmins,
    showDashboard,
    seeShopInfo,
    showTransactions,
    failedTransactions
}: AdminHeaderProps) {
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
    const { pathname } = useLocation();
    const profileModal = useDialog();
    const moreModal = useDialog();
    const logoutModal = useDialog();

    const mobileNavItems = [
        {
            isView: showDashboard,
            link: URLS_MAP.admin.acquiring,
            icon: <BankCardFilledIcon />,
            title: 'Эквайринг'
        },
        {
            isView: showDashboard,
            link: URLS_MAP.admin.index,
            icon: <DashboardFilledIcon />,
            title: 'Дашборд'
        },
        {
            isView: showTransactions,
            link: URLS_MAP.admin.transactions,
            icon: <DocFilledIcon />,
            title: 'Транзакции'
        },
        {
            isView: seeShopInfo,
            link: URLS_MAP.admin.merchants,
            icon: <ShopIcon />,
            title: 'Мерчанты'
        },
        {
            isView: showAdmins,
            link: URLS_MAP.admin.list,
            icon: <CrownFilledIcon />,
            title: 'Админы'
        }
    ];

    const currentMobileNavItems = mobileNavItems.filter(({ isView }) => isView);

    const handleShowMoreModal = () => moreModal.setOpen(true);

    return (
        <>
            <header className={styles.Root}>
                <div className={styles.LogoContainer}>
                    <Logo isShort href={URLS_MAP.admin.index} />
                    <TransactionsProcessing classRoot={styles.InProcessingBtn} />
                </div>
                <nav className={styles.Nav}>
                    <ul className={styles.NavList}>
                        {showDashboard && (
                            <li className={styles.NavItem}>
                                <Button
                                    as={NavLink}
                                    isDark
                                    classes={{
                                        root:
                                            pathname === URLS_MAP.admin.acquiring
                                                ? styles.NavLinkActive
                                                : styles.NavLink
                                    }}
                                    to={URLS_MAP.admin.acquiring}
                                    end
                                    iconStart={<BankCardFilledIcon />}
                                >
                                    Эквайринг
                                </Button>
                            </li>
                        )}

                        {showDashboard && (
                            <li className={styles.NavItem}>
                                <Button
                                    as={NavLink}
                                    isDark
                                    classes={{
                                        root: pathname === URLS_MAP.admin.index ? styles.NavLinkActive : styles.NavLink
                                    }}
                                    to={URLS_MAP.admin.index}
                                    end
                                    iconStart={<DashboardFilledIcon />}
                                >
                                    Дашборд
                                </Button>
                            </li>
                        )}

                        {showTransactions && (
                            <li className={styles.NavItem}>
                                <Button
                                    as={NavLink}
                                    isDark
                                    classes={{
                                        root:
                                            pathname === URLS_MAP.admin.transactions
                                                ? styles.NavLinkActive
                                                : styles.NavLink
                                    }}
                                    to={URLS_MAP.admin.transactions}
                                    iconStart={<DocFilledIcon />}
                                    iconEnd={
                                        failedTransactions ? (
                                            <span className={styles.NavLinkBadge}>{failedTransactions}</span>
                                        ) : undefined
                                    }
                                >
                                    Транзакции
                                </Button>
                            </li>
                        )}

                        {seeShopInfo && (
                            <li className={styles.NavItem}>
                                <Button
                                    as={NavLink}
                                    isDark
                                    classes={{
                                        root:
                                            pathname === URLS_MAP.admin.merchants
                                                ? styles.NavLinkActive
                                                : styles.NavLink
                                    }}
                                    to={URLS_MAP.admin.merchants}
                                    end
                                    iconStart={<ShopIcon />}
                                >
                                    Мерчанты
                                </Button>
                            </li>
                        )}

                        {showAdmins && (
                            <li className={styles.NavItem}>
                                <Button
                                    as={NavLink}
                                    isDark
                                    classes={{
                                        root: pathname === URLS_MAP.admin.list ? styles.NavLinkActive : styles.NavLink
                                    }}
                                    to={URLS_MAP.admin.list}
                                    iconStart={<CrownFilledIcon />}
                                >
                                    Администраторы
                                </Button>
                            </li>
                        )}
                    </ul>
                </nav>
                <div className={styles.Right}>
                    {!isTabletOrLess && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => profileModal.setOpen(true)}
                            classes={{ root: styles.ProfileButton }}
                            iconStart={<ProfileFilledIcon className={styles.ProfileIcon} />}
                        />
                    )}

                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        onClick={() => logoutModal.setOpen(true)}
                        classes={{ root: styles.LogoutButton }}
                        iconStart={<ExitIcon className={styles.LogoutIcon} />}
                    />
                </div>
            </header>
            <div className={styles.Bar}>
                <nav className={styles.BarNav}>
                    <ul className={styles.BarNavList}>
                        {currentMobileNavItems.slice(0, 3).map(({ link, icon, title }) => (
                            <li key={link} className={styles.BarNavItem}>
                                <Button
                                    as={NavLink}
                                    classes={{
                                        root: pathname === link ? styles.BarNavActive : styles.BarNavLink
                                    }}
                                    to={link}
                                    iconStart={icon}
                                >
                                    {title}
                                </Button>
                            </li>
                        ))}

                        {currentMobileNavItems.length < 4 && (
                            <li className={styles.BarNavItem}>
                                <Button
                                    classes={{ root: styles.BarNavLink }}
                                    onClick={() => profileModal.setOpen(true)}
                                    iconStart={<ProfileFilledIcon className={styles.BarNavIconProfile} />}
                                >
                                    Профиль
                                </Button>
                            </li>
                        )}

                        {currentMobileNavItems.length > 3 && (
                            <li className={styles.BarNavItem}>
                                <Button
                                    classes={{ root: styles.BarNavLink }}
                                    onClick={handleShowMoreModal}
                                    fullWidth
                                    iconStart={<DotsIcon />}
                                >
                                    Еще
                                </Button>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            <AdminNavigationModalMore
                buttonsList={currentMobileNavItems}
                openProfileModal={() => profileModal.setOpen(true)}
                {...moreModal}
            />
            <LogoutModal {...logoutModal} />
            <ProfileModal {...profileModal} />
        </>
    );
}
