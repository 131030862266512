import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Validates a login string with phone or email.
 *
 * @param {string} login - The login string to be validated.
 *
 * @returns {Object} An object containing validation results.
 *
 * @property {boolean} isValid - Indicates whether the login is valid (true) or not (false).
 * @property {string} errorText - A message describing any validation error. Empty if the login is valid.
 *
 * @example
 * const { isValid, errorText } = useLoginValidation(loginInput.value);
 *
 * if (isValid) {
 *   console.log("Login is valid!");
 * } else {
 *   console.error("Login is not valid:", errorText);
 * }
 */
export default function useLoginValidation(login: string): { errorText: string; isValid: boolean } {
    const { t } = useTranslation();

    const [isValid, setIsValid] = React.useState(true);
    const [errorText, setErrorText] = React.useState('');

    React.useEffect(() => {
        const loginValue = login.trim();
        const isEmpty = !loginValue.length;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginValue);
        const isPhone = /^[0-9\s()+]+$/.test(loginValue);
        const isLengthValid = loginValue.length > 6;

        if (!isEmpty) {
            if (!isPhone && !isEmailValid) {
                setIsValid(false);
                setErrorText(t('auth.error.emailInvalid'));
            } else if (isPhone && !isLengthValid) {
                setIsValid(false);
                setErrorText(t('auth.error.phoneInvalid'));
            } else {
                setIsValid(true);
                setErrorText('');
            }
        } else {
            setIsValid(true);
            setErrorText('');
        }
    }, [login, t]);

    return {
        isValid,
        errorText
    };
}
