import React from 'react';
import cn from 'classnames';
import removeNonNumeric from 'utils/removeNonNumeric/removeNonNumeric';
import FormControl from '../FormControl/FormControl';
import { InputCodeProps, InputTypes } from './InputCode.d';
import styles from './InputCode.module.scss';

/**
 * Function for validating input value based on input type and filterChars list.
 *
 * @param {string} currentValue - The current input value.
 * @param {InputTypes} inputType - The input type ('numeric', 'decimal', 'tel', or 'email').
 * @param {string[]} [filterChars] - An array of characters to filter (optional).
 * @param {boolean} [allowOnlyListedChars] - Allow only characters from the `filterChars` array (optional).
 * If set to `true`, the 'currentValue' will accept only the characters specified in the `filterChars` array,
 * acting as a whitelist. If set to `false`, the 'currentValue' will reject the characters listed
 * in the `filterChars` array, acting as a blacklist.
 *
 * @returns {string} - The validated input value.
 */
function validateInputValue(
    currentValue: string,
    inputType: InputTypes,
    filterChars?: string[],
    allowOnlyListedChars?: boolean
): string {
    let string = currentValue;

    if (inputType === 'numeric' || inputType === 'decimal') string = removeNonNumeric(currentValue);
    if (inputType === 'tel') string = currentValue.replace(/[^0-9+#*]/g, '');
    if (inputType === 'email') string = currentValue.replace(/[(),:;<>[\]]/g, '');

    if (filterChars?.length) {
        string = string
            .split('')
            .filter((currentChar) => {
                if (allowOnlyListedChars) return filterChars?.includes(currentChar);

                return !filterChars?.includes(currentChar);
            })
            .join('');
    }

    return string;
}

const InputCode = React.forwardRef(
    (
        {
            allowOnlyListedChars,
            autoFocus = true,
            classes,
            disabled,
            fieldsCount = 6,
            filterChars,
            formControlProps,
            helperText,
            isUppercase,
            isError,
            label,
            onChange,
            size = 44,
            type = 'text',
            value,
            ...props
        }: InputCodeProps,
        ref: React.ForwardedRef<(HTMLInputElement | null)[]>
    ) => {
        const id = React.useId();

        const [inputList, setInputList] = React.useState<string[]>([]);

        const inputListRef = React.useRef<(HTMLInputElement | null)[]>([]);

        const { root, input: inputClass, container } = classes ?? {};
        const classesRoot = cn(styles.Root, isError && styles.Error, disabled && styles.Disabled, root);

        React.useImperativeHandle(ref, () => inputListRef.current, []);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = isUppercase ? e.target.value.toUpperCase() : e.target.value;
            const inputId = Number(e.target.id.split('-')[1]);
            const validatedValue = validateInputValue(inputValue, type, filterChars, allowOnlyListedChars);
            const updatedInputList = [...inputList];

            if (validatedValue !== '') {
                if (validatedValue.length > 1) {
                    validatedValue.split('').forEach((char, idx) => {
                        if (inputId + idx < fieldsCount) {
                            updatedInputList[inputId + idx] = char;
                        }
                    });
                } else {
                    updatedInputList[inputId] = validatedValue;
                }

                updatedInputList.forEach((itemValue, idx) => {
                    const inputRef = inputListRef.current && inputListRef.current[idx];

                    if (inputRef) {
                        inputRef.value = itemValue;
                    }
                });

                const nextInputIndex = inputId < updatedInputList.length ? inputId + 1 : inputId;
                const nextInputRef = inputListRef.current?.[nextInputIndex];

                if (nextInputRef) {
                    nextInputRef.focus();
                    nextInputRef.select();
                }

                setInputList(updatedInputList);
            }

            onChange?.(updatedInputList.join(''));
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const targetId = Number(e.currentTarget.id.split('-')[1]);
            const nextInput = inputListRef.current?.[targetId + 1];
            const prevInput = inputListRef.current?.[targetId - 1];

            switch (e.key) {
                case 'Backspace': {
                    e.preventDefault();

                    const targetInput = inputListRef.current?.[targetId];
                    const updatedInputList = [...inputList];

                    if (targetInput) {
                        targetInput.value = '';
                    }

                    updatedInputList[targetId] = '';

                    setInputList(updatedInputList);

                    if (targetInput?.value === '' && prevInput) {
                        prevInput.focus();
                        prevInput.select();
                    }

                    onChange?.(updatedInputList.join(''));
                    break;
                }

                case 'ArrowLeft':
                case 'ArrowUp': {
                    e.preventDefault();

                    if (prevInput) {
                        prevInput.focus();
                        prevInput.select();
                    }

                    break;
                }

                case 'ArrowRight':
                case 'ArrowDown': {
                    e.preventDefault();

                    if (nextInput) {
                        nextInput.focus();
                        nextInput.select();
                    }

                    break;
                }

                default:
                    break;
            }
        };

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

        React.useEffect(() => {
            const inputValues = [...Array(fieldsCount)].map((_, idx) => value?.[idx] || '');

            setInputList(inputValues);
        }, [value, fieldsCount]);

        React.useEffect(() => {
            const setFocus = () => autoFocus && inputListRef.current[0]?.focus();
            const timeoutId = setTimeout(setFocus, 0);

            return () => clearTimeout(timeoutId);
        }, [autoFocus]);

        return (
            <FormControl
                label={label}
                helperText={helperText}
                disabled={disabled}
                isError={isError}
                {...formControlProps}
            >
                <div className={classesRoot} style={{ fontSize: size }}>
                    {inputList.map((inputValue, idx) => (
                        <div
                            className={cn(styles.Container, container)}
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                        >
                            <input
                                className={cn(styles.Input, inputValue && styles.NoCaret, inputClass)}
                                id={`${id}-${idx}`}
                                value={inputValue}
                                type={type === 'password' ? 'password' : 'text'}
                                autoComplete="off"
                                autoCapitalize="off"
                                autoCorrect="off"
                                spellCheck="false"
                                onFocus={handleFocus}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                disabled={disabled}
                                tabIndex={disabled ? -1 : 0}
                                aria-invalid={Boolean(isError)}
                                aria-describedby={`${id}-${idx}`}
                                inputMode={type === 'password' ? 'text' : type}
                                aria-label={idx === 0 ? 'Input the code Character 1' : `Character ${idx + 1}`}
                                {...props}
                                ref={(refItem) => {
                                    inputListRef.current[idx] = refItem;
                                }}
                            />
                        </div>
                    ))}
                </div>
            </FormControl>
        );
    }
);

export default InputCode;
