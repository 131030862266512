import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PayCash from 'assets/images/app-paycash.svg';
import Kalyna from 'assets/images/app-kalyna.svg';
import Malinka from 'assets/images/app-malinka.svg';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import getPaymentAmount from 'helpers/getPaymentAmount/getPaymentAmount';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import Qr from '../Qr/Qr';
import Param from '../Param/Param';
import QrDownload from '../QrDownload/QrDownload';
import { PaymentInfoProps } from './PaymentInfo.d';
import styles from './PaymentInfo.module.scss';

export default function PaymentInfo({
    QrProps,
    alert,
    amount,
    classes,
    currency,
    links,
    memo,
    network,
    qrValue,
    receiver,
    walletInfo = ''
}: PaymentInfoProps) {
    const { t } = useTranslation();
    const isTouchable = useIsTouchableDevice();

    const { root, qrContainer } = classes ?? {};

    const amountValue =
        amount && currency
            ? `${addThousandsSeparator(amount)} ${currency}`
            : `${getPaymentAmount(amount, 6).amount} ${getPaymentAmount(amount).currencyName}`;

    return (
        <div className={cn(styles.Root, root)}>
            {qrValue && (
                <div className={cn(styles.QrContainer, qrContainer)}>
                    <Qr className={styles.Qr} value={qrValue} {...QrProps} />
                    <div className={styles.QrDescription}>
                        {isTouchable ? (
                            <QrDownload hideIcon color="primary" data={qrValue} title={t('deposit.buttonQrDownload')} />
                        ) : (
                            t('payment.paymentInfo.title')
                        )}
                    </div>
                </div>
            )}

            {receiver && (
                <Param
                    classes={{ root: styles.ParamRoot, title: styles.ParamTitle }}
                    label={`${t('payment.paymentInfo.account')} ${walletInfo}`}
                    value={receiver}
                    withCopy
                />
            )}

            {amount && (
                <Param
                    classes={{ root: styles.ParamRoot, title: styles.ParamTitle }}
                    label={t('payment.paymentInfo.amount')}
                    value={amountValue}
                    withCopy
                />
            )}

            {memo && (
                <Param
                    classes={{ root: styles.ParamRoot, title: styles.ParamTitle }}
                    label={t('payment.paymentInfo.memo')}
                    value={memo}
                    withCopy
                />
            )}

            {alert !== false &&
                (alert || <div className={styles.Alert}>{t('payment.info.alert', { context: network })}</div>)}

            {links && (
                <div className={styles.App}>
                    <div className={styles.AppTitle}>{t('payment.paymentInfoAttention.text')}</div>
                    <div className={styles.AppList}>
                        {links.paycash && (
                            <a href={links.paycash} className={styles.AppLink}>
                                <img className={styles.AppImage} src={PayCash} alt="PayCash App" />
                                <span className={styles.AppLabel}>PayCash</span>
                            </a>
                        )}

                        {links.kalyna && (
                            <a href={links.kalyna} className={styles.AppLink}>
                                <img className={styles.AppImage} src={Kalyna} alt="Kalyna App" />
                                <span className={styles.AppLabel}>Kalyna</span>
                            </a>
                        )}

                        {links.malinka && (
                            <a href={links.malinka} className={styles.AppLink}>
                                <img className={styles.AppImage} src={Malinka} alt="Malinka App" />
                                <span className={styles.AppLabel}>Malinka</span>
                            </a>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
