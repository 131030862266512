import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CrossIcon, ShowOffIcon, ShowOnIcon } from '../Icons/Icons';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { TextFieldPasswordProps } from './TextFieldPassword.d';
import styles from './TextFieldPassword.module.scss';

const TextFieldPassword = React.forwardRef<HTMLInputElement, React.PropsWithChildren<TextFieldPasswordProps>>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function FormFieldPasswordComponent({ hideShowButton, validatePassword, classes, ...InputProps }, ref) {
        const { t } = useTranslation();

        const [showPassword, setShowPassword] = React.useState(false);
        const [showTooltip, setShowTooltip] = React.useState(false);

        const inputWrapperRef = React.useRef(null);

        const { tooltipContent, tooltipText: tooltipTextClasses, ...inputClasses } = classes ?? {};

        const handleToggleShowPassword = (show: boolean) => () => setShowPassword(show);

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            InputProps.onFocus?.(e);

            if (validatePassword) setShowTooltip(true);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            InputProps.onBlur?.(e);

            if (validatePassword) setShowTooltip(false);
        };

        return validatePassword ? (
            <Tooltip
                open={showTooltip}
                placement="right"
                anchorRef={inputWrapperRef}
                content={
                    <div className={tooltipContent}>
                        <p className={cn(styles.TooltipText, tooltipTextClasses)}>
                            {validatePassword.length ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('auth.inputPassword.rule.length')}
                        </p>
                        <p className={cn(styles.TooltipText, tooltipTextClasses)}>
                            {validatePassword.hasUppercase ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('auth.inputPassword.rule.uppercase')}
                        </p>
                        <p className={cn(styles.TooltipText, tooltipTextClasses)}>
                            {validatePassword.hasNumber ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('auth.inputPassword.rule.number')}
                        </p>
                        <p className={cn(styles.TooltipText, tooltipTextClasses)}>
                            {validatePassword.hasSpecial ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('auth.inputPassword.rule.special')}
                        </p>
                    </div>
                }
            >
                <TextField
                    label={t('auth.inputPassword.label')}
                    placeholder={t('auth.inputPassword.placeholder')}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type={showPassword ? 'text' : 'password'}
                    adornmentEnd={
                        !hideShowButton && (
                            <Button
                                type="button"
                                variant="text"
                                color="gray"
                                isRound
                                size="small"
                                iconStart={
                                    showPassword ? (
                                        <Tooltip content={t('auth.inputPassword.tooltipHide')}>
                                            <ShowOffIcon aria-label={t('auth.inputPassword.tooltipHide')} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip content={t('auth.inputPassword.tooltipShow')}>
                                            <ShowOnIcon aria-label={t('auth.inputPassword.tooltipShow')} />
                                        </Tooltip>
                                    )
                                }
                                onClick={handleToggleShowPassword(!showPassword)}
                            />
                        )
                    }
                    maxLength={64}
                    ref={ref}
                    classes={inputClasses}
                    inputWrapperRef={inputWrapperRef}
                    {...InputProps}
                />
            </Tooltip>
        ) : (
            <TextField
                label={t('auth.inputPassword.label')}
                placeholder={t('auth.inputPassword.placeholder')}
                onFocus={handleFocus}
                onBlur={handleBlur}
                type={showPassword ? 'text' : 'password'}
                adornmentEnd={
                    !hideShowButton && (
                        <Button
                            type="button"
                            variant="text"
                            color="gray"
                            isRound
                            size="small"
                            iconStart={
                                showPassword ? (
                                    <Tooltip content={t('auth.inputPassword.tooltipHide')}>
                                        <ShowOffIcon aria-label={t('auth.inputPassword.tooltipHide')} />
                                    </Tooltip>
                                ) : (
                                    <Tooltip content={t('auth.inputPassword.tooltipShow')}>
                                        <ShowOnIcon aria-label={t('auth.inputPassword.tooltipShow')} />
                                    </Tooltip>
                                )
                            }
                            onClick={handleToggleShowPassword(!showPassword)}
                        />
                    )
                }
                maxLength={64}
                ref={ref}
                classes={inputClasses}
                {...InputProps}
            />
        );
    }
);

export default TextFieldPassword;
