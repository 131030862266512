import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import {
    DashboardFilledIcon,
    DocFilledIcon,
    DotsIcon,
    HexagonFilledIcon,
    ProfileFilledIcon,
    SocialTelegramFilledIcon,
    TagCloseFilledIcon
} from 'ui/Icons/Icons';
import ProfileModal from '../ProfileModal/ProfileModal';
import NavigationMoreModal from '../NavigationMoreModal/NavigationMoreModal';
import StoreSettingsModal from '../StoreSettingsModal/StoreSettingsModal';
import styles from './Navigation.module.scss';

export const TELEGRAM_LINK = 'https://t.me/paycashchat';

export function Navigation() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <nav className={styles.Nav}>
            <ul className={styles.NavList}>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.dashboard
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.dashboard}
                        end
                        iconStart={<DashboardFilledIcon />}
                    >
                        {t('navigation.dashboard')}
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.transactions
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.transactions}
                        iconStart={<DocFilledIcon />}
                    >
                        {t('navigation.transactions')}
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root: pathname.includes(URLS_MAP.developers.index)
                                ? cn(styles.NavLink, styles.NavLinkActive)
                                : styles.NavLink
                        }}
                        to={URLS_MAP.developers.index}
                        iconStart={<TagCloseFilledIcon />}
                    >
                        {t('navigation.forDevelopers')}
                    </Button>
                </li>
            </ul>
        </nav>
    );
}

export function NavigationMobile() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const profileModal = useDialog();
    const moreModal = useDialog();
    const settingsModal = useDialog();

    const handleShowSettingsModal = () => settingsModal.setOpen(true);
    const handleShowMoreModal = () => moreModal.setOpen(true);

    const handleMoreModalOptionClick = (id: string) => {
        switch (id) {
            case 'settings': {
                handleShowSettingsModal();
                break;
            }
            case 'profile': {
                profileModal.setOpen(true);
                break;
            }
            default:
                break;
        }
    };

    return (
        <div className={cn(styles.Bar, !pathname.includes(URLS_MAP.developers.index) && styles.BarWithShadow)}>
            <nav className={styles.BarNav}>
                <ul className={styles.BarNavList}>
                    <li className={styles.BarNavItem}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.dashboard
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : styles.BarNavLink
                            }}
                            to={URLS_MAP.dashboard}
                            end
                            fullWidth
                            iconStart={<DashboardFilledIcon />}
                        >
                            {t('navigation.dashboard')}
                        </Button>
                    </li>
                    <li className={styles.BarNavItem}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.transactions
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : styles.BarNavLink
                            }}
                            to={URLS_MAP.transactions}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            {t('navigation.transactions')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem, styles.BarNavItemHideOnMobileLandscape)}>
                        <Button
                            classes={{ root: styles.BarNavLink }}
                            onClick={() => profileModal.setOpen(true)}
                            fullWidth
                            iconStart={<ProfileFilledIcon className={styles.BarNavIconProfile} />}
                        >
                            {t('navigation.profile')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem, styles.BarNavItemHideOnMobileLandscape)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root: pathname.includes(URLS_MAP.developers.index)
                                    ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                    : styles.BarNavLink
                            }}
                            to={URLS_MAP.developers.index}
                            fullWidth
                            iconStart={<TagCloseFilledIcon />}
                        >
                            {t('navigation.forDevelopers')}
                        </Button>
                    </li>
                    <li className={styles.BarNavItem}>
                        <Button
                            as="a"
                            href={TELEGRAM_LINK}
                            target="_blank"
                            rel="noreferrer"
                            isDark
                            classes={{ root: styles.BarNavLink }}
                            fullWidth
                            iconStart={<SocialTelegramFilledIcon />}
                        >
                            {t('navigation.support')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem, styles.BarNavItemHideOnMobileLandscape)}>
                        <Button
                            classes={{ root: styles.BarNavLink }}
                            onClick={handleShowSettingsModal}
                            fullWidth
                            iconStart={<HexagonFilledIcon />}
                        >
                            {t('navigation.settings')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem, styles.BarNavItemShowOnMobileLandscape)}>
                        <Button
                            classes={{ root: cn(styles.BarNavLink, moreModal.open && styles.BarNavLinkActive) }}
                            onClick={handleShowMoreModal}
                            fullWidth
                            iconStart={<DotsIcon />}
                        >
                            {t('navigation.more')}
                        </Button>
                    </li>
                </ul>
            </nav>
            <ProfileModal {...profileModal} />
            <NavigationMoreModal onClick={handleMoreModalOptionClick} {...moreModal} />
            <StoreSettingsModal {...settingsModal} />
        </div>
    );
}
