import React from 'react';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import Profile from '../Profile/Profile';
import { View } from '../Profile/Profile.d';

export default function ProfileModal({ open, setOpen, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const [view, setView] = React.useState(View.Confirm);

    const handleClose = () => setOpen(false);
    const handleModalUnmount = () => setView(View.Main);

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess}
            onUnmount={handleModalUnmount}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            {...props}
        >
            <Profile view={view} setView={setView} onClose={handleClose} />
        </Dialog>
    );
}
