export default {
    title: 'Для разработчиков',

    tabs: {
        docs: 'Документация',
        integrations: 'Список интеграций'
    },

    sections: {
        workingWithApi: 'Работа с API',
        integrations: 'Интеграции'
    },

    documentation: {
        title: 'Документация',

        btnBack: 'Перейти к предыдущему разделу',
        btnNext: 'Перейти к следующему разделу',

        begin: {
            title: 'Начало',
            title2: 'Начало работы',

            content: [
                'Для начала работы с API PayCash for store Вам необходимо будет использовать секретный ключ магазина, который был получен при создании магазина.',
                'Если вы не сохранили ключ, Вы можете его перегенерировать его в разделе <1>Магазины > API ключ > Доп.меню > Сгенерировать новый API ключ</1>.',
                'Полученный секретный ключ необходимо сохранить в надежном месте - он будет использоваться для подтверждения запросов.',
                'Все запросы отправляются на url <0>https://api.pc4.store/</0>'
            ]
        },

        auth: {
            title: 'Авторизация',

            content: [
                'Все запросы должны включать авторизационный хэдер вида <0>BasicAuth (store_id, store_secret_key)</0>',
                'Пример <1>curl https://api.pc4.store/v1/create  -u store_id:store_secret_key</1>',
                'В случае отсутствия хэдера авторизации Вы получите <0>JSON ответ со статусом 401</0>',
                'Если магазин с таким id не найден или ключ неправильный <0>JSON ответ со статусом 403</0>'
            ]
        },

        createOrder: {
            title: 'Создание заказа',
            description: 'Для создания заказа клиенту необходимо выполнить post запрос на url <0>/v1/create</0>',

            table: {
                headers: ['Параметр', 'описание', 'пример'],

                description: {
                    amount_to_pay: 'Сумма заказа в cash токенах (строкой)',
                    currency_name: 'Токен',
                    currency_smart_contract:
                        'Смарт контракт, в котором работает токен (все поддерживаемые валюты вы можете найти внизу страницы)',
                    response_url: 'Url, на который придет ответ о успешной или неуспешной оплате',
                    merchant_order_id: 'Уникальный идентификатор заказа в магазине',
                    description: 'Описание заказа. Будет отображаться на странице оплаты',
                    expiration_timeout:
                        'Время, после которого заказ станет невалидным в минутах. По умолчанию 60 минут',
                    success_payment_redirect_url:
                        'Url, на который пользователя перебросит с платежной страницы в случае успешной оплаты заказа',
                    failed_payment_redirect_url:
                        'Url, на который пользователя перебросит с платежной страницы в случае отмены заказа или истечении срока оплаты',
                    allowed_methods:
                        'Список возможных методов для оплаты. Если оплата банковской картой выключена для продавца - эти методы будут проигнорированы. Если валюта заказа USDT - пользователь сможет оплатить USDT через любой блокчейн из списка'
                }
            },

            response: {
                title: 'Клиент сразу получает ответ в зависимости от результата',
                description_success: 'В случае успешного создания заказа:',
                description_failure: 'В случае не успешного создания заказа:',
                example: 'смотри раздел "Информация о заказе"'
            },

            statuses: {
                title: 'Возможные статусы ответа ',
                OK: 'Заказ успешно создан',
                ERROR: 'Некорректный запрос',
                description:
                    'В случае передачи некорректного токена авторизации в запрос, придет <0>https://api.pc4.store/</0>',
                orders: 'В случае, если создание заказов недоступно, вы получите <0>JSON ответ со статусом 503</0>',
                validation: 'В случае ошибки валидации запроса вы получите <0>JSON ответ со статусом 400</0>',
                server: 'В случае внутренней ошибки сервера вы получите <0>JSON ответ со статусом 500</0>'
            },

            currencies: {
                title: 'Список поддерживаемых валют',
                smartContract: 'Смарт контракт'
            }
        },

        orderDetails: {
            title: 'Информация о заказе',
            content: [
                'Чтобы получить информацию о заказе необходимо выполнить GET запрос на адрес <0>/v1/order_info/\u2039order_id\u203a</0>',
                '<0>order_id </0> Уникальный идентификатор заказа в платежной системе',
                'Формат ответа:',
                'Если заказ не найден придёт ответ вида:',
                'В случае передачи некорректного токена авторизации в запрос, придет <0>JSON ответ с http статусом 401 или 403</0>'
            ],

            statuses: {
                title: 'Возможные статусы заказа',
                content: [
                    '<0>CREATED</0> Заказ создан',
                    '<0>MONEY_RECEIVED</0> Оплата получена (но транзакция не irreversible)',
                    '<0>PAID</0> Оплата получена, транзакция необратима (транзакция становится необратимой через 3 минуты после проведения оплаты)',
                    '<0>CANCELLED</0> Заказ был отменен',
                    '<0>EXPIRED </0> Оплата не была получена в установленное время'
                ]
            },

            example: {
                id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # уникальный идентификатор заказа в платежной системе',
                sequentNumber: '1 # порядковый номер заказа в магазине',
                amount: {
                    fullAmount: '"101.05", # сумма, которую должен отправить клиент',
                    amountAfterTax: '"100.24875", # сумма, которую получит магазин',
                    fee: '"0.25125" # комиссия за перевод'
                },
                currency: {
                    name: '"USDCASH", # название токена',
                    smartContract: '"token.pcash", # смарт контракт токена',
                    precission: '5 # количество значимых знаков после запятой'
                },
                status: '"CREATED" # статус заказа',
                expirationDate: '"2021-02-05T13:54:36.691514" # время в UTC-0 когда заказ станет невалидным',
                paymentUrl: '"<https://pcash.store/payment/1235>" # ссылка на страницу с формой оплаты',
                responseUrl: '"https://api.my_market.com/order_payment_result" # url на который придёт callback',
                successPaymentRedirectUrl:
                    '"https://my_market.com/order/1234/successs" # переход в случае успешной оплаты',
                failedPaymentRedirectUrl:
                    '"https://my_market.com/order/1234/failed" # переход в случае неуспешной оплаты',
                isTest: 'false # является ли заказ тестовым',
                details: {
                    merchantOrderId: '"1234" # id заказа на сайте магазина',
                    description: '"order description" # описание заказа'
                },
                paymentTransfer: {
                    description: '# транзакция на оплату заказа (если заказ не был оплачен это поле отсутствует)',
                    id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # уникальный идентификатор перевода',
                    fullAmount: '"101.05", # сумма,которую отправил клиент',
                    amountAfterTax: '"100.24875", # сумма, которую получил магазин',
                    fee: '"0.25125" # комиссия за перевод',
                    name: '"USDCASH", # название токена',
                    smartContract: '"token.pcash", # смарт контракт токена',
                    precission: '5 # количество значимых знаков после запятой',
                    sender: '"12345.pcash" # eos аккаунт, с которого были отправлены средства',
                    receiver: '"141111.pcash" # eos аккаунт, на который были отправлены средства',
                    txnType: '"ORDER_PAYMENT" # цель перевода',
                    status: '"ACCEPTED" # статус перевода',
                    memo: 'order_id: ca2c5cc2-f258-4ead-97e5-99ba64641b4d',
                    txid: '"92003a5982d2491dcf4d285794d906f6c5405cd7b188e85467261952ac5c417e" # идентификатор транзакции в блокчейне EOS',
                    blockNumber: '432542 # номер блока, в который попала транзакция',
                    globalSequence: '54452 # идентификатор transfer action',
                    isIrreversible: 'true # является ли транзакция полностью необратимой',
                    isReversed: 'false # была ли транзакция отменена',
                    allowedMethods: `[ # список возможных методов для оплаты
                "EOS",
                "ETHER",
                "TRON",
                "RUS_PAY",
                "WORLD_PAY"
            ]`
                }
            }
        },

        createTransfer: {
            title: 'Перевод средств',
            description: 'Для создания перевода клиенту необходимо выполнить post запрос на url <0>/v1/transfer</0>',

            table: {
                headers: ['Параметр', 'описание', 'пример'],

                description: {
                    amount: 'Сумма заказа в cash токенах (строкой)',
                    currency_name: 'Cash токен',
                    currency_smart_contract:
                        'Смарт контракт в котором работает токен (все поддерживаемые валюты наведены в соответствующем разделе)',
                    eos_account: 'EOS аккаунт на который будут отправлены средства',
                    response_url: 'Url на который придёт ответ о успешной или неуспешной оплате',
                    fiat_method_id:
                        'Если указано, то выплата считается фиатной. Можно указывать только id методов, у которых direction = OUT (см. запрос fiat_methods)',
                    uniqId: 'Уникальный идентификатор платежа в магазине'
                }
            },

            response: {
                title: 'Клиент сразу получает ответ в зависимости от результата',
                description_success: 'В случае успешного создания перевода:',
                description_failure: 'В случае ошибки:',
                fiat_method_id: 'Если указан <0>fiat_method_id</0>, то в <0>eos_account</0> нужно указать номер карты',
                example: 'ID созданного перевода'
            },

            statuses: {
                title: 'Возможные статусы ответа ',
                content: [
                    'Смотри раздел “Создание заказа“',
                    'После того как транзакция станет необратимой или отменится клиент получает post запрос на response_url с данными по переводу (формат в разделе “Информация о заказе“ ). Смотрите детали по обработке запроса в разделе “Callback“',
                    'Важно: статус перевода ACCEPTED означает что сервис обработал запрос и совершил перевод, но транзакция все еще может быть отвергнута. Чтобы убедится что транзакция необратима дождитесь пока поле transfer.action.is_irreversilble станет True (обычно на это уходит 3 минуты). По завершению этого периода Вы получите callback на указанный response_url. Также Вы можете отслеживать состояние перевода сами (смотри “Информация о переводе“)'
                ]
            }
        },

        transferDetails: {
            title: 'Информация о переводе',
            content: [
                'Чтобы получить информацию о заказе необходимо выполнить GET запрос на адрес <0>/v1/transfer_info/\u2039transfer_id\u203a</0>',
                '<0>transfer_id</0> Уникальный идентификатор заказа в платежной системе',
                'Формат ответа:',
                'Если трансфер не найден придёт ответ вида:',
                'В случае передачи некорректного токена авторизации в запрос, придет <0>JSON ответ с http статусом 401 или 403</0>',
                'Если платеж не фиатный, значение <0>fiat_amount</0> и <0>fiat_method</0> будут <0>null</0>'
            ],
            example: {
                id: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # уникальный идентификатор перевода',
                fullAmount: '"101.05", # отправленная сумма',
                amountAfterTax: '"100.24875", # полученная сумма',
                amountFee: '"0.25125" # комиссия за перевод',
                currencyName: '"USDCASH", # название токена',
                smartContract: '"token.pcash", # смарт контракт токена',
                precission: '5 # количество значимых знаков после запятой',
                sender: '"12345.pcash" # eos аккаунт, с которого были отправлены средства',
                receiver: '"141111.pcash" # eos аккаунт, на который были отправлены средства',
                txnType: '"WITHDRAW" # цель перевода',
                status: '"ACCEPTED" # статус перевода',
                memo: 'user_id: bc2c5cc2-f258-4ead-97e5-99ba74641b4f',
                txid: '"92003a5982d2491dcf4d285794d906f6c5405cd7b188e85467261952ac5c417e" # идентификатор транзакции в блокчейне EOS',
                blockNumber: '432542 # номер блока, в который попала транзакция',
                globalSequence: '54452 # идентификатор transfer action',
                isIrreversible: 'true # является ли транзакция полностью необратимой',
                isReversed: 'false # была ли транзакция отменена',
                amount: '"100.5" # отправленная сумма',
                fiatAmountFee: '"0.5" # комиссия за перевод',
                fiatMethodId: '"ca2c5cc2-f258-4ead-97e5-99ba64641b4d" # уникальный идентификатор фиатного метода',
                fiatMethodName: '"best2pay" # имя платежной системы',
                fiatMethodCurrencyName: '"RUB" # валюта платежной системы',
                location: '"RUS_PAY" # российская либо зарубежная платежная система'
            }
        },

        currencies: {
            title: 'Список валют',
            content: [
                'Чтобы получить информацию о валютах необходимо выполнить GET запрос на адрес <0>/v1/currencies</0>. Возвращает список активных криптовалют.',
                'Формат ответа:'
            ]
        },

        fiatMethods: {
            title: 'Список платёжных систем',
            content: [
                'Чтобы получить информацию о платёжных системах необходимо выполнить GET запрос на адрес <0>/v1/fiat_methods</0>. Возвращает список активных фиатных платёжных систем.',
                'Формат ответа:'
            ]
        },

        callback: {
            title: 'Callback',
            content: [
                'После принятия оплаты от пользователя <0>MONEY_RECEIVED</0> когда транзакция станет необратимой <0>PAID</0>',
                'по истечению expiration_date <0>EXPIRED</0> или при отмене заказа из кабинета <0>CANCELLED</0> клиент получает post запрос',
                'на <0>response_url</0> с данными по заказу формат в разделе “Информация о заказе“',
                'Важно: статус заказа MONEY_RECEIVED означает что магазин получил деньги, но транзакция все еще может быть отвергнута. Статус PAID означает что транзакция уже необратима.',
                'Запрос также содержит хэдер с подписью вида',
                'Чтобы убедиться в подлинности запроса необходимо проверить его подпись.',
                'Используемый алгоритм <0>Ed25519</0>',
                'Публичный ключ <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0>',
                'Чтобы проверить подпись нужно:',
                'Учитывая что',
                '<0>Тело сообщения</0> строка полученная в пункте 2',
                '<0>Подпись </0> значение из хэдера SIGNATURE ',
                'HEX значение публичного ключа <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0> '
            ],

            steps: [
                ['Привести <0>тело запроса</0> к JSON', 'ВАЖНО: не должно быть пробелов между ключом и значением'],
                ['Взять   <0>хэш sha256</0> от JSON строки из пункта 1. На выходе должна получится hex строка'],
                ['Проверить <0>подпись</0> по алгоритму <0>Ed25519</0>']
            ]
        },

        lifecycles: {
            title: 'Циклы жизни заказа',
            content: ['Успешный заказ', 'Заказ был отменен', 'Истек срок ожидания оплаты', 'Транзакция была отвергнута']
        },

        sdk: {
            title: 'SDK',
            content: [
                'Для облегчения интеграции с нашей платежной системой, реализованы библиотеки для следующих языков программирования:'
            ]
        },

        testing: {
            title: 'Тестирование',
            content: [
                'Для тестирования синхронизации с платежной системой, войдите в личный кабинет на сайте, выберете нужный Вам магазин и переведите его в тестовый режим (включен по умолчанию).',
                'Все заказы, созданные пока магазин находится в тестовом режиме, автоматически становятся тестовыми. На странице оплаты появятся кнопки для управления циклом жизни заказа.'
            ]
        }
    },
    apikey: {
        title: 'API ключ'
    },
    logs: {
        title: 'Логи'
    },
    webhooks: {
        title: 'Вебхуки'
    },
    integrations: {
        title: 'Интеграция',

        tilda: {
            title: 'Интеграция с Tilda',

            auth: {
                title: 'Авторизация',
                description:
                    'Перейдите на сайт Тильды и авторизируйтесь в свой аккаунт. На открывшейся странице выберите необходимый вам сайт.',
                buttonTitle: 'Перейти на сайт Тильды'
            },

            websiteSettings: {
                title: 'Настройки сайта',
                description: 'На открывшейся странице с информацией о сайте нажмите на кнопку “Настройки сайта”.'
            },

            paymentSystems: {
                title: 'Платежные системы',
                description:
                    'Слева в меню навигации перейдите в раздел “Платежные системы”. Далее прокрутите страницу вниз до конца и выберите пункт “Универсальная платежная система”'
            },
            newPaymentSystem: {
                title: 'Новая платежная система',
                description:
                    'Нажмите на поле “Шаблон настроек”, прокрутите открывшийся выпадающий список до конца и выберите пункт “Новая платежная система (для разработчиков)”'
            },

            login: {
                title: 'Логин',
                description:
                    'После того, как вы выбрали “Новая платежная система” в выпадающем списке, на странице появится список полей, которые необходимо заполнить. В поле “Логин” необходимо вписать ID магазина с сайта pc4.store.',
                explanation:
                    'Чтобы найти ID вашего магазина, нажмите на кнопку настроек возле активного магазина на сайте pc4.store, откроется модальное окно - “Настройки”. В открывшемся модальном окне, под выбором рабочего/тестового режима вы найдете нужный ID'
            },

            secretSigning: {
                title: 'Секрет для подписи заказа',
                description: 'Следующее поле для заполнения - “Секрет для подписи заказа”'
            },

            localization: {
                title: 'Локализация',
                description: 'Теперь необходимо указать валюту, страну и язык виджета оплаты.',
                step_1: 'Выберите валюту - RUB или USD',
                step_2: 'Укажите двухбуквенный код вашей страны. Код вашей страны можно найти нажав на кнопку ниже',
                step_3: 'Укажите язык - RU или EN',
                buttonTitle: 'Двухбуквенные коды стран'
            },

            advancedSettings: {
                title: 'Расширенные настройки',
                description: 'Чтобы перейти дальше к заполнению полей, нажмите на кнопку “Расширенные настройки”'
            },

            apiUrl: {
                title: 'API URL',
                description: 'Вставьте текст, указанный ниже, в поле API URL'
            },

            matchingFields1: {
                title: 'Список соответствия полей, ч. 1',
                description:
                    'Теперь необходимо заполнить поля, как указано на скриншоте выше. Значения для полей вы можете скопировать ниже.',
                field_1: 'Логин',
                field_2: 'Валюта',
                field_3: 'Язык',
                field_4: 'Страна',
                field_5: 'URL для уведомлений',
                field_6: 'Номер заказа',
                field_7: 'Описание заказа'
            },

            matchingFields2: {
                title: 'Список соответствия полей, ч. 2',
                description: 'Продолжите заполнение полей.',
                field_1: 'Сумма заказа',
                field_2: 'Email покупателя',
                field_3: 'Телефон покупателя',
                field_4: 'Имя покупателя',
                field_5: 'Подпись',
                field_6: 'Товары'
            },

            addAdditionalFields: {
                title: 'Добавить дополнительные поля',
                description:
                    'Теперь необходимо добавить дополнительный поля. Для этого прокрутите страницу немного ниже до раздела “Список дополнительных полей” и нажмите кнопку “Добавить поле 4 раза“. У вас должны появится 4 незаполненных поля'
            },

            fillingAdditionalFields: {
                title: 'Заполнение дополнительных полей',
                description:
                    'После того, как вы добавили поля, их необходимо заполнить. В левой части указывается название поля, в правой части указывается значение',
                field_1: 'Поле 1',
                field_2: 'Поле 2',
                field_3: 'Поле 3',
                field_4: 'Поле 4',
                description_1:
                    'В поле значение необходимо вставить ссылку на страницу, на которую будет автоматически перенаправлять пользователя при успешной оплате',
                description_2:
                    'В поле значение необходимо вставить ссылку на страницу, на которую будет автоматически перенаправлять пользователя при НЕуспешной оплате',
                description_3:
                    'В поле значение укажите USDCASH или USDT, в зависимости от того, какую криптовалюту вы хотите получать после оплаты на баланс',
                description_4:
                    ' В поле значение укажите срок действия счета(время существования ссылки на оплату) для страницы оплаты в минутах. Например - 10'
            },

            signingOrder: {
                title: 'Правила и алгоритм подписи заказа',
                description: 'Далее необходимо отредактировать поля подписи заказа',
                example: 'Особые правила',
                step_1: 'Нажмите на поле “Правила подписи” и выберите - “Особые правила”',
                step_2: 'В поле “Особые правила подписи” вставьте значение из поля ниже',
                step_3: 'Нажмите на поле “Алгоритм шифрования подписи” и выберите в выпадающем списке - “SHA-256”',
                step_4: 'Поставьте галочку возле “Использовать секрет в качестве ключа алгоритма (HMAC)”'
            },

            signingNotifications: {
                title: 'Правила и алгоритм подписи уведомлений',
                description: 'Далее необходимо отредактировать поля подписи уведомления',
                example: 'Особые правила',
                step_1: 'Снимите галочку с пункта “Использовать аналогичные правила для подписи уведомления”',
                step_2: 'Нажмите на поле “Правила подписи” и выберите - “Особые правила”',
                step_3: 'В поле “Особые правила подписи” вставьте значение из поля ниже',
                step_4: 'Нажмите на поле “Алгоритм шифрования подписи” и выберите в выпадающем списке - “SHA-256”',
                step_5: 'Поставьте галочку возле “Использовать секрет в качестве ключа алгоритма (HMAC)”'
            },

            successfulPayment: {
                title: 'Признак успешного платежа и ID транзакции',
                description: 'Далее необходимо настроить уведомления об успешном платеже и ID транзакции',
                step_1: 'Поле 1',
                step_2: 'Значение 1',
                step_3: 'Поле 2',
                step_4: 'Нажмите на “Значение 2” и в выпадающем списке выберите - UUID'
            },

            successAndError: {
                title: 'Успех и ошибка',
                description:
                    'Данные поля должны быть заполнены по умолчанию, но вдруг, если они пустые, введите следующие данные',
                step_1: 'Ответ об успехе уведомления',
                step_2: 'Ответ об ошибке уведомления'
            },

            paymentMethodName: {
                title: 'Название платежного метода',
                description_1: 'Далее проматываем страницу вниз до раздела “Заголовок”',
                description_2: 'Теперь необходимо назвать платежный метод и принять отказ от ответственности.',
                example: 'Заголовок',
                step_1: 'Введите значение в поле заголовок, которое указано ниже',
                step_2: 'Поставьте галочку',
                step_3: 'Нажмите кнопку “Добавить”'
            },

            ready: {
                title: 'Готово!',
                description: 'На странице платежные методы появится настроенная платежная система'
            }
        },

        shopify: {
            title: 'Интеграция с Shopify',

            auth: {
                title: 'Авторизация',
                description:
                    'Перейдите на сайт Shopify и авторизируйтесь в свой аккаунт. На открывшейся странице выберите необходимый вам сайт.',
                buttonTitle: 'Перейти на сайт Shopify'
            },

            websiteSettings: {
                title: 'Настройки сайта',
                description: 'На открывшейся странице выбранного сайта нажмите на кнопку “Settings”'
            },

            payment: {
                title: 'Оплата',
                description: 'Слева в меню навигации перейдите в раздел “Checkout”'
            },

            additionalScripts: {
                title: 'Дополнительные скрипты',
                description:
                    'Перейдя на страницу “Checkout”, прокрутите страницу вниз и найдите блок “Order status page”, в текстовое поле “Additional scripts” вставьте следующий скрипт. Далее нажмите на кнопку “Save”'
            },

            payments: {
                title: 'Платежи',
                step_1: 'Перейдите в раздел “Payments”',
                step_2: 'Найдите блок “Manual payment methods” и нажмите на кнопку “Add manual method”',
                step_3: 'В выпадающем списке выберите пункт “Create custom payment method”'
            },

            addingPaymentMethod: {
                title: 'Добавление платежного метода',
                step_1: 'В открывшемся окне введите название платежного метода в поле “Custom payment method name”',
                step_2: 'Нажмите на кнопку “Activate”'
            },

            applications: {
                title: 'Приложения',
                step_1: 'Теперь перейдите в раздел “Apps and sales channels”',
                step_2: 'Нажмите на кнопку “Develop apps”'
            },

            newApplications: {
                title: 'Новое приложение',
                description: 'Нажмите на кнопку “Create an app”'
            },

            appTitle: {
                title: 'Название',
                step_1: 'В открывшемся окне введите название в поле “App name”',
                step_2: 'Нажмите на кнопку “Create app”'
            },

            apiSettings: {
                title: 'Настройки API',
                description: 'На открывшейся странице нажмите на кнопку “Configure Admin API scopes”'
            },

            orders: {
                title: 'Заказы',
                step_1: 'В блоке “Admin Api access copes” найдите секцию “Orders” и поставьте галочки напротив пунктов “write_orders” и “read_orders”',
                step_2: 'Нажмите на кнопку “Save”'
            },

            Installation: {
                title: 'Установка',
                description: 'Нажмите на кнопку “Install app” для установки приложения'
            },

            acceptInstallation: {
                title: 'Подтвердите установку',
                description: 'В открывшемся окне нажмите на кнопку “Install”'
            },

            apiKeys: {
                title: 'API ключи',
                description: 'Перейдите на страницу “API credentials”'
            },

            done: {
                title: 'Готово',
                description: 'Прокрутите вниз и сохраните себе значение из следующих полей:',
                step_1: 'Admin API access token',
                step_2: 'API secret key'
            }
        }
    },

    newIntegration: {
        title: 'Новая интеграция',
        chooseCms: 'Выберите CMS',
        wait: 'Ожидайте',
        soon: 'Скоро',
        delete: 'Удалить',
        edit: 'Редактировать',
        linkToDocs: 'Подробная инструкция по интеграции',
        newApiKey: `Генерируем новый\nAPI и секретный ключи`,
        continue: 'Продолжить',
        idNotFound: 'Идентификатор магазина не найден',
        requiredField: 'Заполните поле',

        shopifySteps: {
            addScriptStep: {
                title: 'Добавление в “Скрипты”',
                description: 'Чтобы принимать оплату в Shopify через PayCash for Store, добавьте новый скрипт.',
                step: 'Для этого перейдите в'
            },

            addMethodsStep: {
                title: 'Добавление в “Платежные методы”',
                description: 'Добавьте новый платежный метод.',
                step_1: 'Для этого перейдите в',
                step_2: 'Нажмите на выпадающий список',
                step_3: 'Выберите',
                step_4: 'В поле ',
                step_5: 'введите'
            },

            storeStep: {
                title: 'Данные магазина',
                selectLabel: 'Выберите магазин',
                description_1: 'Токен, используемый при оплате в USD',
                description_2:
                    'Название магазина вы можете найти, перейдя в настройки магазина. В левой верхней части открывшегося окна будет название',
                placeholder: 'Выберите активный магазин',
                noOptions: 'Нет активных магазинов',
                inputLabel: 'Название магазина',
                past: 'Вставить',
                create: 'Создать'
            }
        },

        tildaSteps: {
            storeStep: {
                title: 'Выберите магазина',
                description: 'Выберите магазин, который вы хотите связать с Тильдой',
                label: 'Магазин',
                placeholder: 'Выберите активный магазин',
                noOptions: 'Нет активных магазинов'
            },

            planStep: {
                title: 'Тариф на Тильде',
                description:
                    'Для использования платежной системы pc4.store, необходимо использовать один из платных тарифов на Тильде',
                alert: 'Невозможно интегрировать pc4.store, используя бесплатный тариф на Тильде',
                label: 'Я подтверждаю, что использую один из платных тарифов на Тильде'
            },

            keyStep: {
                title: 'Новый API ключ и секрет',
                description:
                    'Чтобы интеграция правильно работала, необходимо сгенерировать новый секретный ключ, но вот засада, чтобы сгенерировать секретный ключ, необходимо обновить API ключ',
                alert: 'При генерации нового API ключа, старый ключ будет удален и недействителен',
                label: 'Я уверен, что хочу сгенерировать новый API и секретный ключи'
            },

            confirmStep: {
                title: 'Подтверждение',
                description: 'Для того чтобы продолжить, необходимо ввести пароль'
            },

            saveStep: {
                title: 'Новый API ключ и секрет',
                stepListTitle: 'Сохранение',
                description: 'Сохраните себе оба ключа, потому что вы не сможете их больше посмотреть',
                param_id: 'ID Магазина',
                param_apiKey: 'Ключ API',
                param_secretKey: 'Секретный ключ',
                alert: 'При генерации нового API ключа, старый ключ будет удален и недействителен',
                checkbox_apiKey: 'Я сохранил API ключ',
                checkbox_secretKey: 'Я сохранил секретный ключ'
            },

            successStep: {
                title: 'Готово',
                description: 'Вы добавили интеграцию Tilda для магазина {{storeName}}',
                alert: 'Интеграции с Tilda не отображаются в таблице всех интеграций магазина',
                close: 'Закрыть'
            }
        },

        noData: {
            title: 'Интеграции отсутствуют',
            description: 'Вы пока не добавили\nни одной интеграции'
        },

        tableHeader: {
            integration: 'Интеграция',
            created: 'Создано',
            storeName: 'Название магазина'
        }
    },

    deleteIntegration: {
        notFound: 'Интеграция не найдена',
        deleteSuccessful: 'Интеграция успешно удалена',
        confirmation: 'Подтверждение',
        description_shop: 'Вы уверены, что хотите удалить интеграцию Shopify для магазина <0>{{shopName}}</0>?',
        description_1: 'Вы уверены, что хотите удалить интеграцию Shopify?',
        cancel: 'Нет, не удалять',
        accept: 'Да, удалить',
        errors: {
            DeleteShopifyIntegrationSuccess: 'Интеграция успешно удалена',
            ShopifyIntegrationNotFoundError: 'Интеграция не найдена'
        }
    },

    editIntegration: {
        errors: {
            UpdateShopifyIntegrationSuccess: 'Интеграция магазина {{shopName}} успешно отредактирована',
            ConnectShopifySuccess: 'Интеграция магазина {{shopName}} успешно создана',
            InvalidCredentialsError: 'Неверный токен или название магазина',
            ShopifyPermissionRequiredError: 'Нет доступа',
            InvalidParamsError: 'Параметры не верны - ',
            ShopifyIntegrationNotFoundError: 'Интеграция не найдена',
            ShopifyIntegrationExistsError: 'Интеграция уже существует'
        },

        edit: 'Редактировать интеграцию',
        save: 'Сохранить'
    }
};
