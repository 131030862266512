import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth/useAuth';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogHeader, DialogFooter, useDialog, DialogBody } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import styles from './AuthLogoutModal.module.scss';

export default function ModalLogout({ open, setOpen, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const { t } = useTranslation();
    const { logout } = useAuth();

    const formId = React.useId();

    const handleClose = () => setOpen(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleClose();
        logout();
    };

    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            {...props}
        >
            <DialogHeader title={t('auth.logout.title')} />
            <DialogBody>
                <form id={formId} onSubmit={handleSubmit}>
                    <div className={styles.Text}>{t('auth.logout.description')}</div>
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    form={formId}
                    size="large"
                    color="gray"
                    style={{ marginBottom: '0.5rem' }}
                    fullWidth
                >
                    {t('auth.logout.buttonSubmit')}
                </Button>
                <Button onClick={handleClose} size="large" fullWidth>
                    {t('auth.logout.buttonCancel')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
