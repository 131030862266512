import React from 'react';
import cn from 'classnames';
import PI from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './PhoneField.module.scss';

export type PhoneInputProps = {
    id?: string;
    inputClassName?: string;
    value?: string;
    inputExtraProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    name?: string;
    defaultValue?: string;
    required?: boolean;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    onChange?(v: string, config: any, event: React.ChangeEvent, formattedValue: string): void;
    onInput?(e: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
    onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
};

export default function PhoneInput(props: PhoneInputProps) {
    return (
        <PI
            containerClass={cn(styles.Container, 'react-tel-input')}
            inputClass={styles.Input}
            buttonClass={styles.Button}
            country="ru"
            {...props}
            masks={{
                az: '.. ... .. ..'
            }}
        />
    );
}
