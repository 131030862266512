import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useOrderRedirectUrlQuery } from 'apollo/generated';
import PaymentSuccess from 'assets/images/status-success-circle.svg';
import PaymentExpired from 'assets/images/status-pending-circle.svg';
import PaymentCancelled from 'assets/images/status-error-circle.svg';
import PaymentProcessing from 'assets/images/status-processing.svg';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { BlocksIoIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { PaymentStatusProps } from './PaymentStatus.d';
import styles from './PaymentStatus.module.scss';
import { TELEGRAM_LINK } from '../Navigation/Navigation';

export default function PaymentStatus({
    amount,
    children,
    domain,
    eosUrl,
    isCancelled,
    isExpired,
    isPaid,
    isProcessing,
    orderId
}: PaymentStatusProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();

    const orderRedirectUrlQuery = useOrderRedirectUrlQuery({
        variables: { id: orderId ?? '' },
        context: { headers: { Authorization: '' } }
    });

    const { data: orderRedirectUrl, loading } = orderRedirectUrlQuery;

    React.useEffect(() => {
        const timer = setTimeout(async () => {
            if (orderRedirectUrl?.orderRedirectUrl) {
                if (isPaid || isExpired || isCancelled) {
                    window.location.href = orderRedirectUrl?.orderRedirectUrl || '';
                } else {
                    onGlobalError(t('payment.status.error.OrderNotFoundError'));
                }
            }
        }, 10 * 1_000);

        return () => clearTimeout(timer);
    }, [
        isPaid,
        isExpired,
        isCancelled,
        orderId,
        onGlobalError,
        orderRedirectUrl?.orderRedirectUrl,
        orderRedirectUrl,
        t
    ]);

    return (
        <div
            className={cn(
                styles.Container,
                isPaid && styles.isPaid,
                isExpired && styles.isExpired,
                isCancelled && styles.isCancelled
            )}
        >
            <div className={styles.Content}>
                <div className={styles.Status}>
                    {isPaid && <img src={PaymentSuccess} alt="Payment success" className={styles.StatusIcon} />}
                    {isExpired && <img src={PaymentExpired} alt="Payment expired" className={styles.StatusIcon} />}

                    {isCancelled && (
                        <img src={PaymentCancelled} alt="Payment Cancelled" className={styles.StatusIcon} />
                    )}

                    {isProcessing && (
                        <img src={PaymentProcessing} alt="Payment processing" className={styles.StatusIcon} />
                    )}

                    <h2 className={styles.Title}>
                        {t('payment.title', {
                            context:
                                (isPaid && 'paid') ||
                                (isExpired && 'expired') ||
                                (isCancelled && 'cancelled') ||
                                (isProcessing && 'processing')
                        })}
                    </h2>

                    {(isExpired || isCancelled || isProcessing) && (
                        <p className={styles.Description}>
                            {t('payment.description', {
                                context:
                                    (isExpired && 'expired') ||
                                    (isCancelled && 'cancelled') ||
                                    (isProcessing && 'processing')
                            })}
                        </p>
                    )}
                </div>
                <div className={styles.Mobile}>
                    <div className={styles.StoreInfo}>{children}</div>

                    {isPaid && (
                        <div className={styles.PaymentInfo}>
                            {amount && <span className={styles.PaymentInfoText}>{t('payment.status.amount')}</span>}

                            <h2 className={styles.PaymentInfoAmount}>{amount}</h2>

                            {eosUrl && (
                                <div className={styles.ScanButtonContainer}>
                                    <Button as="a" href={eosUrl} fullWidth variant="text" iconStart={<BlocksIoIcon />}>
                                        {t('payment.btnCheckTransfer')} {domain}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    {isProcessing && (
                        <div className={styles.PaymentInfo}>
                            {amount && (
                                <span className={styles.PaymentInfoText}>
                                    {t('payment.status.amount', { context: 'processing' })}
                                </span>
                            )}

                            <h2 className={styles.PaymentInfoAmount}>{amount}</h2>
                        </div>
                    )}

                    {orderRedirectUrl?.orderRedirectUrl && (
                        <>
                            <Button
                                as="a"
                                href={orderRedirectUrl?.orderRedirectUrl || ''}
                                size="large"
                                fullWidth
                                loading={loading}
                                disabled={loading}
                            >
                                {t('payment.btnReturnToStore')}
                            </Button>
                            <p className={styles.Time}>{t('payment.time')}</p>
                        </>
                    )}

                    {isProcessing && (
                        <Button as="a" href={TELEGRAM_LINK} target="_blank" rel="noreferrer" size="large" fullWidth>
                            {t('payment.status.button.support')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
