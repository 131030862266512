export default {
    init: {
        title: 'Профиль',
        email: 'Почта',
        phone: 'Номер телефона',
        buttonAddEmail: 'Добавить почту',
        buttonAddPhone: 'Добавить номер телефона',
        buttonChangePassword: 'Сменить пароль',
        buttonEdit: 'Редактировать'
    },
    passwordVerification: {
        title: 'Подтверждение',
        description: 'Для того, чтобы продолжить, необходимо ввести пароль',
        password: 'Пароль',
        error: {
            requiredFields: 'Введите пароль',
            InvalidCredentialsError: 'Неверный пароль'
        }
    },
    email: {
        title_add: 'Добавить почту',
        title_edit: 'Редактировать почту',
        description_add: 'Для привязки почты, вам будет будет выслан код подтверждения',
        description_edit: 'Введите новую почту',
        description_code: 'Для подтверждения, вам на почту <0>{{account}}</0> был выслан код подтверждения',
        inputLabel: 'Почта',
        error: {
            requiredFields: 'Введите почту',
            emailInvalid: 'Неверный формат почты',
            InvalidCredentialsError: 'Неверный пароль',
            InvalidEmailError: 'Неверная почта',
            InvalidPasswordError: 'Неверный пароль',
            InvalidPhoneError: 'Неверный номер телефона',
            UserAlreadyExistsError: 'Данная почта уже используется'
        }
    },
    phone: {
        title_add: 'Добавить номер',
        title_edit: 'Редактировать номер ',
        description_add: 'Для привязки номера телефона, вам будет будет выслан код подтверждения',
        description_edit: 'Введите новый номер телефона',
        description_code: 'Для подтверждения, вам на номер телефона <0>{{account}}</0> был выслан код подтверждения',
        inputLabel: 'Номер телефона',
        error: {
            requiredFields: 'Введите номер телефона',
            InvalidCredentialsError: 'Неверный пароль',
            InvalidEmailError: 'Неверная почта',
            InvalidPasswordError: 'Неверный пароль',
            InvalidPhoneError: 'Неверный номер телефона',
            UserAlreadyExistsError: 'Данный номер телефона уже используется'
        }
    },
    password: {
        title_add: 'Добавьте пароль',
        title_edit: 'Сменить пароль',
        description_add: 'Чтобы пользоваться всеми функциями сайта, добавьте пароль',
        alertSuccess: 'Пароль успешно изменен',
        inputPasswordOld: {
            label: 'Старый пароль',
            placeholder: 'Введите старый пароль',
            error: {
                requiredFields: 'Введите старый пароль'
            }
        },
        inputPassword: {
            label: 'Новый пароль',
            placeholder: 'Введите новый пароль',
            error: {
                shortLength: 'Длина пароля должна быть больше 8 символов',
                noUppercase: 'Пароль должен содержать минимум одну заглавную букву',
                noNumber: 'Пароль должен содержать минимум одну цифру',
                noSpecial: 'Пароль должен содержать минимум один спецсимвол (!@#$%^&*—_+=;:,./?|`~{}[])'
            }
        },
        inputPasswordRepeat: {
            label: 'Новый пароль еще раз',
            placeholder: 'Повторите новый пароль',
            error: {
                notMatch: 'Введенные пароли не совпадают'
            }
        },
        error: {
            requiredFields: 'Для того чтобы продолжить, введите новый пароль и повторите его',
            InvalidCredentialsError: 'Неверный пароль',
            InvalidPasswordError: 'Неверный пароль'
        }
    },
    buttonContinue: 'Продолжить',
    buttonLogout: 'Выйти из аккаунта',
    buttonSave: 'Сохранить'
};
