export default {
    title: 'Create order',
    title_success: 'Order created',
    description_success: 'Order #{{orderNumber}} successfully created',
    currency: 'Select currency',
    fee: 'Fee',
    receive: 'The store will receive',
    receivePlaceholder: 'You will receive',
    send: 'For payment',
    sendPlaceholder: 'Client will pay',
    from: 'From',
    to: 'To',
    fiatWorld: 'International Bank',
    fiatRus: 'Russian Bank',
    method: 'Payment methods',
    inputExpirationTime: {
        label: 'Link lifetime',
        placeholder: 'Enter time'
    },
    inputDetails: {
        label: 'Description',
        placeholder: 'Enter order description'
    },
    inputRedirectSuccess: {
        label: 'Redirect upon successful payment',
        placeholder: 'Enter link'
    },
    inputRedirectFailure: {
        label: 'Redirect if payment fails',
        placeholder: 'Enter link'
    },
    inputCallback: {
        label: 'Callback link',
        placeholder: 'Enter link'
    },
    inputStoreId: {
        label: 'ID on the store website',
        placeholder: 'Enter a value'
    },
    paymentLink: 'Payment link',
    copied: 'Order link copied',
    error: {
        amountMin: 'Minimum {{amount}} {{currency}}',
        amountMax: 'Maximum {{amount}} {{currency}}',
        CurrencyNotFoundError: 'Currency not found',
        InvalidParamsError: 'Parameters are not valid ({{paramName}})',
        ServerProblemError: 'Problem with the server',
        StoreNotFoundError: 'Store not found',
        amountMinimum: 'Minimum order amount {{minAmount}}',
        amountTooLow: 'Order amount is too low',
        priceRequired: 'Enter order amount',
        storeIdAlreadyExist: 'Such an ID already exists on the store website. Try another one.',
        timeMaximum: `The maximum lifetime of a payment link is {{time}} min`,
        tokenRequired: 'Select a token'
    },
    buttonCreate: 'Create order',
    buttonContinue: 'Continue',
    buttonDone: 'Done',
    buttonBack: 'Back',
    buttonDetails: 'Order Details'
};
