import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonClasses } from 'ui/Button/Button.d';
import Button from 'ui/Button/Button';
import { SendFilledIcon } from 'ui/Icons/Icons';
import useCopy from 'hooks/useCopy/useCopy';

interface PaymentShareButtonProps {
    url?: string;
    title?: string;
    text?: string;
    classes?: Partial<ButtonClasses>;
}

export default function PaymentShareButton({ classes, url, title, text }: PaymentShareButtonProps) {
    const { t } = useTranslation();
    const copy = useCopy();

    const handleClick = () => {
        const shareData = {
            title: title || t('payment.shareModal.paymentLink'), // Title for (optional)
            text: text || url, // Text for share (optional)
            url: url || window.location.href // URL fo Share(necessarily)
        };

        if (navigator.share) {
            // Use Web Share API, if supported
            navigator.share(shareData).catch((error) => {
                // eslint-disable-next-line no-console
                console.error(`${t('payment.shareModal.shareError')} ${error}`);
            });
        } else if (navigator.userAgent.match(/Android/i)) {
            // If don't supported Web Share API. For Android, we use intents to open the "Share" dialog box
            try {
                const intent = `intent:${shareData.url}#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=${shareData.text};end;`;
                window.location.href = intent;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`${t('payment.shareModal.shareError')} ${error}`);
            }
        } else {
            copy(url || window.location.href, t('payment.shareModal.sendError'), t('global.error.tryLater'));
        }
    };

    return (
        <Button
            size="large"
            color="gray"
            iconStart={<SendFilledIcon />}
            fullWidth
            classes={classes}
            onClick={handleClick}
        >
            {t('payment.shareModal.send')}
        </Button>
    );
}
