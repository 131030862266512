import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { isAuthVar } from 'apollo/cache';
import { useChangePasswordMutation, useMeQuery } from 'apollo/generated';
import getErrorData from 'helpers/getErrorData/getErrorData';
import getPasswordValidate from 'helpers/getPasswordValidate/getPasswordValidate';
import validateInputValue from 'helpers/validateInputValue/validateInputValue';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useFormField from 'hooks/useFormField/useFormField';
import useAuth from 'hooks/useAuth/useAuth';
import Button from 'ui/Button/Button';
import Dialog, { DialogBody, DialogFooter, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';

const DRAG_ANIMATION_DURATION = 500;

export default function ProfilePasswordAddModal() {
    const { t } = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const onGlobalError = useGlobalError();
    const { logout } = useAuth();
    const isAuth = useReactiveVar(isAuthVar);

    const addPasswordModal = useDialog();

    const formId = React.useId();

    const formRef = React.useRef<HTMLFormElement>(null);

    const inputPassword = useFormField('');
    const inputPasswordRepeat = useFormField('');

    const meQuery = useMeQuery({ skip: !isAuth });
    const me = meQuery?.data?.me;
    const { hasPassword } = me ?? {};

    const [changePasswordMutation, { loading: loadingSignup }] = useChangePasswordMutation();

    const formErrorAnimation = () => {
        const className = 'DragAnimation';

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);

        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const handleLogout = async () => {
        inputPassword.change('');
        inputPasswordRepeat.change('');
        addPasswordModal.setOpen(false);
        logout();
        await meQuery.refetch();
    };

    const handleUnmount = () => {
        inputPassword.change('');
        inputPasswordRepeat.change('');
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        inputPassword.errorChange(false);
        inputPasswordRepeat.errorChange(false);

        const passwordValue = inputPassword.value.trim();
        const passwordRepeatValue = inputPasswordRepeat.value.trim();

        const { length, hasNumber, hasSpecial, hasUppercase } = getPasswordValidate(inputPassword.value);

        if (!passwordValue || !passwordRepeatValue) {
            formErrorAnimation();
            inputPassword.errorChange(true);

            return inputPasswordRepeat.errorChange(true, 'profile.password.error.requiredFields');
        }

        if (!length) return inputPassword.errorChange(true, t('profile.password.inputPassword.error.shortLength'));
        if (!hasNumber) return inputPassword.errorChange(true, t('profile.password.inputPassword.error.noNumber'));
        if (!hasUppercase)
            return inputPassword.errorChange(true, t('profile.password.inputPassword.error.noUppercase'));
        if (!hasSpecial) return inputPassword.errorChange(true, t('profile.password.inputPassword.error.noSpecial'));

        if (passwordValue !== passwordRepeatValue) {
            formErrorAnimation();
            inputPassword.errorChange(true);

            return inputPasswordRepeat.errorChange(true, 'profile.password.inputPasswordRepeat.error.notMatch');
        }

        changePasswordMutation({
            variables: {
                input: {
                    oldPassword: '',
                    newPassword: passwordValue
                }
            }
        })
            .then(async ({ data }) => {
                const changePassword = data?.changePassword;

                if (changePassword?.__typename === 'ChangePasswordSuccess') {
                    await meQuery.refetch();
                } else {
                    const { __typename, errorMessage = '' } = changePassword ?? {};

                    inputPassword.errorChange(
                        true,
                        t([`profile.password.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    React.useEffect(() => {
        if (isAuth && me && hasPassword !== true) {
            addPasswordModal.setOpen(true);
        } else {
            addPasswordModal.setOpen(false);
        }
    }, [addPasswordModal, hasPassword, isAuth, me]);

    return (
        <Dialog {...addPasswordModal} fullScreen={isMobileOrLess} onUnmount={handleUnmount}>
            <DialogHeader
                title={t('profile.password.title', { context: 'add' })}
                description={t('profile.password.description', { context: 'add' })}
                hideCloseButton
            />
            <DialogBody>
                <form
                    id={formId}
                    ref={formRef}
                    onSubmit={handleSubmit}
                    style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }}
                >
                    <div style={{ marginBottom: '1rem' }}>
                        <TextFieldPassword
                            label={t('profile.password.inputPassword.label')}
                            placeholder={t('profile.password.inputPassword.placeholder')}
                            value={inputPassword.value}
                            isError={inputPassword.error}
                            helperText={inputPassword.helperText}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                inputPasswordRepeat.errorChange(false);
                                inputPassword?.change(validateInputValue(e, e.target.value));
                            }}
                            validatePassword={getPasswordValidate(inputPassword.value)}
                            autoComplete="new-password"
                        />
                    </div>
                    <TextFieldPassword
                        label={t('profile.password.inputPasswordRepeat.label')}
                        placeholder={t('profile.password.inputPasswordRepeat.placeholder')}
                        value={inputPasswordRepeat.value}
                        isError={inputPasswordRepeat.error}
                        helperText={inputPasswordRepeat.helperText}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            inputPassword.errorChange(false);
                            inputPasswordRepeat?.change(validateInputValue(e, e.target.value));
                        }}
                        autoComplete="new-password"
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={handleLogout}
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('profile.buttonLogout')}
                </Button>
                <Button
                    type="submit"
                    form={formId}
                    loading={loadingSignup}
                    disabled={
                        !inputPassword.value ||
                        !inputPasswordRepeat.value ||
                        inputPassword.value.length !== inputPasswordRepeat.value.length ||
                        inputPassword.error
                    }
                    size="large"
                    fullWidth
                >
                    {t('profile.buttonSave')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
