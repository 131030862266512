import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { StoreNode, useMeQuery, useUpdateStoreMutation } from 'apollo/generated';
import i18n from 'i18n';
import { setCheckedMark } from 'helpers/dropdownImprovements/dropdownImprovements';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useDropdown from 'hooks/useDropdown/useDropdown';
import { HexagonFilledIcon, PlusIcon, ShopIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import Dropdown from 'ui/Dropdown/Dropdown';
import { DropdownOption } from 'ui/Dropdown/Dropdown.d';
import StoreCreateModal from '../StoreCreateModal/StoreCreateModal';
import StoreSettingsModal from '../StoreSettingsModal/StoreSettingsModal';
import { HeaderStoreProps } from './HeaderStore.d';
import styles from './HeaderStore.module.scss';

const getLabel = (name?: string, isTestMode?: boolean, showShopIcon: boolean = true) =>
    (
        <span className={styles.Label}>
            <span className={styles.LabelIconWrapper}>
                <span className={cn(styles.LabelIndicator, isTestMode && styles.LabelIndicatorTest)} />
                {showShopIcon && <ShopIcon className={styles.LabelIcon} />}
            </span>
            {name}
        </span>
    ) || i18n.t('store.buttonCreate');

const getOptions = (stores?: (Pick<StoreNode, 'id' | 'name' | 'isTestMode'> | null)[] | null, id?: string) =>
    setCheckedMark(
        stores?.map((item): DropdownOption => {
            const { id: storeId, name: storeName } = item ?? {};

            return { label: storeName, value: storeId ?? '', isSelected: Boolean(storeId && storeId === id) };
        }) || [],
        id
    );

export default function HeaderStore({ classes, showShopIcon, ControllerProps }: HeaderStoreProps) {
    const { t } = useTranslation();
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const dropdown = useDropdown();

    const createModal = useDialog();
    const settingsModal = useDialog();

    const { root } = classes ?? {};

    const meQuery = useMeQuery();
    const { defaultStore, stores } = meQuery.data?.me ?? {};
    const { id, name, created, isTestMode } = defaultStore ?? {};

    const [updateStoreMutation] = useUpdateStoreMutation();

    const handleChange = (storeId: string) => {
        if (storeId) {
            updateStoreMutation({
                variables: {
                    input: {
                        storeId,
                        isDefault: true
                    }
                }
            })
                .then(({ data }) => {
                    const updateStore = data?.updateStore;

                    if (updateStore?.__typename === 'UpdateStoreSuccess') {
                        const { name: nextName } = updateStore?.me?.defaultStore ?? {};

                        toast.success(t('store.settings.alertChangeSuccess', { name: nextName }));
                    } else {
                        const { __typename, errorMessage = '' } = updateStore ?? {};

                        onGlobalError(t([`store.settings.error.${__typename}`, errorMessage]));
                    }
                })
                .catch((err) => onGlobalError(getErrorData(err).message));
        } else {
            onGlobalError(t('store.settings.error.StoreNotFoundError'));
        }
    };

    const handleCreate = () => {
        dropdown.setOpen(false);
        createModal.setOpen(true);
    };

    const handleSettingsClick = () => {
        settingsModal.context.dataRef.current = { id, name, created, isTestMode };
        settingsModal.setOpen(true);
    };

    return (
        <>
            <div className={cn(styles.Root, root)}>
                <Dropdown
                    classes={{
                        controller: cn(styles.Controller, !isTabletOrLess && styles.ControllerGroupLeft),
                        dropdown: styles.Dropdown
                    }}
                    open={dropdown.open}
                    onOpenChange={dropdown.setOpen}
                    options={getOptions(stores, id)}
                    onChange={handleChange}
                    ControllerProps={{
                        isDark: true,
                        variant: 'outlined',
                        color: 'secondary',
                        fullWidth: true,
                        ...ControllerProps
                    }}
                    controllerLabel={getLabel(name, isTestMode, showShopIcon)}
                    noOptionText={t('store.noStores')}
                    placement={isTabletOrLess ? 'auto' : 'bottom-start'}
                    footer={
                        <Button
                            classes={{ root: styles.CreateButton }}
                            variant="text"
                            size="large"
                            onClick={handleCreate}
                            tabIndex={0}
                            fullWidth
                        >
                            <PlusIcon />
                            <span>{t('store.buttonCreate')}</span>
                        </Button>
                    }
                />

                {!isTabletOrLess && (
                    <Button
                        classes={{ root: styles.SettingsButton }}
                        iconStart={<HexagonFilledIcon />}
                        isDark
                        color="secondary"
                        variant="outlined"
                        onClick={handleSettingsClick}
                    />
                )}
            </div>

            <StoreCreateModal {...createModal} stores={stores} />
            <StoreSettingsModal {...settingsModal} />
        </>
    );
}
