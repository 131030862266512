import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckPasswordMutation } from 'apollo/generated';
import getErrorData from 'helpers/getErrorData/getErrorData';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import TextFieldPassword from 'ui/TextFieldPassword/TextFieldPassword';
import { ProfilePasswordConfirmProps } from './ProfilePasswordConfirm.d';

export default function ProfilePasswordConfirm({
    onBackButtonClick,
    onSuccess,
    password
}: ProfilePasswordConfirmProps) {
    const { t } = useTranslation();
    const onGlobalError = useGlobalError();

    const formId = React.useId();

    const [checkPasswordMutation, { loading }] = useCheckPasswordMutation();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!password.value) {
            return password.errorChange(true, t('profile.passwordVerification.error.requiredFields'));
        }

        checkPasswordMutation({ variables: { input: { password: password.value } } })
            .then(({ data }) => {
                const checkPassword = data?.checkPassword;

                if (checkPassword?.__typename === 'CheckPasswordSuccess') {
                    onSuccess();
                } else {
                    const { __typename, errorMessage = '' } = checkPassword ?? {};

                    password.errorChange(
                        true,
                        t([`profile.passwordVerification.error.${__typename}`, errorMessage, 'global.error.tryLater'])
                    );
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));

        return undefined;
    };

    return (
        <>
            <DialogHeader
                title={t('profile.passwordVerification.title')}
                description={t('profile.passwordVerification.description')}
                onBackButtonClick={onBackButtonClick}
            />
            <DialogBody>
                <form id={formId} onSubmit={handleSubmit}>
                    <TextFieldPassword
                        value={password.value}
                        isError={password.error}
                        helperText={password.helperText}
                        label={t('profile.passwordVerification.password')}
                        onChange={(e) => password.change(e.target.value)}
                    />
                </form>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    form={formId}
                    loading={loading}
                    disabled={!password.value || password.error}
                    size="large"
                    fullWidth
                >
                    {t('profile.buttonContinue')}
                </Button>
            </DialogFooter>
        </>
    );
}
