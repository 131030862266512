import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderStatus, usePaymentOrderQuery, usePaymentOrderWsSubscription } from 'apollo/generated';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { DEFAULT_BLOCKCHAIN_NAME } from 'helpers/getBlockchainData/getBlockchainData';
import { createTransactionLink } from 'helpers/blockchainScan/blockchainScan';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { CrossFilledCircledIcon, RefreshIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import PaymentTestBar from '../PaymentTestBar/PaymentTestBar';
import PaymentAmountDetails from '../PaymentAmountDetails/PaymentAmountDetails';
import PaymentOrderParams from '../PaymentOrderParams/PaymentOrderParams';
import PaymentInProgress from '../PaymentInProgress/PaymentInProgress';
import PaymentStatus from '../PaymentStatus/PaymentStatus';
import PaymentHowToPayModal from '../PaymentHowToPayModal/PaymentHowToPayModal';
import { PaymentProps } from './Payment.d';
import styles from './Payment.module.scss';

interface ErrorProps {
    onRefetchClick?: () => void;
}

function Error({ onRefetchClick }: ErrorProps) {
    const { t } = useTranslation();

    return (
        <div className={styles.Error}>
            <div className={styles.ErrorContainer}>
                <CrossFilledCircledIcon className={styles.ErrorIcon} />
                <div className={styles.ErrorText}>{t('payment.order.error.loadingFailed')}</div>
                <Button
                    type="button"
                    classes={{ root: styles.ErrorButton }}
                    onClick={onRefetchClick}
                    iconStart={<RefreshIcon />}
                    color="secondary"
                    size="large"
                >
                    {t('payment.order.button.refresh')}
                </Button>
            </div>
        </div>
    );
}

export default function Payment({ id = '' }: PaymentProps) {
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    const modalHowTo = useDialog();

    const [showTestBar, setShowTestBar] = React.useState(!isLandscapeOrLess);

    const { data, loading, error, refetch } = usePaymentOrderQuery({
        variables: { id },
        context: { headers: { Authorization: '' } }
    });

    const {
        amount: orderAmount,
        currency,
        expirationDate,
        id: orderId,
        isTest,
        paymentParams,
        paymentTransfer,
        paymentOptions,
        sequentNumber,
        status,
        store
    } = data?.order ?? {};

    const { name: currencyName, blockchain } = currency ?? {};
    const { name: storeName } = store ?? {};
    const { amount, memo, metaInfo, receiver, deeplinks: deepLinks } = paymentParams ?? {};
    const { action } = paymentTransfer ?? {};
    const { txid: txId } = action ?? {};
    const { send, fee, receive } = orderAmount ?? {};
    const isPaid = status === OrderStatus.Paid || status === OrderStatus.MoneyReceived;
    const isCancelled = status === OrderStatus.Cancelled;
    const isExpired = status === OrderStatus.Expired;
    const isProcessing = status === OrderStatus.Frozen;
    const isFinal = isPaid || isCancelled || isExpired || isProcessing;

    const { domainName, trxLink } = createTransactionLink(txId, blockchain || DEFAULT_BLOCKCHAIN_NAME);

    const eosUrl = txId ? trxLink || '' : '';

    usePaymentOrderWsSubscription({ variables: { orderId: id }, context: { headers: { Authorization: '' } } });

    const handleRefetch = () => refetch({ id });

    if (loading) {
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );
    }

    if (error) return <Error onRefetchClick={handleRefetch} />;

    if (isFinal) {
        return (
            <PaymentStatus
                amount={amount ?? `${addThousandsSeparator(send)} ${currencyName}`}
                currency={currencyName}
                eosUrl={eosUrl}
                domain={domainName}
                isCancelled={isCancelled}
                isExpired={isExpired}
                isPaid={isPaid}
                isProcessing={isProcessing}
                orderId={orderId /* || order?.paymentTransfer?.id */}
            >
                <PaymentOrderParams name={storeName} number={sequentNumber} showContent={false} time={expirationDate} />
            </PaymentStatus>
        );
    }

    return (
        <>
            <main className={styles.Component}>
                <PaymentInProgress
                    amount={amount}
                    AmountDetails={
                        <PaymentAmountDetails currency={currencyName} fee={fee} receive={receive} send={send} />
                    }
                    currency={currencyName}
                    deepLinks={deepLinks}
                    id={id}
                    isFinal={isFinal}
                    isTest={isTest || false}
                    memo={memo || ''}
                    metaInfo={isTest ? 'Test qr' : metaInfo || ''}
                    paymentOptions={paymentOptions}
                    OrderParams={
                        <PaymentOrderParams
                            name={storeName || ''}
                            number={sequentNumber || 0}
                            time={expirationDate}
                            status={status}
                            showContent
                        />
                    }
                    onDevPanelOpen={() => setShowTestBar(true)}
                    receiver={receiver || ''}
                />
            </main>

            {isTest && (
                <PaymentTestBar
                    id={orderId}
                    isOpen={showTestBar}
                    close={() => setShowTestBar(false)}
                    open={() => setShowTestBar(true)}
                />
            )}

            <PaymentHowToPayModal {...modalHowTo} />
        </>
    );
}
