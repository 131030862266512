import React from 'react';
import cn from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import { OSDetection } from 'utils/featuresDetection/featuresDetection';
import PaycashLogo from 'assets/images/app-paycash.svg';
import MalinkaLogo from 'assets/images/app-malinka.svg';
import KalynaLogo from 'assets/images/app-kalyna.svg';
import HowToPay1 from 'assets/images/how-to-pay-1.png';
import HowToPay2 from 'assets/images/how-to-pay-2.png';
import HowToPay3 from 'assets/images/how-to-pay-3.png';
import HowToPay4 from 'assets/images/how-to-pay-4.png';
import HowToPay5 from 'assets/images/how-to-pay-5.png';
import HowToPay6 from 'assets/images/how-to-pay-6.png';
import HowToPay7 from 'assets/images/how-to-pay-7.png';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { ChevronLeftIcon, CrossIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { GuideItemProps } from './PaymentHowToPay.d';
import styles from './PaymentModalHowToPay.module.scss';

const LINKS = [
    {
        name: 'PayCash',
        link: {
            ios: 'https://apps.apple.com/app/paycash/id1557721025',
            android: 'https://play.google.com/store/apps/details?id=online.paycash.app'
        },
        image: { src: PaycashLogo, alt: 'PayCash' }
    },
    {
        name: 'Malinka',
        link: {
            ios: 'https://apps.apple.com/ru/app/malinka/id1635109869',
            android: 'https://play.google.com/store/apps/details?id=life.malinka.wallet'
        },
        image: { src: MalinkaLogo, alt: 'Malinka' }
    },
    {
        name: 'Kalyna',
        link: {
            ios: 'https://apps.apple.com/us/app/kalyna/id1635944949',
            android: 'https://play.google.com/store/apps/details?id=app.kalyna'
        },
        image: { src: KalynaLogo, alt: 'Kalyna' }
    }
];

function Step({ index, title, active, imageUrl, children }: GuideItemProps) {
    return (
        <div className={cn(styles.Step, active && styles.active)}>
            <div
                className={styles.StepImg}
                style={{
                    backgroundImage: `url(${imageUrl})`
                }}
            />
            <div className={styles.StepInfo}>
                <span className={styles.StepIndex}>{index}</span>
                <h3 className={styles.StepTitle}>{title}</h3>
                {children}
            </div>
        </div>
    );
}

export default function PaymentHowToPay({ onClose }: any) {
    const os = OSDetection();
    const { t } = useTranslation();
    const isLandscapeOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const linkOs = os === 'MacOS' || os === 'iOS' ? 'ios' : 'android';

    const [activeSlide, setActiveSlide] = React.useState(0);

    const handlers = useSwipeable({
        onSwipedRight: () => setActiveSlide(activeSlide === 0 ? 0 : activeSlide - 1),
        onSwipedLeft: () => setActiveSlide(activeSlide === 6 ? 6 : activeSlide + 1)
    });

    return (
        <>
            <header className={styles.Header}>
                <h2>{t('payment.howToPay.title')}</h2>
                {isLandscapeOrLess && (
                    <Button
                        variant="text"
                        color="gray"
                        isRound
                        iconStart={<CrossIcon fontSize="1rem" />}
                        onClick={onClose}
                        classes={{ root: styles.HeaderCloseBtn }}
                    />
                )}
                {!isLandscapeOrLess && (
                    <Button
                        color="secondary"
                        variant="outlined"
                        iconStart={<ChevronLeftIcon />}
                        onClick={onClose}
                        classes={{ root: styles.HeaderBackBtn }}
                    >
                        {t('payment.howToPay.goBack')}
                    </Button>
                )}
            </header>

            <div {...(isLandscapeOrLess ? handlers : {})} className={styles.Steps}>
                <Step
                    title={t('payment.howToPay.downloadStep.title')}
                    imageUrl={HowToPay1}
                    index={1}
                    active={activeSlide === 0}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.downloadStep.description')}</span>
                    <div className={styles.AppLinks}>
                        {LINKS.map((btn) => (
                            <Button
                                as="a"
                                href={btn.link[linkOs]}
                                target="_blank"
                                rel="noreferrer"
                                color="secondary"
                                size="large"
                                iconStart={<img src={btn.image.src} alt={btn.image.alt} />}
                            >
                                {btn.name}
                            </Button>
                        ))}
                    </div>
                </Step>
                <Step
                    title={t('payment.howToPay.createAccountStep.title')}
                    imageUrl={HowToPay2}
                    index={2}
                    active={activeSlide === 1}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.createAccountStep.description')}</span>
                    <ol className={cn(styles.StepText, styles.StepList)}>
                        <li>{t('payment.howToPay.createAccountStep.step', { context: 1 })}</li>
                        <li>{t('payment.howToPay.createAccountStep.step', { context: 2 })}</li>
                        <li>{t('payment.howToPay.createAccountStep.step', { context: 3 })}</li>
                    </ol>
                </Step>
                <Step
                    title={t('payment.howToPay.topUpStep.title')}
                    imageUrl={HowToPay3}
                    index={3}
                    active={activeSlide === 2}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.topUpStep.description')}</span>
                    <div className={styles.StepBtnContainer}>
                        <Button color="gray" classes={{ root: styles.StepBtn }}>
                            {t('payment.howToPay.topUpStep.button', { context: 1 })}
                        </Button>
                        <Button color="gray">{t('payment.howToPay.topUpStep.button', { context: 2 })}</Button>
                    </div>
                </Step>
                <Step
                    title={t('payment.howToPay.openQrScanStep.title')}
                    imageUrl={HowToPay4}
                    index={4}
                    active={activeSlide === 3}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.openQrScanStep.description')}</span>
                </Step>
                <Step
                    title={t('payment.howToPay.scanQrStep.title')}
                    imageUrl={HowToPay5}
                    index={5}
                    active={activeSlide === 4}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.scanQrStep.description')}</span>
                </Step>
                <Step
                    title={t('payment.howToPay.confirmStep.title')}
                    imageUrl={HowToPay6}
                    index={6}
                    active={activeSlide === 5}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.confirmStep.description')}</span>
                </Step>
                <Step
                    title={t('payment.howToPay.doneStep.title')}
                    imageUrl={HowToPay7}
                    index={7}
                    active={activeSlide === 6}
                >
                    <span className={styles.StepText}>{t('payment.howToPay.doneStep.description')}</span>
                </Step>
            </div>

            {isLandscapeOrLess && (
                <ul className={styles.Dots}>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 1 })}
                            type="button"
                            className={cn(activeSlide === 0 && styles.active)}
                            onClick={() => setActiveSlide(0)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 2 })}
                            type="button"
                            className={cn(activeSlide === 1 && styles.active)}
                            onClick={() => setActiveSlide(1)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 3 })}
                            type="button"
                            className={cn(activeSlide === 2 && styles.active)}
                            onClick={() => setActiveSlide(2)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 4 })}
                            type="button"
                            className={cn(activeSlide === 3 && styles.active)}
                            onClick={() => setActiveSlide(3)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 5 })}
                            type="button"
                            className={cn(activeSlide === 4 && styles.active)}
                            onClick={() => setActiveSlide(4)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 6 })}
                            type="button"
                            className={cn(activeSlide === 5 && styles.active)}
                            onClick={() => setActiveSlide(5)}
                        />
                    </li>
                    <li>
                        <button
                            aria-label={t('payment.howToPay.step', { context: 7 })}
                            type="button"
                            className={cn(activeSlide === 6 && styles.active)}
                            onClick={() => setActiveSlide(6)}
                        />
                    </li>
                </ul>
            )}
        </>
    );
}
