import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './ru';
import en from './en';

export const LOCAL_STORAGE_KEY_LANGUAGE = 'language';

export const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    }
} as const;

export type Language = keyof typeof resources;
/** Languages id */
const LANGUAGES = Object.keys(resources) as Language[];
/** Default language */
export const DEFAULT_CLIENT_LANGUAGE: Language = 'ru';

/** Try to get the most appropriate language  */
export function getDefaultLng() {
    let language: string = [
        window.localStorage.getItem(LOCAL_STORAGE_KEY_LANGUAGE),
        /** IE */
        navigator.userLanguage,
        navigator.language
    ]
        .filter(Boolean)[0]
        ?.slice(0, 2)
        .toLowerCase();

    if (!LANGUAGES.includes(language as any)) {
        language = DEFAULT_CLIENT_LANGUAGE;
    }

    return language;
}

i18n
    /** Passes i18n down to react-i18next */
    .use(initReactI18next)
    .init({
        /** The translations */
        resources,
        lng: getDefaultLng(),
        interpolation: {
            /** React already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape */
            escapeValue: false
        }
    })
    .then((t) => t('key'));

export default i18n;

/**
 * Outside access example.
 *
 * import i18n from 'i18n';
 * i18n.t('my.key');
 */

/**
 * Overriding the i18next instance example.
 *
 * import i18n from './i18n';
 * const { t, i18n } = useTranslation('ns1', { i18n });
 */
