export default {
    init: {
        title: 'Profile',
        email: 'Email',
        phone: 'Phone',
        buttonAddEmail: 'Add email',
        buttonAddPhone: 'Add phone',
        buttonChangePassword: 'Change password',
        buttonEdit: 'Edit'
    },
    passwordVerification: {
        title: 'Confirmation',
        description: 'You must enter a password to continue',
        password: 'Password',
        error: {
            requiredFields: 'Enter password',
            InvalidCredentialsError: 'Password invalid'
        }
    },
    email: {
        title_add: 'Add email',
        title_edit: 'Edit email',
        description_add: 'To link your email, you will be sent a confirmation code',
        description_edit: 'Enter new email',
        description_code: 'For confirmation, a confirmation code has been sent to your email <0>{{account}}</0>',
        inputLabel: 'Email',
        error: {
            requiredFields: 'Enter your email',
            emailInvalid: 'Invalid email format',
            InvalidCredentialsError: 'Invalid password',
            InvalidEmailError: 'Invalid email',
            InvalidPasswordError: 'Invalid password',
            InvalidPhoneError: 'Invalid phone number',
            UserAlreadyExistsError: 'This email is already in use'
        }
    },
    phone: {
        title_add: 'Add number',
        title_edit: 'Edit number',
        description_add: 'To link your phone number, you will be sent a confirmation code',
        description_edit: 'Enter a new phone number',
        description_code: 'For confirmation, a confirmation code has been sent to your phone number <0>{{account}}</0>',
        inputLabel: 'Phone number',
        error: {
            requiredFields: 'Enter your phone number',
            InvalidCredentialsError: 'Invalid password',
            InvalidEmailError: 'Invalid email',
            InvalidPasswordError: 'Invalid password',
            InvalidPhoneError: 'Invalid phone number',
            UserAlreadyExistsError: 'This phone number is already in use'
        }
    },
    password: {
        title_add: 'Add a password',
        title_edit: 'Change password',
        description_add: 'To use all site features, add a password',
        alertSuccess: 'Password changed successfully',
        inputPasswordOld: {
            label: 'Old password',
            placeholder: 'Enter old password',
            error: {
                requiredFields: 'Enter old password'
            }
        },
        inputPassword: {
            label: 'New password',
            placeholder: 'Enter new password',
            error: {
                shortLength: 'Password length must be greater than 8 characters',
                noUppercase: 'Password must contain at least one uppercase letter',
                noNumber: 'Password must contain at least one number',
                noSpecial: 'The password must contain at least one special character (!@#$%^&*—_+=;:,./?|`~{}[])'
            }
        },
        inputPasswordRepeat: {
            label: 'New password again',
            placeholder: 'Repeat new password',
            error: {
                notMatch: 'The entered passwords do not match'
            }
        },
        error: {
            requiredFields: 'To continue, enter a new password and repeat it',
            InvalidCredentialsError: 'Invalid password',
            InvalidPasswordError: 'Invalid password'
        }
    },
    buttonContinue: 'Continue',
    buttonLogout: 'Logout of account',
    buttonSave: 'Save'
};
