export default {
    title: 'Перевод средств',
    title_internal: 'Внутренний перевод средств',
    title_withdraw: 'Вывод средств',
    token: 'Выберите токен и способ перевода',
    token_internal: 'Токен',
    token_withdraw: 'Выберите токен и способ вывода',
    currency: 'Выберите валюту',
    method: 'Способ вывода',
    fiatWorld: 'Международный банк',
    fiatRus: 'Российский банк',
    amount: 'Сумма перевода',
    amount_withdraw: 'Сумма вывода',
    amountMin: 'Минимальная сумма',
    amountMax: 'Максимальная сумма',
    inputAmount: {
        label: 'Будет списано',
        labelEndContent: 'Максимум',
        placeholder: 'Сумма списания'
    },
    inputAmountTotal: {
        label: 'Будет зачислено',
        placeholder: 'Вы получите'
    },
    inputAccount: {
        label: 'Аккаунт',
        placeholder: 'Введите {{blockchain}} аккаунт',
        placeholder_internal: 'Введите аккаунт получателя',
        helperText_internal: 'Аккаунт получателя - почта, номер телефона, EOS кошелек или ID магазина'
    },
    inputMemo: {
        label: 'MEMO',
        labelEndContent: 'Не обязательно',
        placeholder: 'Введите MEMO'
    },
    inputCard: {
        label: 'Номер карты',
        placeholder: 'Введите номер карты'
    },
    fee: 'Комиссия',
    feePaySystem: 'Комиссия платежной системы',
    confirm: {
        description: 'Для подтверждения перевода средств\nнеобходимо ввести пароль от аккаунта',
        address: 'Адрес кошелька',
        address_internal: 'Аккаунт получателя',
        send: 'Будет списано',
        receive: 'Вы получите',
        receive_internal: 'Будет зачислено',
        memo: 'MEMO'
    },
    cancel: {
        title: 'Отмена',
        description: 'Вы уверены, что хотите прекратить процесс перевода средств?',
        description_withdraw: 'Вы уверены, что хотите прекратить процесс вывода средств'
    },
    success: {
        title: 'Готово',
        description_withdraw: 'Вы можете проверить статус вывода в разделе транзакций, а так же на сайте {{domainName}}'
    },
    alert: {
        success: 'Перевод средств был успешно осуществлен.',
        success_withdraw: 'Вывод средств был успешно осуществлен.'
    },
    error: {
        balanceNotFound: 'Баланс не найден',
        cardInvalid: 'Не верный номер карты',
        amountRequired: 'Вы не указали сумму вывода',
        accountRequired: 'Вы не указали аккаунт',
        cardRequired: 'Вы не указали номер карты',
        ConfirmationFailedError: 'Неверный пароль',
        EosAccountNotExistsError: 'Аккаунт не существует',
        ForbiddenWithdrawalReceiverError: 'Вывод этому получателю запрещен',
        InvalidParamsError: 'Параметры не валидны ({{paramName}})',
        InsufficientFundsError: 'Недостаточно средств на балансе',
        CurrencyNotFoundError: 'Валюта не найдена',
        WithdrawalAmountTooLowError: 'Сумма меньше минимальной. (Минимум {{minimalAmount}})',
        WithdrawalAmountTooHighError: 'Превышена максимальная сумма. (Максимум {{maximalAmount}})',
        WithdrawLimitExceededError: 'Превышен лимит на вывод',
        ExchangePairNotFoundError: 'Обменная пара не найдена',
        FiatMethodNotFoundError: 'Метод пополнения не найден',
        FiatWithdrawalsNotAllowedError: 'Вывод фиатных средств запрещен',
        FiatTransferNotPossibleError: 'Данная карта не поддерживается выбранным методом перевода',
        FiatTransferNotPossibleError_withdraw: 'Данная карта не поддерживается выбранным методом вывода',
        WithdrawDuplicationError:
            'Транзакция с этими параметрами уже в обработке, дождитесь её выполнения или выполните повторную транзакцию позже'
    },
    buttonContinue: 'Продолжить',
    buttonTransfer: 'Перевести',
    buttonWithdraw: 'Вывести',
    buttonBack: 'Назад',
    buttonDone: 'Готово',
    buttonYes: 'Да',
    buttonNo: 'Нет',
    buttonBlockchain: 'Проверить на сайте {{domainName}}'
};
