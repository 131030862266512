import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEY_LANGUAGE } from 'i18n';
import useDropdown from 'hooks/useDropdown/useDropdown';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import Dropdown from 'ui/Dropdown/Dropdown';
import { LanguageSwitcherProps } from './LangaugeSwitcher.d';

const options = [
    { code: 'ru', label: 'Русский' },
    { code: 'en', label: 'English' }
];

export default function LanguageSwitcher({ ControllerProps }: LanguageSwitcherProps) {
    const { i18n } = useTranslation();
    const isTouchableDevice = useIsTouchableDevice();
    const dropdown = useDropdown();

    const onLanguageChange = async (code: string) => {
        await i18n.changeLanguage(code);
        window.localStorage.setItem(LOCAL_STORAGE_KEY_LANGUAGE, code);
    };

    const currentLng = options.find((lng) => lng.code === i18n.language);

    return (
        <Dropdown
            controllerLabel={currentLng?.code.toUpperCase()}
            options={options.map(({ label, code }) => ({
                label,
                value: code,
                isSelected: currentLng?.code === code
            }))}
            open={dropdown.open}
            onChange={onLanguageChange}
            onOpenChange={dropdown.setOpen}
            placement="bottom"
            isModal={isTouchableDevice}
            ControllerProps={ControllerProps}
        />
    );
}
